import Container from "../components/containers/Container";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import Tabs from "../components/common/Tabs";
import AdvancedSettings from "../components/advanced/AdvancedSettings";
import Additional from "../components/advanced/additional/Additional";
import DialPlan from "../components/advanced/dialplan/DialPlan";
import AppLayout from "../components/layout/AppLayout";
import {  useParams } from "react-router-dom";
import { getProjectById, getProjectByName } from "../redux/actions/projectActions";
import Codecs from "../components/advanced/Codecs";
import PhoneBook from "../components/advanced/PhoneBook";
import BadgeTitle from "../components/titles/BadgeTitle";
import { updateProject } from "../redux/actions/projectActions";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";
import { clearErrors } from "../redux/actions/errorActions";  

export default function Advanced() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("main");
  const { accountName } = useParams();
  const { tabId } = useParams();

  const dispatch = useDispatch();
 
  const { project,  error,success } = useSelector(
    (state) => state.getProject
  );

  const {  updateSuccess, updateError } = useSelector(
    (state) => state.updateProject
  );

  const [projectData, setProjectData] = useState({ ...project });

  const mainSelected = tabId === "main" ? true : false;
  const codecsSelected = tabId === "codecs" ? true : false;
  const additionalSelected = tabId === "additional" ? true : false;
  const dialplanSelected = tabId === "dialplan" ? true : false;
  const contactsSelected = tabId === "contacts" ? true : false;

  const tabs = [
    {
      id: "main",
      name: "Main",
      href: `main`,
      current: mainSelected,
    },
    {
      id: "codecs",
      name: "Codecs",
      href: `codecs`,
      current: codecsSelected,
    },
    {
      id: "dialplan",
      name: "Dial Plan",
      href: `dialplan`,
      current: dialplanSelected,
    },

    {
      id: "contacts",
      name: "Contacts",
      href: "contacts",
      current: contactsSelected,
    },
    {
      id: "additional",
      name: "Additional",
      href: `additional`,
      current: additionalSelected,
    },
  ];

  useEffect(() => {
    if ((project == undefined || project == null)) {
      dispatch(getProjectByName(accountName));
      
    }

    if(project != undefined && project != null){
        console.log("Updating projectData");
        setProjectData(project);
    }
    setActiveTab(() => tabId);

    if(error){
      if( error.code === 209) {
        dispatch(openErrorAlert("Session Expired","Please login again to continue"));
        // navigate to login  
         navigate('/login');
      } else {
     
      dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }
  }, [success, error, project]);

  useEffect(() => { 
    if (updateError ) {
     
      if(updateError.code === 209) {
        // navigate to login 
        dispatch(openErrorAlert("Session Expired","Please login again to continue"));
        navigate('/login');
      } else {
        dispatch(openErrorAlert("Error","Project failed to save. Please try again"));
      }
      return;
    }
    
    if (updateSuccess) {
     
      dispatch(openStatusAlert("Success","Project saved successfully"));
      //navigate(`/provisioning/${accountName}`)
      dispatch(clearErrors())
      dispatch(getProjectByName(accountName));
    }

  }, [updateSuccess, updateError]);

  const handleOnUnnestedChange = (e) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
    console.log(projectData);
  };

  const handlePushChange = useCallback((server) => {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        provisioning: {
          ...prevProjectData.settings.provisioning,
          resolvedpush: server.domain,
        },
      },
    }));
  }, []); // Empty dependency array means this function is created only once

  const handleOnChange = (e) => {
    let value;
    if (e.target.checked === true && e.target.id === "checkbox") {
      value = "Enabled";
    } else if (e.target.checked === false && e.target.id === "checkbox") {
      value = "Disabled";
    } else {
      value = e.target.value;
    }
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        provisioning: {
          ...prevProjectData.settings.provisioning,
          [e.target.name]: value,
        },
      },
    }));
    console.log("projectData ");
    console.log(projectData);
  };

  const handleAddItem = (property, itemObject) => {
  
    let nameProperty = ""

    if(property === "ruleslist"){
      nameProperty = "Name"
    }else{
      nameProperty = "name"
    }


    if(itemObject[nameProperty] === "" || itemObject[nameProperty] === undefined || !itemObject[nameProperty]?.trim().length > 0 || itemObject[nameProperty] === null){ 
      dispatch(openErrorAlert("Incomplete Dial Rule","Please enter a valid name"));
      return;
    }

    

    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        provisioning: {
          ...prevProjectData.settings.provisioning,
          [property]: [...prevProjectData.settings.provisioning[property], itemObject],
        },
      },
    }));
    

    if(property === "ruleslist"){
      var cloned = JSON.parse(JSON.stringify(projectData))
      cloned.settings.provisioning.ruleslist.push(itemObject)
      dispatch(updateProject(cloned));
    }
  };

  const handleRemoveItem = (property, itemObject) => {
   

    let nameProperty = ""

    if(property === "ruleslist"){
      nameProperty = "Name"
    }else{
      nameProperty = "name"
    }

   
  
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        provisioning: {
          ...prevProjectData.settings.provisioning,
          [property]: prevProjectData.settings.provisioning[property].filter(
            (item) => item[`${nameProperty}`] !== itemObject[`${nameProperty}`]
            ),
        },
      },
    }));

    if(property === "ruleslist"){
      var cloned = JSON.parse(JSON.stringify(projectData))
      let index = cloned.settings.provisioning.ruleslist.findIndex(
        (item) => item[`${nameProperty}`] === itemObject[`${nameProperty}`]
      );

      cloned.settings.provisioning.ruleslist.splice(index, 1)
      dispatch(updateProject(cloned));
    }
  };

  const projectDisabled = projectData?.status ? (projectData?.status.approval.status === "Prepare" || projectData?.status.approval.status === "Update" ? false :  true) : true 

  const handleSave = (e) => {
   
    dispatch(updateProject(projectData));
  };

 function handleEditRule(ruleObject){

  if (projectData.settings.provisioning.ruleslist === undefined) {
    return;
  }

  if(ruleObject?.Name === "" || ruleObject?.Name === undefined || ruleObject?.Name?.trim().length > 0 || ruleObject?.Name === null){ 
    dispatch(openErrorAlert("Incomplete Dial Rule","Please enter a valid name"));
    return;
  }

  setProjectData((prevProjectData) => ({
    ...prevProjectData,
    settings: {
      ...prevProjectData.settings,
      provisioning: {
        ...prevProjectData.settings.provisioning,
        ruleslist: [
          ...prevProjectData.settings.provisioning.ruleslist,
          {
            ...ruleObject,
          },
        ],
      },
    },
  }));
 

    var cloned = JSON.parse(JSON.stringify(projectData))
    cloned.settings.provisioning.ruleslist.push(ruleObject)
   
    dispatch(updateProject(cloned));
  
 }

  return (
    <>
      <AppLayout project={project} type={project?.type ? project?.type : ""}/>
      <div>
        <Container>
          <BadgeTitle
            title={`Advanced`}
            badge={projectData?.status ? projectData?.status.approval.status : ""}
            projectName={`${projectData?.accountName ? projectData?.accountName : ""}`}
          />
          <Tabs tabs={tabs} />
          {activeTab === "main" ? (
            <AdvancedSettings
            projectData={projectData}
              handleOnChange={handleOnChange}
              handlePushChange={handlePushChange}
              projectDisabled={projectDisabled}
            />
          ) : (
            <></>
          )}
          {activeTab === "codecs" ? (
            <Codecs
            projectData={projectData}
              handleAddCodec={handleAddItem}
              handleRemoveCodec={handleRemoveItem}
              projectDisabled={projectDisabled}
              handleSave={handleSave}
            />
          ) : (
            <></>
          )}
          {activeTab === "dialplan" ? (
            <DialPlan projectData={projectData} onChange={handleOnChange} projectDisabled={projectDisabled}
            handleAddRule={handleAddItem}
            handleSave={handleSave}
              handleRemoveRule={handleRemoveItem}
              handleEditRule={handleEditRule}/>
          ) : (
            <></>
          )}
          {activeTab === "contacts" ? (
            <PhoneBook
              projectData={projectData}
              handleOnChange={handleOnChange}
              projectDisabled={projectDisabled}
              handleSave={handleSave}
            />
          ) : (
            <></>
          )}
          {activeTab === "additional" ? (
            <Additional projectData={projectData} handleOnChange={handleOnChange} projectDisabled={projectDisabled} handleSave={handleSave}/>
          ) : (
            <></>
          )}
        </Container>
      </div>
    </>
  );
}
