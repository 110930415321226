import React, { useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { clearGraphics } from './../../redux/actions/projectActions';
import { useDispatch } from "react-redux";
import { updateProject } from "../../redux/actions/projectActions";


function MobileGraphicsForm({project ,iosGraphicsData, androidGraphicsData, accountName,
  handleiOSOnFileUpload,
  handleAndroidOnFileUpload,
  projectDisabled,
  handleAndroidImageDrop,
  handleiOSImageDrop
  }) {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  
  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event) {
    event.preventDefault();
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function handleSave(e) {
  
        dispatch(updateProject(project));
      
    }

  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Whitelabel Mobile Logo and Icon Settings
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
              Resources such as memory are constrained on a mobile device. Please ensure png graphic assets have been optimised prior to uploading. Ideally all assets should be below 100Kb. Loading large bitmaps into memory can cause the operating system on the mobile device to refuse to load the bitmap and this can result in a poor user experience.
              </p>
              <a href="https://tinypng.com/"
              className="mt-1 text-sm hover:underline text-iceberg-400 cursor-pointer hover:text-iceberg-300">
              https://tinypng.com/
              </a>

              <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Icon 192x192 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon192"
                      disabled={projectDisabled}
                      onDrop={(e) => handleAndroidImageDrop(e, "icon192")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.icon192 ? (
                          <img
                            src={androidGraphicsData?.icon192?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload1"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload1"
                              type="file"
                              className="sr-only"
                              name="icon192"
                              onChange={(e) =>
                                handleAndroidOnFileUpload(e, "icon192")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Icon 180x180 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon180"
                      disabled={projectDisabled}
                      onDrop={(e) => handleiOSImageDrop(e, "icon180")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {iosGraphicsData?.icon180 ? (
                          <img
                            src={iosGraphicsData?.icon180?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload2"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload2"
                              type="file"
                              className="sr-only"
                              name="icon180"
                              onChange={(e) =>
                                handleiOSOnFileUpload(e, "icon180")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Icon 1024x1024 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon1024"
                      disabled={projectDisabled}
                      onDrop={(e) => handleiOSImageDrop(e, "icon1024")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {iosGraphicsData?.icon1024 ? (
                          <img
                            src={iosGraphicsData?.icon1024?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload9"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload9"
                              type="file"
                              className="sr-only"
                              name="icon1024"
                              onChange={(e) =>
                                handleiOSOnFileUpload(e, "icon1024")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Logo 900x576 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="logo900_576"
                      disabled={projectDisabled}
                      onDrop={(e) => handleAndroidImageDrop(e, "logo900_576")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.logo900_576 ? (
                          <img
                            src={androidGraphicsData?.logo900_576?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload3"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload3"
                              type="file"
                              className="sr-only"
                              name="logo900_576"
                              onChange={(e) =>
                                handleAndroidOnFileUpload(e, "logo900_576")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Logo 600x180 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="logo600_180"
                      disabled={projectDisabled}
                      onDrop={(e) => handleiOSImageDrop(e, "logo600_180")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {iosGraphicsData?.logo600_180 ? (
                          <img
                            src={iosGraphicsData?.logo600_180?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload4"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload4"
                              type="file"
                              className="sr-only"
                              name="logo600_180"
                              onChange={(e) =>
                                handleiOSOnFileUpload(e, "logo600_180")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>


                 
                 
                </div>
              </dl>
            </div>

            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
                onClick={(e) => {
                  navigate(`/projects/${accountName}`);
                  dispatch(clearGraphics());
                }}
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleSave}
                disabled={projectDisabled}
                className={classNames(projectDisabled ? ``: `hover:bg-iceberg-300 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`,
                 `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
              >
                Save
              </button>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    </>
  );
}

export default MobileGraphicsForm;
