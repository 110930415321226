import React from 'react'
import CheckEmailForm from '../components/auth/CheckEmailForm'

export default function CheckEmail() {
  return (
    <>
     <div className="flex items-center justify-center min-h-screen py-2">
   <CheckEmailForm email={"example@test.com"}/>
     </div>
    </>
  )
}
