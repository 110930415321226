import React, { useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateProject } from "../../redux/actions/projectActions";
import { clearGraphics } from "../../redux/actions/projectActions";

function WinForm({ project,winGraphicsData, accountName, projectDisabled,
  handleImageDrop,
  handleDragOver,
  handleOnFileUpload,
 }) {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 


  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event) {
    event.preventDefault();
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

   function handleSave(e) {
    
          dispatch(updateProject(project));
        
      }

  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Windows Image Settings
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
                Please ensure png graphic assets have been optimised prior to
                uploading. Ideally all assets should be below 100Kb. Loading
                large bitmaps into memory can cause the operating system to
                refuse to load the bitmap and this can result in a poor user
                experience.
              </p>
              <a href=" https://tinypng.com/"
              className="mt-1 text-sm hover:underline text-iceberg-400 cursor-pointer hover:text-iceberg-300">
                https://tinypng.com/
              </a>
              <p className="mt-1 text-sm leading-6 text-slate-500">
              For Windows Tile graphics please ensure there is enough padding on all sides. For further information please see the following
              </p>
              <a href=" https://docs.microsoft.com/en-us/windows/uwp/design/style/app-icons-and-logos#tile-padding-recommendations"
              className="mt-1 text-sm hover:underline text-iceberg-400 cursor-pointer hover:text-iceberg-300">
              https://docs.microsoft.com/en-us/windows/uwp/design/style/app-icons-and-logos#tile-padding-recommendations
              </a>

              <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
               

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Windows Icon 88x88 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon88"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "icon88")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {winGraphicsData?.icon88 ? (
                          <img
                            src={winGraphicsData?.icon88?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-16"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload2"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                             id="file-upload2"
                             type="file"
                             className="sr-only"
                             name="icon88"
                             onChange={(e) =>
                               handleOnFileUpload(e, "icon88")
                             }
                             disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Windows Logo 92x36 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="logo92_36"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "logo92_36")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {winGraphicsData?.logo92_36 ? (
                          <img
                            src={winGraphicsData?.logo92_36?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-16"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload3"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload3"
                              type="file"
                              className="sr-only"
                              name="logo92_36"
                              onChange={(e) =>
                                handleOnFileUpload(e, "logo92_36")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Windows Wide Tile 620x300 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="widetile620_300"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "widetile620_300")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {winGraphicsData?.widetile620_300 ? (
                          <img
                            src={winGraphicsData?.widetile620_300?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-16"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload4"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload4"
                              type="file"
                              className="sr-only"
                              name="widetile620_300"
                              onChange={(e) =>
                                handleOnFileUpload(e, "widetile620_300")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Windows Large Tile 620x620 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="largetile620_620"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "largetile620_620")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {winGraphicsData?.largetile620_620 ? (
                          <img
                            src={winGraphicsData?.largetile620_620?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-16"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload5"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload5"
                              type="file"
                              className="sr-only"
                              name="largetile620_620"
                              onChange={(e) =>
                                handleOnFileUpload(e, "largetile620_620")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Windows ICO
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="ico"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "ico")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {winGraphicsData?.ico ? (
                          <img
                            src={winGraphicsData?.ico?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-16"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload6"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload6"
                              type="file"
                              className="sr-only"
                              name="ico"
                              onChange={(e) =>
                                handleOnFileUpload(e, "ico")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                 
                </div>
              </dl>
            </div>

            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                             type="button"
                             className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
                             onClick={(e) => {
                               navigate(`/projects/${accountName}`);
                               dispatch(clearGraphics());
                             }}
                           >
                             Back
                           </button>
                           <button
                             type="button"
                             onClick={handleSave}
                             disabled={projectDisabled}
                             className={classNames(projectDisabled ? ``: `hover:bg-iceberg-300 
                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`,
                              `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
                           >
                             Save
                           </button>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    </>
  );
}

export default WinForm;
