import React, { useState } from "react";
import LoginForm from "../components/auth/LoginForm";
import Parse from "parse/dist/parse.min.js";
import { useNavigate } from "react-router-dom";
import { openErrorAlert } from "../redux/actions/alertActions";
import { useDispatch } from "react-redux";

export default function Login() {

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("login button clicked " );

    try {
      const user = await Parse.User.logIn(email, password);
      if (user != null && user.get('emailVerified') == true) {

        Parse.User.enableUnsafeCurrentUser()
        
        navigate("/");
      } else {
        Parse.User.logOut();
        dispatch(openErrorAlert("Email Not Verified", "Please verify your email address"));
      }
    } catch (error) {
      // Show the error message somewhere and let the user try again.
      //alert("Error: " + error.code + " " + error.message);
      console.log("failed to log in " + error.code + " " + error.message)
      dispatch(openErrorAlert(error.message,"Login failed, please try again"));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen py-2">
      <LoginForm
        action={handleSubmit}
        email={email}
        password={password}
        setEmail={setEmail}
        setPassword={setPassword}
      />
    </div>
  );
}
