import TaskList from "../components/tasks/TaskList";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SubmissionBox from "../components/tasks/SubmissionBox";
import DeleteProjectModal from "../components/modals/DeleteProjectModal";
import { openDeleteProjectModal } from "../redux/actions/modalActions";
import BadgeTitle from "../components/titles/BadgeTitle";
import LightPanel from "../components/actionPanels/LightPanel";

import AppLayout from "../components/layout/AppLayout";
import React, {  useEffect, useRef } from "react";
import {
  getProject,
  getProjectByName,
  createNewVersionProject,
  deleteProject,
  submitApprovalProject,
  activateTrialProject,
} from "../redux/actions/projectActions";
import {  useParams } from "react-router-dom";
import EmptyState from "../components/common/EmptyState";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  
import Spinner from '../components/spinner/Spinner';


export default function EditProject() {
  
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.deleteProjectModal);
  const { project,  loading, error } = useSelector(
    (state) => state.getProject
  );
  const {
    success: cSuccess,
    error: cError,
    message,
  } = useSelector((state) => state.changeProject);

  const isMountedRef = useRef(true);
  const isRefTwo = useRef(true);
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const navigate = useNavigate();
  //console.log(project);

  function handleDeleteProject(event) {
    event.preventDefault();
    dispatch(openDeleteProjectModal());
  }

  useEffect(() => {
   
  // Dispatch your action when the component mounts
    if(!project) {
      dispatch(getProjectByName(accountName));
    }

   if(error ){   
      if(error === 209) 
      {
        dispatch(openErrorAlert("Session Expired","Please login again to continue ")); 
          // navigate to login
          navigate("/login");
      } else {
        dispatch(openErrorAlert("Error","Something went wrong, please try again ")); 
      }
    }
  }, [ project, error]);

  useEffect(() => { 
    
    if (cError ) {
      if(cError === 209) 
        {
          dispatch(openErrorAlert("Session Expired","Please login again to continue ")); 
            // navigate to login
            navigate("/login");
        } else {
           dispatch(openErrorAlert("Error",message));
        }
    } else if (cSuccess) {
      dispatch(openStatusAlert("Success",message)); 
      dispatch(getProjectByName(accountName));
      if (message === "Project deleted.") {
        navigate("/projects");
      } 
    }

  }, [cSuccess, cError]);

  function handleNavigate(page) {
    
    if (page === "/miscellaneous") {
      navigate(`${page}/${accountName}/details`);
    } else {
      navigate(`${page}/${accountName}`);
    }
  }

  function handleDelete(projectName) {
    if (isRefTwo.current == false) {
      isRefTwo.current = true;
    }

    dispatch(deleteProject(projectName));
  }

  function handleUpdate(projectName) {
    if (isRefTwo.current == false) {
      isRefTwo.current = true;
    }

    dispatch(createNewVersionProject(projectName));
  }

  function handleSubmitApproval(projectName, version) {
    if (isRefTwo.current == false) {
      isRefTwo.current = true;
    }

    dispatch(submitApprovalProject(projectName, version));
  }

  function handleActivateTrial(projectName, type, ){
    if (isRefTwo.current == false) {
      isRefTwo.current = true;
    }

    dispatch(activateTrialProject(projectName, type));
  }

  return (
    <>
      <AppLayout  project={project} type={project?.type ? project?.type : ""}/>

      {!loading && project ? (
        <>
          <DeleteProjectModal
            modalOpen={open}
            projectName={project?.accountName ? project?.accountName : ""}
            handleDelete={handleDelete}
          />
          <main className="lg:pl-72 ">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 space-y-6">
              <BadgeTitle
                title={project?.accountName ? project?.accountName : ""}
                badge={project?.status ? project?.status.approval.status : ""}
              />

              <LightPanel
                title={"Get Started"}
                note={
                  "Please tap a section to edit.Complete all sections and then please submit for approval."
                }
              />

              <div className="mt-4 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16 ">
                <section
                  aria-labelledby="cart-heading"
                  className="lg:col-span-7"
                >
                  <TaskList project={project} handleNavigate={handleNavigate} />
                </section>

                <section className="lg:col-span-1"></section>

                <section className="mt-16 rounded-lg px-4 py-6 sm:p-6 lg:col-span-4 lg:mt-0 lg:p-8  bg-slate-50 ">
                  {/* border border-slate-200 */}
                  <SubmissionBox
                    clickDelete={handleDeleteProject}
                    clickUpdate={handleUpdate}
                    clickApproval={handleSubmitApproval}
                    clickTrial={handleActivateTrial}
                    project={project}
                  />
                </section>
              </div>
            </div>
          </main>
        </>
      ) : (
        <>
          
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner />
           </div>
        </>
      )}
    </>
  );
}
