import React from 'react'
import ReactDOM from 'react-dom';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';


const portal = document.getElementById('portal');

function StatusContainer() {

  const { statusOpen, errorOpen, statusTitle, statusMessage, errorTitle, errorMessage } = useSelector((state) => state.alertStatus);

  return (
    <>
      {createPortal(
        <>
        <SuccessNotification open={statusOpen} title={statusTitle} message={statusMessage}/>
         <ErrorNotification open={errorOpen} title={errorTitle} message={errorMessage}/>
         </>,
        portal
      )}
    </>
  );

}

export default StatusContainer