import { ChevronRightIcon } from "@heroicons/react/20/solid";


const stages = {
  Prepare: "text-blue-700 bg-blue-100 ",
  Cancelled: "text-red-700 bg-red-100 ",
  Live: "text-green-700 bg-green-100 ",
  Submitted: "text-violet-700 bg-violet-50 ",
  Approved: "text-lime-700 bg-lime-100 ",
  Update: "text-teal-700 bg-teal-100 ",
  Trial: "text-gray-700 bg-gray-100 ",
  Rejected: "text-pink-700 bg-pink-100",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getPlanName(plan) {
  switch (plan) {
    case "sessioncloud_vvs":
      return "Generic VVS";
      case "generic_softphone":
      return "Generic";
    case "sessioncloud_business":
      return "Generic Business";
    case "sessioncloud_secure":
      return '"Generic Secure';
    case "sessioncloud_all":
      return "Generic";
    case "generic-platforms":
      return "Generic";
    case "whitelabel_basic":
      return "Whitelabel Basic";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
      case "whitelabel-windows":
      return "Whitelabel Desktop";
    case "whitelabel_desktop":
      return "Whitelabel Desktop";
      case "whitelabel-windows-annual":
      return "Whitelabel Desktop";
    case "whitelabel_premium":
      return "Whitelabel Premium";
      case "whitelabel_mobile":
        return "Whitelabel Mobile";
      case "whitelabel-mobile-annual":
      return "Whitelabel Premium";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    default:
      return "Generic";
  }
}

export default function ProjectList({ projects, action }) {
  return (
    <ul role="list" className="space-y-5">
      {projects.map((project) => (
        <li
          key={project.id}
          className="items-center space-x-3 rounded-lg shadow hover:shadow-md  bg-white px-6  focus-within:ring-2 
          focus-within:ring-iceberg-300 focus-within:ring-offset-2 cursor-pointer"
        >
          {/* border border-gray-200 hover:border-gray-300 */}

          <div className="relative flex justify-between gap-x-4 py-5">
            <div className="flex min-w-0 gap-x-6">
             
              <div className="min-w-0 flex-auto">
                <p className="text-base font-semibold leading-6 text-gray-900">
                  <a onClick={(e)=> {
                    action(project)
                  }}>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {project.accountName}
                  </a>
                </p>
                <div className="mt-1 flex items-center gap-x-2  leading-5 text-sm text-gray-500">
                  <p>{getPlanName(project.type)}</p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className=" font-semibold text-gray-400">
                    {project.status.overall.completion} %
                  </p>
                </div>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <div
                  className={classNames(
                    stages[project.status.approval.status],
                    "rounded-md flex-none py-1 px-2 text-xs font-medium "
                  )}
                >
                  {project.status.approval.status}
                </div>
              </div>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
