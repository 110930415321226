import React, { useState, useEffect, useRef } from "react";
import {
  DocumentIcon,IdentificationIcon
} from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


function Windows({ projectData, handleOnChange, projectDisabled, handleOnFileUpload,handleImageDrop }) {



  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event){
    event.preventDefault();
  };

  return (
    <>
      <div >
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          Windows
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
          We'll always let you know about important changes, but you pick what
          else you want to hear about.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Windows Version
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="winVersion"
                value={
                  projectData?.settings?.appstore?.winVersion ? projectData?.settings?.appstore.winVersion : ""
                }
                 maxLength={100}
                  autoComplete="win-version"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Windows Build No
            </label>
            <div className="mt-2">
              <input
                type="text"
                  maxLength={100}
                  autoComplete="win-build-number"
                value={
                  projectData?.settings?.appstore?.winBuildNumber ? projectData?.settings?.appstore?.winBuildNumber : ""
                }
                
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Windows Update Url
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="winupdateurl"
                value={
                  projectData?.settings?.appstore?.winupdateurl ? projectData?.settings?.appstore?.winupdateurl : ""
                }
                  maxLength={100}
                  autoComplete="win-update-url"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              App Installer Url
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="appInstallerUrl"
                value={
                  projectData?.settings?.appstore?.appInstallerUrl ? projectData?.settings?.appstore?.appInstallerUrl : ""
                }
                 maxLength={100}
                  autoComplete="app-installer-url"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

        

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
              Windows Certificate
            </label>
            <p className="text-sm text-slate-500">
              This is your Code Signing certificate in pfx format
            </p>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
               name="wincert"
               disabled={projectDisabled}
              onDrop={(e)=> handleImageDrop(e, "wincert", "wincertName")}
              onDragOver={handleDragOver}>
              <div className="text-center">

              { projectData?.wincertName ? (
                    <IdentificationIcon
                    className="mx-auto h-12 w-12 text-slate-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload5"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload5"
                      name="wincert"
                      type="file"
                      
                      onChange={(e) => handleOnFileUpload(e, "wincert", "wincertName")}
                      disabled={projectDisabled}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-slate-600">
                  {
                    projectData?.wincertName
                    ? projectData?.wincertName
                    : ""
                  }
                  </p>
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Certificate Common Name
            </label>
            <div className="mt-2">
              <textarea
                 rows={3}
                name="winPublisherCN"
                value={
                  projectData?.settings?.appstore?.winPublisherCN ? projectData?.settings?.appstore?.winPublisherCN : ""
                }
                  maxLength={100}
                  autoComplete="common-name"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Publisher Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="winPublisherName"
                value={
                  projectData?.settings?.appstore?.winPublisherName ? projectData?.settings?.appstore?.winPublisherName : ""
                }
                 maxLength={100}
                  autoComplete="publisher-name"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Certificate Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="winCertPassword"
                value={
                  projectData?.settings?.appstore?.winCertPassword ? projectData?.settings?.appstore?.winCertPassword : ""
                }
                 maxLength={100}
                  autoComplete="certificate-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Certificate Thumbprint
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="winIdentity"
                value={
                  projectData?.settings?.appstore?.winIdentity ? projectData?.settings?.appstore?.winIdentity : ""
                }
                maxLength={100}
                  autoComplete="certificate-thumprint"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>
        </div>
        </dl>
      </div>
    </>
  );
}

export default Windows;
