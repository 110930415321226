import React from "react";
import { FadeLoader } from "react-spinners";

// const override = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

function LoadingIndicator() {
  return (
    <>
      {
        // cssOverride={override}
      }

      <div className="place-items-center content-center h-screen">
        <div className="flex  items-center justify-center  h-2/3">
          <FadeLoader
            color={"#00a2e9"}
            loading={true}
            height={15}
            width={5}
            radius={2}
            margin={2}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </>
  );
}

export default LoadingIndicator;
