import React from "react";
import { useNavigate } from "react-router-dom";
import Video from "./Video";
import STUN from "./STUN";
import Push from "./Push";
import { updateProject } from "../../redux/actions/projectActions";
import { useDispatch } from "react-redux";  

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdvancedSettings({
  projectData,
  handleOnChange,
  handlePushChange,
  projectDisabled,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <Video
              projectData={projectData}
              handleOnChange={handleOnChange}
              projectDisabled={projectDisabled}
            />
            <STUN
              projectData={projectData}
              handleOnChange={handleOnChange}
              projectDisabled={projectDisabled}
            />
            <Push
              projectData={projectData}
              handleOnChange={handleOnChange}
              handlePushChange={handlePushChange}
              projectDisabled={projectDisabled}
            />

            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="button"
                onClick={(e) =>
                  projectData
                    ? navigate(`/provisioning/${projectData.accountName}`)
                    : ""
                }
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
              >
                Back
              </button>
              <button
                type="button"
                disabled={projectDisabled}
                onClick={(e) => {  dispatch(updateProject(projectData))}}
                className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`, 
                `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:opacity-25`)}
              >
                Save
              </button>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    </>
  );
}

export default AdvancedSettings;
