import {
    GET_BUILDS_REQUEST,
    GET_BUILDS_SUCCESS,
    GET_BUILDS_FAIL,
    CREATE_BUILD_REQUEST,
    CREATE_BUILD_SUCCESS,
    CREATE_BUILD_FAIL,
    GET_CURRENT_BUILDS_REQUEST,
    GET_CURRENT_BUILDS_SUCCESS,
    GET_CURRENT_BUILDS_FAIL,
    DOWNLOAD_BUILD_REQUEST,
    DOWNLOAD_BUILD_SUCCESS,
    DOWNLOAD_BUILD_FAIL,
    DELETE_BUILD_REQUEST,
    DELETE_BUILD_SUCCESS,
    DELETE_BUILD_FAIL,
    CANCEL_BUILD_REQUEST,
    CANCEL_BUILD_SUCCESS,
    CANCEL_BUILD_FAIL,
    CREATE_BUILD_CLEAR,
    DELETE_BUILD_CLEAR,
    CANCEL_BUILD_CLEAR,
    DOWNLOAD_BUILD_CLEAR,
    UPDATE_BUILD_REQUEST,
    UPDATE_BUILD_SUCCESS,
    UPDATE_BUILD_FAIL,  
    UPDATE_BUILD_CLEAR
} from "../constants/buildConstants";

export const getBuildsReducer = (
    state = { success: false, loading: false, error: false, builds: undefined },
    action
  ) => {
    switch (action.type) {
      case GET_BUILDS_REQUEST:
        return {
          loading: true,
        };
  
      case GET_BUILDS_SUCCESS:
        return {
          loading: false,
          builds: action.payload,
          success:true
        };
  
      case GET_BUILDS_FAIL:
        return {
          loading: true,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export const createBuildReducer = (
    state = { success: false, loading: false, error: false },
    action
  ) => {
    switch (action.type) {
      case CREATE_BUILD_REQUEST:
        return {
          loading: true,
        };
  
      case CREATE_BUILD_SUCCESS:
        return {
          loading: false,
          success:true
        };
  
      case CREATE_BUILD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };

        case CREATE_BUILD_CLEAR:
          return {
            success: false,
            loading: false,
            error: false,
          };
  
      default:
        return state;
    }
  };
  
  export const updateBuildReducer = (
    state = { success: false, loading: false, error: false },
    action
  ) => {
    switch (action.type) {
      case UPDATE_BUILD_REQUEST:
        return {
          loading: true,
        };
  
      case UPDATE_BUILD_SUCCESS:
        return {
          loading: false,
          success:true
        };
  
      case UPDATE_BUILD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };

        case UPDATE_BUILD_CLEAR:
          return {
            success: false,
            loading: false,
            error: false,
          };
  
      default:
        return state;
    }
  };

  export const getCurrentBuildsReducer = (
    state = { success: false, loading: false, error: false, builds: undefined },
    action
  ) => {
    switch (action.type) {
      case GET_CURRENT_BUILDS_REQUEST:
        return {
          loading: true,
        };
  
      case GET_CURRENT_BUILDS_SUCCESS:
        return {
          loading: false,
          builds: action.payload,
          success:true
        };
  
      case GET_CURRENT_BUILDS_FAIL:
        return {
          loading: true,
          error: action.payload,
        };

        
  
      default:
        return state;
    }
  };
  
  export const downloadBuildReducer = (
    state = { success: false, loading: false, error: false, artifact: undefined },
    action
  ) => {
    switch (action.type) {
      case DOWNLOAD_BUILD_REQUEST:
        return {
          loading: true,
        };
  
      case DOWNLOAD_BUILD_SUCCESS:
        return {
          loading: false,
          artifact: action.payload,
          success:true
        };
  
      case DOWNLOAD_BUILD_FAIL:
        return {
          loading: true,
          error: action.payload,
        };

        case DOWNLOAD_BUILD_CLEAR:
          return {
            loading: false,
            success:false,
            error: false,
            artifact: undefined
          };
  
      default:
        return state;
    }
  };
  
  export const deleteBuildReducer = (
    state = { success: false, loading: false, error: false, },
    action
  ) => {
    switch (action.type) {
      case DELETE_BUILD_REQUEST:
        return {
          loading: true,
        };
  
      case DELETE_BUILD_SUCCESS:
        return {
          loading: false,
          success:true
        };
  
      case DELETE_BUILD_FAIL:
        return {
          loading: true,
          error: action.payload,
        };
  
        case DELETE_BUILD_CLEAR:
          return {
            loading: false,
            success:false,
            error: false,
          };

      default:
        return state;
    }
  };

  export const cancelBuildReducer = (
    state = { success: false, loading: false, error: false, },
    action
  ) => {
    switch (action.type) {
      case CANCEL_BUILD_REQUEST:
        return {
          loading: true,
        };
  
      case CANCEL_BUILD_SUCCESS:
        return {
          loading: true,
          artifact: action.payload,
          success:true
        };
  
      case CANCEL_BUILD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };

        case CANCEL_BUILD_CLEAR:
        return {
          success: false,
          loading: false,
          error: false,
        };
  
      default:
        return state;
    }
  };
  