import React from "react";
import {  ArrowDownTrayIcon, } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';


function convertPlatform(platform) {
  if (platform === "ios") {
    return "iOS";
  } else if (platform === "android") {
    return "Android";
  } else if (platform === "win") {
    return "Windows";
  } else if (platform === "mac") {
    return "Mac";
  }
}

function getStateText(build) {
  if (build == undefined || build == null) {
    return "Waiting";
  }

  if (build.status == "inProgress") {
    return "Building";
  } else if (build.result == "failed") {
    return "Failed";
  } else if (build.result == "canceled") {
    return "Cancelled";
  } else if (build.result == "succeeded") {
    return "Succeeded";
  } else {
    return "Waiting";
  }
}

function getStateColour(build) {
  if (build == undefined || build == null) {
    return "text-blue-700 bg-blue-100 ";
  }

  if (build.status == "inProgress") {
    return "bg-yellow-100 text-yellow-800";
  } else if (build.result == "failed" || build.result == "canceled") {
    return "text-red-700 bg-red-100 ";
  } else if (build.result == "succeeded") {
    return "text-green-700 bg-green-100 ";
  } else {
    return "text-blue-700 bg-blue-100 ";
  }
}

function getIcon(platform){
  if (platform === "ios") {
    return  <span
    className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 "
    aria-hidden="true"
  >iOS</span>
  } else if (platform === "android") {
    return <FontAwesomeIcon icon={faAndroid}  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400  " aria-hidden="true" />
  } else if (platform === "win") {
    return <FontAwesomeIcon icon={faWindows}  className="-ml-0.5 mr-1.5  h-5 w-5 text-gray-400   " aria-hidden="true" />
  } else if (platform === "mac") {
    return <FontAwesomeIcon icon={faApple}  className="-ml-0.5 mr-1.5  h-5 w-5 text-gray-400  " aria-hidden="true" />
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BuildsTable({ project, builds, type, handleCreateBuild, androidEnabled, winEnabled, macEnabled, iosEnabled, downloadBuild, deleteBuild }) {
  return (
    <>
      <div>
        <div className="sm:flex sm:items-center space-x-8 mt-4  md:mt-0">
          {type === "whitelabel-windows" ||
          type === "whitelabel-windows-annual" ||
          type === "whitelabel_desktop"
          ||  project?.settings?.provisioning?.usewindows == "Enabled" ? (
            <button
              type="button"
              onClick={(e) => handleCreateBuild("windows")}
              disabled={!winEnabled}
              className={classNames(
                winEnabled == true
                  ? `hover:bg-iceberg-300 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-600` : "", `inline-flex items-center rounded-md bg-iceberg-400 px-3
               py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
            >
              
              <FontAwesomeIcon icon={faWindows}  className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Build Windows Softphone
            </button>
          ) : (
            <></>
          )}

          {type === "whitelabel-windows" ||
          type === "whitelabel-windows-annual" ||
          type === "whitelabel_desktop" ? (
            <button
              type="button"
              onClick={(e) => handleCreateBuild("mac")}
              disabled={!macEnabled}
              className={classNames(
                macEnabled == true
                  ? `hover:bg-iceberg-300 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-600` : "", `inline-flex items-center rounded-md bg-iceberg-400 px-3
               py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
            >
             
              <FontAwesomeIcon icon={faApple}  className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Build Mac Softphone
            </button>
          ) : (
            <></>
          )}

          {type != "whitelabel-windows" &&
          type != "whitelabel-windows-annual" &&
          type != "whitelabel_desktop" ? (
            <button
              type="button"
              onClick={(e) => handleCreateBuild("android")}
              disabled={!androidEnabled}
              className={classNames(
                androidEnabled == true
                  ? `hover:bg-iceberg-300 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-600` : "", `inline-flex items-center rounded-md bg-iceberg-400 px-3
               py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
            >
             
              <FontAwesomeIcon icon={faAndroid}  className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Build Android Softphone
            </button>
          ) : (
            <></>
          )}

          {type != "whitelabel-windows" &&
          type != "whitelabel-windows-annual" &&
          type != "whitelabel_desktop" ? (
            <button
              type="button"
              onClick={(e) => handleCreateBuild("ios")}
              disabled={!iosEnabled}
              className={classNames(
                iosEnabled == true
                  ? `hover:bg-iceberg-300 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-600` : "", `inline-flex items-center rounded-md bg-iceberg-400 px-3
               py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
            >
             
              <FontAwesomeIcon icon={faApple}  className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Build iOS Softphone
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Platform
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Created Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Build Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Build Version
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Software Version
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Progress
                    </th>

                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {builds.map((build) => (
                    <tr key={build.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {
getIcon(build?.platform ? build.platform : <></>)
                        }
                      
                        {build?.platform ? convertPlatform(build.platform) : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {build?.createdAt ? build.createdAt : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {build?.buildNumber ? build.buildNumber : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {build?.version ? build.version : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {build?.tag ? build.tag : "Unknown"}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {
                                    build?.completion ? build?.completion : 0
                                  }%
                      </td>


                     
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="space-x-8">
                          <button
                            type="button"
                            onClick={(e) => downloadBuild(build.buildId)}
                            className="rounded-full bg-iceberg-400 p-2 text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
                          >
                            <ArrowDownTrayIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>

                          <button
                            type="button"
                            onClick={(e) => deleteBuild(build.buildId)}
                            className="rounded-full bg-white p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <span
                          className={classNames(
                            getStateColour(build),
                            "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium "
                          )}
                        >
                          
                          {getStateText(build)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildsTable;
