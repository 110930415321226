import React, {  useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}




function RegisterForm({ user, action, onChangeUser, errors }) {

  useEffect(() => {}, [errors]);

  return (
    <>
      <form className="space-y-6 text-start" onSubmit={action}>
        <div>
          <label
            htmlFor="firstname"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            First Name
          </label>
          <div className="mt-2">
            <input
              id="firstname"
              name="firstname"
              type="text"
              pattern=".{1,}"
              autoComplete="name"
              placeholder="First Name"
              minLength={1}
              maxLength={32}
              value={user.firstname}
              onChange={onChangeUser}
              
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
            {errors.firstnameError ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Please fill in the missing field
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="lastname"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Last Name
          </label>
          <div className="mt-2">
            <input
              id="lastname"
              name="lastname"
              placeholder="Last Name"
              type="text"
              autoComplete="name"
              pattern=".{1,}"
              minLength={1}
              maxLength={32}
              value={user.lastname}
              onChange={onChangeUser}
              
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
            {errors.lastnameError ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Please fill in the missing field
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              autoComplete="email"
              minLength={5}
              maxLength={64}
              value={user.email}
              onChange={onChangeUser}
              
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
             {errors.emailError ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Please enter a valid email address
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              minLength={8}
              maxLength={32}
              pattern=".{8,}"
              value={user.password}
              onChange={onChangeUser}
              placeholder="Password"
             
              
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
            {errors.passwordError ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                 Please enter a password of at least 8 characters
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Confirm Password
          </label>
          <div className="mt-2">
            <input
              id="cpassword"
              name="cpassword"
              type="password"
              minLength={8}
              maxLength={32}
              pattern=".{8,}"
              placeholder="Confirm Password"
             
              value={user.cpassword}
              onChange={onChangeUser}
              
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
            {errors.cpasswordError ? (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Please enter a matching password
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              required
              className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400"
            />
            <label
              htmlFor="remember-me"
              className="ml-3 block text-sm leading-6 text-gray-900"
            >
              I agree to the 
              <a  href="https://sessiontalk.co.uk/terms"
                    className="font-semibold text-iceberg-400 hover:text-iceberg-300"> Terms of Service
                </a> & <a  href="https://sessiontalk.co.uk/privacy"
                    className="font-semibold text-iceberg-400 hover:text-iceberg-300">Privacy Policy</a>
            </label>
          </div>
        </div>

        <div>
          <button
            type="submit"
            
            className="flex w-full justify-center rounded-md bg-iceberg-400 px-3 py-1.5 text-sm font-semibold 
      leading-6 text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
      focus-visible:outline-iceberg-400"
          >
            Sign Up
          </button>
        </div>
      </form>
    </>
  );
}

export default RegisterForm;
