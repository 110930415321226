import React, { useEffect, useRef, useState } from "react";
import LoadingIndicator from "../common/LoadingIndicator";



async function createCheckoutWithApi(url,pageId,redirectToThankYou) {
  try {
   
    const checkout = await window.Chargebee.getInstance().createCheckout({
      url,
      callbacks: {
        loaded: () => {
          console.log("Checkout opened");
        },
        error: (error) => {
          console.error("Error during checkout:", error);
        },
        close: () => {
          console.log("Checkout closed");
        },
        success: (data) => {
          redirectToThankYou(data);
          console.log("Checkout success. ID:", data.id);
        },
        step: (value) => {
          console.log("Checkout step:", value);
        },
      },
    });

    
    // Mount checkout on the container
    checkout.mount('#checkout');
  } catch (error) {
    console.error("An error occurred:", error);
  }
}



function CheckoutFrame({ page, redirectToThankYou }) {
  const checkoutCreated = useRef(false);

  useEffect(() => {
    if (page && page.hosted_page && !checkoutCreated.current) {
      createCheckoutWithApi(page.hosted_page.url, page.hosted_page.id,redirectToThankYou);
      checkoutCreated.current = true;
    }
  }, [page]);

  return (
    <>
        <div id="checkout" style={{ width: '50%', height: '80vh',marginLeft: '20', marginRight: 'auto' }}/>
    </>
  );
}

export default CheckoutFrame;
