import React from "react";

function Calling({projectData, handleOnChange, projectDisabled}) {
  const callingModes = [
    { id: "from-username", title: "FromUsername" },
    { id: "remote-party-id", title: "RemotePartyId" },
    { id: "p-preferred-identity", title: "PPreferredIdentity" },
    { id: "p-asserted-identity", title: "PAssertedIdentity" },
  ];

  function convertText(text) {
    if (text === "Enabled" || text === "on") {
      return true;
    }
    return false;
  }

  function getChoice  (typeId, choiceId) {
    if (typeId === choiceId) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {" "}
          Calling
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
        Customise your calling preferences to suit your needs.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  disabled={projectDisabled}
                  name="stripDomain"
                  onChange={handleOnChange}
                  aria-describedby="call-history-no-domain-box"
                  
                    checked={convertText(
                      projectData?.settings?.provisioning?.stripDomain
                        ? projectData?.settings?.provisioning.stripDomain
                        : "Disabled")
                    }
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Strip Domain from Call History
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="ignoreDisplayName"
                  onChange={handleOnChange}
                  aria-describedby="ignore-display-name-box"
                  disabled={projectDisabled}
                    checked={convertText(
                      projectData?.settings?.provisioning?.ignoreDisplayName
                        ? projectData?.settings?.provisioning.ignoreDisplayName
                        : "Disabled")
                    }
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Ignore Display Name
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="allowRecording"
                  onChange={handleOnChange}
                  aria-describedby="allow-recording-box"
                  disabled={projectDisabled}
                    checked={convertText(
                      projectData?.settings?.provisioning?.allowRecording
                        ? projectData?.settings?.provisioning.allowRecording
                        : "Disabled")
                    }
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Allow Call Recording
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-gray-900">
              Incoming Caller Id
            </label>
            <p className="text-sm text-gray-500">
              What property should be shown on the call screen?
            </p>
            <fieldset className="mt-4">
              <legend className="sr-only">Incoming Caller Id</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                {callingModes.map((mode) => (
                  <div key={mode.title} className="flex items-center">
                    <input
                      id={mode.title}
                      name="incomingCallerId"
                      type="radio"
                      aria-describedby="incoming-caller-id-settings"
                      disabled={projectDisabled}
                      value={mode.title}
                      checked={getChoice(
                        projectData?.settings?.provisioning?.incomingCallerId
                          ? projectData?.settings.provisioning.incomingCallerId
                          : "FromUsername",
                          mode.title
                      )}
                      onChange={handleOnChange}
                      className="h-4 w-4 border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                    <label
                      htmlFor={mode.id}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      {mode.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Calling;
