import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  TrashIcon, 
} from "@heroicons/react/20/solid";
import {
  PhoneIcon, 
} from "@heroicons/react/24/solid";
import { openErrorAlert } from '../../redux/actions/alertActions';
import { createCustomerSubscription, updateCustomerSubscription } from '../../redux/actions/pricingActions';

const products = [
  {
    id: 1,
    title: "Basic Tee",
    href: "#",
    price: "$32.00",
    color: "Black",
    size: "Large",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/checkout-page-02-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
  },
  // More products...
];

function roundedto2points(value) {
  const total = value / 100;
  const roundedTotal = Math.round(total * 100) / 100;
  const totalString = roundedTotal.toFixed(2);
  return `$${totalString}`;
}




function OrderSummary({  subscription, project, estimate }) {

  const accountName = project?.accountName;
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const { result:existingSubscription,error: cError } = useSelector((state) =>  state.getCustomerSubscriptionReducer);
  
  const { update,  error: updateError } = useSelector((state) => state.updateSubscriptionReducer);

  useEffect(() => { 
    if(existingSubscription != null && existingSubscription != undefined) {
      navigateConfirm(existingSubscription.invoice.id);
    } else if(cError) {
      dispatch(openErrorAlert("Error",cError)); 
    } 
  }, [existingSubscription,cError]);

  useEffect(() => { 
    if(update != null && update != undefined && update.invoice != null && update.invoice != undefined) {   
      navigateConfirm(update.invoice.id); 
    } else if(updateError) {
      dispatch(openErrorAlert("Error",updateError));
    } 
  }, [update,updateError]);


  function handleUpdateSubscription(project, subscription)
  {
      
      var addonarray = [];
       var tunnel = {id : "sessionflow"};

       var current = new Date().getTime();
       var tmp = Math.round(current/1000) + (3600 * 24 * 14);
        // Add 14 days
        var trialend = Math.round(tmp);

        if (project?.type === "sessioncloud_vvs" || project?.type === "sessioncloud_business" ||project?.type === "sessioncloud_secure" || project?.type === "sessioncloud_all") {
         var windows = {id : "windows-generic", trial_end: trialend};
         if( project?.settings.provisioning.usewindows != undefined &&  project?.settings.provisioning.usewindows == 'Enabled')
         {
           addonarray.push(windows);
         }

        } else if(project?.type === "whitelabel_basic" ||  project?.type === "whitelabel_advanced" || project?.type === "" ||  project?.type === "whitelabel-mobile-annual") {
         var windows = {id : "windows-whitelabel", trial_end: trialend};
         if( project?.settings.provisioning.usewindows != undefined &&  project?.settings.provisioning.usewindows == 'Enabled')
         {
           addonarray.push(windows);
         }
        }

      if( project?.settings.provisioning.usetunnel == 'Enabled')
      {
          addonarray.push(tunnel);
      }
      if(subscription == null)
      {
        // dispatch action to create new subscription exisitng customer
        dispatch(createCustomerSubscription(project?.accountName, project?.type,addonarray, project?.type + "_coupon"));
      }
      else{
        // dispatch action to update existing subscription exisitng customer
        dispatch(updateCustomerSubscription(project?.accountName, subscription, project?.type,addonarray));      
      }
  }

  function navigateConfirm(invoiceId) {
    navigate(`/checkout-confirm/${invoiceId}/${project?.accountName}`);
  }

  return (
    <>
      <div className="mt-10 lg:mt-0">
        <h2 className="text-lg font-medium text-gray-900">Order summary</h2>

        <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          <h3 className="sr-only">Items in your cart</h3>
          <ul role="list" className="divide-y divide-gray-200">
            {products.map((product) => (
              <li key={product.id} className="flex px-4 py-6 sm:px-6">
                <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-iceberg-600">
                  
                    <PhoneIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  
                  {/* <img src={product.imageSrc} alt={product.imageAlt} className="w-20 rounded-md" /> */}
                </div>

                <div className="ml-6 flex flex-1 flex-col">
                  <div className="flex">
                    <div className="min-w-0 flex-1">
                      <h4 className="text-sm">
                        <a className="font-medium text-gray-700 hover:text-gray-800">
                          {project?.accountName ? project?.accountName : ""}
                        </a>
                      </h4>
                      <p className="mt-1 text-sm text-gray-500">
                        {estimate?.next_invoice_estimate?.line_items[0]?.description ? 
                         estimate?.next_invoice_estimate?.line_items[0]?.description
                        : ""}
                        </p>
                           
                    </div>

                    {/* <div className="ml-4 flow-root flex-shrink-0">
                      <button
                        type="button"
                        className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Remove</span>
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div> */}
                  </div>

                  <div className="flex flex-1 items-end justify-between pt-2">
                    <p className="mt-1 text-sm font-medium text-gray-900">
                      {estimate?.next_invoice_estimate?.line_items[0]?.amount ? 
                      roundedto2points(estimate?.next_invoice_estimate?.line_items[0]?.amount)
                    : ""}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
            
            <div className="flex items-center justify-between">
              <dt className="text-sm">Set Up Fee</dt>
              <dd className="text-sm font-medium text-gray-900">
              {estimate?.next_invoice_estimate?.line_item_taxes[0]?.tax_amount ? 
                      roundedto2points(estimate?.next_invoice_estimate?.line_item_taxes[0]?.tax_amount)
                    : ""}
              </dd>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base font-medium">Total</dt>
              <dd className="text-base font-medium text-gray-900">
              {estimate?.next_invoice_estimate?.amount_due ? 
                      roundedto2points(estimate?.next_invoice_estimate?.amount_due)
                    : ""}
              </dd>
            </div>
          </dl>

          <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
            <button
              type="button"
              onClick={(e) => handleUpdateSubscription(project, subscription)
              }
              
              className="w-full rounded-md border border-transparent bg-iceberg-400 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-iceberg-300 focus:outline-none focus:ring-2 focus:ring-iceberg-300 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummary;
