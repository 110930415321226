import React from "react";

function AdvancedLink({action}) {
  return (
    <>
      <div className="mt-6 px-6 py-6 text-right">
        <a onClick={action} className="text-sm font-semibold leading-6 text-iceberg-400  cursor-pointer  hover:text-iceberg-300">
          Advanced Settings <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </>
  );
}

export default AdvancedLink;
