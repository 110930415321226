import React, { useCallback, useMemo } from "react";
import SelectCodecs from "../selection/SelectCodecs";
import { useNavigate } from "react-router-dom";

const audioCodecs = [
  { name: "G722" },
  { name: "G711U" },
  { name: "G711A" },
  { name: "GSM" },
  { name: "iLBC" },
  { name: "G729A" },
  { name: "Opus" },
];

const videoCodecs = [
  { id: 1, name: "VP8", enabled: true, added: true },
  { id: 2, name: "H264", enabled: true, added: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Codecs({
  projectData,
  handleSave,
  handleRemoveCodec,
  handleAddCodec,
  projectDisabled
}) {
  const navigate = useNavigate();

  // Memoize the onClick handlers
  const handleBackClick = useCallback(() => {
    if (projectData) {
      navigate(`/provisioning/${projectData.accountName}`);
    }
  }, [navigate, projectData]);

  const handleSaveClick = useCallback(() => {
    handleSave();
  }, [handleSave]);

  // Memoize the active codecs for each section
  const wifiCodecs = useMemo(() => {
    return projectData?.settings?.provisioning.wificodecs || [];
  }, [projectData]);

  const threeGCodecs = useMemo(() => {
    return projectData?.settings?.provisioning.threeGcodecs || [];
  }, [projectData]);

  const videoWifiCodecs = useMemo(() => {
    return projectData?.settings?.provisioning.videowificodecs || [];
  }, [projectData]);

  const videoThreeGCodecs = useMemo(() => {
    return projectData?.settings?.provisioning.videothreegcodecs || [];
  }, [projectData]);

  return (
    <form>
      <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 className="text-base font-semibold leading-7 text-slate-900">
              Codecs
            </h2>
            <p className="mt-1 text-sm leading-6 text-slate-500">
              Optimize your audio and video communication to the finest
              detail. Select, prioritize, and configure your preferred codecs.
            </p>

            <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* WiFi Codecs */}
                <div className="col-span-full pt-6">
                  <div>
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-semibold leading-6 text-slate-900">
                        WiFi Codecs
                        <p className="mt-1 text-sm leading-6 text-slate-600 font-normal">
                          Select which codecs you want to use in the apps
                        </p>
                      </h2>
                    </div>

                    <fieldset className="mt-2">
                      <legend className="sr-only">WiFi Codecs</legend>
                      <SelectCodecs
                        projectDisabled={projectDisabled}
                        codecs={audioCodecs}
                        activeCodecs={wifiCodecs}
                        handleAddCodec={handleAddCodec}
                        handleRemoveCodec={handleRemoveCodec}
                        property={"wificodecs"}
                      />
                      {wifiCodecs.length < 1 && (
                        <p className="mt-2 text-red-600 text-sm">
                          At least one codec is required
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>

                {/* Mobile Codecs */}
                <div className="col-span-full">
                  <div>
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-semibold leading-6 text-slate-900">
                        Mobile Codecs
                        <p className="mt-1 text-sm leading-6 text-slate-600 font-normal">
                          Select which codecs you want to use in the apps
                        </p>
                      </h2>
                    </div>

                    <fieldset className="mt-2">
                      <legend className="sr-only">Mobile Codecs</legend>
                      <SelectCodecs
                        projectDisabled={projectDisabled}
                        codecs={audioCodecs}
                        activeCodecs={threeGCodecs}
                        handleAddCodec={handleAddCodec}
                        handleRemoveCodec={handleRemoveCodec}
                        property={"threeGcodecs"}
                      />
                      {threeGCodecs.length < 1 && (
                        <p className="mt-2 text-red-600 text-sm">
                          At least one codec is required
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>

                {/* Video WiFi Codecs */}
                <div className="col-span-full">
                  <div>
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-semibold leading-6 text-slate-900">
                        Video WiFi Codecs
                        <p className="mt-1 text-sm leading-6 text-slate-600 font-normal">
                          Select which codecs you want to use in the apps
                        </p>
                      </h2>
                    </div>

                    <fieldset className="mt-2">
                      <legend className="sr-only">Video WiFi Codecs</legend>
                      <SelectCodecs
                        projectDisabled={projectDisabled}
                        codecs={videoCodecs}
                        activeCodecs={videoWifiCodecs}
                        handleAddCodec={handleAddCodec}
                        handleRemoveCodec={handleRemoveCodec}
                        property={"videowificodecs"}
                      />
                      {videoWifiCodecs.length < 1 && (
                        <p className="mt-2 text-red-600 text-sm">
                          At least one codec is required
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>

                {/* Video Mobile Codecs */}
                <div className="col-span-full pt-6">
                  <div>
                    <div className="flex items-center justify-between">
                      <h2 className="text-sm font-semibold leading-6 text-slate-900">
                        Video Mobile Codecs
                        <p className="mt-1 text-sm leading-6 text-slate-600 font-normal">
                          Select which codecs you want to use in the apps
                        </p>
                      </h2>
                    </div>

                    <fieldset className="mt-2">
                      <legend className="sr-only">Video Mobile Codecs</legend>
                      <SelectCodecs
                        projectDisabled={projectDisabled}
                        codecs={videoCodecs}
                        activeCodecs={videoThreeGCodecs}
                        handleAddCodec={handleAddCodec}
                        handleRemoveCodec={handleRemoveCodec}
                        property={"videothreegcodecs"}
                      />
                      {videoThreeGCodecs.length < 1 && (
                        <p className="mt-2 text-red-600 text-sm">
                          At least one codec is required
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              type="button"
              onClick={handleBackClick}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            >
              Back
            </button>
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleSaveClick}
              className={classNames(
                projectDisabled ? "" : `hover:bg-iceberg-300 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`,
                `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:opacity-25`
              )}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

// Memoize the Codecs component to prevent unnecessary re-renders
export default React.memo(Codecs);