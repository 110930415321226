import React from "react";

function getPlanName(plan) {
  switch (plan) {
    case "sessioncloud_vvs":
      return "Generic VVS";
      case "generic_softphone":
        return "Generic";
    case "sessioncloud_business":
      return "Generic Business";
    case "sessioncloud_secure":
      return '"Generic Secure';
    case "sessioncloud_all":
      return "Generic";
    case "generic-platforms":
      return "Generic";
    case "whitelabel_basic":
      return "Whitelabel Basic";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    case "whitelabel-windows":
      return "Whitelabel Desktop";
    case "whitelabel_desktop":
      return "Whitelabel Desktop";
    case "whitelabel-windows-annual":
      return "Whitelabel Desktop";
    case "whitelabel_premium":
      return "Whitelabel Premium";
    case "whitelabel-mobile-annual":
      return "Whitelabel Premium";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    default:
      return "Generic";
  }
}

function getPricing(plan) {
  if (
    plan == "sessioncloud_vvs" ||
    plan == "generic_softphone" ||
    plan == "sessioncloud_business" ||
    plan == "sessioncloud_secure" ||
    plan == "sessioncloud_all" ||
    plan == "generic-platforms"
  ) {
    return "75";
  } else if (
    plan == "whitelabel_mobile" ||
    plan == "whitelabel_advanced" ||
    plan == "whitelabel_premium" ||
    plan == "whitelabel-mobile-annual"
  ) {
    return "400";
  } else if (
    plan == "whitelabel-windows" ||
    plan == "whitelabel-windows-annual" ||
    plan == "whitelabel_desktop"
  ) {
    return "400";
  }
}

function PlansList({
  handleCancelSubscription,
  handleReactivate,
  subscriptions,
  handleViewInvoices,
}) {
  console.log("subscriptions");
  console.log(subscriptions);

  return (
    <>
      <ul role="list" className=" space-y-4 ">
        {subscriptions.map((subscription) => (
          <li
            key={subscription.subscription.id}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap rounded-lg border border-gray-200 bg-white px-6"
          >
            <div>
              <p className="text-md font-semibold leading-6 text-gray-900">
                {subscription.subscription.id
                  ? subscription.subscription.id
                  : ""}
              </p>
              <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                <p>
                  {subscription.subscription.plan_id
                    ? getPlanName(subscription.subscription.plan_id)
                    : ""}
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p>
                  $
                  {subscription.subscription.plan_id
                    ? getPricing(subscription.subscription.plan_id)
                    : "0"}
                </p>
              </div>
            </div>
            <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
              {subscription.subscription.status !== "in_trial" ? (
                <button
                  type="button"
                  onClick={(e) =>
                    handleViewInvoices(e, subscription.subscription.id)
                  }
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  View Invoices
                </button>
              ) : (
                <>
                  <span
            className="text-gray-700 bg-gray-100 inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium " >
           Trial
          </span>
                </>
              )}

              {
                subscription.subscription.status != "in_trial" ? 

                <div className="flex -space-x-0.5">
                {subscription.subscription.status === "active" ? (
                  <button
                    type="button"
                    onClick={(e) =>
                      handleCancelSubscription(
                        e,
                        subscription.subscription.id,
                        subscription.subscription.plan_id
                      )
                    }
                    className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Deactivate
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) =>
                      handleReactivate(
                        e,
                        subscription.subscription.id,
                        subscription.subscription.plan_id
                      )
                    }
                    className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Reactivate
                  </button>
                )}
              </div>
              : <></>
              }

              
            </dl>
          </li>
        ))}
      </ul>
    </>
  );
}

export default PlansList;
