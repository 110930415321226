import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import OrderSummary from "./OrderSummary";
import BillingAddress from "./BillingAddress";

const deliveryMethods = [
  {
    id: 1,
    title: "Standard",
    turnaround: "4–10 business days",
    price: "$5.00",
  },
  { id: 2, title: "Express", turnaround: "2–5 business days", price: "$16.00" },
];
const paymentMethods = [
  { id: "credit-card", title: "Credit card" },
  // { id: 'paypal', title: 'PayPal' },
  // { id: 'etransfer', title: 'eTransfer' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CheckoutForm({ customer, subscription,card, project, estimate}) {
  return (
    <>
      <div className="bg-slate-50 rounded-md">
        <div className="mx-auto max-w-2xl px-4 pb-12 pt-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:pt-8">
          <h2 className="sr-only">Checkout</h2>

          <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            {/* Payment */}
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                Payment Method
              </h2>

              <fieldset className="mt-4">
                <legend className="sr-only">Payment type</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {paymentMethods.map((paymentMethod, paymentMethodIdx) => (
                    <div key={paymentMethod.id} className="flex items-center">
                      {paymentMethodIdx === 0 ? (
                        <input
                          id={paymentMethod.id}
                          name="payment-type"
                          type="radio"
                          defaultChecked
                          disabled={true}
                          className="h-4 w-4 border-gray-300 text-iceberg-400 cursor-default"
                        />
                      ) : (
                        <input
                          id={paymentMethod.id}
                          name="payment-type"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-iceberg-400 cursor-default"
                        />
                      )}

                      <label
                        htmlFor={paymentMethod.id}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {paymentMethod.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>

              <div className="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
                <div className="col-span-4">
                  <label
                    htmlFor="card-number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Card number
                  </label>
                  <div className="mt-1">
                    <input
                      type="tel"
                      maxLength={19}
                      id="card-number"
                      disabled={true}
                      value={
                        card?.masked_number
                          ? card?.masked_number
                          : ""
                      }
                      placeholder="0000 0000 0000 0000"
                      name="card-number"
                      autoComplete="cc-number"
                      className="block w-full rounded-md border-gray-300 shadow-sm cursor-default sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-4">
                  <label
                    htmlFor="expiration-date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Expiration date (MM/YY)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="expiration-date"
                      id="expiration-date"
                      disabled={true}
                      value={
                        card?.expiry_month
                          ? `${card?.expiry_month}/${card?.expiry_year}`
                          : ""
                      }
                      autoComplete="cc-exp"
                      className="block w-full rounded-md border-gray-300 shadow-sm cursor-default sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Order summary */}
            <OrderSummary
              subscription={subscription}
              project={project}
              estimate={estimate} 
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default CheckoutForm;
