import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindows,
  faAndroid,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {io} from "socket.io-client";
import { updateBuild } from "../../redux/actions/buildActions";
import { openErrorAlert } from "../../redux/actions/alertActions";



function convertPlatform(platform) {
  if (platform === "ios") {
    return "iOS";
  } else if (platform === "android") {
    return "Android";
  } else if (platform === "win") {
    return "Windows";
  } else if (platform === "mac") {
    return "Mac";
  }
}

function getStateText(build) {
  if (build == undefined || build == null) {
    return "Waiting";
  }

  if (build.status == "inProgress") {
    return "Building";
  } else if (build.result == "failed") {
    return "Failed";
  } else if (build.result == "canceled") {
    return "Cancelled";
  } else if (build.result == "succeeded") {
    return "Succeeded";
  }else if (build.status  !== undefined && build.state !== null) {
    return build.status;
  }else {
    return "Waiting";
  }
}

function getStateColour(build) {
  if (build == undefined || build == null) {
    return "text-blue-700 bg-blue-100 ";
  }

  if (build.status == "inProgress") {
    return "bg-yellow-100 text-yellow-800";
  } else if (build.result == "failed" || build.result == "canceled") {
    return "text-red-700 bg-red-100 ";
  } else if (build.result == "succeeded") {
    return "text-green-700 bg-green-100 ";
  } else {
    return "text-blue-700 bg-blue-100 ";
  }
}




function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getIcon(platform) {
  if (platform === "ios") {
    return (
      <span
        className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 "
        aria-hidden="true"
      >
        iOS
      </span>
    );
  } else if (platform === "android") {
    return (
      <FontAwesomeIcon
        icon={faAndroid}
        className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 "
        aria-hidden="true"
      />
    );
  } else if (platform === "win") {
    return (
      <FontAwesomeIcon
        icon={faWindows}
        className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 "
        aria-hidden="true"
      />
    );
  } else if (platform === "mac") {
    return (
      <FontAwesomeIcon
        icon={faApple}
        className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 "
        aria-hidden="true"
      />
    );
  }
}

function CurrentBuildList({ currentBuilds, deleteBuild, updateFinalBuild }) {
  
  const dispatch = useDispatch();
  const socketRef = useRef(null); // UseRef to manage socket instance
  const [builds, setBuilds] = useState(currentBuilds);
  const [loading, setLoading] = useState(false);
  const { success: updateSuccess, error: updateError } = useSelector(
    (state) => state.updateBuild
  );

  const updateBuilds = (buildId, progress,status) => {
    console.log("Updating buildId:", buildId, "with progress:", progress);
  
    setBuilds((prevBuilds) => {
      const updatedBuilds = prevBuilds.map((build) => {

        // convert the buildId to a number
        buildId = parseInt(buildId);
        // Check if the buildId matches the current build
        if (build.buildId === buildId) {
        
          return { ...build, completion: progress,status :status }; // Shallow copy with updated value
        }
        return build;
      });
      console.log("Updated Builds Array:", updatedBuilds);
      return updatedBuilds; // Return the updated array
    });
  };

  useEffect(() => {
    console.log("Builds state updated:", builds);
    // update build progrss on server
    builds.forEach((build) => {
      dispatch(updateBuild(build,build.status ,build.completion));
      if(build.status === "complete"){
        updateFinalBuild();
      }
    });

  }, [builds]);

  useEffect(() => {
   

    if (updateError) {
      dispatch(openErrorAlert("Error", "update build error  "));
    }

    if (updateSuccess) {
      console.log("Builds updated successfully");
    }
   

    setLoading(false);
  }, [
    updateSuccess,
    updateError,
  ]);

  useEffect(() => {
    const serverUrl = process.env.ROOT_PUBLIC_SERVER_URL;
    console.log("Connecting to server:", serverUrl);
    socketRef.current = io(serverUrl);
  
    socketRef.current.on("connect", () => {
      console.log("Connected to server");
    });
  
    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  
    socketRef.current.on("buildProgress", (data) => {
      console.log("Received build update:", data);
      updateBuilds(data.buildId, data.progress, data.status);
    });
  
    // Clean up the socket connection and listeners on unmount
    return () => {
      socketRef.current.disconnect();
    };
  }, []); // Empty dependency 

  return (
    <>
      <div className="mx-auto   sm:py-8  ">
        {/* Products */}
        <div>
          <h2 className="sr-only">Products purchased</h2>

          <div className="space-y-8">
            {builds.map((build) => (
              <div
                key={build.id}
                className="border-b border-t border-slate-200 bg-white shadow-sm sm:rounded-lg sm:border"
              >
                <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                  <div className="sm:flex lg:col-span-2">
                    <div className="mt-6 sm:mt-0">
                      <h3 className="text-base font-medium text-slate-900">
                        {getIcon(build?.platform ? build.platform : <></>)}
                        {build?.platform ? convertPlatform(build.platform) : ""}
                      </h3>
                      <p className="mt-2 text-sm font-medium text-slate-500">
                        {" "}
                      </p>
                    </div>
                  </div>

                  <div className="mt-6 lg:col-span-10 lg:mt-0 ">
                    <dl className="grid grid-cols-6 gap-x-6 text-sm">
                      <div>
                        <dt className="font-medium text-slate-500">
                          {" "}
                          Created Date
                        </dt>
                        <dd className="mt-3 text-slate-900">
                          <span className="block">
                            {build?.createdAt ? build.createdAt : ""}
                          </span>
                        </dd>
                      </div>

                      <div>
                        <dt className="font-medium text-slate-500">
                          {" "}
                          Build Number
                        </dt>
                        <dd className="mt-3 text-slate-900">
                          <span className="block">
                            {build?.buildNumber ? build.buildNumber : ""}
                          </span>
                        </dd>
                      </div>

                      <div>
                        <dt className="font-medium text-slate-500">
                          {" "}
                          Build Version
                        </dt>
                        <dd className="mt-3 text-slate-900">
                          <span className="block">
                            {" "}
                            {build?.version ? build.version : ""}
                          </span>
                        </dd>
                      </div>

                      <div>
                        <dt className="font-medium text-slate-500">
                          {" "}
                          Software Version
                        </dt>
                        <dd className="mt-3 text-slate-900">
                          <span className="block">
                            {" "}
                            {build?.tag ? build.tag : "Unknown"}
                          </span>
                        </dd>
                      </div>

                      <div>
                        <dt className="font-medium text-slate-500 text-center">
                          {" "}
                          <button
                            type="button"
                            onClick={(e) => deleteBuild(build.buildId)}
                            className="rounded-md bg-slate-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-300 focus-visible:outline 
                            focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-400"
                          >
                            Cancel
                          </button>
                        </dt>
                        <dd className="mt-3 text-slate-900"></dd>
                      </div>

                      <div>
                        <dt className="font-medium text-slate-900 text-right">
                          <span
                            className={classNames(
                              getStateColour(build),
                              "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium "
                            )}
                          >
                            {getStateText(build)}
                          </span>
                        </dt>
                        <dd className="mt-3 space-y-3 text-slate-500 text-right"></dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div className=" px-4 pb-6  sm:px-6 lg:pb-8 lg:px-8 ">
                  <h4 className="sr-only">Status</h4>
                  <p className="text-sm font-medium text-slate-500">
                    {" "}
                    {`${build ? build?.completion : 0}%`} complete
                  </p>
                  <div className="mt-4" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-slate-200">
                      <div
                        className="h-2 rounded-full bg-iceberg-400 transition-all duration-300 ease-in-out"
                        style={{
                          width: `${
                            build?.completion ? build?.completion : 0
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentBuildList;
