import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import {  useState } from "react";
import { Dialog,  } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddRuleForm({
  handleCloseSlideOver,
  handleAddRule,
  rule,
  handleSave,handleEditRule,
}) {
  
  const [ruleData, setRuleData] = useState(
    rule 
      ? rule
      : {
          Name: "",
          MatchNumber: "",
          PrefixAdd: "",
          PrefixRemove: "",
          Type: "VoIP",
        }
  );

  const handleOnChange = (e) => {
    let value;
    if (e.target.checked === true && e.target.id === "checkbox") {
      value = "GSM";
    } else if (e.target.checked === false && e.target.id === "checkbox") {
      value = "VoIP";
    } else {
      value = e.target.value;
    }

    setRuleData({ ...ruleData, [e.target.name]: value });
    console.log(ruleData);
  };

  const handleAdd = (ruleObject) => {
    if(rule){
      handleEditRule(ruleObject)
    }else{
      handleAddRule("ruleslist",ruleObject);
    }
   
  };

  function convertText(text) {
    if (text === "GSM") {
      return true;
    }
    return false;
  }

  return (
    <>
      <form className="flex h-full flex-col divide-y divide-slate-200 bg-white shadow-xl">
        <div className="h-0 flex-1 overflow-y-auto">
          <div className="bg-iceberg-500 px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                Add Rule
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="relative rounded-md bg-iceberg-500 text-iceberg-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={handleCloseSlideOver}
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-iceberg-100">
                Get started by filling in the information below to create your
                new project.
              </p>
            </div>
          </div>

          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-slate-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5 pt-6">
                <div>
                  <label
                    htmlFor="project-name"
                    className="block text-sm font-medium leading-6 text-slate-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      onChange={handleOnChange}
                      name="Name"
                      maxLength={100}
                      autoComplete="rule-name"
                      value={ruleData.Name}
                      id="project-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="project-name"
                    className="block text-sm font-medium leading-6 text-slate-900"
                  >
                    Match Number
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      onChange={handleOnChange}
                      name="MatchNumber"
                       maxLength={100}
                      autoComplete="match-number"
                      value={ruleData.MatchNumber}
                      id="project-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="project-name"
                    className="block text-sm font-medium leading-6 text-slate-900"
                  >
                    Prefix Add
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      onChange={handleOnChange}
                      name="PrefixAdd"
                       maxLength={100}
                      autoComplete="prefix-add-number"
                      value={ruleData.PrefixAdd}
                      id="project-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="project-name"
                    className="block text-sm font-medium leading-6 text-slate-900"
                  >
                    Prefix Remove
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      onChange={handleOnChange}
                      name="PrefixRemove"
                      maxLength={100}
                      autoComplete="prefix-remove-number"
                      value={ruleData.PrefixRemove}
                      id="project-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>

                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="checkbox"
                      aria-describedby="comments-description"
                      onChange={handleOnChange}
                      name="Type"
                      checked={convertText(ruleData.Type)}
                      type="checkbox"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      GSM
                    </label>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            onClick={handleCloseSlideOver}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {handleAdd(ruleData); handleCloseSlideOver()}}
            className="ml-4 inline-flex justify-center rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default AddRuleForm;
