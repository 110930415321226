import React from "react";
import { useState } from "react";
import TooltipIcon from "../TooltipIcon";

const authChoices = [
  { id: "0", name: "Username & Password" },
  { id: "1", name: "Username, Password & Authorization Username" },
  { id: "2", name: "Username, Password & Subdomain" },
  { id: "3", name: "Username, Password, Authorization Username & Subdomain" },
];

const transportOptions = [
  { id: "UDP", title: "UDP" },
  { id: "TCP", title: "TCP" },
  { id: "TLS", title: "TLS" },
];

const dtmfModes = [
  { id: "RFC2833", title: "RFC2833" },
  { id: "inband", title: "Inband" },
  { id: "sipinfo", title: "SIP INFO" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LoginFields({ projectData, handleOnChange, handleOnUnnestedChange, projectDisabled }) {

  function getChoice(typeId, choiceId) {
    if (choiceId === typeId) {
      return true;
    }
    return false;
  }


  function convertText(text) {
    if (text === "Enabled" || text === "on" ) {
      return true;
    }
    return false;
  }


  const transportDescription = `UDP - This is the most common transport used by most SIP servers,
            however due to keepalives required to keep NAT pinholes open, this
            will drain the battery on a mobile device where the softphone is
            being used. It is recommended to use our Push option for incoming
            calls. TCP - less common than UDP. Only set this if you are
            sure your SIP server supports this transport. Push is still the
            recommended option, although battery drain will not usually be as
            great as when using UDP.
             TLS - all SIP messages are encrypted, only set this if your
            SIP server supports.`;

  return (
    <>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-4">
          <fieldset>
            <legend className="sr-only">Auth Choices</legend>
            <div className="space-y-5">
              {authChoices.map((choice) => (
                <div key={choice.id} className="relative flex items-start ">
                  <div className="flex h-6 items-center">
                    <input
                      checked={getChoice(
                        projectData?.settings?.provisioning?.preconfiguredtype
                          ? projectData?.settings.provisioning.preconfiguredtype
                          : "0",
                        choice.id
                      )}
                      id={`radiobutton-${choice.id}`}
                      name="preconfiguredtype"
                      aria-describedby="preconfigured-auth-choice-settings"
                      value={choice.id}
                      type="radio"
                      disabled={projectDisabled}
                      onChange={handleOnChange}
                      className={classNames(`h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 
                      disabled:opacity-50`)}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor={choice.id}
                      className="font-medium text-slate-900"
                    >
                      {choice.name}
                    </label>{" "}
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        {projectData?.type == "sessioncloud_vvs" ||
        projectData?.type == "sessioncloud_business" ||
        projectData?.type == "generic_softphone" ||
        projectData?.type == "sessioncloud_secure" ||
        projectData?.type == "generic-platforms" ||
        projectData?.type == "sessioncloud_all"  ? (
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Provider Id
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="first-name"
                placeholder="Company Name"
                name="operatorcode"
                disabled={projectDisabled}
                aria-describedby="provider-id-settings"
                value={projectData?.operatorcode ? projectData?.operatorcode : ""}
                onChange={handleOnUnnestedChange}
                autoComplete="given-name"
               
                minLength="6"
                maxLength="32"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
        invalid:border-red-500 invalid:text-red-600
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p className="mt-2 text-sm text-slate-500" id="email-description">
              Provider Id must have between six and thirty-two characters
            </p>
          </div>
        ) : (
          <></>
        )}

        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            Provider Name
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="first-name"
              name="providerName"
              value={
                projectData?.settings?.provisioning?.providerName
                  ? projectData?.settings.provisioning.providerName
                  : ""
              }
              aria-describedby="provider-name-settings"
              onChange={handleOnChange}
              disabled={projectDisabled}
              placeholder="VoIP Service Name"
              autoComplete="given-name"
              minLength="6"
              maxLength="32"
              className={classNames(
                `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
          </div>
          <p className="mt-2 text-sm text-slate-500" id="email-description">
            Provider Name must have between six and thirty-two characters
          </p>
        </div>

        {projectData?.settings?.provisioning?.opendomain ? 
              (projectData?.settings.provisioning.opendomain != "Enabled" )? (
          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Domain
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="last-name"
                name="domain"
                value={
                  projectData?.settings?.provisioning?.domain ? projectData?.settings.provisioning.domain : ""
                }
                autoComplete="domain"
                onChange={handleOnChange}
                minLength={1}
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                maxLength="64"
                placeholder="sip.cloudpbx.com"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p
              className="mt-2 text-sm text-slate-500"
              id="email-description"
            ></p>
          </div>
        ) : (
          <></>) : <></>
        }

       
        <div className="col-span-full">
          <label className="text-sm font-semibold leading-6 text-slate-900">
            Transport
            <span className=" ml-4 inline-block align-top ">
              <TooltipIcon description={transportDescription} />
            </span>
          </label>
          <p className="text-sm text-slate-500">
            Choose the right transport method for your SIP server.
          </p>
          <fieldset className="mt-4">
            <legend className="sr-only"> Transport</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {transportOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input
                    id={`radiobutton-${option.id}`}
                    name="transport"
                    type="radio"
                    disabled={projectDisabled}
                    aria-describedby="transport-settings"
                    value={option.id}
                    checked={getChoice(
                      projectData?.settings?.provisioning?.transport
                        ? projectData?.settings.provisioning.transport
                        : "UDP",
                      option.id
                    )}
                    onChange={handleOnChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
        <div className="col-span-full">
          <label className="text-sm font-semibold leading-6 text-slate-900">
            DTMF Mode
          </label>
          <p className="text-sm text-slate-500">
            Select the appropriate DTMF mode that matches your SIP server
            configuration.
          </p>
          <fieldset className="mt-4">
            <legend className="sr-only">DTMF Mode</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {dtmfModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="dtmfmode"
                    aria-describedby="dtmf-settings"
                    type="radio"
                    disabled={projectDisabled}
                    value={mode.id}
                    checked={getChoice(
                      projectData?.settings?.provisioning?.dtmfmode
                        ? projectData?.settings.provisioning.dtmfmode
                        : "RFC2833",
                      mode.id
                    )}
                    onChange={handleOnChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
}

export default LoginFields;
