import React from "react";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";

export default function ResetPassword() {
  return (
    <div className="flex items-center justify-center min-h-screen py-2">
      <ResetPasswordForm />
    </div>
  );
}
