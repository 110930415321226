import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

function InfoAlert({message}) {
  return (
    <>
     <div className="rounded-md bg-iceberg-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-iceberg-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-iceberg-700">{message}</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            
          </p>
        </div>
      </div>
    </div>
    </>
  )
}

export default InfoAlert