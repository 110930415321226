import React from 'react'

function Title({title,action, buttonTitle,}) {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  return (
   

  <>
   <div className="md:flex md:items-center md:justify-between">
    <div className="min-w-0 flex-1">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight md:pt-8 md:pb-4 py-2">
      {title}
      </h2>
    </div>

    {
      buttonTitle ? <div className="mt-4 flex md:ml-4 md:mt-0">
        
      <button
        type="button"
        onClick={action}
        className={classNames( buttonTitle === "Cancel" ? "bg-gray-600 hover:bg-gray-700 focus-visible:outline-gray-600" : "bg-iceberg-400 hover:bg-iceberg-500 focus-visible:outline-iceberg-400",
        "inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ")}
      >
       {buttonTitle}
      </button>
    </div> : <>
    </>
    }
    
  </div>
  </>
  )
}

export default Title