import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Common({ projectData, handleOnChange, projectDisabled }) {
  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          Common
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
          Configure and manage the essential login details for your web app.
          Customize required fields to enhance security, control access, and
          tailor your login experience. Safeguard your account while simplifying
          the login process for your users.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 pt-6">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                App Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="first-name"
                  name="appName"
                  value={
                    projectData?.settings?.appstore?.appName
                      ? projectData?.settings?.appstore?.appName
                      : ""
                  }
                  aria-describedby="provider-name-settings"
                  onChange={handleOnChange}
                  disabled={projectDisabled}
                
                  autoComplete="app-name"
                  noValidate
                  minLength="3"
                  maxLength="12"
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-slate-500" id="email-description">
                App Name must have between three and twelve characters
              </p>
            </div>

            <div className="sm:col-span-3 pt-6">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                App Identifier
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="last-name"
                  name="appIdentifier"
                  value={
                    projectData?.settings?.appstore?.appIdentifier
                      ? projectData?.settings?.appstore?.appIdentifier
                      : ""
                  }
                  autoComplete="app-identifier"
                  onChange={handleOnChange}
                  noValidate
                  disabled={projectDisabled}
                  aria-describedby="domain-settings"
                  maxLength="32"
                  minLength={6}
                  
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-slate-500" id="email-description">
                App Identifier must have between six and thirty two characters
              </p>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                Update Private Key
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="first-name"
                  name="updateprivatekey"
                  value={
                    projectData?.settings?.appstore?.updateprivatekey
                      ? projectData?.settings?.appstore?.updateprivatekey
                      : ""
                  }
                  aria-describedby="provider-name-settings"
                  onChange={handleOnChange}
                  disabled={projectDisabled}
                  
                   maxLength={100}
                  autoComplete="private-key"
                  noValidate
                 minLength={1}
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
             
            </div>

            <div className="col-span-full">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                Update Public Key
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="last-name"
                  name="updatepublickey"
                  value={
                    projectData?.settings?.appstore?.updatepublickey
                      ? projectData?.settings?.appstore?.updatepublickey
                      : ""
                  }
                  maxLength={100}
                  autoComplete="public-key"
                  onChange={handleOnChange}
                  noValidate
                  disabled={projectDisabled}
                  aria-describedby="domain-settings"
                  minLength={1}
                
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
              <p
                className="mt-2 text-sm text-slate-500"
                id="email-description"
              ></p>
            </div>
          </div>
        </dl>
      </div>
    </>
  );
}

export default Common;
