import { configureStore } from "@reduxjs/toolkit";

import {
  addRuleSlideOverReducer,
  projectDetailsSlideOverReducer,
} from "./reducers/slideOverReducers";

import {
  deleteProjectModalReducer,
  cancelSubscriptionModalReducer,
  openProjectNameModalReducer,
} from "./reducers/modalReducers";

import {
  getPricingDetailsReducer,
  getExistingCustomerSubscriptionReducer,
  updateCustomerSubscriptionReducer
} from "./reducers/pricingReducers";

import {
  getProjectsReducer,
  getWhitelabelLiveAppsReducer,
  getSessionCloudLiveAppsReducer,
  getProjectsCountReducer,
  getLiveAppsCountReducer,
  getProjectReducer,
  checkProjectReducer,
  createProjectReducer,
  getRuleReducer,
  getProjectBillingReducer,
  getAdminProjectsReducer,
  changeProjectReducer,
  getGraphicsReducer,
  saveGraphicsReducer,
  saveFileReducer,
  updateProjectReducer
} from "./reducers/projectReducers";

import {
  getUserReducer,
  getInvoicesReducer,
  getInvoicePDFReducer,
  getSubscriptionsReducer,
  getCustomerDetailsReducer,
  getSubscriptionReducer,
  getEstimateReducer,
  getCheckoutHostedPageReducer,
  retrieveInvoiceReducer,
  checkAdminReducer,
  sendQRCodeEmailReducer
} from "./reducers/userReducers";

import {
  getBuildsReducer,
  createBuildReducer,
  updateBuildReducer,
  getCurrentBuildsReducer,
  deleteBuildReducer,
  downloadBuildReducer,
  cancelBuildReducer,
} from "./reducers/buildReducers";

import { alertStatusReducer } from "./reducers/alertReducers";  
import { saveFile } from './actions/projectActions';



export const store = configureStore({
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: true,
  }),
  reducer: {
    addRuleSlideOver: addRuleSlideOverReducer,
    deleteProjectModal: deleteProjectModalReducer,
    projectDetailsSlideOver: projectDetailsSlideOverReducer,
    cancelSubscriptionModal: cancelSubscriptionModalReducer,
    openProjectNameModal: openProjectNameModalReducer,
    getProjects: getProjectsReducer,
    getPricing: getPricingDetailsReducer,
    getWhitelabelLiveApps: getWhitelabelLiveAppsReducer,
    getSessionCloudLiveApps: getSessionCloudLiveAppsReducer,
    getProjectsCount: getProjectsCountReducer,
    getLiveAppsCount: getLiveAppsCountReducer,
    getProject: getProjectReducer,
    updateProject:updateProjectReducer,
    checkProject: checkProjectReducer,
    createProject: createProjectReducer,
    getRule: getRuleReducer,
    changeProject: changeProjectReducer,
    getProjectBilling: getProjectBillingReducer,
    getAdminProjects: getAdminProjectsReducer,
    getBuilds: getBuildsReducer,
    getUser: getUserReducer,
    getSubscriptions: getSubscriptionsReducer,
    getInvoices: getInvoicesReducer,
    getInvoicePDF: getInvoicePDFReducer,
    createBuild: createBuildReducer,
    updateBuild: updateBuildReducer,
    getCurrentBuilds: getCurrentBuildsReducer,
    deleteBuild: deleteBuildReducer,
    downloadBuild: downloadBuildReducer,
    cancelBuild: cancelBuildReducer,
    getCustomerDetails: getCustomerDetailsReducer,
    getSubscription: getSubscriptionReducer,
    getEstimate: getEstimateReducer,
    getHostedCheckoutPage: getCheckoutHostedPageReducer,
    retrieveInvoice: retrieveInvoiceReducer,
    getGraphics:getGraphicsReducer,
    saveGraphics:saveGraphicsReducer,
    saveFile:saveFileReducer,
    alertStatus: alertStatusReducer,
    getCustomerSubscriptionReducer:getExistingCustomerSubscriptionReducer,
    updateSubscriptionReducer:updateCustomerSubscriptionReducer,
    checkAdminReducer:checkAdminReducer,
    sendQRCodeEmailReducer:sendQRCodeEmailReducer
  },
});
