import React from 'react'
import {
  DocumentIcon
} from "@heroicons/react/24/solid";

import { DocumentPlusIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


function AppleOS({ projectData, handleOnChange, projectDisabled, handleOnFileUpload, handleImageDrop }) {

  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event){
    event.preventDefault();
  };

  return (
    <>
     <div >
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          iOS
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-600">
          We'll always let you know about important changes, but you pick what
          else you want to hear about.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             iOS Version
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="appVersion"
                value={
                  projectData?.settings?.appstore?.appVersion ? projectData?.settings?.appstore?.appVersion : ""
                }
                 maxLength={100}
                  autoComplete="ios-version"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              iOS Build No
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="buildNumber"
                value={
                  projectData?.settings?.appstore?.buildNumber ? projectData?.settings?.appstore?.buildNumber : ""
                }
                 maxLength={100}
                  autoComplete="build-number"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
              
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             Provisioning Profile Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="provprofileName"
                value={
                  projectData?.settings?.appstore?.provprofileName ? projectData?.settings?.appstore?.provprofileName : ""
                }
                 maxLength={100}
                  autoComplete="prov-profile-name"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             Provisioning Profile UUID
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="provprofileUUID"
                value={
                  projectData?.settings?.appstore?.provprofileUUID ? projectData?.settings?.appstore?.provprofileUUID : ""
                }
                 maxLength={100}
                  autoComplete="prov-profile-uuid"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
            Provisioning Profile
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
             name="pprofile"
             disabled={projectDisabled}
             onDrop={(e)=> handleImageDrop(e, "pprofile", "pprofileName")}
             onDragOver={handleDragOver}>
              <div className="text-center">
              <p className="text-lg font-semibold leading-5 text-slate-600 mb-2">
                  {
                    projectData?.pprofileName
                    ? projectData?.pprofileName
                    : ""
                  }
                  </p>
              { projectData?.pprofileName ? (
                    <DocumentIcon
                    className="mx-auto h-12 w-12 text-iceberg-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentPlusIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="pprofile"
                      type="file"
                      className="sr-only"
                      onChange={(e)=> handleOnFileUpload(e, "pprofile", "pprofileName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
               
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             Messaging Extension Provisioning Profile Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="msg_provprofileName"
                value={
                  projectData?.settings?.appstore?.msg_provprofileName ? projectData?.settings?.appstore?.msg_provprofileName : ""
                }
                 maxLength={100}
                  autoComplete="msg-prov-profile-name"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
            Messaging Extension Provisioning Profile UUID
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="msg_provprofileUUID"
                value={
                  projectData?.settings?.appstore?.msg_provprofileUUID ? projectData?.settings?.appstore?.msg_provprofileUUID : ""
                }
                maxLength={100}
                  autoComplete="msg-prov-profile-uuid"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
            Messaging Extension Provisioning Profile
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
               name="msg_pprofile"
               disabled={projectDisabled}
             onDrop={(e)=> handleImageDrop(e, "msg_pprofile", "msg_pprofileName")}
             onDragOver={handleDragOver}>
              <div className="text-center">
              <p className="text-lg font-semibold leading-5 text-slate-600 mb-2">
                  {
                    projectData?.msg_pprofileName
                    ? projectData?.msg_pprofileName
                    : ""
                  }
                  </p>
              { projectData?.msg_pprofileName ? (
                    <DocumentIcon
                    className="mx-auto h-12 w-12 text-iceberg-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentPlusIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload1"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload1"
                      name="msg_pprofile"
                      type="file"
                      className="sr-only"
                      onChange={(e)=> handleOnFileUpload(e, "msg_pprofile", "msg_pprofileName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
              
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             Distribution Certificate Identity
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="certificateIdentity"
                value={
                  projectData?.settings?.appstore?.certificateIdentity ? projectData?.settings?.appstore?.certificateIdentity : ""
                }
                maxLength={100}
                  autoComplete="certificate-identity"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
           Distribution Certificate Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="certificatePassword"
                value={
                  projectData?.settings?.appstore?.certificatePassword ? projectData?.settings?.appstore?.certificatePassword : ""
                }
                maxLength={100}
                  autoComplete="certificate-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
            Distribution Certificate
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
              name="iosdistcert"
              disabled={projectDisabled}
             onDrop={(e)=> handleImageDrop(e, "iosdistcert", "iosdistcertName")}
             onDragOver={handleDragOver}>
              <div className="text-center">
              <p className="text-lg font-semibold leading-5 text-slate-600 mb-2">
                  {
                    projectData?.iosdistcertName
                    ? projectData?.iosdistcertName
                    : ""
                  }
                  </p>
              { projectData?.iosdistcertName ? (
                    <DocumentIcon
                    className="mx-auto h-12 w-12 text-iceberg-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentPlusIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload2"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload2"
                      name="iosdistcert"
                      type="file"
                      className="sr-only"
                      onChange={(e)=> handleOnFileUpload(e, "iosdistcert", "iosdistcertName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
          
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
             iTunes Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="iTunesUsername"
                value={
                  projectData?.settings?.appstore?.iTunesUsername ? projectData?.settings?.appstore?.iTunesUsername : ""
                }
                maxLength={100}
                  autoComplete="itunes-username"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
                
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
          iTunes Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="iTunesPassword"
                value={
                  projectData?.settings?.appstore?.iTunesPassword ? projectData?.settings?.appstore?.iTunesPassword : ""
                }
                maxLength={100}
                  autoComplete="itunes-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>
        
        </div>
        </dl>
      </div>
    </>
  )
}

export default AppleOS