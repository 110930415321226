import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DetailsForm({projectData, handleOnChange, projectDisabled}) {
  return (
    <>
     <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          Details
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
        Fine-tune your softphone's behavior and preferences.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6"></div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3 pt-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            Contact email
          </label>
          <div className="mt-2">
            <input
              type="email"
              name="contactEmail"
              disabled={projectDisabled}
              aria-describedby="contact-email"
              value={projectData?.settings?.miscellaneous?.contactEmail ? projectData?.settings.miscellaneous.contactEmail : ""}
              onChange={handleOnChange}
              id="first-name"
              minLength={1}
              maxLength={254}
              noValidate
              autoComplete="contact-email"
              className={classNames(
                `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-3 pt-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
          >
            Main Help Menu Title
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="helptitle"
              disabled={projectDisabled}
              aria-describedby="contact-email"
              value={projectData?.settings?.miscellaneous?.helptitle ? projectData?.settings.miscellaneous.helptitle : ""}
              onChange={handleOnChange}
              id="first-name"
              minLength={1}
              maxLength={100}
              noValidate
              autoComplete="help-title"
              className={classNames(
                `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default DetailsForm;
