import { OPEN_ADD_RULE_SLIDEOVER } from "../constants/slideOverConstants";
import { CLOSE_ADD_RULE_SLIDEOVER } from "../constants/slideOverConstants";
import { OPEN_PROJECT_DETAILS_SLIDEOVER } from "../constants/slideOverConstants";
import { CLOSE_PROJECT_DETAILS_SLIDEOVER } from "../constants/slideOverConstants";

export const addRuleSlideOverReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_ADD_RULE_SLIDEOVER:
      console.log("ran open add rule slideover reducer");
      return {
        ...state,
        open: true,
      };

    case CLOSE_ADD_RULE_SLIDEOVER:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const projectDetailsSlideOverReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_PROJECT_DETAILS_SLIDEOVER:
      console.log("ran open project details slideover reducer");
      return {
        ...state,
        open: true,
      };

    case CLOSE_PROJECT_DETAILS_SLIDEOVER:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};
