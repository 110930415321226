import React from 'react'
import AppLayout from '../components/layout/AppLayout'
import Container from '../components/containers/Container'
import Title from '../components/titles/Title'
import LoadingIndicator from '../components/common/LoadingIndicator'  
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { retrieveInvoice, retrievePage } from "../redux/actions/userActions";
import { openErrorAlert } from "../redux/actions/alertActions";
import { useEffect, useRef } from 'react';  
import { moveToPaid } from '../redux/actions/projectActions'; 
import { openStatusAlert } from '../redux/actions/alertActions';

export default function CheckoutConfirm() {
    const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const { invoiceId,accountName } = useParams();
  const navigate = useNavigate();

  const { invoice:result, error, loading } = useSelector((state) => {
    return state.retrieveInvoice;
  });

  const { success } = useSelector((state) => {
    return state.changeProject;
  });

  

  useEffect(() => {
    if (isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(retrieveInvoice(invoiceId));
      isMountedRef.current = false;
    }

    if(error){
      if(error.message === "Invalid session token"){
        dispatch(openErrorAlert("Error","Session expired, please login again"));
        navigate("/login");
      }  
      else {
        dispatch(openErrorAlert("Error", "Invoice not found"));
      }
    }

   
  }, [result, error]);

  useEffect(() => {
    if(result != null && result != undefined) {
      dispatch(moveToPaid(accountName));
    }
  }, [result]);

  useEffect(() => {
    if(success) {
      dispatch(openStatusAlert("Success", "Project moved to live status"));
    }
  }, [success]);

  function navigateApps() {
    navigate("/");
  }


  return (
    <>
    <AppLayout />
    <Container>
        <Title title={""} 
        action={navigateApps} buttonTitle={"Back"}/>
        { result != null && result != undefined ?
         <div className="flex flex-col items-center justify-center animate-fadeInRight">
    <div className="flex flex-col md:flex-row text-center">
      <div>
        <h2 className="font-bold">Thank You for your order</h2>
        <small>You should receive an email shortly confirming your purchase.</small>
      
        <div className="xs:block text-center">
          <img src="..\..\success.png" alt="Success" />
        </div>
        <div className="text-center">
          <h2 className="font-bold">Payment Information</h2>
          <hr />
          <table className="mx-auto">
            <tbody>
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Order ID:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{result.invoice.id}</h4></td>
            </tr>
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Total:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{result.invoice.total}</h4></td>
            </tr>
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Date:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{result.invoice.date}</h4></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>  :  <LoadingIndicator />}
    </Container>
    </>
  )
}
