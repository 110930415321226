import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa, faCcMastercard, faCcJcb, faCcDiscover, faCcAmex
} from "@fortawesome/free-brands-svg-icons";
import {  CreditCardIcon, } from "@heroicons/react/20/solid";



function getPaymentClasses(card_type) {
  if (card_type === "visa")
    return (
      <FontAwesomeIcon
        icon={faCcVisa}
        className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400  "
        aria-hidden="true"
      />
    );
  else if (card_type === "mastercard")
    return (
      <FontAwesomeIcon
        icon={faCcMastercard}
        className="-ml-0.5 mr-1.5 h-6 w-6 text-gray-400  "
        aria-hidden="true"
      />
    );
  else if (card_type === "discover")
    return (
      <FontAwesomeIcon
        icon={faCcDiscover}
        className="-ml-0.5 mr-1.5 h-6 w-6 text-gray-400  "
        aria-hidden="true"
      />
    );
  else if (card_type === "American Express")
    return (
      <FontAwesomeIcon
        icon={faCcAmex}
        className="-ml-0.5 mr-1.5 h-6 w-6 text-gray-400  "
        aria-hidden="true"
      />
    );
  else if (card_type === "JCB")
    return (
      <FontAwesomeIcon
        icon={faCcJcb}
        className="-ml-0.5 mr-1.5 h-6 w- text-gray-400  "
        aria-hidden="true"
      />
    );
  else if (card_type === "None")
    return (
      <CreditCardIcon
        className="-ml-0.5 mr-1.5 h-6 w-6 text-gray-400  "
        aria-hidden="true"
      />
    );
}

function PaymentDetails({action, customer}) {
  return (
    <>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Payment Details
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={action}
              className="block rounded-md bg-iceberg-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
            >
              Update Payment
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Card
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Expiry Date
                    </th>
                    {/* <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                     
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
              
                    <tr key={customer?.card?.masked_number }>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {customer?.card?.first_name ? customer?.card.first_name : ""}{" "}
                        {customer?.card?.last_name ? customer?.card?.last_name : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {getPaymentClasses(customer?.card?.card_type ? customer?.card.card_type : <></>)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {customer?.card?.masked_number ? customer?.card?.masked_number : " "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {customer?.card?.expiry_month ? customer?.card?.expiry_month : " "}
                        {"/"}
                        {customer?.card?.expiry_year ? customer?.card?.expiry_year : " "}
                      </td>
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                       
                      </td> */}
                    </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
