import React from "react";
import { Fragment, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { getAdminProjects, getProjectsCount } from "../../redux/actions/projectActions";
import { useDispatch } from "react-redux";

const sortOptions = [
  { id: "newest-created-date", name: "Newest Created Date", href: "#", current: true },
  { id: "oldest-created-date", name: "Oldest Created Date", href: "#", current: false },
  {
    id: "newest-updated-date",
    name: "Newest Updated Date",
    href: "#",
    current: false,
  },
  { id: "oldest-updated-date", name: "Oldest Updated Date", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminSort({parameterFilters,setParameterFilters, itemsPerPage, setCurrentPage}) {
  const [open, setOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState("newest-updated-date");
  const [selectedSearch, setSelectedSearch] = useState("");
 
  const [currentFilters, setCurrentFilters] = useState([
    {
      id: "stage",
      name: "Stage",
      options: [
        {
          value: "Prepare",
          label: "Prepare",
          checked: false,
        },
        {
          value: "Submitted",
          label: "Submitted",
          checked: false,
        },
        {
          value: "Approved",
          label: "Approved",
          checked: false,
        },
        {
          value: "Trial",
          label: "Trial",
          checked: false,
        },
        {
          value: "Live",
          label: "Live",
          checked: false,
        },
        {
          value: "Update",
          label: "Update",
          checked: false,
        },
        {
          value: "Cancelled",
          label: "Cancelled",
          checked: false,
        },
        {
          value: "Rejected",
          label: "Rejected",
          checked: false,
        },
      ],
    },
    {
      id: "plan",
      name: "Plan",
      options: [
        { value: "generic", label: "Generic", checked: false },
        { value: "whitelabel", label: "Whitelabel", checked: false },
      ],
    },
    {
      id: "progress",
      name: "Progress",
      options: [
        { value: "in-progress", label: "In Progress", checked: false },
        { value: "complete", label: "Complete", checked: false },
      ],
    },
  ]);

  const dispatch = useDispatch();


  function handleOnSort(sortOption) {
    const parameterObject = {
     sort:sortOption,
     search:selectedSearch,
     plans: parameterFilters.plans,
     stages: parameterFilters.stages,
     progress:parameterFilters.progress,
    };

    setSelectedSort(sortOption)

    setParameterFilters(parameterObject)

    dispatch(getAdminProjects(0, itemsPerPage, parameterObject));
    setCurrentPage(1)
  }

  function handleOnChange(sectionIdx, optionIdx, parameter, e) {
    console.log("parameter " + parameter);
    console.log("optionIdx " + optionIdx);
    console.log("sectionIdx " + sectionIdx);

    if (e.target.checked) {
      addFilter(sectionIdx, optionIdx, parameter);
    } else {
      removeFilter(sectionIdx, optionIdx, parameter);
    }

    setCurrentFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];

      updatedFilters[sectionIdx].options[optionIdx].checked = e.target.checked;
      return updatedFilters;
    });
    return;
  }

  function addFilter(sectionIdx, optionIdx, parameter) {
    const parameterObject = {
      sort:selectedSort,
      search:selectedSearch,
      plans: [],
      stages: [],
    };

    if (sectionIdx === 0) {
      parameterObject.stages.push(parameter);
    }

    currentFilters[0].options.forEach((option) => {
      if (option.checked === true) {
        parameterObject.stages.push(option.value);
      }
    });

    if (
      currentFilters[1].options[0].checked === true ||
      (sectionIdx === 1 && optionIdx === 0 )
    ) {
      parameterObject.plans.push(
        "sessioncloud_vvs",
        "sessioncloud_business",
        "sessioncloud_secure",
        "generic-platforms",
        "generic_softphone",
        "sessioncloud_all"
      );
    }

    if (
      currentFilters[1].options[1].checked === true ||
      (sectionIdx === 1 && optionIdx === 1 )
    ) {
      parameterObject.plans.push(
        "whitelabel_advanced",
        "whitelabel-windows",
        "whitelabel_desktop",
        "whitelabel_premium",
        "whitelabel_mobile",
        "whitelabel-windows-annual",
        "whitelabel-mobile-annual",
        "whitelabel_advanced"
      );
    }

    if(sectionIdx === 2){

      if(optionIdx === 0 && currentFilters[2].options[1].checked === false){
        parameterObject.progress = "incomplete";
      }else if(optionIdx === 1 && currentFilters[2].options[0].checked === false){
        parameterObject.progress = "complete";
      }
    }else{
      
      if(currentFilters[2].options[1].checked === false && currentFilters[2].options[0].checked === true){
        parameterObject.progress = "incomplete";
      }else if(currentFilters[2].options[1].checked === true && currentFilters[2].options[0].checked === false){
        parameterObject.progress = "complete";
      }
    }

    setParameterFilters({
      search:parameterObject.search,
      sort:parameterObject.sort,
      plans:parameterObject.plans,
      stages:parameterObject.stages,
      progress:parameterObject.progress,
    })
    dispatch(getAdminProjects(0, itemsPerPage, parameterObject));
    dispatch(getProjectsCount(parameterObject));
    setCurrentPage(1)
  }

  function removeFilter(sectionIdx, optionIdx, parameter) {
    const parameterObject = {
      sort:selectedSort,
      search:selectedSearch,
      plans: [],
      stages: [],
    };

    currentFilters[0].options.forEach((option) => {
      if (option.checked === true) {
        parameterObject.stages.push(option.value);
      }
    });

    if (sectionIdx === 0) {
      const index = parameterObject.stages.indexOf(parameter);

      if (index !== -1) {
        parameterObject.stages.splice(index, 1);
      }
    }

    if (
      currentFilters[1].options[0].checked === true &&
      !(sectionIdx === 1 && optionIdx == 0 )
    ) {
      parameterObject.plans.push(
        "sessioncloud_vvs",
        "sessioncloud_business",
        "sessioncloud_secure",
        "generic-platforms",
        "sessioncloud_all"
      );
    }

    if (
      currentFilters[1].options[1].checked === true &&
      !(sectionIdx === 1 && optionIdx == 1 )
    ) {
      parameterObject.plans.push(
        "whitelabel_mobile",
        "whitelabel_advanced",
        "whitelabel-windows",
        "whitelabel_desktop",
        "whitelabel_premium",
        "whitelabel-windows-annual",
        "whitelabel-mobile-annual",
        "whitelabel_advanced"
      );
    }

    if(sectionIdx === 2){

      if(optionIdx == 0 && currentFilters[2].options[1].checked === true){
        parameterObject.progress = "complete"
      }else {
        parameterObject.progress = "incomplete";
      }
    }else{
      if(currentFilters[2].options[1].checked === false && currentFilters[2].options[0].checked === true){
        parameterObject.progress = "incomplete";
      }else if(currentFilters[2].options[1].checked === true && currentFilters[2].options[0].checked === false){
        parameterObject.progress = "complete";
      }
    }

    setParameterFilters({
      search:parameterObject.search,
      sort:parameterObject.sort,
      plans:parameterObject.plans,
      stages:parameterObject.stages,
      progress:parameterObject.progress,
    })

    dispatch(getAdminProjects(0, itemsPerPage, parameterObject));
    dispatch(getProjectsCount(parameterObject));
    setCurrentPage(1)
  }

  function handleKeyDown(e){

    if(e.key === 'Enter'){
      const parameterObject = {
        sort:selectedSort,
        search:selectedSearch,
        plans: parameterFilters.plans,
        stages: parameterFilters.stages,
        progress:parameterFilters.progress,
       };

       setParameterFilters(parameterObject)
   
       dispatch(getAdminProjects(0, itemsPerPage, parameterObject));
       dispatch(getProjectsCount(parameterObject));
       setCurrentPage(1)
    }
    
  }

  console.log("current filters");
  console.log(currentFilters);

  return (
    <>
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 sm:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 
                      hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-iceberg-300"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    {currentFilters.map((section,sectionIdx) => (
                      <Disclosure
                        as="div"
                        key={section.name}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                <span className="font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "-rotate-180" : "rotate-0",
                                      "h-5 w-5 transform"
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={option.value}
                                      checked={option.checked}
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleOnChange(
                                          sectionIdx,
                                          optionIdx,
                                          option.value,
                                          e
                                        )
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-300"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <section
          aria-labelledby="filter-heading"
          className="border-t border-gray-200 pt-6"
        >
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 ">
              <div className="flex items-center px-6 md:mx-auto md:max-w-3xl lg:ml-0 lg:max-w-none xl:pl-0  lg:mr-6 xl:pr-6">
                <div className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      value={selectedSearch}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => setSelectedSearch(e.target.value)}
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-iceberg-300 sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <div className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
              <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
                {currentFilters.map((section, sectionIdx) => (
                  <Popover
                    as="div"
                    key={section.name}
                    id={`desktop-menu-${sectionIdx}`}
                    className="relative inline-block text-left"
                  >
                    <div>
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 ring-0 ring-white border-0 outline-0">
                        <span>{section.name}</span>
                        
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-${section.id}-${optionIdx}`}
                                name={option.value}
                                checked={option.checked}
                                type="checkbox"
                                onChange={(e) =>
                                  handleOnChange(
                                    sectionIdx,
                                    optionIdx,
                                    option.value,
                                    e
                                  )
                                }
                                className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-300"
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </form>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                ))}
              </Popover.Group>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.id}>
                          {({ active }) => (
                            <button
                              onClick={(e) => handleOnSort(option.id)}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                option.id === selectedSort ? "text-gray-900" : "text-gray-500",
                                "block px-4 py-2 text-sm font-medium "
                              )}
                            >
                              {option.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AdminSort;
