import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function Balance({projectData, handleOnChange, projectDisabled}) {
  return (
    <>
     <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {" "}
          Balance
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
        View your credit from within the app.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Balance URL
            </label>
            <div className="mt-2">
              <input
                type="text"
                disabled={projectDisabled}
                name="balanceurl"
                value={
                  projectData?.settings?.provisioning?.balanceurl ? projectData?.settings.provisioning.balanceurl : ""
                }
                onChange={handleOnChange}
                maxLength={100}
                aria-describedby="balance-url-settings"
                id="first-name"
                autoComplete="balance-url"
                className={classNames(
                  `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Balance Tag
            </label>
            <div className="mt-2">
              <input
                type="text"
                disabled={projectDisabled}
                name="balancetag"
                value={
                  projectData?.settings?.provisioning?.balancetag ? projectData?.settings.provisioning.balancetag : ""
                }
                onChange={handleOnChange}
                aria-describedby="balance-tag-settings"
                id="last-name"
                maxLength={100}
                autoComplete="balance-tag"
                className={classNames(
                  `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          {projectData?.settings ? (
            (projectData.type.includes("whitelabel") )
             ? (
              <div className="sm:col-span-4">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="checkbox"
                      aria-describedby="push-simulate-nat-description"
                      name="opendomain"
                      disabled={projectDisabled}
                      type="checkbox"
                      checked={convertText(
                        projectData?.settings?.provisioning?.opendomain
                          ? projectData?.settings?.provisioning.opendomain
                          : "Disabled"
                      )}
                      onChange={handleOnChange}
                      className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900"
                    >
                    Open Domain{" "}
                    <span className="font-normal text-gray-500">
                    {" "}
                    ( Please note enabling this option
                                                is subject to per user monthly fees )
                      </span>
                    </label>{" "}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
          }
        </div>
        </div>
      </div>
    </>
  );
}

export default Balance;
