import React from "react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled") {
    return true;
  }
  return false;
}

function PrivacyPolicy({projectData, handleOnChange, projectDisabled, handleSave}) {
  const navigate = useNavigate();
  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Provisioning
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
              Provision your softphone with ease.
              </p>

              <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full pt-6">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="checkbox"
                      disabled={projectDisabled}
                      name="enableprivacypolicy"
                      onChange={handleOnChange}
                      aria-describedby="enable-privacy-policy-input"
                      checked={convertText(
                        projectData?.settings?.miscellaneous?.enableprivacypolicy
                          ? projectData?.settings?.miscellaneous.enableprivacypolicy
                          : "Disabled"
                      )}
                      type="checkbox"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      Enable Privacy Policy
                    </label>{" "}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="style2"
                      disabled={projectDisabled}
                      name="privacybtncolor"
                      onChange={handleOnChange}
                      aria-describedby="privacy-button-color-input"
                      value={projectData?.settings?.miscellaneous?.privacybtncolor ? projectData?.settings.miscellaneous.privacybtncolor : "#000000"}
                      type="color"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                    Privacy Button Color
                    </label>{" "}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="style2"
                      disabled={projectDisabled}
                      name="privacybtntextcolor"
                      aria-describedby="privacy-buttont-textcolor-input"
                      value={projectData?.settings?.miscellaneous?.privacybtntextcolor  ? projectData?.settings.miscellaneous.privacybtntextcolor : "#000000"}
                      onChange={handleOnChange}
                      type="color"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                   Privacy Button Text Color
                    </label>{" "}
                  </div>
                </div>
              </div>

            

              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
                  Privacy Policy Heading
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    disabled={projectDisabled}
                    name="privacyheading"
                    aria-describedby="privacy-heading-input"
                    value={projectData?.settings?.miscellaneous?.privacyheading ? projectData?.settings.miscellaneous.privacyheading : ""}
                    onChange={handleOnChange}
                    id="first-name"
                     maxLength={100}
                    autoComplete="heading"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                      invalid:border-red-500 invalid:text-red-600
                      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                      focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
                  Privacy Button Text
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    disabled={projectDisabled}
                    name="privacybtntext"
                    aria-describedby="privacy-button-text-input"
                    value={projectData?.settings?.miscellaneous?.privacybtntext ? projectData?.settings.miscellaneous.privacybtntext : ""}
                    onChange={handleOnChange}
                    id="first-name"
                    defaultValue={"Agree"}
                    autoComplete="privacy-btn-text"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                      invalid:border-red-500 invalid:text-red-600
                      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                      focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <div>
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium leading-6 text-slate-900"
                  >
                    Privacy Policy Text
                  </label>
                  <div className="mt-2">
                    <textarea
                      rows={6}
                      disabled={projectDisabled}
                      name="privacypolicy"
                    aria-describedby="privacy-policy-input"
                    value={projectData?.settings?.miscellaneous?.privacypolicy ? projectData?.settings.miscellaneous.privacypolicy : ""}
                      
                    autoComplete="privacy-policy-text"
                    onChange={handleOnChange}
                      id="comment"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                     
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              type="button"
              onClick={(e) => projectData ? navigate(`/projects/${projectData.accountName}`) : navigate(`/login`)}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            >
              Back
            </button>
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleSave}
              className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`, `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold 
              text-white shadow-sm disabled:opacity-25`)}
            >
              Save
            </button>
          </div>
        </div>
        </div>
      </form>
    </>
  );
}

export default PrivacyPolicy;
