import React, { useState, useEffect, useRef } from "react";
import BadgeTitle from "../components/titles/BadgeTitle";
import Container from "../components/containers/Container";
import Common from "../components/appStore/Common";
import Windows from "../components/appStore/Windows";
import MacOS from "../components/appStore/MacOS";
import AppleOS from "../components/appStore/AppleOS";
import Android from "../components/appStore/Android";
import AppLayout from "../components/layout/AppLayout";
import {
  getProjectById,
  getProjectByName,
  updateProject,
} from "../redux/actions/projectActions";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  
import { clearErrors } from "../redux/actions/errorActions";  
import { saveFile } from "../redux/actions/projectActions";  

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppStore() {
  const navigate = useNavigate();
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const dispatch = useDispatch();
  const { project, success, error } = useSelector(
    (state) => state.getProject
  );

  const {  updateSuccess, updateError } = useSelector(
    (state) => state.updateProject
  );

  const { saved, error: sError } = useSelector((state) => state.saveFile);

  const [projectData, setProjectData] = useState({ ...project });

  function handleOnChange(e) {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        appstore: {
          ...prevProjectData.settings.appstore,
          [e.target.name]: e.target.value,
        },
      },
    }));
    
  }

  function handleOnFileUpload(e, propertyName,name ) {
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    const file = e.target.files[0];
   
    dispatch(
      saveFile(accountName, propertyName, name, file)
    );
    e.target.value = null;
  }

  function handleImageDrop(e,propertyName, name){
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    const file = e.dataTransfer.files[0];
    
  
    dispatch(
      saveFile(accountName, propertyName, name, file)
    );
    e.target.value = null;
  };

  const projectDisabled = projectData?.status
    ? projectData?.status.approval.status === "Prepare" ||
      projectData?.status.approval.status === "Update"
      ? false
      : true
    : true;

  function handleSave(e) {
    e.preventDefault();

    //if(projectData?.settings?.appstore?.appName?.length >= 3 && projectData?.settings?.appstore?.appIdentifier?.length >= 6 && 
     // projectData?.settings?.appstore?.updateprivatekey?.length >= 1 && projectData?.settings?.appstore?.updatepublickey?.length >= 1
    //) {
      isErrorRef.current = true;
      dispatch(updateProject(projectData));
   // }else{
     // dispatch(openErrorAlert("Incomplete Details","Please fill in all required fields."));
    //}
   
  }

  useEffect(() => {

    if(error){
      if(error === 209) { 
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login');
        return;
      } else {
      dispatch(openErrorAlert("Error","Something went wrong, please try again ")); 
      }
    }

    if ((project == undefined || project == null) ) {
      dispatch(getProjectByName(accountName));
    }

    setProjectData(project);
    
   
  }, [success, error]);


  useEffect(() => {
    
    if (updateError ) {
     
      if(updateError.code === 209) {
        // navigate to login 
        dispatch(openErrorAlert("Session Expired","Please login again to continue"));
        navigate('/login');
      } else {
        dispatch(openErrorAlert("Error","Project failed to save. Please try again"));
      }
      return;
    }
    
    if (updateSuccess) {
     
      dispatch(openStatusAlert("Success","Project saved successfully"));
      navigate(`/projects/${accountName}`)
      dispatch(clearErrors())
     
    }
  }, [updateSuccess, updateError]); 

  useEffect(() => {

    if(sError) {
      if(sError.code === 209) {
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login'); 
        return;
      }
      else  {
        dispatch(saveFile(accountName, sError.name, "", null));
        dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }

    if(saved) {
      dispatch(getProjectByName(accountName));
    }

  }, [saved, sError]);

  return (
    <>
      <AppLayout project={project} type={project?.type ? project?.type : ""}/>
      <Container>
        <BadgeTitle
          title={`App Store`}
          badge={projectData?.status ? projectData?.status.approval.status : ""}
          projectName={`${
            projectData?.accountName ? projectData?.accountName : ""
          }`}
        />

        <form>
          <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <Common
                projectData={projectData}
                handleOnChange={handleOnChange}
                projectDisabled={projectDisabled}
              />

              {projectData?.type === "whitelabel-windows" ||
              projectData?.type === "whitelabel-windows-annual" ||
              projectData?.type === "whitelabel_desktop" || 
              projectData?.settings?.provisioning?.usewindows == "Enabled" ? (
                <Windows
                  projectData={projectData}
                  handleOnChange={handleOnChange}
                  handleOnFileUpload={handleOnFileUpload}
                  projectDisabled={projectDisabled}
                  handleImageDrop={handleImageDrop}
                />
              ) : (
                <></>
              )}

              {projectData?.type === "whitelabel_desktop" ? (
                <MacOS
                  projectData={projectData}
                  handleOnChange={handleOnChange}
                  handleOnFileUpload={handleOnFileUpload}
                  projectDisabled={projectDisabled}
                  handleImageDrop={handleImageDrop}
                />
              ) : (
                <></>
              )}

              {projectData?.type != "whitelabel-windows" &&
              projectData?.type != "whitelabel-windows-annual" &&
              projectData?.type != "whitelabel_desktop" ? (
                <AppleOS
                  projectData={projectData}
                  handleOnChange={handleOnChange}
                  handleOnFileUpload={handleOnFileUpload}
                  projectDisabled={projectDisabled}
                  handleImageDrop={handleImageDrop}
                />
              ) : (
                <></>
              )}

              {projectData?.type != "whitelabel-windows" &&
              projectData?.type != "whitelabel-windows-annual" &&
              projectData?.type != "whitelabel_desktop" ? (
                <Android
                  projectData={projectData}
                  handleOnChange={handleOnChange}
                  handleOnFileUpload={handleOnFileUpload}
                  projectDisabled={projectDisabled}
                  handleImageDrop={handleImageDrop}
                />
              ) : (
                <></>
              )}

          <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              type="button"
              onClick={(e) => projectData ? navigate(`/projects/${projectData.accountName}`) : navigate(`/login`)}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            >
              Back
            </button>
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleSave}
              className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`, `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold 
              text-white shadow-sm disabled:opacity-25`)}
            >
              Save
            </button>
          </div>
            </div>
            {/*  */}
          </div>
        </form>
      </Container>
    </>
  );
}
