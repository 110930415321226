import React, { useRef, useEffect,useImperativeHandle, forwardRef } from 'react';
import QRCode from 'qrcode';

const QRCodeComponent = forwardRef((props, ref) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Generate the QR code
    QRCode.toCanvas(
      canvasRef.current,
      props.data, // Data to encode (URL or text)
      {
        width: 300, // Size of the QR code
        margin: 2, // Margin around the QR code
        color: {
          dark: '#000000', // Color of the QR code dots
          light: '#ffffff', // Background color
        },
      },
      (error) => {
        if (error) console.error('Error generating QR code:', error);
      }
    );
  }, [ props.data]);

  const handleDownload = () => {
    if (canvasRef.current) {
      // Convert the canvas to a data URL
      const url = canvasRef.current.toDataURL('image/png');

      // Trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  const getImageBuffer = () => {
    if (canvasRef.current) {
      // Convert the canvas to a data URL
      const dataUrl = canvasRef.current.toDataURL('image/png');

      // Convert the data URL to a buffer
      const base64Data = dataUrl.split(',')[1]; // Remove the data URL prefix
     
      return base64Data;
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    getImageBuffer, // Expose the getImageBuffer method
    download: handleDownload, // Expose the download method
  }));

  return (
    <div>
      <canvas ref={canvasRef} style={{ marginBottom: '20px' }}></canvas>
    </div>
  );
});

export default QRCodeComponent;