import React, { useEffect, useRef, useState } from "react";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import GrayPanel from "../components/actionPanels/GrayPanel";
import AppLayout from "../components/layout/AppLayout";
import { getPricingDetails, createCustomerSubscription, updateCustomerSubscription } from "../redux/actions/pricingActions";


import {
  
  createEstimate,
  updateEstimate,
  checkoutExisting,
  retrievePage,
  checkoutNew,
} from "../redux/actions/userActions";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CheckoutForm from "../components/checkout/CheckoutForm";
import CheckoutFrame from "../components/checkout/CheckoutFrame";
import { openErrorAlert } from '../redux/actions/alertActions';
import { clearErrors } from "../redux/actions/errorActions";  

export default function Checkout() {
  const dispatch = useDispatch();
  const isPageRef = useRef(true);
  
  const { accountName } = useParams();
  const navigate = useNavigate();
  const [cardURL, setCardURL] = useState("");
  const [site, setSite] = useState("");
  

  const { pricing, error } = useSelector((state) => state.getPricing);
  
  const { estimate, error: nError } = useSelector((state) => {
        return state.getEstimate;
      });
  const { page, error: pageError, loading } = useSelector((state) => {
    return state.getHostedCheckoutPage;
  });

  

  useEffect(() => {

    if(error) {
      
      if(error === "Invalid session token") {
      navigate("/login");
      } else {
        dispatch(openErrorAlert("Error",error));
        navigate(`/projects/${accountName}`);
      }
      return;
    }

    if ( !pricing ) {
      // Dispatch your action when the component mounts
      dispatch(getPricingDetails(accountName));
      
    } else {
      if( pricing.billing.customer !== null && pricing.billing.customer.card_status === 'valid' && 
      pricing.billing.subscription === null)
      {
          CreateSubscriptionEstimate(pricing.billing.customer);
      } 
      else if( pricing.billing.customer !== null && pricing.billing.customer.card_status === 'valid' && pricing.billing.subscription !== null)
      {
          UpdateSubscriptionEstimate(pricing.project,pricing.billing.customer);
      }
      else if( pricing.billing.customer !== null && pricing.billing.customer.card_status === 'no_card')
      {
          ChargeExistingSubscription(pricing.project);
      }
      else {
          ChargeNewSubscription(pricing.billing.customer);
      }
      
    }

  }, [pricing, error]);


  
  
  function getPlanName(plan) {
    switch (plan) {
      case "sessioncloud_vvs":
        return "Voice, Video and SMS";
      case "sessioncloud_business":
        return "Business";
        case "generic_softphone":
          return "Generic";
      case "sessioncloud_secure":
        return '"Secure';
      case "sessioncloud_all":
        return "Generic";
      case "generic-platforms":
        return "Generic";
      case "whitelabel_basic":
        return "Basic";
      case "whitelabel_advanced":
        return "Advanced";
      case "whitelabel-windows":
        return "Desktop";
      case "whitelabel_desktop":
        return "Desktop";
      case "whitelabel-windows-annual":
        return "Desktop";
      case "whitelabel_premium":
        return "Premium";
        case "whitelabel_mobile":
          return "Mobile";
      case "whitelabel-mobile-annual":
        return "Premium Annual";
      case "whitelabel_advanced":
        return "Advanced";
      default:
        return "Generic";
    }
  }

  function getPlanDescription(plan) {
    if (plan === "sessioncloud_vvs") {
      return "Intro SessionCloud package, if you don't need your own app or are just starting out this package has the essentials. Includes Balance Display and Voicemail. G729 available as optional extra.";
    } else if (plan === "sessioncloud_business") {
      return "Business SessionCloud package includes Voice, Video and SMS plus additionally call transfer,conferencing, dial plan and call recording. Includes Balance Display and Voicemail. G729 and SIP Tunneling available as optional extra.";
    } else if (plan === "sessioncloud_secure") {
      return "Premium SessionCloud package includes all features in Business plus additionally TLS Encryption and SRTP Audio. Includes Balance Display and Voicemail. G729 available as optional extra.";
    } else if (plan === "sessioncloud_all") {
      return "All features including  Push Notifications, Video calls, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "generic-platforms" || plan === "generic_softphone") {
      return "All features including  Push Notifications, Video calls, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "whitelabel_basic") {
      return "Your own whitelabel SIP softphone, which you can offer under your own iTunes and Google Play developer account. Includes Voice , Video and SMS together with Balance Display and Voicemail. G729  available as optional extra.";
    } else if (plan === "whitelabel_advanced") {
      return "Your own whitelabel SIP softphone, which you can offer under your own iTunes and Google Play developer account. Includes Voice, Video and SMS plus additionally call transfer,conferencing, dial plan and call recording. Includes Balance Display and Voicemail. G729 available as optional extra.";
    } else if (plan === "whitelabel_premium" || plan === "whitelabel_mobile") {
      return "Your own whitelabel SIP softphone, which you can offer under your own iTunes and Google Play developer account. All features including Push Notifications, Video Calls, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "whitelabel-windows") {
      return "Your own whitelabel SIP softphone, which you can offer under your own Microsoft developer account. All features including , Video Calls, Messaging, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "whitelabel-windows-annual") {
      return "Your own whitelabel SIP softphone, which you can offer under your own Microsoft developer account. All features including , Video Calls, Messaging, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "whitelabel_desktop") {
      return "Your own Windows & MacOS Whitelabel Softphones. All features including , Conferencing, Video Calls, Messaging, Business Features, TLS Encryption and SRTP Audio. G729 included";
    } else if (plan === "whitelabel-mobile-annual") {
      return "Your own whitelabel SIP softphone, which you can offer under your own iTunes and Google Play developer account. All features including Push Notifications, Video Calls, Business Features, TLS Encryption and SRTP Audio. G729 included";
    }
  }

  function CreateSubscriptionEstimate(project,customer) {
    var addonarray = [];
    var tunnel = { id: "sessionflow" };

    var current = new Date().getTime();
    var tmp = Math.round(current / 1000) + 3600 * 24 * 14;
    // Add 14 days
    var trialend = Math.round(tmp);

    if (
      project?.type === "sessioncloud_vvs" ||
      project?.type === "sessioncloud_business" ||
      project?.type === "sessioncloud_secure" ||
      project?.type === "sessioncloud_all"
    ) {
      const windows = { id: "windows-generic", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    } else if (
      project?.type === "whitelabel_mobile" ||
      project?.type === "whitelabel_advanced" ||
      project?.type === "whitelabel_premium" ||
      project?.type === "whitelabel-mobile-annual"
    ) {
      var windows = { id: "windows-whitelabel", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    }

    if (project?.settings?.provisioning?.usetunnel == "Enabled") {
      addonarray.push(tunnel);
    }

    dispatch(
      createEstimate(
        customer,
        accountName,
        project?.type,
        addonarray,
        project?.type + "_coupon"
      )
    );
    
  }

  function UpdateSubscriptionEstimate(project,customer) {
    var addonarray = [];
    var tunnel = { id: "sessionflow" };

    var current = new Date().getTime();
    var tmp = Math.round(current / 1000) + 3600 * 24 * 14;
    // Add 14 days
    var trialend = Math.round(tmp);

    if (
      project?.type === "sessioncloud_vvs" ||
      project?.type === "sessioncloud_business" ||
      project?.type === "sessioncloud_secure" ||
      project?.type === "sessioncloud_all"
    ) {
      var windows = { id: "windows-generic", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    } else if (
      project?.type === "whitelabel_mobile" ||
      project?.type === "whitelabel_advanced" ||
      project?.type === "whitelabel_premium" ||
      project?.type === "whitelabel-mobile-annual"
    ) {
      var windows = { id: "windows-whitelabel", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    }

    if (project?.settings?.provisioning?.usetunnel == "Enabled") {
      addonarray.push(tunnel);
    }

    dispatch(
      updateEstimate(
        customer,
        project?.accountName,
        project?.type,
        addonarray,
        project?.type + "_coupon"
      )
    );

    
  }

  function ChargeExistingSubscription(project) {
    var addonarray = [];
    var tunnel = { id: "sessionflow" };

    var current = new Date().getTime();
    var tmp = Math.round(current / 1000) + 3600 * 24 * 14;
    // Add 14 days
    var trialend = Math.round(tmp);

    if (
      project?.type === "sessioncloud_vvs" ||
      project?.type === "sessioncloud_business" ||
      project?.type === "sessioncloud_secure" ||
      project?.type === "sessioncloud_all"
    ) {
      var windows = { id: "windows-generic", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    } else if (
      project?.type === "whitelabel_mobile" ||
      project?.type === "whitelabel_advanced" ||
      project?.type === "whitelabel_premium" ||
      project?.type === "whitelabel-mobile-annual"
    ) {
      var windows = { id: "windows-whitelabel", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    }

    if (project?.settings?.provisioning?.usetunnel == "Enabled") {
      addonarray.push(tunnel);
    }

    dispatch(
      checkoutExisting(
        accountName,
        project?.type,
        addonarray,
        project?.type + "_coupon"
      )
    );
  }

  function ChargeNewSubscription(project) {
    var addonarray = [];
    var tunnel = { id: "sessionflow" };

    var current = new Date().getTime();
    var tmp = Math.round(current / 1000) + 3600 * 24 * 14;
    // Add 14 days
    var trialend = Math.round(tmp);

    if (
      project?.type === "sessioncloud_vvs" ||
      project?.type === "sessioncloud_business" ||
      project?.type === "sessioncloud_secure" ||
      project?.type === "generic-platforms" ||
      project?.type === "generic_softphone" ||
      project?.type === "sessioncloud_all"
    ) {
      var windows = { id: "windows-generic", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    } else if (
      project?.type === "whitelabel_mobile" ||
      project?.type === "whitelabel_advanced" ||
      project?.type === "whitelabel_premium" ||
      project?.type === "whitelabel-mobile-annual"
    ) {
      var windows = { id: "windows-whitelabel", trial_end: trialend };
      if (
        project?.settings?.provisioning?.usewindows != undefined &&
        project?.settings?.provisioning?.usewindows == "Enabled"
      ) {
        addonarray.push(windows);
      }
    }

    if (project?.settings?.provisioning?.usetunnel == "Enabled") {
      addonarray.push(tunnel);
    }

    dispatch(
      checkoutNew(
        accountName,
        project?.type,
        addonarray,
        `${project?.type}_coupon`
      )
    );

    isPageRef.current = true;
  }


  function navigateApps() {
    navigate("/");
  }

  

  function navigateSuccess(hostedPageId) {
    dispatch(clearErrors())
    navigate(`/checkout-success/${hostedPageId}/${accountName}`);
  }

  function navigateConfirm(invoiceId) {
    dispatch(clearErrors())
    navigate(`/checkout-confirm/${invoiceId}/${accountName}`);
  }

  function navigateBack() {
    navigate(`/projects/${accountName}`);
  }

  console.log("pricing");
  console.log(pricing);

  return (
    <>
      <AppLayout type={pricing?.project?.type ? pricing?.project?.type : ""}/>
      <Container>
        <Title title={"Pricing"} action={navigateBack} buttonTitle={"Cancel"} />
        <GrayPanel
          title={getPlanName(pricing?.project?.type)}
          note={getPlanDescription(pricing?.project?.type)}
        />
        {pricing?.billing?.customer && pricing?.billing?.customer?.card_status === 'valid' ? (
          <CheckoutForm
            customer={pricing?.billing?.customer}
            subscription={pricing?.billing?.subscription}
            card={pricing?.billing?.card}
            project={pricing?.project}
            estimate={estimate}
          />
        ) : (
          <CheckoutFrame
          loading={loading}
            page={page}
            redirectToThankYou={navigateSuccess}
            navigateBack={navigateBack}
          />
        )}
      </Container>
    </>
  );
}
