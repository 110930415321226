import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SelectCodecs({codecs, activeCodecs, handleRemoveCodec, handleAddCodec, property, projectDisabled}) {

const filteredCodecs = activeCodecs?.length > 0 ?  codecs.filter((codec) => !activeCodecs.some(codec2 => codec.name === codec2.name)) : codecs

const handleAdd = (codecObject) => {
    handleAddCodec(property, codecObject)
}

const handleRemove = (codecObject) => {
   
    handleRemoveCodec(property, codecObject)
}

  return (
    <>
     <div className="bg-white">
          <div className="max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-sm font-medium text-slate-500">
              Active{"  "}{"  "}
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden h-5 w-px bg-slate-300 sm:ml-4 sm:block" />

            <div className="mt-2 sm:ml-4 sm:mt-0 ">
              <div className="-m-1 flex flex-wrap items-center">
                { activeCodecs?.length > 0 ? (activeCodecs.map((codec) => (
                  <button
                    key={codec.name}
                    disabled={projectDisabled}
                    onClick={(e) => handleRemove(codec)}
                    className={ classNames( projectDisabled != true ?"focus:ring-2 focus:ring-iceberg-400 focus:ring-offset-2 hover:bg-iceberg-300" : "",
                      `m-1 inline-flex items-center rounded-md 
                    bg-iceberg-400 text-white  py-1.5 pl-4 pr-4 text-sm font-medium disabled:opacity-25 disabled:cursor-not-allowed`)}
                  >
                    <span>{codec.name}</span>
                    {/* <button
                      type="button"
                      className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-white "
                    >
                      <span className="sr-only">Remove filter for {activeFilter.label}</span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button> */}
                  </button>
                ))) : <>
                </>}
              </div>
            </div>
          </div>
          </div>
     <div className="bg-slate-100">
          <div className="max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-sm font-medium text-slate-500">
              Inactive
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden h-5 w-px bg-slate-300 sm:ml-4 sm:block" />

            <div className="mt-2 sm:ml-4 sm:mt-0">
              <div className="-m-1 flex flex-wrap items-center">
                {filteredCodecs.map((codec) => (
                    
                     
                      <button
                      disabled={projectDisabled}
                        key={codec.name}
                        onClick={(e) => handleAdd(codec)}
                        className={ classNames( projectDisabled != true ? "hover:bg-slate-50 focus:ring-2 focus:ring-iceberg-400 focus:ring-offset-2" : "",
                          `m-1 inline-flex items-center rounded-md 
                         border border-slate-200 bg-white py-1.5 pl-4 pr-4 text-sm font-medium text-slate-900 disabled:opacity-50 disabled:cursor-not-allowed`)}
                      >
                        <span>{codec.name}</span>
                        
                      </button>
                      
                    
                  
                ))}
              </div>
            </div>
          </div>
          </div>

         
    </>
  )
}

export default SelectCodecs