import React from "react";

const stages = {
  Prepare: "text-blue-700 bg-blue-100 ",
  Cancelled: "text-red-700 bg-red-100 ",
  Live: "text-green-700 bg-green-100 ",
  Submitted: "text-violet-700 bg-violet-50 ",
  Approved: "text-lime-700 bg-lime-100 ",
  Update: "text-teal-700 bg-teal-100 ",
  Trial: "text-gray-700 bg-gray-100 ",
  Rejected: "text-pink-700 bg-pink-100",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BadgeTitle({ title, projectName, badge }) {
  return (
    <>
      <div className="md:flex md:items-center md:justify-between max-w-7xl">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-slate-900 sm:truncate sm:text-3xl sm:tracking-tight md:pt-8 md:pb-4 py-2">
            {title}{"   "}

            <span className="ml-3 text-slate-400 font-medium">
            {"   "}{projectName}
            </span>
          </h2>
        </div>

        <div className="mt-4 md:ml-4 md:mt-0">
          <span
            className={classNames(
              stages[badge],
              "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium "
            )}
          >
            {badge}
          </span>
        </div>
      </div>
    </>
  );
}

export default BadgeTitle;
