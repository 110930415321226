
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const LoginForm = ({action, email, password, setPassword, setEmail}) => {
  return (
    <>
      {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-white">
      <body class="h-full">
      ```
    */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src="logo_purple.svg"
            alt="Sessiontalk"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6 "  onSubmit={action}>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Email 
                </label>
              </div>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                  noValidate
                  minLength={1}
                  maxLength={64}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classNames(
                    `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
               
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                  placeholder="Password"
                 
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-semibold text-iceberg-400 hover:text-iceberg-300"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  minLength={8}
                  maxLength={32}
                  autoComplete="current-password"
                  noValidate
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={classNames(
                    `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
                
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={action}
                className="flex w-full justify-center rounded-md bg-iceberg-400 px-3 py-1.5 text-sm font-semibold leading-6 
                text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                focus-visible:outline-iceberg-400"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            New to SessionTalk?{" "}
            <a
              href="/register"
              className="font-semibold leading-6 text-iceberg-400 hover:text-iceberg-300"
            >
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
