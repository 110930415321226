import { OPEN_STATUS_ALERT } from "../constants/alertConstants";
import { CLOSE_STATUS_ALERT } from "../constants/alertConstants";
import { OPEN_ERROR_ALERT } from "../constants/alertConstants"; 
import { CLOSE_ERROR_ALERT } from "../constants/alertConstants";   

export const alertStatusReducer = (state = { statusOpen: false , errorOpen: false,
  statusTitle:"",statusMessage: "", errorTitle:"",errorMessage: ""}, action) => {
  switch (action.type) {
    case OPEN_STATUS_ALERT:
      console.log("ran alertStatusReducer reducer");
      return {
        ...state,
        statusTitle: action.payload.statusTitle,
        statusMessage: action.payload.statusMessage,  
        statusOpen: true,
      };

      case OPEN_ERROR_ALERT:
        return {
          ...state,
          errorTitle: action.payload.errorTitle,
          errorMessage: action.payload.errorMessage, 
          errorOpen: true,
        };

    case CLOSE_STATUS_ALERT:
      return {
        ...state,
        statusOpen: false,
      };

      case CLOSE_ERROR_ALERT:
      return {
        ...state,
        errorOpen: false,
      };

    default:
      return state;
  }
};