import { OPEN_DELETE_PROJECT_MODAL } from "../constants/modalConstants";
import { CLOSE_DELETE_PROJECT_MODAL } from "../constants/modalConstants";
import { OPEN_CANCEL_SUBSCRIPTION_MODAL } from "../constants/modalConstants";
import { CLOSE_CANCEL_SUBSCRIPTION_MODAL } from "../constants/modalConstants";
import { OPEN_PROJECT_NAME_MODAL } from "../constants/modalConstants";
import { CLOSE_PROJECT_NAME_MODAL } from "../constants/modalConstants";

export const openDeleteProjectModal = () => {
    return {
      type: OPEN_DELETE_PROJECT_MODAL,
    };
  };
  
  export const closeDeleteProjectModal = () => {
    return {
      type: CLOSE_DELETE_PROJECT_MODAL,
    };
  };

  export const openCancelSubscriptionModal = () => {
    return {
      type: OPEN_CANCEL_SUBSCRIPTION_MODAL,
    };
  };
  
  export const closeCancelSubscriptionModal = () => {
    return {
      type: CLOSE_CANCEL_SUBSCRIPTION_MODAL,
    };
  };

  export const openProjectNameModal = (plan) => {
    return {
      type: OPEN_PROJECT_NAME_MODAL,
      payload: plan,
    };
  };
  
  export const closeProjectNameModal = () => {
    return {
      type: CLOSE_PROJECT_NAME_MODAL,
    };
  };