import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "How to set up Dialing Rules?",
    answer:
    <div className='text-sm leading-7 text-gray-600'><p>
        Tap the 'Add Rule' button on the right side after entering details of a rule. 
        Rules will be applied in order from top to bottom. Please see below for examples. Tap ‘Delete’ to delete the rule.
        </p>
      
      <p  className='mt-2'><span className='text-iceberg-400' >Name - </span> enter a name for your rule here, this can be anything you like.</p>

      <p  className='mt-2'><span className='text-iceberg-400' >Match Number - </span> define the pattern used to match dialled numbers.
       Your rule will be applied to any matched numbers.</p>
    
      <p  className='mt-2'><span className='text-iceberg-400' >Prefix Remove - </span> when a dialled number is matched with the rule the prefix 
      listed here will be removed from the number assuming the number has this prefix.</p>
      
      <p  className='mt-2'><span className='text-iceberg-400' >Prefix Add - </span> when a dialled number is matched with the rule, any prefix to
       be removed listed above will be removed first then the prefix to be added will appended to the dialled number. Tap the ‘Save’ button to save
        a rule. To set up a rule a pattern that the dialled number must match needs to be defined followed by an action to be taken on the dialled
         number once a match is detected. An action can be removing a prefix, removing a prefix or removing a prefix followed by adding a prefix.</p>
    
      <p  className='mt-2'>To set up a rule a pattern that the dialled number must match needs to be defined followed by an action to be taken
       on the dialled number once a match is detected. An action can be removing a prefix, or removing a prefix followed by adding a prefix. 
       The following characters can be used to generate a match pattern. If GSM is selected the call will routed through the native phone application
        rather than the softphone. This could be used for emergency numbers.</p>
    
      <p className='mt-2'>0,1,2,3,4,5,6,7,8,9 any digit</p>
      <p>* # + any of these three symbols</p>
      <p>[-] matches a range of symbols eg [45-9] matches any of these digits 4,5,6,7,8,9</p>
      <p>x matches any of the above digits or symbols</p>
      <p>? specifies that the previous character can be repeated 0 or more times</p>
      
      <p className='mt-2'>Example 1</p>
      <p>Match Number +44x?</p>
      <p>Remove Prefix +44</p>
      <p>Add Prefix 9</p>
      <p>Any number starting with +44 will have the +44 replaced with 9 so the number +44773764181 will be dialled as 9773764181</p>
     
      
      <p  className='mt-2'>Example 2</p>
      <p>Match Number xxxxxx780[1-9]</p>
      <p> Add Prefix 7</p>
      <p>Any 10 digit number with the last 4 digits being 7801- 7809 will have a prefix of 7. So 5003007804 is dialled as 75003007804</p>
   
      
      <p  className='mt-2'>Example 3</p>
      <p>Match Number 0xxxxxxxxxx</p>
      <p>Remove Prefix 0</p>
      <p>Add Prefix +44</p>
      <p>Any eleven digit number starting with 0 will have the zero removed and replaced with +44`,</p></div>
  },
  // More questions...
]


function SetupRuleText() {
  return (
    <>
<div className="bg-white">
      <div >
        <div >
         
          <dl className="space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} >
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-6 text-gray-900">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <div>{faq.answer}</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </>
  )
}

export default SetupRuleText