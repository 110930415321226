import React, { useEffect, useRef, useState } from "react";
import {Helmet} from "react-helmet";
import AppLayout from "../components/layout/AppLayout";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { retrievePage } from "../redux/actions/userActions";
import { moveToPaid } from "../redux/actions/projectActions";
import { openErrorAlert } from "../redux/actions/alertActions"; 
import { openStatusAlert } from "../redux/actions/alertActions";
import LoadingIndicator from "../components/common/LoadingIndicator";


export default function CheckoutSuccess() {
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const { accountName } = useParams();
  const { hostedPageId } = useParams();
  const navigate = useNavigate();

  const { page, error } = useSelector((state) => {
    return state.getHostedCheckoutPage;
  });

  const {
    success,
    message,
    error: cError,
  } = useSelector((state) => {
    return state.changeProject;
  });

  

  useEffect(() => {
   
    if(!page && isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(retrievePage(hostedPageId, accountName));
      isMountedRef.current = false;
    }

    if(error){
      if(error.message === "Invalid session token"){
        dispatch(openErrorAlert("Error","Session expired, please login again"));
        navigate("/login");
      }  
      else {
        dispatch(openErrorAlert("Error", "Invoice not found"));
      }
    }
   
  }, [error]);

  useEffect(() => {
    if(page != null && page != undefined) {
      dispatch(moveToPaid(accountName));
    }
  }, [page]);

  useEffect(() => {
    if(success) {
      dispatch(openStatusAlert("Success", "Project moved to live status"));
    }
  }, [success]);

  function navigateApps() {
    navigate("/");
  }

  return (
    <>
     <Helmet>
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `gtag('event', 'conversion', {'send_to': '${process.env.REACT_APP_GOOGLE_ADS_ID}'});`,
    }}
  />
</Helmet>
      <AppLayout />
      <Container>
      <Title title={""} 
        action={navigateApps} buttonTitle={"Back"}/>
        { page != null && page != undefined ?
         <div className="flex flex-col items-center justify-center animate-fadeInRight">
    <div className="flex flex-col md:flex-row text-center">
      <div>
        <h1 className="font-bold text-4xl">Thank You for your order</h1>
        <small>You should receive an email shortly confirming your purchase.</small>
      
        <div className="xs:block text-center">
          <img src="..\..\success.png" alt="Success" />
        </div>
        <div className="text-center">
          <h2 className="font-bold">Payment Information</h2>
          <hr />
          <table className="mx-auto">
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Order ID:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{page.hosted_page.content.invoice.id}</h4></td>
            </tr>
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Total:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{page.hosted_page.content.invoice.total}</h4></td>
            </tr>
            <tr>
              <td className="px-4"><h4 className="font-bold text-right">Date:</h4></td>
              <td className="px-4"><h4 className="font-medium text-left">{page.hosted_page.content.invoice.date}</h4></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>  :  <LoadingIndicator />}
      </Container>
    </>
  );
}
