import React, { useState, useEffect, useRef } from "react";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import AppLayout from "../components/layout/AppLayout";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getGraphics,
  getProjectById,
  getProjectByName,saveGraphics
} from "../redux/actions/projectActions";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert } from "../redux/actions/alertActions"; 


import BadgeTitle from "../components/titles/BadgeTitle";
import DesktopForm from "../components/generic/DesktopForm";


export default function DesktopGraphics() {
  const navigate = useNavigate(); 
  const isMountedRef = useRef(true);
  const isSaveRef = useRef(true);
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const dispatch = useDispatch();
  const { project, error } = useSelector((state) => state.getProject);
  const { graphics, success, error: gError } = useSelector((state) => state.getGraphics);
  const { saved, error: sError } = useSelector((state) => state.saveGraphics);

  const [projectData, setProjectData] = useState({ ...project });
  const [winGraphicsData, setWinGraphicsData] = useState({...graphics});
  const [macGraphicsData, setMacGraphicsData] = useState({...graphics});

  
  useEffect(() => {
    if ( !project ) {
      dispatch(getProjectByName(accountName));
    }

    if (project) {
      setProjectData(project);
    }

    if ((error )) {
     
      if(error.code === 209){
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login');
        return;
      }

      
    }
  
  }, [project, error]);

  useEffect(() => {
    
    if(!graphics) {
       dispatch(getGraphics(accountName, "operatorwingraphics"));
    }

    if( success ) {
      if(graphics?.platform == "win"){
        setWinGraphicsData(graphics)
      }else if(graphics?.platform == "mac"){
        setMacGraphicsData(graphics)
      }
    }


  }, [graphics, success, gError]);

  useEffect(() => {

    if(sError) {
      if(sError.code === 141){
        dispatch(openErrorAlert("Error","Invalid file format. Please ensure that the dimensions   are accurate"));
        dispatch(saveGraphics(accountName, sError.name, null, "operatorwingraphics"));
        dispatch(getGraphics(accountName, "operatorwingraphics"));
      } 
      else if(sError.code === 209) {
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login'); 
        return;
      }
      else  {
        dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }

    if(saved) {
      dispatch(getGraphics(accountName, "operatorwingraphics"));
      dispatch(getProjectByName(accountName));
    }

  }, [saved, sError]);

  const projectDisabled = projectData?.status
  ? projectData?.status.approval.status === "Prepare" ||
    projectData?.status.approval.status === "Update"
    ? false
    : true
  : true;

  function handleWinImageDrop(e,propertyName, ){
    e.preventDefault();
    
    if (projectDisabled) {
      return;
    }
    isErrorRef.current = true;
    const file = e.dataTransfer.files[0];
    setWinGraphicsData({
      ...winGraphicsData,
      [propertyName]: file.name//URL.createObjectURL(file),
      //[name]: file.name,
    });
  
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName,propertyName,file, "operatorwingraphics" ))
    e.target.value = null;  
  };

  function handleMacImageDrop(e,propertyName, ){
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    isErrorRef.current = true;
    const file = e.dataTransfer.files[0];
    setMacGraphicsData({
      ...macGraphicsData,
      [propertyName]: file.name//URL.createObjectURL(file),
      //[name]: file.name,
    });
  
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName,propertyName,file, "operatormacgraphics" ))
    e.target.value = null;
  };

  function handleWinOnFileUpload(e, propertyName, ) {
    //e.preventDefault();
    isErrorRef.current = true;
    const file = e.target.files[0];
   
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName, propertyName,file, "operatorwingraphics" ))
    e.target.value = null;
  }

  function handleMacOnFileUpload(e, propertyName, ) {
    //e.preventDefault();
    isErrorRef.current = true;
    const file = e.target.files[0];
    setMacGraphicsData({
      ...macGraphicsData,
      [propertyName]: file.name//URL.createObjectURL(file),
      //[name]: file.name,
    });
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName, propertyName,file, "operatormacgraphics" ))
    e.target.value = null;
  }

 
 

  return (
    <>
    <AppLayout  project={project} type={project?.type ? project?.type : ""}/>
      <Container>
      <BadgeTitle  title={"Desktop Branding"}
          badge={projectData?.status ? projectData?.status.approval.status : ""}
          projectName={`${
            projectData?.accountName ? projectData?.accountName : ""
          }`}
        />
       <DesktopForm winGraphicsData={winGraphicsData ? winGraphicsData : undefined} 
       macGraphicsData={macGraphicsData ? macGraphicsData : undefined} accountName={accountName}
       handleWinOnFileUpload={handleWinOnFileUpload}
         projectDisabled={projectDisabled}
         handleMacOnFileUpload={handleMacOnFileUpload}
         handleMacImageDrop={handleMacImageDrop}
         handleWinImageDrop={handleWinImageDrop}
       />
      </Container>
    </>
  );
}
