import {
  GET_PRICING_REQUEST,
  GET_PRICING_SUCCESS,
  GET_PRICING_FAIL,
  CREATE_SUBSCRIPTION_CUSTOMER_REQUEST,
  CREATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
  CREATE_SUBSCRIPTION_CUSTOMER_FAIL,
  UPDATE_SUBSCRIPTION_CUSTOMER_REQUEST,
  UPDATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
  UPDATE_SUBSCRIPTION_CUSTOMER_FAIL
} from "../constants/pricingConstants";

import Parse from "parse/dist/parse.min.js";

export const getPricingDetails = (accountName) => async (dispatch) => { 
  try {
    dispatch({ type: GET_PRICING_REQUEST });
    const response = await Parse.Cloud.run("getPricingDetails", {
      accountName,
    });

    // Need this to get rid of parse object-redux errors 
    response.project.operatorandroidgraphics = undefined;
    response.project.operatoriosgraphics = undefined;
    response.project.operatorwingraphics = undefined;
    response.project.whitelabelandroidgraphics = undefined;
    response.project.whitelabeliosgraphics = undefined;
    response.project.whitelabelwingraphics = undefined;
    response.project.whitelabelmacgraphics = undefined;

    dispatch({ type: GET_PRICING_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: GET_PRICING_FAIL, payload: error.message });
  }
};

export const createCustomerSubscription = (projectName, type, addonarray, couponid) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SUBSCRIPTION_CUSTOMER_REQUEST,
    });

    const results = await Parse.Cloud.run('createSubscriptionCustomer', {appName:projectName, planid:type, addonarray: addonarray, couponid:couponid}); 

  
    dispatch({
      type: CREATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SUBSCRIPTION_CUSTOMER_FAIL,
      payload: error.message,
    });
  }
};

export const updateCustomerSubscription = (projectName, customer, type, addonarray) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SUBSCRIPTION_CUSTOMER_REQUEST
    });

     const results = await Parse.Cloud.run('updateSubscriptionMakePayment', {customer:customer, appName:projectName, planid:type, addonarray: addonarray, replace:false, meta_data: { plan_updated: false  }}); 

    dispatch({
      type: UPDATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SUBSCRIPTION_CUSTOMER_FAIL,
      payload: error.message,
    });
  }
};

