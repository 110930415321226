import React, {useEffect, useRef} from "react";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import { useSelector } from "react-redux";
import PricingPlans from "../components/pricing/PricingPlans";
import InfoAlert from "../components/pricing/InfoAlert";
import ProjectNameModal from "../components/modals/ProjectNameModal";
import { useNavigate } from "react-router-dom";
import AppLayout from "../components/layout/AppLayout";
import { useDispatch } from "react-redux";  
import { openStatusAlert, openErrorAlert } from "../redux/actions/alertActions";

export default function CreateProject() {
  const dispatch = useDispatch(); 
  const isErrorRef = useRef(true);
  const navigate = useNavigate();
  const { open, plan } = useSelector((state) => state.openProjectNameModal);
  const { success, error,  } = useSelector((state) => state.createProject);

  const handleBack = () => {
    navigate("/projects");
  };


  useEffect(() => {

    if(success){
      dispatch(openStatusAlert("Success","Project created"));
      navigate("/projects");
    }

    if(error && isErrorRef.current){
      isErrorRef.current = false;
      dispatch(openErrorAlert("Error","Project creation failed, please try again"));
     
    }

  }, [success,error ]);
  const pricingNote =
    "Choose the Generic package if you don't want to have your own app. Your users will download the SessionCloud Windows,MacOS,iOS or Android apps and then enter the Provider Id you have specified. Basic logo branding is available. The Whitelabel packages allow you to have your own apps listed under your developer accounts.";

  return (
    <>
      <AppLayout />
      <Container>
        <ProjectNameModal modalOpen={open}  plan={plan} />
        <Title
          title={"Create Project"}
          buttonTitle={"Back"}
          action={handleBack}
        />

        <InfoAlert message={pricingNote} />
        <PricingPlans />
      </Container>
    </>
  );
}
