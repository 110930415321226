import React, { useState, useEffect, useRef } from "react";
import BadgeTitle from "../components/titles/BadgeTitle";
import Container from "../components/containers/Container";
import AppLayout from "../components/layout/AppLayout";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getGraphics,
  getProjectById,
  getProjectByName,
  saveGraphics,
} from "../redux/actions/projectActions";
import { useSelector, useDispatch } from "react-redux";
import MobileForm from "../components/generic/MobileForm";
import { openErrorAlert } from "../redux/actions/alertActions"; 


export default function MobileGraphics() {
  const navigate = useNavigate();
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const dispatch = useDispatch();
  const { project, error } = useSelector((state) => state.getProject);
  const { graphics,success, error: gError } = useSelector((state) => state.getGraphics);
  const { saved, error: sError } = useSelector((state) => state.saveGraphics);

  const [projectData, setProjectData] = useState({ ...project });
  const [androidGraphicsData, setAndroidGraphicsData] = useState({
    ...graphics,
  });
  const [iosGraphicsData, setIosGraphicsData] = useState({ ...graphics });

  useEffect(() => {
    
    if(!project) {
      dispatch(getProjectByName(accountName));
    }

    if(project) {
      setProjectData(project);
    }

    if ((error )) {
     
      if(error.code === 209){
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login');
        return;
      }

      
    }
  }, [project, error,gError]);

  useEffect(() => {
    
    if(!graphics) {
       dispatch(getGraphics(accountName, "operatorandroidgraphics"));
    }

      if( success ) {
        if (graphics?.platform == "ios") {
          setIosGraphicsData(graphics);
        } else if (graphics?.platform == "android") {
        setAndroidGraphicsData(graphics);
        }
    }


  }, [graphics, success]);

  useEffect(() => {

    if(sError) {
      if(sError.code === 141){
        dispatch(openErrorAlert("Error","Invalid file format. Please ensure that the dimensions   are accurate"));
        dispatch(saveGraphics(accountName, sError.name, null, "operatorandroidgraphics"));
        dispatch(getGraphics(accountName, "operatorandroidgraphics"));
      } 
      else if(sError.code === 209) {
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login'); 
        return;
      }
      else  {
        dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }

    if(saved) {
      dispatch(getGraphics(accountName, "operatorandroidgraphics"));
      dispatch(getProjectByName(accountName));
    }

  }, [saved, sError]);

  const projectDisabled = project?.status
    ? project?.status.approval.status === "Prepare" ||
      project?.status.approval.status === "Update"
      ? false
      : true
    : true;

  function handleAndroidImageDrop(e, propertyName) {
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    
    const file = e.dataTransfer.files[0];
   
    dispatch(
      saveGraphics(accountName, propertyName, file, "operatorandroidgraphics")
    );
    e.target.value = null;
  }

  function handleIOSImageDrop(e, propertyName) {
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    
    const file = e.dataTransfer.files[0];
    
    dispatch(
      saveGraphics(accountName, propertyName, file, "operatoriosgraphics")
    );
    e.target.value = null;
  }

  function handleAndroidOnFileUpload(e, propertyName) {
    
    isErrorRef.current = true;
    const file = e.target.files[0];
    
    console.log("Image uploaded");
    dispatch(
      saveGraphics(accountName, propertyName, file, "operatorandroidgraphics")
    );
    e.target.value = null;
  }
  function handleIOSOnFileUpload(e, propertyName) {
    
    isErrorRef.current = true;
    const file = e.target.files[0];
   
    console.log("Image uploaded");
    dispatch(
      saveGraphics(accountName, propertyName, file, "operatoriosgraphics")
    );
    e.target.value = null;
  }

  return (
    <>
      <AppLayout  project={project} type={project?.type ? project?.type : ""} />
      <Container>
        <BadgeTitle
          title={"Mobile Branding"}
          badge={projectData?.status ? projectData?.status.approval.status : ""}
          projectName={`${
            projectData?.accountName ? projectData?.accountName : ""
          }`}
        />

        <MobileForm
          androidGraphicsData={
            androidGraphicsData ? androidGraphicsData : undefined
          }
          iosGraphicsData={iosGraphicsData ? iosGraphicsData : undefined}
          accountName={accountName}
          projectDisabled={projectDisabled}
          handleIOSOnFileUpload={handleIOSOnFileUpload}
          handleAndroidOnFileUpload={handleAndroidOnFileUpload}
          handleAndroidImageDrop={handleAndroidImageDrop}
          handleIOSImageDrop={handleIOSImageDrop}
        />
      </Container>
    </>
  );
}
