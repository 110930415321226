import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "../components/containers/Container";
import Details from "../components/miscellaneous/Details";
import Help from "../components/miscellaneous/Help";
import PrivacyPolicy from "../components/miscellaneous/PrivacyPolicy";
import Tabs from "../components/common/Tabs";
import {  useParams } from "react-router-dom";
import Provisioning from "../components/miscellaneous/Provisioning";
import AppLayout from "../components/layout/AppLayout";
import { getProjectById, getProjectByName } from "../redux/actions/projectActions";
import BadgeTitle from "../components/titles/BadgeTitle";
import { updateProject } from "../redux/actions/projectActions";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  
import { useNavigate } from 'react-router-dom';
import { clearErrors } from "../redux/actions/errorActions";  

export default function Miscellaneous() {

  const navigate = useNavigate(); 
  const [activeTab, setActiveTab] = useState("details");
  const { accountName } = useParams();
  const { tabId } = useParams();

  const detailsSelected = tabId === "details" ? true : false;
  const provisioningSelected = tabId === "provisioning" ? true : false;
  const policySelected = tabId === "privacy-policy" ? true : false;
  const helpSelected = tabId === "help" ? true : false;

  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const isErrorRef = useRef(true);
  const { project,  error, success } = useSelector(
    (state) => state.getProject
  );

  const {  updateSuccess, updateError } = useSelector(
    (state) => state.updateProject
  );

  const [projectData, setProjectData] = useState({ ...project });

  const tabs = [
    {
      id: "details",
      name: "Details",
      href: "details",
      current: detailsSelected,
    },
    {
      id: "provisioning",
      name: "Provisioning",
      href: "provisioning",
      current: provisioningSelected,
    },

    {
      id: "help",
      name: "Help",
      href: "help",
      current: helpSelected,
    },
  ];

  if (
    projectData?.type != "sessioncloud_vvs" &&
    projectData?.type != "sessioncloud_business" &&
    projectData?.type != "sessioncloud_secure" &&
    projectData?.type != "sessioncloud_all"
  ) {
    // Remove the 'Privacy Policy' tab if projectData.type is 'sessioncloud_secure'
    tabs.push({
      id: "privacy-policy",
      name: "Privacy Policy",
      href: "privacy-policy",
      current: policySelected,
    });
  }

  useEffect(() => {
    if ((project == undefined || project == null) ) {
      dispatch(getProjectByName(accountName));
    }

    setProjectData(project);
    setActiveTab(() => tabId);

    if(error && isErrorRef.current){
      isErrorRef.current = false;
      dispatch(openErrorAlert("Error","Something went wrong, please try again ")); 
    }
  }, [success, error]);

  useEffect(() => {
    
    if (updateError ) {
     
      if(updateError.code === 209) {
        // navigate to login 
        dispatch(openErrorAlert("Session Expired","Please login again to continue"));
        navigate('/login');
      } else {
        dispatch(openErrorAlert("Error","Project failed to save. Please try again"));
      }
      return;
    }
    
    if (updateSuccess) {
     
      dispatch(openStatusAlert("Success","Project saved successfully"));
      navigate(`/projects/${accountName}`)
      dispatch(clearErrors())
     
    }
  }, [updateSuccess, updateError]); 

  const handleOnUnnestedChange = (e) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
    console.log(projectData);
  };

  const onHandleLanguage = (language) => {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        miscellaneous: {
          ...prevProjectData.settings.miscellaneous,
          language: language,
          
        },
      },
    }));
   }

  const handleOnChange = (e) => {
    let value;
    if (e.target.checked === true && e.target.id === "checkbox") {
      value = "Enabled";
    } else if (e.target.checked === false && e.target.id === "checkbox") {
      value = "Disabled";
    } else {
      value = e.target.value;
    }

    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        miscellaneous: {
          ...prevProjectData.settings.miscellaneous,
          [e.target.name]: value,
        },
      },
    }));
    //setProjectData({ ...projectData, [e.target.name]: e.target.value });
    console.log("projectData ");
    console.log(projectData);
  };

  const projectDisabled = project?.status
    ? project?.status.approval.status === "Prepare" ||
      project?.status.approval.status === "Update"
      ? false
      : true
    : true;

  const handleSave = (e) => {
  

    if(projectData?.settings?.miscellaneous?.contactEmail?.length >= 1 && projectData?.settings?.miscellaneous?.helptitle?.length >= 1 && 
      projectData?.settings?.miscellaneous.username1?.length >= 1 && projectData?.settings?.miscellaneous.username2?.length >= 1 &&
      projectData?.settings?.miscellaneous.password1?.length >= 1 && projectData?.settings?.miscellaneous.password2?.length >= 1
    ){
      isErrorRef.current = true;
      dispatch(updateProject(projectData));
    }else{
      dispatch(openErrorAlert("Incomplete Details","Please fill in all required fields.")); 
    }
    
  };

  return (
    <>
      <AppLayout  project={project} type={project?.type ? project?.type : ""}/>
      <Container>
        <BadgeTitle
          title={"Miscellaneous"}
          badge={project?.status ? project?.status.approval.status : ""}
          projectName={`${project?.accountName ? project?.accountName : ""}`}
        />
        <Tabs tabs={tabs} />
        {activeTab === "details" ? (
          <Details
            projectData={projectData}
            handleOnChange={handleOnChange}
            projectDisabled={projectDisabled}
            handleSave={handleSave}
          />
        ) : (
          <></>
        )}
        {activeTab === "provisioning" ? (
          <Provisioning
            projectData={projectData}
            handleOnChange={handleOnChange}
            projectDisabled={projectDisabled}
            handleSave={handleSave}
            onHandleLanguage={onHandleLanguage}
          />
        ) : (
          <></>
        )}

        {activeTab === "privacy-policy" ? (
          <PrivacyPolicy
            projectData={projectData}
            handleOnChange={handleOnChange}
            projectDisabled={projectDisabled}
            handleSave={handleSave}
          />
        ) : (
          <></>
        )}

        {activeTab === "help" ? (
          <Help
            projectData={projectData}
            handleOnChange={handleOnChange}
            projectDisabled={projectDisabled}
            handleSave={handleSave}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
