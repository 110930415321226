import React from "react";
import SelectRegion from "../selection/SelectRegion";
import TooltipIcon from "../provisioning/TooltipIcon";

const incomingCallModes = [
  { id: "Push", title: "Push" },
  // { id: "Push & Run In Background", title: "Push & Run In Background" },
  // { id: "Run In Background", title: "Run In Background" },
  { id: "Standard", title: "Run In Background" },
];

const pushRegistrationModes = [
  { id: "Standard", title: "Multiple" },
  { id: "Synchronous", title: "Synchronous" },
  { id: "Single Register", title: "Single Register" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getChoice(typeId, choiceId) {
  if (typeId === choiceId) {
    return true;
  }
  return false;
}

function convertText(text) {
  if (text === "Enabled") {
    return true;
  }
  return false;
}

const description = `RunInBackground - Softphone will run in the background to maintain
registration.
 Push - Softphone will transfer user details to Push server.
Push server will maintain registration and therefore save the
battery of the user's device. This is the recommended option.
Selecting Push & RunInBackground allows the user the choice
which Mode they wish to use.`;

function Push({
  projectData,
  handleOnChange,
  handlePushChange,
  projectDisabled,
}) {
  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          Push Settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
          Optimize your notifications and real-time updates with our
          customizable PUSH settings in our web app, tailored to your
          preferences.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full pt-6 ">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="checkbox"
                    aria-describedby="incoming-calls-description"
                    name="incomingcalls"
                    type="checkbox"
                    disabled={projectDisabled}
                    checked={convertText(
                      projectData?.settings?.provisioning?.incomingcalls
                        ? projectData?.settings?.provisioning.incomingcalls
                        : "Disabled"
                    )}
                    onChange={handleOnChange}
                    className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-slate-900"
                  >
                    Incoming Calls
                  </label>{" "}
                </div>
              </div>
            </div>

            <div className="sm:col-span-3 ">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-slate-900"
              >
                Push Proxy
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="pushproxy"
                  id="last-name"
                  disabled={projectDisabled}
                  value={
                    projectData?.settings?.provisioning?.pushproxy
                      ? projectData?.settings.provisioning.pushproxy
                      : ""
                  }
                  aria-describedby="push-proxy-settings"
                  maxLength={100}
                  onChange={handleOnChange}
                  autoComplete="push-proxy"
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
            </div>

            

            <div className="col-span-full ">
              <label className="text-sm font-semibold leading-6 text-slate-900">
                Incoming Calls Mode
                <span className=" ml-4 inline-block align-top ">
                  <TooltipIcon description={description} />
                </span>
              </label>
              <p className="text-sm text-slate-500">
                Select the ideal Incoming Calls Mode to tailor your softphone
                app's call reception behavior.
              </p>
              <fieldset className="mt-4">
                <legend className="sr-only">Incoming Calls Mode</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {incomingCallModes.map((mode) => (
                    <div key={mode.id} className="flex items-center">
                      <input
                        id={mode.id}
                        disabled={projectDisabled}
                        name="incomingcallsmode"
                        type="radio"
                        value={mode.id}
                        checked={getChoice(
                          projectData?.settings?.provisioning?.incomingcallsmode
                            ? projectData?.settings.provisioning
                                .incomingcallsmode
                            : "Push",
                          mode.id
                        )}
                        onChange={handleOnChange}
                        aria-describedby="incoming-calls-mode-settings"
                        className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                      <label
                        htmlFor={mode.id}
                        className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                      >
                        {mode.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            {projectData?.settings ? (
              projectData?.settings.provisioning.incomingcallsmode == "Push" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & Standard" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & RunInBackground" ? (
                <div className="sm:col-span-3 ">
                  <SelectRegion
                    projectData={projectData}
                    projectDisabled={projectDisabled}
                    handlePushChange={handlePushChange}
                    handleOnChange={handleOnChange}
                  />
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? (
              projectData?.settings.provisioning.incomingcallsmode == "Push" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & Standard" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & RunInBackground" ? (
                <div className="col-span-full ">
                  <label className="text-sm font-semibold leading-6 text-slate-900">
                    Push Registration Mode
                  </label>
                  <p className="text-sm text-slate-500">
                    Select the best Push Registration Mode for your softphone
                    app.
                  </p>
                  <fieldset className="mt-4">
                    <legend className="sr-only">Push Registration Mode</legend>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                      {pushRegistrationModes.map((mode) => (
                        <div key={mode.id} className="flex items-center">
                          <input
                            id={mode.id}
                            disabled={projectDisabled}
                            name="pushRegistrationMode"
                            type="radio"
                            value={mode.id}
                            checked={getChoice(
                              projectData?.settings?.provisioning
                                ?.pushRegistrationMode
                                ? projectData?.settings.provisioning
                                    .pushRegistrationMode
                                : "Synchronous",
                              mode.id
                            )}
                            onChange={handleOnChange}
                            aria-describedby="push-registration-mode-settings"
                            className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                          />
                          <label
                            htmlFor={mode.id}
                            className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                          >
                            {mode.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? (
              projectData?.settings.provisioning.incomingcallsmode == "Push" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & Standard" ||
              projectData?.settings.provisioning.incomingcallsmode ==
                "Push & RunInBackground" ? (
                <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="push-simulate-nat-description"
                        name="push_simulate_nat"
                        type="checkbox"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.push_simulate_nat
                            ? projectData?.settings?.provisioning
                                .push_simulate_nat
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                        Push Simulate NAT
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? (
              projectData?.settings.provisioning.incomingcallsmode == "Push" ||
              projectData.settings.provisioning.incomingcallsmode ==
                "Push & Standard" ||
              projectData.settings.provisioning.incomingcallsmode ==
                "Push & RunInBackground" ? (
                <div className="sm:col-span-3 ">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="block-reg-push-call-description"
                        name="blockregpushcall"
                        type="checkbox"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.blockregpushcall
                            ? projectData?.settings?.provisioning
                                .blockregpushcall
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                        Block Registration During Call
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? (
              projectData.settings.provisioning.incomingcallsmode == "Push" ||
              projectData.settings.provisioning.incomingcallsmode ==
                "Push & Standard" ||
              projectData.settings.provisioning.incomingcallsmode ==
                "Push & RunInBackground" ? (
                <div className="sm:col-span-3 ">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="private-push-description"
                        name="privatepush"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.privatepush
                            ? projectData?.settings?.provisioning.privatepush
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                        Private Push Enabled
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? 
              (projectData.type.includes("whitelabel)" &&
                (projectData.settings.provisioning.incomingcallsmode ==
                  "Push" )) ? (
                <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="push-simulate-nat-description"
                        name="externalpush"
                        type="checkbox"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.externalpush
                            ? projectData?.settings?.provisioning.externalpush
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                        External Push
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

{projectData?.settings &&
  projectData.settings.provisioning.externalpush === "Enabled" &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" && (
    <div className="sm:col-span-3">
      <label
        htmlFor="push-key-id"
        className="block text-sm font-medium leading-6 text-slate-900"
      >
        Push Key Id
      </label>
      <div className="mt-2">
        <input
          type="text"
          maxLength={100}
          name="pushKeyId"
          id="push-key-id"
          disabled={projectDisabled}
          value={projectData?.settings?.provisioning?.pushKeyId || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="push-key-id"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
             focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
             invalid:border-red-500 invalid:text-red-600
             disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
             focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
  )}

    {projectData?.settings &&
        projectData.settings.provisioning.externalpush === "Enabled" &&
        projectData.type.includes("whitelabel") &&
        projectData.settings.provisioning.incomingcallsmode === "Push" ? (
          <div className="sm:col-span-3">
              <label
                htmlFor="push-team-id"
                className="block text-sm font-medium leading-6 text-slate-900"
               >
              Push Team Id
            </label>
            <div className="mt-2">
              <input
          type="text"
          maxLength={100}
          name="pushTeamId"
          id="push-team-id"
          disabled={projectDisabled}
          value={projectData?.settings?.provisioning?.pushTeamId || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="push-team-id"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
            invalid:border-red-500 invalid:text-red-600
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
           />
          </div>
          </div>
          ) : null}
           
            

           {projectData?.settings &&
  projectData.settings.provisioning.privatepush === "Enabled" &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" ? (
    <div className="sm:col-span-3">
      <label
        htmlFor="docker-id"
        className="block text-sm font-medium leading-6 text-slate-900"
      >
        Docker Id
      </label>
      <div className="mt-2">
        <input
          type="text"
          maxLength={100}
          name="dockerId"
          id="docker-id"
          disabled={projectDisabled}
          value={projectData?.settings?.provisioning?.dockerId || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="docker-id"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
            invalid:border-red-500 invalid:text-red-600
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
  ) : null}

{projectData?.settings &&
  projectData.settings.provisioning.privatepush === "Enabled" &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" ? (
    <>
      {/* Firebase Web API Key Input */}
      <div className="sm:col-span-3">
        <label
          htmlFor="firebase-api-key"
          className="block text-sm font-medium leading-6 text-slate-900"
        >
          Firebase Web API Key
        </label>
        <div className="mt-2">
          <input
            type="text"
            maxLength={100}
            disabled={projectDisabled}
            name="firebase_apikey"
            id="firebase-api-key"
            value={projectData?.settings?.provisioning?.firebase_apikey || ""}
            aria-describedby="push-proxy-settings"
            onChange={handleOnChange}
            autoComplete="firebase-api-key"
            className={classNames(
              `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
            )}
          />
        </div>
      </div>

      {/* Push Server IP Input */}
      <div className="sm:col-span-3">
        <label
          htmlFor="push-server-ip"
          className="block text-sm font-medium leading-6 text-slate-900"
        >
          Push Server IP
        </label>
        <div className="mt-2">
          <input
            type="text"
            maxLength={100}
            name="pushserverIP"
            id="push-server-ip"
            disabled={projectDisabled}
            value={projectData?.settings?.provisioning?.pushserverIP || ""}
            aria-describedby="push-server-IP-settings"
            onChange={handleOnChange}
            autoComplete="push-server-ip"
            className={classNames(
              `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
            )}
          />
        </div>
      </div>
    </>
  ) : null}

{projectData?.settings &&
  (projectData.settings.provisioning.externalpush === "Enabled" ||
    projectData.settings.provisioning.privatepush === "Enabled") &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" ? (
    <div className="sm:col-span-3">
      <label
        htmlFor="firebase-app-id"
        className="block text-sm font-medium leading-6 text-slate-900"
      >
        Firebase Application Id
      </label>
      <div className="mt-2">
        <input
          type="text"
          maxLength={100}
          disabled={projectDisabled}
          name="firebase_appId"
          id="firebase-app-id"
          value={projectData?.settings?.provisioning?.firebase_appId || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="firebase-app-id"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
            invalid:border-red-500 invalid:text-red-600
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
  ) : null}
           {projectData?.settings &&
  (projectData.settings.provisioning.externalpush === "Enabled" ||
    projectData.settings.provisioning.privatepush === "Enabled") &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" && (
    <div className="sm:col-span-3">
      <label
        htmlFor="firebase-project-id"
        className="block text-sm font-medium leading-6 text-slate-900"
      >
        Firebase Project Id
      </label>
      <div className="mt-2">
        <input
          type="text"
          maxLength={100}
          name="firebase_projectId"
          id="firebase-project-id"
          disabled={projectDisabled}
          value={projectData?.settings?.provisioning?.firebase_projectId || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="firebase-project-id"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
             focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
             invalid:border-red-500 invalid:text-red-600
             disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
             focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
  )}

{projectData?.settings &&
  projectData.settings.provisioning.privatepush === "Enabled" &&
  projectData.type.includes("whitelabel") &&
  projectData.settings.provisioning.incomingcallsmode === "Push" && (
    <div className="sm:col-span-3">
      <label
        htmlFor="firebase-database-url"
        className="block text-sm font-medium leading-6 text-slate-900"
      >
        Firebase Database URL
      </label>
      <div className="mt-2">
        <input
          type="text"
          maxLength={100}
          disabled={projectDisabled}
          name="firebase_databaseurl"
          id="firebase-database-url"
          value={projectData?.settings?.provisioning?.firebase_databaseurl || ""}
          aria-describedby="push-proxy-settings"
          onChange={handleOnChange}
          autoComplete="firebase-database-url"
          className={classNames(
            `block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
             focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
             invalid:border-red-500 invalid:text-red-600
             disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
             focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
  )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Push;
