import React from "react";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function RulesTable({ handleOpenEditRule, rules, projectDisabled, handleRemoveRule, handleClick }) {


  const removeRule = (ruleObject) => {
    handleRemoveRule("ruleslist", ruleObject);
  };

  const editRule = (rule) => {
    handleOpenEditRule(rule) 
  };

  return (
    <>
      <div className=" space-y-12  ">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-slate-900">
              Dial Rules
            </h1>
            <p className="mt-2 text-sm text-slate-700">
              Create and manage call routing rules tailored to your preferences.
              Customize how calls are placed and directed, giving you greater
              control over your softphone app's calling behavior.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleClick}
              className={classNames(
                projectDisabled != true
                  ? `hover:bg-iceberg-300
              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`
                  : "",
                `block rounded-md bg-iceberg-400 px-3 py-2 
              text-center text-sm font-semibold text-white shadow-sm  disabled:opacity-25`
              )}
            >
              Add rule
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-slate-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                    >
                      Match Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                    >
                      Prefix Add
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                    >
                      Prefix Remove
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                    >
                      Type
                    </th>
                    {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th> */}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200">
                  {rules.map((rule) => (
                    <tr key={rule.Name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-0">
                        {rule?.Name ? rule.Name : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                        {rule?.MatchNumber ? rule.MatchNumber : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                        {rule?.PrefixAdd ? rule.PrefixAdd : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                        {rule?.PrefixRemove ? rule.PrefixRemove : ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                        {rule?.Type ? rule?.Type : ""}
                      </td>
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                          onClick={(e) => editRule(rule)}
                          disabled={projectDisabled}
                          className={classNames(
                            projectDisabled != true
                              ? "text-iceberg-400 hover:text-iceberg-300"
                              : "text-slate-300",
                            " disabled:opacity-25"
                          )}
                        >
                          Edit<span className="sr-only">, {rule.Name}</span>
                        </button>
                      </td> */}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                          type="button"
                          disabled={projectDisabled}
                          onClick={(e) => {
                            removeRule(rule);
                          }}
                          className={classNames(
                            projectDisabled != true
                              ? `hover:bg-red-500 focus-visible:outline focus-visible:outline-2
                      focus-visible:outline-offset-2 focus-visible:outline-red-600`
                              : "",
                            `rounded-md bg-red-600 px-2.5 py-1.5 text-sm  disabled:opacity-25
                      font-semibold text-white shadow-sm `
                          )}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RulesTable;
