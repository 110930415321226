import React from "react";
import PasswordSuccessForm from "../components/auth/PasswordSuccessForm";

export default function PasswordSuccess() {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen py-2">
        <PasswordSuccessForm />
      </div>
    </>
  );
}
