import React from 'react'

function CheckEmailForm({email}) {
  return (
   <>
     <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src="logo_purple.svg"
            alt="Sessiontalk"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Check your email
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-500">
           A password link has been sent to {email}
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6 " action="#" method="POST">
           

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-iceberg-400 px-3 py-1.5 
                text-sm font-semibold leading-6 text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline 
                focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
              >
                Reset Password
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Didn't receive the email?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-iceberg-400 hover:text-iceberg-300"
            >
              Resend
            </a>
          </p>

          <p className="mt-10 text-center text-sm text-gray-500">
            Return to{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-iceberg-400 hover:text-iceberg-300"
            >
              Log In
            </a>
          </p>
        </div>
      </div>
   </>
  )
}

export default CheckEmailForm