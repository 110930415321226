import Container from "../components/containers/Container";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppLayout from "../components/layout/AppLayout";
import { useParams } from "react-router-dom";
import { clearErrors } from "../redux/actions/errorActions";  
import {
  getProjectById,
  getProjectByName,
} from "../redux/actions/projectActions";

import Main from "../components/provisioning/main/Main";
import { useNavigate } from "react-router-dom";
import BadgeTitle from "../components/titles/BadgeTitle";
import { updateProject } from "../redux/actions/projectActions";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  

export default function Provisioning() {
  const navigate = useNavigate();
  const isMountedRef = useRef(true);
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const dispatch = useDispatch();
  const { project, success, error } = useSelector(
    (state) => state.getProject
  );

  const {  updateSuccess, updateError } = useSelector(
    (state) => state.updateProject
  );

  const [projectData, setProjectData] = useState({ ...project });

  function handleOnUnnestedChange(e){
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
    console.log(projectData);
  };

  function handleOnChange(e) {
    let value;
    if (e.target.checked === true && e.target.id === "checkbox") {
      value = "Enabled";
    } else if (e.target.checked === false && e.target.id === "checkbox") {
      value = "Disabled";
    } else {
      value = e.target.value;
    }

    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      settings: {
        ...prevProjectData.settings,
        provisioning: {
          ...prevProjectData.settings.provisioning,
          [e.target.name]: value,
        },
      },
    }));
    //setProjectData({ ...projectData, [e.target.name]: e.target.value });
    console.log("projectData ");
    console.log(projectData);
  }

  function handleNavigate() {
    navigate(`/advanced/${accountName}/main`);
  }

  useEffect(() => {

    if(error ){
      if(error.code === 209) {
        // navigate to login 
        dispatch(openErrorAlert("Session Expired","Please login again to continue"  ));
        navigate('/login');
        return;
      } else {
        dispatch(openErrorAlert("Error","Something went wrong, please try again")); 
        return;
      }
    }

    if ((project === undefined || project == null) ) {
      dispatch(getProjectByName(accountName));
      
    }

    if(project) {
      setProjectData(project);
    }

   
  }, [success, error]);

  useEffect(() => {
    
    if (updateError ) {
     
      if(updateError.code === 209) {
        // navigate to login 
        dispatch(openErrorAlert("Session Expired","Please login again to continue"));
        navigate('/login');
      } else {
        dispatch(openErrorAlert("Error","Project failed to save. Please try again"));
      }
      return;
    }
    
    if (updateSuccess) {
     
      dispatch(openStatusAlert("Success","Project saved successfully"));
      navigate(`/projects/${accountName}`)
      dispatch(clearErrors())
     
    }
  }, [updateSuccess, updateError]); 

  const projectDisabled = projectData?.status
    ? projectData?.status.approval.status === "Prepare" ||
      projectData?.status.approval.status === "Update"
      ? false
      : true
    : true;


  return (
    <>
      <AppLayout  project={project} type={project?.type ? project?.type : ""}/>
      <div>
        <Container>
          <BadgeTitle
            title={`Provisioning`}
            badge={
              projectData?.status ? projectData?.status.approval.status : ""
            }
            projectName={`${
              projectData?.accountName ? projectData?.accountName : ""
            }`}
          />
          <Main
            accountName={accountName}
            projectData={projectData}
            handleOnUnnestedChange={handleOnUnnestedChange}
            handleOnChange={handleOnChange}
            action={handleNavigate}
            projectDisabled={projectDisabled}
          />
        </Container>
      </div>
    </>
  );
}
