import {
  GET_PRICING_REQUEST,
  GET_PRICING_SUCCESS,
  GET_PRICING_FAIL,
  CREATE_SUBSCRIPTION_CUSTOMER_REQUEST,
  CREATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
  CREATE_SUBSCRIPTION_CUSTOMER_FAIL,
  UPDATE_SUBSCRIPTION_CUSTOMER_REQUEST,
  UPDATE_SUBSCRIPTION_CUSTOMER_SUCCESS,
  UPDATE_SUBSCRIPTION_CUSTOMER_FAIL
} from "../constants/pricingConstants";
import { CLEAR_ERRORS } from "../constants/errorConstants";


export const getPricingDetailsReducer = (
  state = { pricing: null, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_PRICING_REQUEST:
     
      return {
        loading: true,
        success: false,
        pricing: null,
        error: null,
      };

    case GET_PRICING_SUCCESS:
     
      return {
        success: true,
        loading: false,
        pricing: action.payload,
        error: null,
      };

    case GET_PRICING_FAIL:
      return {
        loading: false,
        success: false,
        pricing: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        pricing: null,
      };

    default: {
      return state;
    }
  }
};

export const getExistingCustomerSubscriptionReducer = (
  state = { result: null, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION_CUSTOMER_REQUEST:
     
      return {
        loading: true,
        success: false,
        result: null,
        error: null,
      };

    case CREATE_SUBSCRIPTION_CUSTOMER_SUCCESS:
     
      return {
        success: true,
        loading: false,
        result: action.payload,
        error: null,
      };

    case CREATE_SUBSCRIPTION_CUSTOMER_FAIL:
      return {
        loading: false,
        success: false,
        result: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        result: null,
      };

    default: {
      return state;
    }
  }
};

export const updateCustomerSubscriptionReducer = (
  state = { update: null, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_CUSTOMER_REQUEST:
     
      return {
        ...state,
        loading: true,
        success: false,
        update: null,
        error: null,
      };

    case UPDATE_SUBSCRIPTION_CUSTOMER_SUCCESS:
     
      return {
        ...state,
        success: true,
        loading: false,
        update: action.payload,
        error: null,
      };

    case UPDATE_SUBSCRIPTION_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        update: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        update: null,
      };

    default: {
      return state;
    }
  }
};