import React from "react";
import { useNavigate } from "react-router-dom";
import Language from "./Language";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const sources = ["Profile", "Provisioning"];

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function getChoice(typeId, choiceId) {
  if (choiceId === typeId) {
    return true;
  }
  return false;
}

function Provisioning({ projectData, handleOnChange, projectDisabled, handleSave,onHandleLanguage }) {
  const navigate = useNavigate();
   


  return (
    <>
       <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Provisioning
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
              Provision your softphone with ease.
              </p>

              <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="sm:col-span-3 pt-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
                  External Provisioning Url
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    disabled={projectDisabled}
                    name="autoprovUrl"
                    aria-describedby="auto-provisioning-url-input"
                    value={
                      projectData?.settings?.miscellaneous?.autoprovUrl
                        ? projectData?.settings.miscellaneous.autoprovUrl
                        : ""
                    }
                    onChange={handleOnChange}
                    id="first-name"
                      maxLength={100}
                    autoComplete="auto-provisioning-url"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label className="text-sm font-semibold leading-6 text-slate-900">
                  Credentials Source
                </label>
                <p className="text-sm text-slate-500">
                Set the preferred source for your credentials to enhance your softphone app's security and performance.
                </p>
                <fieldset className="mt-4">
                  <legend className="sr-only"> Credentials Source</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    {sources.map((source) => (
                      <div key={source} className="flex items-center">
                        <input
                          id={source}
                          disabled={projectDisabled}
                          name="credentials_source"
                          checked={
                            getChoice(projectData?.settings?.miscellaneous?.credentials_source
                              ? projectData?.settings.miscellaneous
                                  .credentials_source
                              : "Profile", source)
                          }
                          aria-describedby="credentials-source-input"
                          onChange={handleOnChange}
                          type="radio"
                        value={source}
                          className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                        />
                        <label
                          htmlFor={source}
                          className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                        >
                          {source}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="checkbox"
                      name="autoprov"
                      disabled={projectDisabled}
                      onChange={handleOnChange}
                      aria-describedby="auto-provisioning-input"
                      checked={convertText(
                        projectData?.settings?.miscellaneous?.autoprov
                          ? projectData?.settings?.miscellaneous.autoprov
                          : "Disabled"
                      )}
                      type="checkbox"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      External Provisioning
                    </label>{" "}
                  </div>
                </div>
              </div>

              {projectData?.settings ?(
                (projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' )
                ? (
                  <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="checkbox"
                      name="qrcode"
                      disabled={projectDisabled}
                      onChange={handleOnChange}
                      aria-describedby="use-qr-code-input"
                      checked={convertText(
                        projectData?.settings?.miscellaneous?.qrcode
                          ? projectData?.settings?.miscellaneous.qrcode
                          : "Disabled"
                      )}
                      type="checkbox"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      QR Code
                    </label>{" "}
                  </div>
                </div>
              </div>
                ) : (
                  <></>
                )
               : (
                <></>
              )
              }

              <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="style2"
                      name="themeColor"
                      disabled={projectDisabled}
                      aria-describedby="theme-color-input"
                      value={
                        projectData?.settings?.miscellaneous?.themeColor
                          ? projectData?.settings.miscellaneous.themeColor
                          : "#000000"
                      }
                      onChange={handleOnChange}
                      type="color"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      Application Theme Color
                    </label>{" "}
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="style2"
                      name="callBtnColor"
                      disabled={projectDisabled}
                      aria-describedby="call-button-color-input"
                      value={
                        projectData?.settings?.miscellaneous?.callBtnColor
                          ? projectData?.settings.miscellaneous.callBtnColor
                          : "#000000"
                      }
                      onChange={handleOnChange}
                      type="color"
                      className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-slate-900"
                    >
                      Call Button Color
                    </label>{" "}
                  </div>
                </div>
              </div>

              

             

              
              {projectData?.settings ? (
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="style2"
                        disabled={projectDisabled}
                        name="provisionBackgroundColor"
                        aria-describedby="call-button-color-input"
                        value={
                          projectData?.settings?.miscellaneous?.provisionBackgroundColor
                            ? projectData?.settings.miscellaneous.provisionBackgroundColor
                            : "#000000"
                        }
                        onChange={handleOnChange}
                        type="color"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                       Provisioning Form Background Color
                      </label>{" "}
                    </div>
                  </div>
                </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

{projectData?.settings ? (
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="style2"
                        disabled={projectDisabled}
                        name="provisionSigninColor"
                        aria-describedby="call-button-color-input"
                        value={
                          projectData?.settings?.miscellaneous?.provisionSigninColor
                            ? projectData?.settings.miscellaneous.provisionSigninColor
                            : "#000000"
                        }
                        onChange={handleOnChange}
                        type="color"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                      Provisioning Form Button Color
                      </label>{" "}
                    </div>
                  </div>
                </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

{projectData?.settings ? (
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="style2"
                        disabled={projectDisabled}
                        name="provisionSigninTextColor"
                        aria-describedby="call-button-color-input"
                        value={
                          projectData?.settings?.miscellaneous?.provisionSigninTextColor
                            ? projectData?.settings.miscellaneous.provisionSigninTextColor
                            : "#000000"
                        }
                        onChange={handleOnChange}
                        type="color"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                     Provisioning Form Button Text Color
                      </label>{" "}
                    </div>
                  </div>
                </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

{projectData?.settings ? (
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="style2"
                        disabled={projectDisabled}
                        name="provisionTextColor"
                        aria-describedby="call-button-color-input"
                        value={
                          projectData?.settings?.miscellaneous?.provisionTextColor
                            ? projectData?.settings.miscellaneous.provisionTextColor
                            : "#000000"
                        }
                        onChange={handleOnChange}
                        type="color"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                    Provisioning Form TextColor
                      </label>{" "}
                    </div>
                  </div>
                </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

{projectData?.settings ? (
                (projectData.settings.miscellaneous.qrcode == 'Enabled' && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="col-span-full">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="style2"
                        disabled={projectDisabled}
                        name="qrcodetextcolor"
                        aria-describedby="call-button-color-input"
                        value={
                          projectData?.settings?.miscellaneous?.qrcodetextcolor
                            ? projectData?.settings.miscellaneous.qrcodetextcolor
                            : "#000000"
                        }
                        onChange={handleOnChange}
                        type="color"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-25 disabled:cursor-not-allowed"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                   QR Code Label TextColor
                      </label>{" "}
                    </div>
                  </div>
                </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

              <div className="col-span-full">
              <Language onHandleLanguage={onHandleLanguage} language={projectData?.settings?.miscellaneous.language} />
              </div>

              {projectData?.settings ?
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
                 Username Placeholder text
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    disabled={projectDisabled}
                    name="username_placeholder"
                    aria-describedby="auto-provisioning-url-input"
                    value={
                      projectData?.settings?.miscellaneous?.username_placeholder
                        ? projectData?.settings.miscellaneous.username_placeholder
                        : ""
                    }
                    onChange={handleOnChange}
                    id="first-name"
                     maxLength={100}
                    autoComplete="username-placeholder-text"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>
                ) : (
                  <></>
                )
               : (
                <></>
              )
              }

{projectData?.settings ?
                ((projectData.settings.miscellaneous.autoprov == 'Enabled' || projectData.settings.miscellaneous.qrcode == 'Enabled')
                 && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
               Password Placeholder text
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    disabled={projectDisabled}
                    name="password_placeholder"
                    aria-describedby="auto-provisioning-url-input"
                    value={
                      projectData?.settings?.miscellaneous?.password_placeholder
                        ? projectData?.settings.miscellaneous.password_placeholder
                        : ""
                    }
                    onChange={handleOnChange}
                    id="first-name"
                     maxLength={100}
                    autoComplete="password-placeholder-text"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>
                ) : (
                  <></>
                )
               : (
                <></>
              )
              }

{projectData?.settings ? (
                (projectData.settings.miscellaneous.qrcode == 'Enabled' && projectData.type != 'sessioncloud_vvs' 
                &&  projectData.type != 'sessioncloud_business' && projectData.type != 'sessioncloud_secure' && projectData.type != 'sessioncloud_all')
                ? (
                  <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-slate-900"
                >
                 QR Code Text
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="qrcodetext"
                    disabled={projectDisabled}
                    aria-describedby="auto-provisioning-url-input"
                    value={
                      projectData?.settings?.miscellaneous?.qrcodetext
                        ? projectData?.settings.miscellaneous.qrcodetext
                        : ""
                    }
                    onChange={handleOnChange}
                    id="first-name"
                    maxLength={100}
                    autoComplete="qr-code-text"
                    className={classNames(
                      `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                    )}
                  />
                </div>
              </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
              }

            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              type="button"
              onClick={(e) => projectData ? navigate(`/projects/${projectData.accountName}`) : ""}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            >
              Back
            </button>
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleSave}
              className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`, `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold 
              text-white shadow-sm disabled:opacity-25`)}
            >
              Save
            </button>
          </div>
        </div>
        </div>
      </form>
    </>
  );
}

export default Provisioning;
