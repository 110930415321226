import ProjectList from "../components/projects/ProjectList";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import { useNavigate } from "react-router-dom";

import LightPanel from "../components/actionPanels/LightPanel";
import BinariesBox from "../components/info/BinariesBox";
import Pagination from "../components/common/Pagination";
import AppLayout from "../components/layout/AppLayout";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import EmptyState from "../components/common/EmptyState";
import { getProjects,getProjectsCount, getProject } from "../redux/actions/projectActions";
import { clearErrors } from "../redux/actions/errorActions";
import { openErrorAlert } from "../redux/actions/alertActions"; 



export default function Projects() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { projects, success, error,  } = useSelector((state) => {
    return state.getProjects;
  });

  const { length } = useSelector((state) => {
    return state.getProjectsCount;
  });

  function handleCreate(e) {
    dispatch(clearErrors())
    e.preventDefault();
    navigate("/create-project");
  }

  function handleEditProject(project) {
    dispatch(clearErrors());
    console.log("send project");
    console.log(project);
    dispatch(getProject(project));
    navigate(`/projects/${project.accountName}`);
    // send id over? or in slug
  }

  //

  const introText =
    "A Project is an app in development. Once all SIP settings, app store data and graphics have been uploaded for a project the project can be submitted for review. After approval and setup of the subscription , the app will convert to a Live App which is available for users.";
  const introTitle = "What is a Project?";



  const itemsPerPage = 10;
  let totalPages = length ? Math.ceil(length / itemsPerPage) : 1;


  function handleNewPage(page) {
    console.log("new page" + page);
    setCurrentPage(page);

    dispatch(getProjects(page - 1, itemsPerPage));
  }

  useEffect(() => {
    if (isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(getProjects(currentPage - 1, itemsPerPage));
      dispatch(getProjectsCount());
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
    }

    if (success) {
      console.log("successfully received apps");
      console.log(projects);
    }

    if (error) {
      console.log("Error: " + error);
      if(error.code === 209) {
          // Navigate to login page
          navigate("/login");
      } else {
        dispatch(openErrorAlert("Error", error.message));
      }
      //dispatch(clearErrors());
    }
  }, [projects, success, error]);

  return (
    <>
      <AppLayout />
      <div>
        <Container>
          <Title
            title={"Projects"}
            buttonTitle={"Create Project"}
            action={handleCreate}
          />
          <LightPanel title={introTitle} note={introText} />
          <div className="mt-4 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16 min-h-screen">
            <section aria-labelledby="cart-heading" className="lg:col-span-7">
              {projects?.length > 0 ? (
                <ProjectList projects={projects} action={handleEditProject} />
              ) : (
                <EmptyState
                  title={"No Projects"}
                  description={"Please create a project"}
                />
              )}
            </section>

            <section className="lg:col-span-1"></section>
            <section className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-4 lg:mt-0 lg:p-8">
              <BinariesBox />
            </section>
          </div>

          {projects?.length > 0 ? (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handleNewPage={handleNewPage}
            />
          ) : (
            <></>
          )}
        </Container>
      </div>
    </>
  );
}
