import React from 'react'
import { useState, useRef,useEffect } from "react";
import SIPAccount from './SIPAccount';
import QRCodeComponent from './QRCodeComponent';
import ExternalProvisioningCredentials from './ExternalProvisioningCredentials';
import { sendQRCodeEmail } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { openStatusAlert } from '../../redux/actions/alertActions';
import QRCode from 'qrcode';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const provisioningModes = [
  { id: "External", title: "External" },
  { id: "Local", title: "Local" }
];

const enabledModes = [
  { id: "Enabled", title: "Enabled" },
  { id: "Disabled", title: "Disabled" }
];

const transportOptions = [
  { id: "UDP", title: "UDP" },
  { id: "TCP", title: "TCP" },
  { id: "TLS", title: "TLS" },
];



const QRFields = ({project}) => {
 
  const qrCodeRef = useRef(null);
  const dispatch = useDispatch();

  const { success:emailSucess, loading, emailLoading, error:emailError } = useSelector((state) => state.sendQRCodeEmailReducer);
  const handleExternalDownload = () => {
    if (qrCodeRef.current) {
      qrCodeRef.current.download(); // Call the download function via ref
    }
  };

  const [sipfragments, setSipfragments] = useState([]);
  const [fragobjects, setfragobjects] = useState([]);
  const [sipfragmentString, setSipfragmentString] = useState('');
  const [externalprovString, setExternalprovString] = useState('');
  const [provisioningMode, setProvisioningMode] = useState('Local');
  const addSipFragment = (newFragment) => {
    
    const fragobj = JSON.parse(newFragment);
    const existingFragment = fragobjects.find(fragment => fragment["sipusername"] === fragobj["sipusername"]);
    if(existingFragment) {
      return;
    }

    
    setfragobjects([...fragobjects, fragobj]);
      console.log(fragobj["sipusername"]);
    

     
    
      if(sipfragments.length < 4) {
    setSipfragments([...sipfragments, newFragment]);
      }
    
  };
  
const clearSipAccounts = () => {  
  setSipfragments([]);
 
}

useEffect(() => {
  if(sipfragments.length === 0) {
    setSipfragmentString("https://sessiontalk.co.uk");
    setfragobjects([]); 
    return;
  }
  const updatedString = sipfragments
    .map(fragment => {
      // Manually construct the JSON string for each fragment
      return `${fragment}`;
    })
    .join(','); // Join the JSON strings with a comma

  setSipfragmentString(updatedString);
}, [sipfragments]);

  useEffect(() => {
    generateQRCode();
    
  }, [sipfragmentString,provisioningMode,externalprovString, project]);

  useEffect(() => {
    if(emailSucess) {
      dispatch(openStatusAlert("Success", "Email sent successfully"));
    }
  }, [emailSucess]);

  const [email, setEmail] = useState('');
  
  
  const [qrCodeValue , setQRCodeValue ] = useState('');

  const handleOnChange = (e) => { 
  
    setProvisioningMode(e.target.value);
   
  }

  



const generateQRCode = () => {
  
  if(project != null && project != undefined && project.operatorcode != null && project.operatorcode !=   undefined) {    
    if(provisioningMode === "Local") {
      if(project.type.includes("generic") || project.type.includes("sessioncloud")) {
  
       const qrCode = `scloc:${project.operatorcode}|{ "sipaccounts": [${sipfragmentString}] }`;
        setQRCodeValue(qrCode);
        console.log(qrCode);
        } else {
          const qrCode = `scloc:{ "sipaccounts": [${sipfragmentString}] }`;
          setQRCodeValue(qrCode);
          console.log(qrCode);
        }
    } else {
      if(project.type.includes("generic")) {
        //scsc:username:password:providerId
        const qrCode = `scsc:${externalprovString}:${project.operatorcode}`;
        setQRCodeValue(qrCode);
        console.log(qrCode);
      } else {
        const qrCode = `scsc:${externalprovString}`;
        setQRCodeValue(qrCode);
        console.log(qrCode);
       }  
    }
  }
}

const getQRCodeBase64 =  () => {
  if (qrCodeRef.current) {
    const buffer = qrCodeRef.current.getImageBuffer();
    if (buffer) {
      console.log('QR Code Buffer:', buffer);
      return buffer;
      // You can now use the buffer (e.g., upload it to a server)
    } else {
      console.error('Failed to get QR code buffer');
      return null;
    }
  }
};

const handleSendEmail = async () => {
  try {
    const qrCodeData = getQRCodeBase64(); // Get base64 image
    dispatch(sendQRCodeEmail(email, qrCodeData)); // Send email with base64 image
  } catch (error) {
    console.error('Error sending email:', error);
    alert('An error occurred while sending the email.');
  }
};

  return (
    <>
     <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
    <div className="col-span-full">
    <label className="text-sm font-semibold leading-6 text-slate-900">
      Are you using your own Provisioning Server?
    </label>
    <p className="text-sm text-slate-500">
      Select External if using your own server to provision SIP credentials 
        otherwise select Local.
      
    </p>
    <fieldset className="mt-4">
      <legend className="sr-only">Provisioning Mode</legend>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {provisioningModes.map((mode) => (
          <div key={mode.id} className="flex items-center">
            <input
              id={`radiobutton-${mode.id}`}
              name="mode"
              type="radio"
              value={mode.id}
              checked={provisioningMode === mode.id}
              onChange={handleOnChange}
              className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
            />
            <label
              htmlFor={mode.id}
              className="ml-3 block text-sm font-medium leading-6 text-slate-900"
            >
              {mode.title}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  </div>

 
  </div>

       { provisioningMode === "Local" ?
      <SIPAccount  addSipFragment={addSipFragment} clearSipAccounts={clearSipAccounts}/>
      : <ExternalProvisioningCredentials externalprovString={externalprovString} setExternalprovString={setExternalprovString}/>
        }
          
          { provisioningMode === "Local" ?  
  <div className="col-span-full">
    <label className="text-sm font-semibold leading-6 text-slate-900">
      SIP Accounts included
    </label>
    
    <fieldset className="mt-4">
      <legend className="sr-only">Provisioning Mode</legend>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {fragobjects.map((fragment) => (
          <div key={fragment["sipusername"]} className="flex items-center">
           
            <label
              className="ml-3 block text-sm font-medium leading-6 text-slate-900"
            >
             {fragment["sipusername"]}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  </div>
   : <></> }
        <QRCodeComponent ref={qrCodeRef} data={qrCodeValue} />
          
          
          <div className="mt-2 flex items-end gap-x-6">
    <div className="flex-1">
      <label
        htmlFor="email-user"
        className="block text-sm font-medium leading-6 text-slate-900 after:ml-0.5 after:text-red-500"
      >
        Email
      </label>
      <div className="mt-2">
        <input
          type="text"
          id="email-user"
          placeholder="Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          minLength={1}
          maxLength="64"
          className={classNames(
            `block w-full py-2 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
            invalid:border-red-500 invalid:text-red-600
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            focus:invalid:border-red-500 focus:invalid:ring-red-500`
          )}
        />
      </div>
    </div>
    <div className="flex-1">
      <button
        type="button"
        onClick={handleSendEmail}
        className="rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-iceberg-300 h-10 "
      >
        Send
      </button>
    </div>
          </div>
           

          <div className="mt-6 flex items-center justify-start gap-x-6  border-t border-slate-200 pt-10">
              <button
                type="button"
                onClick={(e) => {} }
                className="rounded-md  bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleExternalDownload}
                disabled={false}
                className={classNames(`hover:bg-iceberg-300  
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400`,
                 `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
              >
                Download
              </button>
            </div>
  </>
  )
}

export default QRFields