import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function Voicemail({ projectData, handleOnChange,  projectDisabled }) {
  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {" "}
          Voicemail
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
        Personalize and configure your voicemail settings according to your preferences.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3 pt-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Voicemail Number
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="first-name"
              name="voicemail"
              disabled={projectDisabled}
              onChange={handleOnChange}
              maxLength={100}
              aria-describedby="voicemail-number"
              value={
                projectData?.settings?.provisioning?.voicemail ? projectData.settings.provisioning.voicemail : ""
              }
              autoComplete="voicemail-number"
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-3 pt-6">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Proxy
          </label>
          <div className="mt-2">
            <input
              type="text"
              id="last-name"
              name="proxy"
              maxLength={100}
              disabled={projectDisabled}
              onChange={handleOnChange}
              aria-describedby="proxy-number"
              value={
                projectData?.settings?.provisioning?.proxy ? projectData.settings.provisioning.proxy : ""
              }
              autoComplete="proxy-number"
              className={classNames(
                `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="checkbox"
                name="subscribevm"
                disabled={projectDisabled}
                onChange={handleOnChange}
                aria-describedby="subscribe-voicemail-description"
                checked={convertText(
                  projectData?.settings?.provisioning?.subscribevm
                    ? projectData?.settings?.provisioning.subscribevm
                    : "Disabled"
                )}
                type="checkbox" 
                className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Subscribe Voicemail (MWI)
              </label>{" "}
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
}

export default Voicemail;
