import React, { useState, useEffect, useRef } from "react";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import ProjectsTable from "../components/admin/ProjectsTable";
import ProjectSlideOver from "../components/admin/ProjectSlideOver";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openProjectDetailsSlideOver } from "../redux/actions/sliderOverActions";
import {
  getAdminProjects,
  getProjectsCount,
  getProject,
  getProjectBilling,
  approveProject,
  rejectProject,
  createNewVersionProject,
  deleteProject,
} from "../redux/actions/projectActions";
import Pagination from "../components/common/Pagination";
import AdminSort from "../components/admin/AdminSort";
import AppLayout from "../components/layout/AppLayout";

import EmptyState from "../components/common/EmptyState";
import { clearErrors } from "../redux/actions/errorActions";
import { openErrorAlert, openStatusAlert } from '../redux/actions/alertActions';


export default function AdminProjects() {
  const navigate = useNavigate();
  const isMountedRef = useRef(true);
  const isMountedRefTwo = useRef(true);
  const isMountedRefThree = useRef(true);
  const isErrorRef = useRef(true);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [project, setProject] = useState({});
  const [parameterFilters, setParameterFilters] = useState({
    plans: [],
    stages: [],
  });

  const { open } = useSelector((state) => state.projectDetailsSlideOver);

  const { projects, success, error, loading } = useSelector((state) => {
    return state.getAdminProjects;
  });

  const {
    success: cSuccess,
    error: cError,
    message,
  } = useSelector((state) => {
    return state.changeProject;
  });

  const {
    billing,
    success: bSuccess,
    error: bError,
   
  } = useSelector((state) => state.getProjectBilling);

  const { length } = useSelector((state) => {
    return state.getProjectsCount;
  });

  function handleShowDetails(event, project) {
    event.preventDefault();
    console.log("clicked open slideover button");
    if (isMountedRefThree.current == false) {
      isMountedRefThree.current = true;
    }
    setProject(project);
    dispatch(getProjectBilling(project.accountName));
  }
  const itemsPerPage = 10;

  let totalPages = length ? Math.ceil(length / itemsPerPage) : 1;

  function handleNewPage(page) {
    console.log("new page" + page);
    setCurrentPage(page);

    dispatch(getAdminProjects(page - 1, itemsPerPage, parameterFilters));
  }

  useEffect(() => {
    if (isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(getAdminProjects(0, itemsPerPage, parameterFilters));
      dispatch(getProjectsCount(parameterFilters));
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
    }


    if (bSuccess) {
      dispatch(openProjectDetailsSlideOver());
    } else if (success) {
      console.log("successfully received apps");
      console.log(projects);
    }

    if (cSuccess && isMountedRefTwo.current) {
    
      dispatch(openStatusAlert("Success", message));
      dispatch(clearErrors);
      isMountedRefTwo.current = false;
      dispatch(getAdminProjects(0, itemsPerPage, parameterFilters));
      dispatch(getProjectsCount(parameterFilters));
    } else if (cError && isMountedRefTwo.current) {

      if(cError.code === 209) {
        dispatch(openErrorAlert("Session Expired", "Please login again to continue"));
        navigate("/login");
      } else {
      dispatch(openErrorAlert("Error", cError.message));  
      isMountedRefTwo.current = false;
      }
      dispatch(clearErrors);
    } else if (bError && isMountedRefThree.current) {
      isMountedRefThree.current = false;
    
      dispatch(openErrorAlert("Error", bError.message)  );
      dispatch(clearErrors());
    }

    if (error && isMountedRef.isErrorRef) {
      console.log("Error: " + error);
      isErrorRef.current = false;
      dispatch(openErrorAlert("Error", error.message)  ); 
    }
  }, [
    projects,
    success,
    error,
    bSuccess,
    project,
    bError,
    cSuccess,
    message,
    cError,
    open,
  ]);

  function handleEditProject(e, project) {
    e.preventDefault();
    if (isMountedRefThree.current == false) {
      isMountedRefThree.current = true;
    }
    dispatch(clearErrors);
    console.log("send project");
    console.log(project);
    dispatch(getProject(project));
    navigate(`/projects/${project.accountName}`);
  }

  function handleApprove(e, project) {
    e.preventDefault();
    if (isMountedRefTwo.current == false) {
      isMountedRefTwo.current = true;
    }
    console.log("approve project");
    dispatch(
      approveProject(project.accountName, project.email, project.status.version)
    );
  }

  function handleReject(e, project) {
    e.preventDefault();
    if (isMountedRefTwo.current == false) {
      isMountedRefTwo.current = true;
    }
    console.log("reject project");
    dispatch(
      rejectProject(project.accountName, project.email, project.status.version)
    );
  }

  function handleNewVersion(e, project) {
    e.preventDefault();
    if (isMountedRefTwo.current == false) {
      isMountedRefTwo.current = true;
    }

    console.log("new version of project");
    dispatch(createNewVersionProject(project.accountName));
  }

  function handleShowPrepare(e) {
    e.preventDefault();
    dispatch(openErrorAlert("Warning", "This project has no billing details."));  
  }

  function handleDelete(e, project) {
    e.preventDefault();
    dispatch(deleteProject(project.accountName));
  }

  return (
    <>
      <AppLayout />
      {/* {open ? ( */}
      <ProjectSlideOver
        slideOverOpen={open}
        billing={billing}
        project={project}
      />
      {/* ) : (
        <></>
      )} */}

      <Container>
        <Title title={"Admin"} />
        <AdminSort
          parameterFilters={parameterFilters}
          setParameterFilters={setParameterFilters}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
        />
     

    <div className=" min-h-screen">

  
        {projects?.length > 0 ? (
          <ProjectsTable
            handleShowDetails={handleShowDetails}
            projects={projects}
            handleEditProject={handleEditProject}
            handleApprove={handleApprove}
            handleReject={handleReject}
            handleNewVersion={handleNewVersion}
            handleDelete={handleDelete}
          />
        ) : (
          <EmptyState title={"No Projects found"} />
        )}
          </div>
         
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handleNewPage={handleNewPage}
        />
      </Container>
    </>
  );
}
