import React from "react";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SubmissionBox({ project, clickDelete, clickUpdate, clickApproval, clickTrial }) {
  const navigate = useNavigate();

  return (
    <>
      <div aria-labelledby="summary-heading">
        {/* bg-gray-50  */}
        <dl className="space-y-4">
        
            {
               project?.status?.approval?.status != "Trial" && project?.status?.approval?.status != "Update" && 
               project?.status?.approval?.status != "Cancelled" && project?.status?.approval?.status != "Live"? 
               <div>
               <button
                 type="button"
                 onClick={(e) => clickTrial(project.accountName, project.type)}
                 disabled={project?.status?.approval?.status != "Approved"}
                 className={classNames(project?.status?.approval?.status === "Approved" ? `hover:bg-gray-500 focus:outline-none 
                 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50`: ``,
                  `w-full rounded-md border border-transparent bg-gray-400 px-4 py-3 
                 text-base font-medium text-white shadow-sm 
                 disabled:cursor-not-allowed disabled:opacity-25`
                 )}
               >
                 Trial
               </button>
   
               <p className="mt-2 max-w-4xl text-sm text-gray-500">
                 Free 14 day trial to test things out.
               </p>
             </div>
             : <></>
            }
          

           {project.type.includes("whitelabel") ? (
            <div>
              <button
                type="button"
                onClick={(e) =>  navigate(`/builds/${project.accountName}`)}
                disabled={
                  project?.status?.approval?.status != "Live" &&
                  project?.status?.approval?.status != "Trial" &&
                  project?.status?.approval?.status != "Update"
                }
                className={classNames( project?.status?.approval?.status === "Live" ||
                project?.status?.approval?.status === "Trial" ||
                project?.status?.approval?.status === "Update" ? `hover:bg-gray-500 focus:outline-none focus:ring-2 
                focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50` :``,
                  `w-full rounded-md border border-transparent bg-gray-400 px-4 py-3
                 text-base font-medium text-white shadow-sm  disabled:cursor-not-allowed disabled:opacity-25 `)}
              >
                Builds
              </button>

              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Build Softphone binaries
              </p>
            </div>
           ) : (
            <></>
          )} 


{/*  */}

          {
            project?.status?.approval?.status == "Approved" || project?.status?.approval?.status == "Trial"
             || project?.status?.approval?.status == "Cancelled"  ? 

             <div>
            <button
              type="button"
              hidden={  project?.status?.approval?.status === "Rejected" || project?.status?.approval?.status === "Approved"}
              onClick={() => navigate(`/checkout/${project.accountName}`)}
              className={classNames(project?.status?.approval?.status == "Trial" || project?.status?.approval?.status == "Cancelled" ? `` : ` hover:bg-gray-500 focus:outline-none focus:ring-2
              focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50`,
                `w-full rounded-md border border-transparent  disabled:cursor-not-allowed disabled:opacity-25
              bg-gray-400 px-4 py-3 text-base font-medium text-white shadow-sm`)}
            >
              Checkout
            </button>

            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              Checkout to move the app to Live status.
            </p>
          </div>
          : <></>
          }
          

          {project?.status?.overall?.completion >= 100 &&
          (project?.status?.approval?.status === "Live" || project?.status?.approval?.status === "Trial" || project?.status?.approval?.status === "Rejected")? (
            <div>
              <button
                type="button"
                onClick={(e) => clickUpdate(project.accountName)}
                className={classNames(`w-full rounded-md border border-transparent bg-gray-400
                 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 
                 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50  
                 disabled:cursor-not-allowed disabled:opacity-25`)}
              >
                New Version
              </button>

              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Create a new version of the app.
              </p>
            </div>
          ) : (
            <></>
          )}

          
         
            <div>
              <button
                type="button"
                disabled={project?.status?.approval?.status === "Submitted" || project?.status?.overall?.completion < 100 || 
                (project?.status?.approval?.status != "Prepare" && project?.status?.approval?.status != "Update")}
                onClick={(e) =>
                  clickApproval(project?.accountName, project?.status?.version)
                }
                className={classNames(project?.status?.approval?.status === "Submitted" || 
                project?.status?.overall?.completion < 100 || 
                (project?.status?.approval?.status != "Prepare" && project?.status?.approval?.status != "Update") ? ` ` :
                `hover:bg-gray-500 
                focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50`,
                  `w-full rounded-md border border-transparent bg-gray-400 
                px-4 py-3 text-base font-medium text-white shadow-sm  disabled:cursor-not-allowed disabled:opacity-25`)}
              >
                Submit for Approval
              </button>

              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Once all sections are 100% complete the project is ready for
                submission. We will review the settings and usually approve
                within 24 hours.
              </p>
            </div>
         
        </dl>

        {
          //  (! ( project?.status?.version > 1) ||  (project?.status?.approval.status == 'Live') || (project?.status?.approval.status == 'Submitted')
          //  || (project?.status?.approval.status == 'Trial')) 
        }

        {  (project?.status?.approval.status == 'Submitted')  || (project?.status?.approval.status == 'Trial') ||
        (project?.status?.approval.status == 'New') || (project?.status?.approval.status == 'Prepare')  || (project?.status?.approval.status == 'Started') 
        ? 
           <div className="mt-6">
           <button
             type="button"
             className={classNames(`w-full rounded-md border border-transparent bg-red-600 px-4 
             py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 
             focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:cursor-not-allowed disabled:opacity-25`)}
             onClick={clickDelete}
           >
             Delete Project
           </button>
 
           <p className="mt-2 max-w-4xl text-sm text-gray-500">
             This will delete all settings and graphics associated with the
             project
           </p>
         </div>
         : <></>
        }
       
      </div>
    </>
  );
}

export default SubmissionBox;
