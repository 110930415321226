import React from "react";

const testAccounts = [
  {
    id: 1,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },
  {
    id: 2,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },

  {
    id: 3,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },
 
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function TestSIPAccounts({projectData, handleOnChange, projectDisabled}) {
  return (
    <>
     
     <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          Test SIP Accounts
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
        Please provide at least two test SIP accounts. We are unable to
              approve the project without these.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">

            <ul role="list" className="divide-y divide-slate-100">
              {testAccounts.map((account, index) => (
                <li key={account.id}>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className={classNames(`block text-sm font-medium leading-6 text-slate-900`, index < 2 ? "after:content-['*'] after:ml-0.5 after:text-red-500" : "")}
                      >
                        Test SIP Username {account.id}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled={projectDisabled}
                          name={[`username${index + 1}`]}
                          aria-describedby="test-username-input"
                          value={projectData?.settings?.miscellaneous[`username${index + 1}`] ? projectData?.settings.miscellaneous[`username${index + 1}`] : ""}
                          onChange={handleOnChange}
                          id="first-name"
                          noValidate
                          minLength={index < 2 ? 1 : 0}
                          maxLength={100}
                          autoComplete="test-username"
                          className={classNames(
                            `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                            invalid:border-red-500 invalid:text-red-600
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            focus:invalid:border-red-500 focus:invalid:ring-red-500`
                          )}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className={classNames("block text-sm font-medium leading-6 text-slate-900", index < 2 ? "after:content-['*'] after:ml-0.5 after:text-red-500" : "")}
                      >
                        Test SIP Password {account.id}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          disabled={projectDisabled}
                          name={[`password${index + 1}`]}
                          aria-describedby="test-password-input"
                          value={projectData?.settings?.miscellaneous[`password${index + 1}`] ? projectData?.settings.miscellaneous[`password${index + 1}`] : ""}
                          onChange={handleOnChange}
                          id="first-name"
                          noValidate
                          minLength={index < 2 ? 1 : 0}
                          maxLength={100}
                          autoComplete="test-password"
                          className={classNames(
                            `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                            focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                            invalid:border-red-500 invalid:text-red-600
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            focus:invalid:border-red-500 focus:invalid:ring-red-500`
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
    </>
  );
}

export default TestSIPAccounts;
