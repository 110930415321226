import {
    GET_BUILDS_REQUEST,
    GET_BUILDS_SUCCESS,
    GET_BUILDS_FAIL,
    CREATE_BUILD_REQUEST,
    CREATE_BUILD_SUCCESS,
    CREATE_BUILD_FAIL,
    GET_CURRENT_BUILDS_REQUEST,
    GET_CURRENT_BUILDS_SUCCESS,
    GET_CURRENT_BUILDS_FAIL,
    DOWNLOAD_BUILD_REQUEST,
    DOWNLOAD_BUILD_SUCCESS,
    DOWNLOAD_BUILD_FAIL,
    DELETE_BUILD_REQUEST,
    DELETE_BUILD_SUCCESS,
    DELETE_BUILD_FAIL, CANCEL_BUILD_FAIL, CANCEL_BUILD_REQUEST, CANCEL_BUILD_SUCCESS,
    CREATE_BUILD_CLEAR,
    DELETE_BUILD_CLEAR,
    CANCEL_BUILD_CLEAR,
    DOWNLOAD_BUILD_CLEAR,
    UPDATE_BUILD_REQUEST,
    UPDATE_BUILD_SUCCESS,
    UPDATE_BUILD_FAIL,
    UPDATE_BUILD_CLEAR


} from "../constants/buildConstants";

import Parse from "parse/dist/parse.min.js";

function extractBuild(result) {
    const build = {};
    build.id = result.id;
    build.platform = result.get("platform");
    build.buildId = result.get("buildId");
    build.buildNumber = result.get("buildNumber");
    build.version = result.get("version");
    build.completion = result.get("completion");
    build.status = result.get("status");
    build.tag = result.get("tag");
    build.result = result.get("result");
    build.accountName = result.get("accountName");
    let cDate = new Date(result.get("createdAt"));
    build.createdAt = cDate.toLocaleString();
    let uDate = new Date(result.get("updatedAt"));
    build.updatedAt = uDate.toLocaleString();
  
    return build;
  }

export const getBuilds =
  (page, displaylimit, projectName, plan) => async (dispatch) => {
    try {
      dispatch({
        type: GET_BUILDS_REQUEST,
      });

      const results = [];
      if (
        plan != "whitelabel-windows" &&
        plan != "whitelabel-windows-annual" &&
        plan != "whitelabel_desktop"
      ) {

        
        const result = await getPlatformBuilds(displaylimit, page, projectName);
        
        if (result?.length > 0) {
          result.forEach((result) => {
            results.push(extractBuild(result));
          });
        }
      
        
      } else {

       
          const result = await getPlatformBuilds(displaylimit, page, projectName);

          if (result?.length > 0) {
            result.forEach((result) => {
              results.push(extractBuild(result));
            });
          }

      }
       

      dispatch({
        type: GET_BUILDS_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: GET_BUILDS_FAIL,
        payload: error.code,
      });
    }
  };


  async function getPlatformBuilds(limit, page, projectName) {
    const result = await Parse.Cloud.run("getPlatformBuilds", {
      limit: limit,
      page: page,
      appName: projectName,
    });
  
    return result;
  }





  

export const createBuild = (projectName, platform) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BUILD_REQUEST,
    });

    let result;
    
    if(platform === "ios"){
      result = await Parse.Cloud.run("BuildWhitelabeliOS", {
        accountName: projectName,
      });
    }else if(platform === "android"){
      result = await Parse.Cloud.run("BuildWhitelabelAndroid", {
        accountName: projectName,
      });
    }else if(platform === "windows"){
      result = await Parse.Cloud.run("BuildWhitelabelWindows", {
        accountName: projectName,
      });

    }else if(platform === "mac"){
      result = await Parse.Cloud.run("BuildWhitelabelMac", {
        accountName: projectName,
      });
    }
    
   

    dispatch({
      type: CREATE_BUILD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BUILD_FAIL,
      payload: error.code,
    });
  }
};

export const createBuildClear = () => async (dispatch) => {

  dispatch({
    type: CREATE_BUILD_CLEAR,
  });

};



export const updateBuildClear = () => async (dispatch) => {

  dispatch({
    type: UPDATE_BUILD_CLEAR,
  });

};

export const updateBuild = (build, status, completion) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_BUILD_REQUEST,
    });

    await Parse.Cloud.run("UpdateBuild", {
      build: build,
      status: status,
      completion: completion
    });
    
    dispatch({
      type: UPDATE_BUILD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BUILD_FAIL,
      payload: error.code,
    });
  }
};


export const getCurrentBuilds = (projectName, plan) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURRENT_BUILDS_REQUEST,
    });

    const results = [];
    if (
      plan != "whitelabel-windows" &&
      plan != "whitelabel-windows-annual" &&
      plan != "whitelabel_desktop"
    ) {

     
        const result = await getCurrentPlatformBuilds(projectName);

      if (result && result != undefined && result != "none") {
        results.push(extractBuild(result));
      }

    } else {

     
        const result = await getCurrentPlatformBuilds(projectName);

        if (result && result != undefined && result != "none") {
          results.push(extractBuild(result));
        }
      
     
      
     
    }

    dispatch({
      type: GET_CURRENT_BUILDS_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_BUILDS_FAIL,
      payload: error.code,
    });
  }
};


async function getCurrentPlatformBuilds(projectName) {
  const result = await Parse.Cloud.run("currentPlatformBuild", {
    appName: projectName,
  });

  return result;
}


export const deleteBuild = (projectName,buildId ) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BUILD_REQUEST,
    });

    const result = await Parse.Cloud.run("DeleteBuild", { appName: projectName, buildId:  buildId });

    dispatch({
      type: DELETE_BUILD_SUCCESS,
     
    });
  } catch (error) {
    dispatch({
      type: DELETE_BUILD_FAIL,
      payload: error.code,
    });
  }
};

export const deleteBuildClear = () => async (dispatch) => {

    dispatch({
      type: DELETE_BUILD_CLEAR,
    });

};

export const downloadBuild = (projectName, buildId) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_BUILD_REQUEST,
    });

    const result = await Parse.Cloud.run("getBuildArtifact", { appName: projectName, buildId:  buildId });

    dispatch({
      type: DOWNLOAD_BUILD_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: DOWNLOAD_BUILD_FAIL,
      payload: error.code,
    });
  }
};

export const downloadBuildClear = () => async (dispatch) => {

  dispatch({
    type: DOWNLOAD_BUILD_CLEAR,
  });

};

export const cancelBuildClear = () => async (dispatch) => {

  dispatch({
    type: CANCEL_BUILD_CLEAR,
  });

};

export const cancelBuild = (projectName, buildId) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_BUILD_REQUEST,
    });

    const result = await Parse.Cloud.run("CancelBuild", { appName: projectName, buildId:  buildId });

    dispatch({
      type: CANCEL_BUILD_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_BUILD_FAIL,
      payload: error.code,
    });
  }
};
