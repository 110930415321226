import React, {useEffect} from "react";
import RulesTable from "./RulesTable";
import AddRuleSlideover from "./AddRuleSlideover";
import { useSelector, useDispatch } from "react-redux";
import { openAddRuleSlideOver } from "../../../redux/actions/sliderOverActions";
import SetupRuleText from "./SetupRuleText";
import EmptyState from "../../common/EmptyState";
import { getRule } from "../../../redux/actions/projectActions";
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DialPlan({ projectData, handleAddRule, handleRemoveRule, handleSave, handleEditRule }) {
  const { open } = useSelector((state) => state.addRuleSlideOver);
  const { rule } = useSelector((state) => state.getRule);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const projectDisabled = projectData?.status
    ? projectData?.status?.approval?.status === "Prepare" ||
    projectData?.status?.approval?.status === "Update"
      ? false
      : true
    : true;

    const handleOpenEditRule = (rule) => {

      if(!projectDisabled){
        console.log("clicked open slideover button");
        dispatch(getRule(rule));
        dispatch(openAddRuleSlideOver());
      }
     
    };

    useEffect(() => {
    }, [rule]);

    const handleClick = (e) => {
      if(!projectDisabled){
        dispatch(openAddRuleSlideOver());
      }
    }

  return (
    <>
      <AddRuleSlideover
        slideOverOpen={open}
        projectDisabled={projectDisabled}
        handleAddRule={handleAddRule}
        handleSave={handleSave}
        rule={rule ? rule : undefined}
        handleClick={handleClick}
        handleEditRule={handleEditRule}
      />

      {
      projectData?.settings?.provisioning?.ruleslist?.length > 0 ? (
        <RulesTable
        handleOpenEditRule={handleOpenEditRule}
          projectDisabled={projectDisabled}
          rules={
            projectData?.settings?.provisioning?.ruleslist
              ? projectData?.settings?.provisioning?.ruleslist
              : []
          }
          handleClick={handleClick}
          handleRemoveRule={handleRemoveRule}
        />
      ) : (
        <EmptyState
          title={"No Rules"}
          buttonText={"Create Rule"}
          description={"Get started by creating a new rule."}
          action={handleClick}
          disabled={projectDisabled}
        />) }

      <SetupRuleText />
      <div className="mt-6 flex items-center justify-start gap-x-6">
          <button
            type="button"
            onClick={(e) => projectData ? navigate(`/provisioning/${projectData.accountName}`) : ""}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
          >
            Back
          </button>
         
        </div>
    </>
  );
}

export default DialPlan;
