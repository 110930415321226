export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";
export const GET_WHITELABEL_LIVE_APPS_REQUEST = "GET_WHITELABEL_LIVE_APPS_REQUEST";
export const GET_WHITELABEL_LIVE_APPS_SUCCESS = "GET_WHITELABEL_LIVE_APPS_SUCCESS";
export const GET_WHITELABEL_LIVE_APPS_FAIL = "GET_WHITELABEL_LIVE_APPS_FAIL";
export const GET_SESSIONCLOUD_LIVE_APPS_REQUEST = "GET_SESSIONCLOUD_LIVE_APPS_REQUEST";
export const GET_SESSIONCLOUD_LIVE_APPS_SUCCESS = "GET_SESSIONCLOUD_LIVE_APPS_SUCCESS";
export const GET_SESSIONCLOUD_LIVE_APPS_FAIL = "GET_SESSIONCLOUD_LIVE_APPS_FAIL";
export const GET_PROJECTS_COUNT_REQUEST = "GET_PROJECTS_COUNT_REQUEST";
export const GET_PROJECTS_COUNT_SUCCESS = "GET_PROJECTS_COUNT_SUCCESS";
export const GET_PROJECTS_COUNT_APPS_FAIL = "GET_PROJECTS_COUNT_APPS_FAIL";
export const GET_LIVE_APPS_COUNT_REQUEST = "GET_LIVE_APPS_COUNT_REQUEST";
export const GET_LIVE_APPS_COUNT_SUCCESS = "GET_LIVE_APPS_COUNT_SUCCESS";
export const GET_LIVE_APPS_COUNT_APPS_FAIL = "GET_LIVE_APPS_COUNT_APPS_FAIL";
export const GET_PROJECT = "GET_PROJECT";
export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL";
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";
export const CHECK_PROJECT_REQUEST = "CHECK_PROJECT_REQUEST";
export const CHECK_PROJECT_SUCCESS = "CHECK_PROJECT_SUCCESS";
export const CHECK_PROJECT_FAIL = "CHECK_PROJECT_FAIL";
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const  UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const  UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";
export const GET_RULE = "GET_RULE";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const  DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const  DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";
export const GET_PROJECT_BILLING_REQUEST = "GET_PROJECT_BILLING_REQUEST";
export const  GET_PROJECT_BILLING_SUCCESS = "GET_PROJECT_BILLING_SUCCESS";
export const  GET_PROJECT_BILLING_FAIL = "GET_PROJECT_BILLING_FAIL";
export const SET_PROJECT_UPDATE_REQUEST = "SET_PROJECT_UPDATE_REQUEST";
export const  SET_PROJECT_UPDATE_SUCCESS = "SET_PROJECT_UPDATE_SUCCESS";
export const  SET_PROJECT_UPDATE_FAIL = "SET_PROJECT_UPDATE_FAIL";
export const SUBMIT_APPROVAL_REQUEST = "SUBMIT_APPROVAL_REQUEST";
export const  SUBMIT_APPROVAL_SUCCESS = "SUBMIT_APPROVAL_SUCCESS";
export const  SUBMIT_APPROVAL_FAIL = "SUBMIT_APPROVAL_FAIL";
export const GET_ADMIN_PROJECTS_REQUEST = "GET_ADMIN_PROJECTS_REQUEST";
export const  GET_ADMIN_PROJECTS_SUCCESS = "GET_ADMIN_PROJECTS_SUCCESS";
export const  GET_ADMIN_PROJECTS_FAIL = "GET_ADMIN_PROJECTS_FAIL";
export const CREATE_NEW_VERSION_REQUEST = "CREATE_NEW_VERSION_REQUEST";
export const  CREATE_NEW_VERSION_SUCCESS = "CREATE_NEW_VERSION_SUCCESS";
export const  CREATE_NEW_VERSION_FAIL = "CREATE_NEW_VERSION_FAIL";
export const APPROVE_PROJECT_REQUEST = "APPROVE_PROJECT_REQUEST";
export const  APPROVE_PROJECT_SUCCESS = "APPROVE_PROJECT_SUCCESS";
export const  APPROVE_PROJECT_FAIL = "APPROVE_PROJECT_FAIL";
export const REJECT_PROJECT_REQUEST = "REJECT_PROJECT_REQUEST";
export const  REJECT_PROJECT_SUCCESS = "REJECT_PROJECT_SUCCESS";
export const  REJECT_PROJECT_FAIL = "REJECT_PROJECT_FAIL";
export const ACTIVATE_TRIAL_REQUEST = "ACTIVATE_TRIAL_REQUEST";
export const  ACTIVATE_TRIAL_SUCCESS = "ACTIVATE_TRIAL_SUCCESS";
export const  ACTIVATE_TRIAL_FAIL = "ACTIVATE_TRIAL_FAIL";

export const MAKE_LIVE_REQUEST = "MAKE_LIVE_REQUEST";
export const  MAKE_LIVE_SUCCESS = "MAKE_LIVE_SUCCESS";
export const  MAKE_LIVE_FAIL = "MAKE_LIVE_FAIL";

export const GET_GRAPHICS_REQUEST = "GET_GRAPHICS_REQUEST";
export const  GET_GRAPHICS_SUCCESS = "GET_GRAPHICS_SUCCESS";
export const  GET_GRAPHICS_FAIL = "GET_GRAPHICS_FAIL";
export const  GET_GRAPHICS_CLEAR = "GET_GRAPHICS_CLEAR";


export const SAVE_GRAPHICS_REQUEST = "SAVE_GRAPHICS_REQUEST";
export const  SAVE_GRAPHICS_SUCCESS = "SAVE_GRAPHICS_SUCCESS";
export const  SAVE_GRAPHICS_FAIL = "SAVE_GRAPHICS_FAIL";

export const SAVE_FILE_REQUEST = "SAVE_FILE_REQUEST";
export const  SAVE_FILE_SUCCESS = "SAVE_FILE_SUCCESS";
export const  SAVE_FILE_FAIL = "SAVE_FILE_FAIL";





