import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Parse from "parse/dist/parse.min.js";
import RegisterForm from "../components/auth/RegisterForm";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  
import { useDispatch } from "react-redux";  


export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const [errors, setErrors] = useState({
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    passwordError: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({
      firstnameError: user.firstname.length >= 1 ? false : true,
      lastnameError: user.lastname.length >= 1 ? false : true,
      emailError: user.email.length >= 1 ? false : true,
      passwordError: user.password.length >= 8 ? false : true,
      cpasswordError: user.password === user.cpassword ? false : true,
    });

    if (
      errors.emailError ||
      errors.firstnameError ||
      errors.lastnameError ||
      errors.passwordError ||
      errors.cpasswordError
    ) {
      console.log("invalid fields");
      return false;
    }

    try {
      const parseUser = new Parse.User();
      parseUser.set("username", user.email);
      parseUser.set("password", user.password);
      parseUser.set("email", user.email);
      parseUser.set("firstname", user.firstname);
      parseUser.set("lastname", user.lastname);

      var currentUser = Parse.User.current();
                if (currentUser) {
                    // do stuff with the user
                    Parse.User.logOut();
                } 


      const signedUpUser = await parseUser.signUp(null, {
        useMasterKey: false,
        sessionToken: undefined  // Ensure clean session
    });

    // Verify session token was created
    const sessionToken = signedUpUser.getSessionToken();
    if (!sessionToken) {
        throw new Error('Session token not created');
    }
      dispatch(openStatusAlert("Success", "Successfully signed up"));

      const result =  await Parse.Cloud.run('AddContactToCrm', {email: user.email,
        firstname: user.firstname,
           lastname: user.lastname});

      navigate("/login");
    } catch (error) {
      // Show the error message somewhere and let the user try again.
      dispatch(openErrorAlert("Error", error.message));
      //alert("Error: " + error.code + " " + error.message);
      console.log(error.code + " " + error.message);
    }
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {}, [errors]);

  return (
    <>
      <div className="h-full bg-gray-50">
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="logo_purple.svg"
              alt="Sessiontalk"
            />
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign up for an account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <RegisterForm
                onChangeUser={onChange}
                action={handleSubmit}
                user={user}
                errors={errors}
              />
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{"    "}
              <a
                href="/login"
                className="font-semibold leading-6 text-iceberg-400 hover:text-iceberg-300"
              >
                Log in
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
