import React, { useState, useEffect, useRef } from "react";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import AppLayout from '../components/layout/AppLayout';
import { useParams } from "react-router-dom";
import { getProjectById, getProjectByName, getGraphics, saveGraphics } from "../redux/actions/projectActions";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert } from "../redux/actions/alertActions"; 
import { useNavigate } from "react-router-dom";

import MobileGraphicsForm from "../components/whitelabel/MobileGraphicsForm";

export default function WhitelabelIOSGraphics() {
  const navigate = useNavigate();
  
  const isErrorRef = useRef(true);
  const {accountName} = useParams();
  const dispatch = useDispatch();

  const { project, error } = useSelector((state) => state.getProject);
  const { graphics,success, error: gError } = useSelector((state) => state.getGraphics);
  const { saved, error: sError } = useSelector((state) => state.saveGraphics);

  const [projectData, setProjectData] = useState({ ...project });
  const [iosGraphicsData, setIosGraphicsData] = useState({ ...graphics });
  const [androidGraphicsData, setAndroidGraphicsData] = useState({
    ...graphics,
  });

  useEffect(() => {
   
    if(!project) {
      dispatch(getProjectByName(accountName));
    }
     

    if(project) {
      setProjectData(project);
    }

   
    if ((error )) {
     
      if(error.code === 209){
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login');
        return;
      }

      
    }
  }, [project, error,  sError]);

  useEffect(() => {
    
    if(!graphics) {
       dispatch(getGraphics(accountName, "whitelabelandroidgraphics"));
    }

      if( success ) {
        if (graphics?.platform == "ios") {
          setIosGraphicsData(graphics);
        } 
    }


  }, [graphics, success, gError]);

  useEffect(() => {

    if(sError) {
      if(sError.code === 141){
        dispatch(openErrorAlert("Error","Invalid file format. Please ensure that the dimensions   are accurate"));
        dispatch(saveGraphics(accountName, sError.name, null, "operatorandroidgraphics"));
        dispatch(getGraphics(accountName, "operatorandroidgraphics"));
      } 
      else if(sError.code === 209) {
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login'); 
        return;
      }
      else  {
        dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }

    if(saved) {
      dispatch(getGraphics(accountName, "whitelabeliosgraphics"));
      dispatch(getProjectByName(accountName));
    }

  }, [saved, sError]);

  const projectDisabled = projectData?.status
  ? projectData?.status.approval.status === "Prepare" ||
    projectData?.status.approval.status === "Update"
    ? false
    : true
  : true;

  function handleImageDrop(e,propertyName, ){
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    isErrorRef.current = true;
    const file = e.dataTransfer.files[0];
   
  
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName,propertyName,file, "whitelabeliosgraphics" ))
    e.target.value = null;
  };

  function handleOnFileUpload(e, propertyName, ) {
    
    isErrorRef.current = true;
    const file = e.target.files[0];
   
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName, propertyName,file, "whitelabeliosgraphics" ))
    e.target.value = null;
  }

 

  return (
   <>
   <AppLayout  project={project} type={project?.type ? project?.type : ""}/>
   <Container>
   <Title title={"Mobile Branding"}/>
      <MobileGraphicsForm iosGraphicsData={iosGraphicsData ? iosGraphicsData : undefined} accountName={accountName}
       handleOnFileUpload={handleOnFileUpload}
       projectDisabled={projectDisabled}
     
       handleImageDrop={handleImageDrop}/>
      </Container>
   </>
  )
}