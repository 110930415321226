import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { updatePayment } from "../redux/actions/userActions";
import GrayPanel from "../components/actionPanels/GrayPanel";
import LoadingIndicator from "../components/common/LoadingIndicator";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";  

export default function EditPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const isErrorRef = useRef(true);
  const { id } = useParams();
  const [hostedPage, setHostedPage] = useState({});

  function handleBack() {
    navigate(`/account/${id}/billing`);
  }
  const { error, page, loading } = useSelector((state) => state.getHostedCheckoutPage);

 
  useEffect(() => {
    if ((page == undefined || page == {}) && isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(updatePayment());
      // Set the ref to false to prevent further dispatches
      isMountedRef.current = false;
    }

    setHostedPage(page);

    if (error && isErrorRef.current) {
      isErrorRef.current = false;
      dispatch(openErrorAlert("Error","")); 
    }
  }, [page, error, loading]);

  function handleOnSuccess() {
    dispatch(openStatusAlert("Success","Payment details updated")); 
    navigate(`/account/${id}/billing`);
  }

  function handleOnCancel() {
    navigate(`/account/${id}/billing`);
  }

  function handleOnClick(e) {
    console.log("Iframe was clicked");
    console.log(e);
  }

  function handleOnError(e) {
    dispatch(openErrorAlert("Error","Something went wrong, please refresh and try again")); 
  }

  return (
    <>
      <AppLayout />
      <Container>
        <Title
          title={"Update Payment"}
          buttonTitle={"Cancel"}
          action={handleBack}
        />
        <GrayPanel title={""} description={""} />

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl">
            {loading ? (
              <LoadingIndicator />
            ) : (
              <iframe
                className="border-0 overflow-hidden w-full"
                id={"paymentIframe"}
                src={page?.hosted_page?.url ? page.hosted_page.url : ""}
                width="800"
                height="600"
                loading="lazy"
                title="Edit payment Iframe"
                onSuccess={handleOnSuccess}
                onCancel={handleOnCancel}
                onClick={handleOnClick}
                onError={handleOnError}
                onSubmit={handleOnClick}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
