import React from "react";
import CountryDropdown from "../selection/CountryDropdown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AccountForm({userData, handleOnChange, handleCountryChange}) {
  return (
    <>
      <div className="sm:col-span-3">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          First Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            id="first-name"
            placeholder="Please enter your first name"
            name="firstname"
            aria-describedby="first-name"
            value={userData?.firstname ? userData?.firstname : ""}
            onChange={handleOnChange}
            autoComplete="given-name"
            required
            noValidate
            maxLength="64"
            className={classNames(
              `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500 peer`
            )}
          />
           <p className="invisible peer-invalid:visible text-red-600 text-sm">
      Please provide a valid first name.
    </p> 
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Last Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="lastname"
            aria-describedby="last-name"
            placeholder="Please enter your last name"
            value={userData?.lastname ? userData?.lastname : ""}
            onChange={handleOnChange}
            required
            maxLength="64"
            id="last-name"
            autoComplete="last-name"
            className={classNames(
              `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500 peer`
            )}
          />
          
           <p className="invisible  peer-invalid:visible text-red-600 text-sm">
      Please provide a valid last name.
    </p> 
   
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="email"
            aria-describedby="email"
            placeholder="Please enter your email"
            value={userData?.email ? userData?.email : ""}
            minLength="5"
            required
            maxLength="64"
            onChange={handleOnChange}
            autoComplete="given-name"
            className={classNames(
              `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500 peer`
            )}
          />

            <p className="invisible  peer-invalid:visible text-red-600 text-sm">
      Please provide a valid email address.
    </p> 
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Phone
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="phone"
            aria-describedby="phone"
            placeholder="Please enter your phone number"
            value={userData?.phone ? userData?.phone : ""}
            onChange={handleOnChange}
            id="first-name"
            maxLength="64"
            autoComplete="given-name"
            className={classNames(
              `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
            )}
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <CountryDropdown  userData={userData}
                    handleCountryChange={handleCountryChange}/>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Website
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="website"
            aria-describedby="website"
            placeholder="Please enter your website"
            value={userData?.website ? userData?.website : ""}
            onChange={handleOnChange}
            maxLength="32"
            id="first-name"
            autoComplete="given-name"
            className={classNames(
              `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
              focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
              invalid:border-red-500 invalid:text-red-600
              disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
              focus:invalid:border-red-500 focus:invalid:ring-red-500`
            )}
          />
        </div>
      </div>
    </>
  );
}

export default AccountForm;
