import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BuildsEmptyState({
  type,
  handleCreateBuild,
  androidEnabled,
  winEnabled,
  macEnabled,
  iosEnabled,
}) {
  return (
    <>
      <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h3 className="mt-2 text-sm font-semibold text-slate-900">
            No Builds Found
          </h3>
          <p className="mt-1 text-sm text-slate-500">
            Get started by creating a build
          </p>

          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
              {type === "whitelabel-windows" ||
              type === "whitelabel-windows-annual" ||
              type === "whitelabel_desktop" ? (
                <button
                  className={classNames(
                    winEnabled == true
                      ? "focus-within:ring-2 focus-within:ring-inset focus-within:ring-iceberg-300 hover:shadow-md"
                      : "",
                    `group relative  overflow-hidden rounded-lg bg-white px-4 py-5 
shadow sm:p-6  disabled:opacity-25`
                  )}
                  disabled={!winEnabled}
                  onClick={(e) => handleCreateBuild("windows")}
                >
                  <div className={classNames(" p-6 ")}>
                    <div className="flex  items-center justify-center justify-self-center">
                      <div
                        className={classNames(
                          "bg-iceberg-500",
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg justify-self-center"
                        )}
                      >
                        <FontAwesomeIcon icon={faWindows} 
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          Build Windows Softphone
                        </div>
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by creating a new project.
                      </p>
                    </div>
                  </div>
                </button>
              ) : (
                <></>
              )}

              {type === "whitelabel-windows" ||
              type === "whitelabel-windows-annual" ||
              type === "whitelabel_desktop" ? (
                <button
                  className={classNames(
                    macEnabled == true
                      ? "focus-within:ring-2 focus-within:ring-inset focus-within:ring-iceberg-300 hover:shadow-md"
                      : "",
                    `group relative  overflow-hidden rounded-lg bg-white px-4 py-5 
                shadow sm:p-6  disabled:opacity-25`
                  )}
                  disabled={!macEnabled}
                  onClick={(e) => handleCreateBuild("mac")}
                >
                  <div className={classNames(" p-6 ")}>
                    <div className="flex  items-center justify-center justify-self-center">
                      <div
                        className={classNames(
                          "bg-iceberg-500",
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg justify-self-center"
                        )}
                      >
                        <FontAwesomeIcon icon={faApple} 
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          Build Mac Softphone
                        </div>
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by creating a new project.
                      </p>
                    </div>
                  </div>
                </button>
              ) : (
                <></>
              )}

              {type != "whitelabel-windows" &&
              type != "whitelabel-windows-annual" &&
              type != "whitelabel_desktop" ? (
                <button
                  className={classNames(
                    iosEnabled == true
                      ? "focus-within:ring-2 focus-within:ring-inset focus-within:ring-iceberg-300 hover:shadow-md"
                      : "",
                    `group relative  overflow-hidden rounded-lg bg-white px-4 py-5 
                shadow sm:p-6  disabled:opacity-25`
                  )}
                  disabled={!iosEnabled}
                  onClick={(e) => handleCreateBuild("ios")}
                >
                  <div className={classNames(" p-6 ")}>
                    <div className="flex  items-center justify-center justify-self-center">
                      <div
                        className={classNames(
                          "bg-iceberg-500",
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg justify-self-center"
                        )}
                      >
                        <span
                          className="h-6 w-6 text-white font-semibold"
                          aria-hidden="true"
                        >iOS</span>
                      </div>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          Build iOS Softphone
                        </div>
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by creating a new project.
                      </p>
                    </div>
                  </div>
                </button>
              ) : (
                <></>
              )}

              {type != "whitelabel-windows" &&
              type != "whitelabel-windows-annual" &&
              type != "whitelabel_desktop" ? (
                <button
                  className={classNames(
                    androidEnabled == true
                      ? "focus-within:ring-2 focus-within:ring-inset focus-within:ring-iceberg-300 hover:shadow-md"
                      : "",
                    `group relative  overflow-hidden rounded-lg bg-white px-4 py-5 
                shadow sm:p-6  disabled:opacity-25`
                  )}
                  disabled={!androidEnabled}
                  onClick={(e) => handleCreateBuild("android")}
                >
                  <div className={classNames(" p-6 ")}>
                    <div className="flex  items-center justify-center justify-self-center">
                      <div
                        className={classNames(
                          "bg-iceberg-500",
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg justify-self-center"
                        )}
                      >
                        <FontAwesomeIcon icon={faAndroid} 
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="mt-8">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          Build Android Softphone
                        </div>
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by creating a new project.
                      </p>
                    </div>
                  </div>
                </button>
              ) : (
                <></>
              )}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildsEmptyState;
