import React from "react";
import ForgotPasswordForm from "../components/auth/ForgotPasswordForm";
import Parse from "parse/dist/parse.min.js";

async function handleSubmit(e,email){
  try {
  e.preventDefault();
  console.log("forgot password button clicked " );
  await Parse.User.requestPasswordReset(email);
  }
  catch (error) {
    console.error("Error while submitting forgot password form", error);
  } 

};

export default function ForgotPassword() {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen py-2">
        <ForgotPasswordForm handleSubmit={handleSubmit}/>
      </div>
    </>
  );
}
