import React from "react";

import {
  CheckIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

function Invoices({ checks, handleDownload, appName }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function getStatus(status) {
    if (status === "paid") {
      return (
        <CheckIcon
          className="h-6 w-6 shrink-0 text-green-400"
          aria-hidden="true"
        />
      );
    } else if (status === "payment_due") {
      return (
        <ExclamationCircleIcon
          className="h-6 w-6 shrink-0 text-green-400"
          aria-hidden="true"
        />
      );
    } else if (status === "not_paid") {
      return (
        <XMarkIcon
          className="h-6 w-6 shrink-0 text-green-400"
          aria-hidden="true"
        />
      );
    }
  }

  console.log("checks");
  console.log(checks);
  return (
    <>
    {/* className="px-4 sm:px-6 lg:px-8" */}
      <div >
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Invoices
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {`A list of all the invoices for the ${appName} subscription.`}
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Invoice Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Amount
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {checks.map((check) => (
                    <tr key={check.invoice.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {getStatus(check.invoice.status)}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <time dateTime={check.datetime}>
                          {new Date(
                            check.invoice.date * 1000
                          ).toLocaleDateString()}
                        </time>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {" "}
                        {check.invoice.id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          ${check.invoice.amount_paid / 100} USD
                        </div>
                        <div className="mt-1 text-gray-500">
                          Paid on{" "}
                          {new Date(
                            check.invoice.paid_at * 1000
                          ).toLocaleDateString()}
                        </div>
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                          onClick={(e) => handleDownload(check.invoice.id)}
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          PDF Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoices;
