import React from "react";

import AppLayout from "../components/layout/AppLayout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Files() {
  return (
    <>
      <AppLayout />
    </>
  );
}
