import { Fragment, useEffect } from "react";
import {  Menu, Transition } from "@headlessui/react";

import {  UserCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Parse from "parse/dist/parse.min.js";
import { useLocation } from "react-router-dom";
import { clearErrors } from "../../redux/actions/errorActions";
import {  useDispatch } from "react-redux";
import { openErrorAlert } from "../../redux/actions/alertActions"; 
import StatusContainer from "../alerts/StatusContainer";
import { checkAdminUser  } from "../../redux/actions/userActions";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

const userIcon = undefined
let currentUser;

  const userNavigation = [
    {
      name: "Manage Account",
      action: function () {
        try{
          currentUser = Parse.User.current();
          console.log("account user id " + currentUser.id);
          if(currentUser){
            navigate(`/account/${currentUser.id}/account-details`);
          }
         
        }catch(error){
          dispatch(openErrorAlert("Error","Failed to load account details, please try again"));
          dispatch(clearErrors())
        }
        
      },
    },
    {
      name: "Sign out",
      action: function () {
        try {
          Parse.User.logOut().then(() => {
            currentUser = Parse.User.current(); // this will now be null
          });
          navigate("/login");
          console.log("logged out");
        } catch (error) {
          
          dispatch(openErrorAlert("Error","Logout failed, please try again"));
          dispatch(clearErrors())
        }
      },
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (
      !(
        location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/password-success" ||
        location.pathname === "/check-email" ||
        location.pathname === "/reset-password" ||
        location.pathname === "/account-verification"
      )
    ) {
      try {
        const currentUser = Parse.User.current();

       

        if (currentUser) {
          // do stuff with the user
          dispatch(checkAdminUser());
        } else {
          Parse.User.logOut().then(() => {
            const currentUser = Parse.User.current(); // this will now be null
          });

          navigate("/login");
        }
      } catch (error) {
        console.timeLog(error);
        Parse.User.logOut();
        navigate("/login");
      }
    }
  }, [location, currentUser]);

  return (
    <>
   
    <div className="flex min-h-full shadow">
      <div className="flex w-0 flex-1 flex-col lg:pl-64 ">
        <div className="sticky top-0  flex h-16 flex-shrink-0  bg-white  ">
          {/* border-b border-slate-200 */}
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1 justify-end items-center mr-12">
            <a
           href="https://docs.sessiontalk.co.uk"
            className="text-sm/6 font-semibold text-gray-700 mr-4"
            >
             Help Center
        </a>
            </div>
        <div className="ml-4 flex items-center lg:ml-12">
     
       
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-iceberg-300 focus:ring-offset-2">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          {userIcon ? (
            <img
              className="h-8 w-8 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          ) : (
            <UserCircleIcon className="h-8 w-8 text-slate-300 rounded-full" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  onClick={item.action}
                  className={classNames(
                    active ? "bg-slate-100" : "",
                    "block px-4 py-2 text-sm text-slate-700"
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
</div>
        </div>
      </div>
    </div>
    </>
  );
}
