import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function Registration({ projectData, handleOnChange, projectDisabled }) {
  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {" "}
          Registration
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Tailor your registration process to meet your specific requirements.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 pt-6">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="checkbox"
                    name="udpkeepalive"
                    disabled={projectDisabled}
                    aria-describedby="udp-keep-alive-settings"
                    checked={convertText(
                      projectData?.settings?.provisioning?.udpkeepalive
                        ? projectData?.settings?.provisioning.udpkeepalive
                        : "Enabled"
                    )}
                    onChange={handleOnChange}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    UDP Keepalives
                  </label>{" "}
                </div>
              </div>
            </div>

            <div className="sm:col-span-3 pt-6">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="checkbox"
                    name="customDns"
                    disabled={projectDisabled}
                    aria-describedby="custom-dns-settings"
                    checked={convertText(
                      projectData?.settings?.provisioning?.customDns
                        ? projectData?.settings?.provisioning.customDns
                        : "Disabled"
                    )}
                    onChange={handleOnChange}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Custom DNS
                  </label>{" "}
                </div>
              </div>
            </div>

            {projectData?.settings ? (
              projectData.settings.provisioning.customDns == "Enabled" ? (
                <div className="sm:col-span-3 ">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name Server 1
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      disabled={projectDisabled}
                      name="dns1"
                      id="last-name"
                      maxLength={100}
                      value={
                        projectData?.settings?.provisioning?.dns1
                          ? projectData?.settings.provisioning.dns1
                          : ""
                      }
                      aria-describedby="push-proxy-settings"
                      onChange={handleOnChange}
                      autoComplete="name-server-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
  invalid:border-red-500 invalid:text-red-600
  disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {projectData?.settings ? (
              projectData.settings.provisioning.customDns == "Enabled" ? (
                <div className="sm:col-span-3 ">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name Server 2
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="dns2"
                      disabled={projectDisabled}
                      id="last-name"
                      maxLength={100}
                      value={
                        projectData?.settings?.provisioning?.dns2
                          ? projectData?.settings.provisioning.dns2
                          : ""
                      }
                      aria-describedby="push-proxy-settings"
                      onChange={handleOnChange}
                      autoComplete="name-server-name"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
  invalid:border-red-500 invalid:text-red-600
  disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}




           

            {projectData?.settings ? (
              (projectData.type.includes("whitelabel")) 
               || (projectData.type.includes("sessioncloud"))
               || (projectData.type.includes("generic"))

               ? (
                <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="push-simulate-nat-description"
                        name="srtp"
                        disabled={projectDisabled}
                        type="checkbox"
                        checked={convertText(
                          projectData?.settings?.provisioning?.srtp
                            ? projectData?.settings?.provisioning.srtp
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                       SRTP
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )
            }

{projectData?.settings ? (
              (projectData.type.includes("whitelabel") )
               || (projectData.type.includes("generic")) 
               || (projectData.type.includes("sessioncloud"))

               ? (
                <div className="sm:col-span-3">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="push-simulate-nat-description"
                        name="useencryption"
                        type="checkbox"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.useencryption
                            ? projectData?.settings?.provisioning.useencryption
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                      Show Encryption Options Menu
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )
            }

<div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Register Expiry
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="expiry"
                  maxLength={100}
                  disabled={projectDisabled}
                  value={
                    projectData?.settings?.provisioning.expiry
                      ? projectData?.settings.provisioning.expiry
                      : ""
                  }
                  onChange={handleOnChange}
                  aria-describedby="expiry-settings"
                  id="last-name"
                  placeholder="600"
                  autoComplete="register-expiry-number"
                  className={classNames(
                    `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
