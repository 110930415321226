import React from "react";


const binaries = [
    {
      id:1,
        name:  "MacOS Softphone",
        os: "MacOS",
        link: "https://mac-softphone.s3.eu-west-2.amazonaws.com/Softphone.dmg",
    },
    {
      id:2,
        name:  "Windows Softphone",
        os: "Windows",
        link: "https://windows-softphone.s3.eu-west-2.amazonaws.com/sessioncloud.appxbundle",
    }
   ,
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function BinariesBox() {


  return (
    <>
      <div>
        <h2
        
          className={classNames(
            "text-gray-900",
            "text-lg font-semibold leading-8"
          )}
        >
          Generic Softphone Binaries
        </h2>
        <p className="mt-4 text-sm leading-6 text-gray-600">
        The Windows and MacOS apps can be downloaded here. 
        </p>
        <p className="mt-6 flex items-baseline gap-x-1">
        </p>
        <ul
          role="list"
          className="mt-2 space-y-3 text-sm leading-6 text-gray-600"
        >
          {binaries.map((binary) => (
            <li key={binary.id} className="flex gap-x-3">
              
              {binary.os}: {"  "}
              <a href={binary.link} className="text-iceberg-400 hover:text-iceberg-300 hover:underline"  download={binary.name}>
              {binary.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default BinariesBox;
