import { OPEN_DELETE_PROJECT_MODAL } from "../constants/modalConstants";
import { CLOSE_DELETE_PROJECT_MODAL } from "../constants/modalConstants";
import { OPEN_CANCEL_SUBSCRIPTION_MODAL } from "../constants/modalConstants";
import { CLOSE_CANCEL_SUBSCRIPTION_MODAL } from "../constants/modalConstants";
import { OPEN_PROJECT_NAME_MODAL } from "../constants/modalConstants";
import { CLOSE_PROJECT_NAME_MODAL } from "../constants/modalConstants";

export const deleteProjectModalReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_DELETE_PROJECT_MODAL:
      console.log("ran delete project modal reducer");
      return {
        ...state,
        open: true,
      };

    case CLOSE_DELETE_PROJECT_MODAL:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const cancelSubscriptionModalReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_CANCEL_SUBSCRIPTION_MODAL:
      console.log("ran cancel subscription modal reducer");
      return {
        ...state,
        open: true,
      };

    case CLOSE_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const openProjectNameModalReducer = (state = { open: false, plan: "" }, action) => {
  switch (action.type) {
    case OPEN_PROJECT_NAME_MODAL:
      console.log("ran project name modal reducer");
      return {
        ...state,
        open: true,
        plan: action.payload
      };

    case CLOSE_PROJECT_NAME_MODAL:
      return {
        ...state,
        open: false,
        plan: "",
      };

    default:
      return state;
  }
};

