export const GET_BUILDS_REQUEST = "GET_BUILDS_REQUEST";
export const GET_BUILDS_SUCCESS = "GET_BUILDS_SUCCESS";
export const GET_BUILDS_FAIL = "GET_BUILDS_FAIL";
export const CREATE_BUILD_REQUEST = "CREATE_BUILD_REQUEST";
export const CREATE_BUILD_SUCCESS = "CREATE_BUILD_SUCCESS";
export const CREATE_BUILD_FAIL = "CREATE_BUILD_FAIL";
export const CREATE_BUILD_CLEAR = "CREATE_BUILD_CLEAR";
export const GET_CURRENT_BUILDS_REQUEST = "GET_CURRENT_BUILDS_REQUEST";
export const GET_CURRENT_BUILDS_SUCCESS = "GET_CURRENT_BUILDS_SUCCESS";
export const GET_CURRENT_BUILDS_FAIL = "GET_CURRENT_BUILDS_FAIL";
export const DOWNLOAD_BUILD_REQUEST = "DOWNLOAD_BUILD_REQUEST";
export const DOWNLOAD_BUILD_SUCCESS = "DOWNLOAD_BUILD_SUCCESS";
export const DOWNLOAD_BUILD_FAIL = "DOWNLOAD_BUILD_FAIL";
export const DOWNLOAD_BUILD_CLEAR = "DOWNLOAD_BUILD_CLEAR";
export const DELETE_BUILD_REQUEST = "DELETE_BUILD_REQUEST";
export const DELETE_BUILD_SUCCESS = "DELETE_BUILD_SUCCESS";
export const DELETE_BUILD_CLEAR = "DELETE_BUILD_CLEAR";
export const DELETE_BUILD_FAIL = "DELETE_BUILD_FAIL";
export const CANCEL_BUILD_REQUEST = "CANCEL_BUILD_REQUEST";
export const CANCEL_BUILD_SUCCESS = "CANCEL_BUILD_SUCCESS";
export const CANCEL_BUILD_CLEAR = "CANCEL_BUILD_CLEAR";
export const CANCEL_BUILD_FAIL = "CANCEL_BUILD_FAIL";
export const UPDATE_BUILD_REQUEST = "UPDATE_BUILD_REQUEST";
export const UPDATE_BUILD_SUCCESS = "UPDATE_BUILD_SUCCESS";
export const UPDATE_BUILD_FAIL = "UPDATE_BUILD_FAIL";
export const UPDATE_BUILD_CLEAR = "UPDATE_BUILD_CLEAR";




export const GET_CURRENT_PROGRESS_REQUEST = "GET_CURRENT_PROGRESS_REQUEST";
export const GET_CURRENT_PROGRESS_SUCCESS = "GET_CURRENT_PROGRESS_SUCCESS";
export const GET_CURRENT_PROGRESS_FAIL = "GET_CURRENT_PROGRESS_FAIL";
