import React, { useState, useEffect, useRef } from "react";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import AppLayout from '../components/layout/AppLayout';
import { useParams } from "react-router-dom";
import { getProjectById, getProjectByName, getGraphics, saveGraphics } from "../redux/actions/projectActions";
import { useSelector, useDispatch } from "react-redux";
import AndroidForm from "../components/whitelabel/AndroidForm";
import { openErrorAlert } from "../redux/actions/alertActions"; 

export default function WhitelabelAndroidGraphics() {
  const isMountedRef = useRef(true);
  const isSaveRef = useRef(true);
  const isErrorRef = useRef(true);
  const {accountName} = useParams();
  const dispatch = useDispatch();
  const { project, error } = useSelector((state) => state.getProject);

  const { graphics, error: gError } = useSelector((state) => state.getGraphics);
  const { success, error: sError } = useSelector((state) => state.saveGraphics);

  const [projectData, setProjectData] = useState({ ...project });
  const [androidGraphicsData, setAndroidGraphicsData] = useState({...graphics});

  useEffect(() => {
    if ( isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(getProjectByName(accountName));
      dispatch(getGraphics(accountName, "whitelabelandroidgraphics"));
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
      isErrorRef.current = true;
    }

    console.log("Whitelabel android graphics page");
    console.log(graphics);
    console.log(project);
    setAndroidGraphicsData(graphics);
    setProjectData(project);

    if(  success && isSaveRef.current){
      isSaveRef.current = false;
       dispatch(getGraphics(accountName, "whitelabelandroidgraphics"));
    }

    if ((error || gError || sError) &&  isErrorRef.current) {
      isErrorRef.current = false;
      console.log(error);
      console.log(gError);
      console.log(sError);
      if(sError === "101"){
        dispatch(openErrorAlert("Error","Invalid file format. Please ensure that the dimensions are accurate.") );
      }else{
        dispatch(openErrorAlert("Error","Something went wrong, please try again.") ); 
      }
    }
  }, [project, error, graphics, gError, success, sError]);

  const projectDisabled = projectData?.status
  ? projectData?.status.approval.status === "Prepare" ||
    projectData?.status.approval.status === "Update"
    ? false
    : true
  : true;

  function handleImageDrop(e,propertyName, ){
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    isErrorRef.current = true;
    const file = e.dataTransfer.files[0];
    setAndroidGraphicsData({
      ...androidGraphicsData,
      [propertyName]: file.name//URL.createObjectURL(file),
      //[name]: file.name,
    });
  
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName,propertyName,file, "whitelabelandroidgraphics" ))
    isSaveRef.current = true
  };

  function handleOnFileUpload(e, propertyName, ) {
    //e.preventDefault();
    isErrorRef.current = true;
    const file = e.target.files[0];
    setAndroidGraphicsData({
      ...androidGraphicsData,
      [propertyName]: file.name//URL.createObjectURL(file),
      //[name]: file.name,
    });
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName, propertyName,file, "whitelabelandroidgraphics" ))
    isSaveRef.current = true
  }

  function handleSave(e) {
    e.preventDefault();
    console.log("whitelabel android graphics androidGraphicsData ");
    console.log(androidGraphicsData);
   // dispatch(updateProject(projectData));
  }

  return (
   <>
   <AppLayout  project={project} type={project?.type ? project?.type : ""}/>
   <Container>
   <Title title={"Android Branding"}/>
      <AndroidForm androidGraphicsData={androidGraphicsData ? androidGraphicsData : undefined} accountName={accountName}
       handleOnFileUpload={handleOnFileUpload}
       projectDisabled={projectDisabled}
      
       handleImageDrop={handleImageDrop}/>
      </Container>
   </>
  )
}