import React, { useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

function AndroidForm({androidGraphicsData, accountName, projectDisabled,
  handleImageDrop,
  handleDragOver,
  handleOnFileUpload,
  }) {
  const navigate = useNavigate();
  
  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event) {
    event.preventDefault();
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Android Logo and Image Settings
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
              Resources such as memory are constrained on a mobile device. Please ensure png graphic assets have been optimised prior to uploading. Ideally all assets should be below 100Kb. Loading large bitmaps into memory can cause the operating system on the mobile device to refuse to load the bitmap and this can result in a poor user experience.
              </p>
              <a href="https://tinypng.com/"
              className="mt-1 text-sm hover:underline text-iceberg-400 cursor-pointer hover:text-iceberg-300">
              https://tinypng.com/
              </a>

              <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Icon 36x36 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon36"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "icon36")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.icon36 ? (
                          <img
                            src={androidGraphicsData?.icon36?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload1"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload1"
                              type="file"
                              className="sr-only"
                              name="icon36"
                              onChange={(e) =>
                                handleOnFileUpload(e, "icon36")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Icon 192x192 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="icon192"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "icon192")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.icon192 ? (
                          <img
                            src={androidGraphicsData?.icon192?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload2"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                               id="file-upload2"
                               type="file"
                               className="sr-only"
                               name="icon192"
                               onChange={(e) =>
                                 handleOnFileUpload(e, "icon192")
                               }
                               disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Logo 200x96 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="logo200_96"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "logo200_96")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.logo200_96 ? (
                          <img
                            src={androidGraphicsData?.logo200_96?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload3"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload3"
                              type="file"
                              className="sr-only"
                              name="logo200_96"
                              onChange={(e) =>
                                handleOnFileUpload(e, "logo200_96")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Logo 900x288 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="logo900_576"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "logo900_576")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.logo900_576 ? (
                          <img
                            src={androidGraphicsData?.logo900_576?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload4"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                               id="file-upload4"
                               type="file"
                               className="sr-only"
                               name="logo900_576"
                               onChange={(e) =>
                                 handleOnFileUpload(e, "logo900_576")
                               }
                               disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Splash 240x320 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="splash240x320"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "splash240x320")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.splash240x320 ? (
                          <img
                            src={androidGraphicsData?.splash240x320?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload5"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload5"
                              type="file"
                              className="sr-only"
                              name="splash240x320"
                              onChange={(e) =>
                                handleOnFileUpload(e, "splash240x320")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Splash 1080x1920 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="splash1080x1920"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "splash1080x1920")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.splash1080x1920 ? (
                          <img
                            src={androidGraphicsData?.splash1080x1920?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload6"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                               id="file-upload6"
                               type="file"
                               className="sr-only"
                               name="splash1080x1920"
                               onChange={(e) =>
                                 handleOnFileUpload(e, "splash1080x1920")
                               }
                               disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      About 240x320 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="about240x320"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "about240x320")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.about240x320 ? (
                          <img
                            src={androidGraphicsData?.about240x320?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload7"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                             id="file-upload7"
                             type="file"
                             className="sr-only"
                             name="about240x320"
                             onChange={(e) =>
                               handleOnFileUpload(e, "about240x320")
                             }
                             disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      About 1080x1920 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="about1080x1920"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "about1080x1920")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.about1080x1920 ? (
                          <img
                            src={androidGraphicsData?.about1080x1920?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload8"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload8"
                              type="file"
                              className="sr-only"
                              name="about1080x1920"
                              onChange={(e) =>
                                handleOnFileUpload(e, "about1080x1920")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      In Call 240x320 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="incall240x320"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "incall240x320")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.incall240x320 ? (
                          <img
                            src={androidGraphicsData?.incall240x320?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload9"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                               id="file-upload9"
                               type="file"
                               className="sr-only"
                               name="incall240x320"
                               onChange={(e) =>
                                 handleOnFileUpload(e, "incall240x320")
                               }
                               disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      In Call 1080x1920 png
                    </label>
                    <div
                      className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-slate-300 px-6 py-10"
                      name="incall1080x1920"
                      disabled={projectDisabled}
                      onDrop={(e) => handleImageDrop(e, "incall1080x1920")}
                      onDragOver={handleDragOver}
                    >
                      <div className="text-center">
                        {androidGraphicsData?.incall1080x1920 ? (
                          <img
                            src={androidGraphicsData?.incall1080x1920?.url}
                            alt="Uploaded Logo"
                            className="mx-auto h-32"
                          />
                        ) : (
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-slate-300"
                            aria-hidden="true"
                          />
                        )}
                        <div className="mt-4 flex text-sm leading-6 text-slate-600 justify-center">
                          <label
                            htmlFor="file-upload10"
                            className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                            focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                            `relative  rounded-md bg-white font-semibold `)}
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload10"
                              type="file"
                              className="sr-only"
                              name="incall1080x1920"
                              onChange={(e) =>
                                handleOnFileUpload(e, "incall1080x1920")
                              }
                              disabled={projectDisabled}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-slate-600">
                          PNG up to 500KB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </dl>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={(e)=> navigate(`/projects/${accountName}`)}
              >
                Cancel
              </button>
              {/* <button
                type="submit"
                className="rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
              >
                Save
              </button> */}
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    </>
  );
}

export default AndroidForm;
