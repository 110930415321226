import React from 'react'

function TitleNoButton({title,action, buttonTitle,}) {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  return (
   

  <>
   <div className="md:flex md:items-center md:justify-between">
    <div className="min-w-0 flex-1">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight md:pt-8 md:pb-4 py-2">
      {title}
      </h2>
    </div>

    
    
  </div>
  </>
  )
}

export default TitleNoButton