import React, { useState, useEffect, useRef } from "react";
import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import AppLayout from "../components/layout/AppLayout";
import { useParams } from "react-router-dom";
import {
  getGraphics,
  getProjectById,
  getProjectByName,saveGraphics
} from "../redux/actions/projectActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WinForm from "../components/whitelabel/WinForm";
import BadgeTitle from "../components/titles/BadgeTitle";
import { openErrorAlert } from "../redux/actions/alertActions"; 
import { clearErrors } from "../redux/actions/errorActions";
import { openStatusAlert } from "../redux/actions/alertActions";


export default function WhitelabelWinGraphics() {
  const navigate = useNavigate();
  const isErrorRef = useRef(true);
  const { accountName } = useParams();
  const dispatch = useDispatch();

  const { project, error } = useSelector((state) => state.getProject);

  const { graphics,success, error: gError } = useSelector((state) => state.getGraphics);
  const { saved, error: sError } = useSelector((state) => state.saveGraphics);
   const {  updateSuccess, updateError } = useSelector(
        (state) => state.updateProject
      );
  const [projectData, setProjectData] = useState({ ...project });
  const [winGraphicsData, setWinGraphicsData] = useState({...graphics});

  useEffect(() => {
        
        if (updateError ) {
         
          if(updateError.code === 209) {
            // navigate to login 
            dispatch(openErrorAlert("Session Expired","Please login again to continue"));
            navigate('/login');
          } else {
            dispatch(openErrorAlert("Error","Project failed to save. Please try again"));
          }
          return;
        }
        
        if (updateSuccess) {
         
          dispatch(openStatusAlert("Success","Project saved successfully"));
          navigate(`/projects/${accountName}`)
          dispatch(clearErrors())
         
        }
      }, [updateSuccess, updateError]); 

  useEffect(() => {
    if ( !project) {
      // Dispatch your action when the component mounts
      dispatch(getProjectByName(accountName));
    }

    if(project) {
      setProjectData(project);
    }

    if ((error )) {
     
      if(error.code === 209){
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login');
        return;
      }

      
    }

  }, [project, error,  sError]);

  useEffect(() => {
    
    if(!graphics) {
      dispatch(getGraphics(accountName, "whitelabelwingraphics"));
       
    }

      if( success ) {
        if (graphics?.platform == "win") {
          setWinGraphicsData(graphics);
        } 
    }


  }, [graphics, success, gError]);


   
  useEffect(() => {

    if(sError) {
      if(sError.code === 141){
        dispatch(openErrorAlert("Error","Invalid file format. Please ensure that the dimensions   are accurate"));
        dispatch(saveGraphics(accountName, sError.name, null, sError.type));
        dispatch(getGraphics(accountName, sError.type));
      } 
      else if(sError.code === 209) {
        dispatch(openErrorAlert("Error","Session expired. Please login again."));
        navigate('/login'); 
        return;
      }
      else  {
        dispatch(openErrorAlert("Error","Something went wrong. Please try again"));
      }
    }

    if(saved) {
      dispatch(getGraphics(accountName, "whitelabelwingraphics"));
      
      dispatch(getProjectByName(accountName));
    }

  }, [saved, sError]);

  const projectDisabled = projectData?.status
  ? projectData?.status.approval.status === "Prepare" ||
    projectData?.status.approval.status === "Update"
    ? false
    : true
  : true;

  function handleImageDrop(e,propertyName, ){
    e.preventDefault();
    if (projectDisabled) {
      return;
    }
    isErrorRef.current = true;
    const file = e.dataTransfer.files[0];
   
  
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName,propertyName,file, "whitelabelwingraphics" ))
    e.target.value = null;
  };

  function handleOnFileUpload(e, propertyName, ) {
    //e.preventDefault();
    isErrorRef.current = true;
    const file = e.target.files[0];
   
    console.log("Image uploaded")
    dispatch(saveGraphics(accountName, propertyName,file, "whitelabelwingraphics" ))
    e.target.value = null;
  
  }

  

  return (
    <>
      <AppLayout  project={project} type={project?.type ? project?.type : ""} />
      <Container>
        <BadgeTitle
          title={"Windows Branding"}
          badge={projectData?.status ? projectData?.status.approval.status : ""}
          projectName={`${
            projectData?.accountName ? projectData?.accountName : ""
          }`}
        />

        <WinForm project={projectData} winGraphicsData={winGraphicsData ? winGraphicsData : undefined} accountName={accountName}
         handleOnFileUpload={handleOnFileUpload}
         projectDisabled={projectDisabled}
        
         handleImageDrop={handleImageDrop}/>
      </Container>
    </>
  );
}
