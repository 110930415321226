import React from 'react'
import Generator from '../components/qrcodes/Generator'
import AppLayout from '../components/layout/AppLayout'
import Container from '../components/containers/Container'
import TitleNoButton from '../components/titles/TitleNoButton'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getProjects } from "../redux/actions/projectActions";
import { openErrorAlert } from "../redux/actions/alertActions";
import EmptyState from '../components/common/EmptyState'
import QRFields from '../components/qrcodes/QRFields'


const QRCodes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null);

  const { projects, success, error,  } = useSelector((state) => {
    return state.getProjects;
  });

  useEffect(() => {
    if (isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(getProjects(0,10));
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
    }

    if (success) {
      console.log("successfully received apps");
      console.log(projects);
      if (projects.length > 0) {
        setSelectedProject(projects[0]); // Set the first project as the selected project
      }
    }

    if (error) {
      console.log("Error: " + error);
      if(error.code === 209) {
          // Navigate to login page
          navigate("/login");
      } else {
        dispatch(openErrorAlert("Error", error.message));
      }
      //dispatch(clearErrors());
    }
  }, [projects, success, error]);

  return (
    <>
    <AppLayout />
    <div>
        <Container>
        <TitleNoButton
            title={"QR Code Generator"}
           
          />
          { projects  && projects.length > 0 ?
          <Generator  projects={projects} setQRCodeProject={setSelectedProject} />
          : <EmptyState
          title={"No Projects"}
          description={"Please create a project"}
        />
       
          }
           <QRFields project={selectedProject}/> 
      </Container>
      </div>
  </>
  )
}

export default QRCodes