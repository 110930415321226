import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {  XMarkIcon } from "@heroicons/react/24/outline";
import ProjectDetails from "../projects/ProjectDetails";
import { closeProjectDetailsSlideOver } from "../../redux/actions/sliderOverActions";
import { clearErrors } from "../../redux/actions/errorActions";
import { useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";

function ProjectSlideOver({ slideOverOpen, billing, project }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(slideOverOpen);
    if (slideOverOpen === true) {
      console.log("addEventListener ");
      document.addEventListener("keydown", escFunction);
    }

    return () => {
      console.log("removeEventListener");
      document.removeEventListener("keydown", escFunction);
    };
  }, [slideOverOpen]);

  const handleCloseSlideOver = () => {
    //setOpen(false)
    dispatch(closeProjectDetailsSlideOver());
    dispatch(clearErrors());
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      console.log("esc pressed");
      handleCloseSlideOver();
    }
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleCloseSlideOver()}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-iceberg-500 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              Details
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-iceberg-500 text-iceberg-50 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleCloseSlideOver}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Main */}
                        <ProjectDetails billing={billing} project={project}/>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ProjectSlideOver;
