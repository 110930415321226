import { OPEN_ADD_RULE_SLIDEOVER } from "../constants/slideOverConstants";
import { CLOSE_ADD_RULE_SLIDEOVER } from "../constants/slideOverConstants";
import { OPEN_PROJECT_DETAILS_SLIDEOVER } from "../constants/slideOverConstants";
import { CLOSE_PROJECT_DETAILS_SLIDEOVER } from "../constants/slideOverConstants";

export const openAddRuleSlideOver = () => {
  return {
    type: OPEN_ADD_RULE_SLIDEOVER,
  };
};

export const closeAddRuleSlideOver = () => {
  return {
    type: CLOSE_ADD_RULE_SLIDEOVER,
  };
};

export const openProjectDetailsSlideOver = () => {
  return {
    type: OPEN_PROJECT_DETAILS_SLIDEOVER,
  };
};

export const closeProjectDetailsSlideOver = () => {
  return {
    type: CLOSE_PROJECT_DETAILS_SLIDEOVER,
  };
};
