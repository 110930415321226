import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useCallback } from "react";
import AddRuleForm from "./AddRuleForm";
import { useDispatch,  } from "react-redux";
import { closeAddRuleSlideOver } from "../../../redux/actions/sliderOverActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddRuleSlideover({ slideOverOpen, projectDisabled, handleAddRule, handleSave, rule, handleEditRule }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(slideOverOpen);
    if (slideOverOpen === true) {
      console.log("addEventListener ");
      document.addEventListener("keydown", escFunction);
    }

    return () => {
      console.log("removeEventListener");
      document.removeEventListener("keydown", escFunction);
    };
  }, [slideOverOpen]);

  const handleCloseSlideOver = () => {
    dispatch(closeAddRuleSlideOver());
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      console.log("esc pressed");
      handleCloseSlideOver();
    }
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            handleCloseSlideOver();
          }}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <AddRuleForm handleCloseSlideOver={handleCloseSlideOver} handleAddRule={handleAddRule} handleSave={handleSave} rule={rule} 
                    handleEditRule={handleEditRule}/>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default AddRuleSlideover;
