import React from "react";
import { useNavigate } from "react-router-dom";
import LoginFields from "./LoginFields";
import AdvancedLink from "./AdvancedLink";
import { useDispatch } from "react-redux";  
import { updateProject } from "../../../redux/actions/projectActions";
import { openErrorAlert } from "../../../redux/actions/alertActions"; 

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Main({ accountName,projectData, handleOnChange, action, handleOnUnnestedChange, projectDisabled }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSave(e) {

    if(  projectData?.settings?.provisioning?.providerName.length >= 6 &&  (projectData?.type?.includes("whitelabel") || projectData?.operatorcode?.length >= 6 )){

      dispatch(updateProject(projectData));
    }else{
      dispatch(openErrorAlert("Incomplete Details","Please fill in all required fields.")); 
    }
    
    
  }

  return (
    <>
      <form noValidate className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <h2 className="text-base font-semibold leading-7 text-slate-900">
              {" "}
              Login Fields
            </h2>
            <p className="mt-1 text-sm leading-6 text-slate-500">
              Configure and manage the essential login details for your web app.
              Customize required fields to enhance security, control access, and
              tailor your login experience. Safeguard your account while
              simplifying the login process for your users.
            </p>

            <dl className="mt-6 space-y-6  border-t border-slate-200 text-sm leading-6">
              <LoginFields
                projectData={projectData}
                handleOnUnnestedChange={handleOnUnnestedChange}
                handleOnChange={handleOnChange}
                projectDisabled={projectDisabled}
              />
              <AdvancedLink id={projectData ? projectData.id : ""} action={action} />
            </dl>
            
            <div className="mt-6 flex items-center justify-start gap-x-6  border-t border-slate-200 pt-10">
              <button
                type="button"
                onClick={(e) => accountName ? navigate(`/projects/${accountName}`) : ""}
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleSave}
                disabled={projectDisabled}
                className={classNames(projectDisabled ? ``: `hover:bg-iceberg-300 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`,
                 `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Main;
