import Title from "../components/titles/Title";
import Container from "../components/containers/Container";
import LiveApps from "../components/live/LiveApps";
import AppLayout from "../components/layout/AppLayout";
import Pagination from "../components/common/Pagination";
import EmptyState from "../components/common/EmptyState";
import { Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLiveAppsCount, getProjectBilling,  getWhitelabelLiveApps,
  getSessionCloudLiveApps, } from "../redux/actions/projectActions";
import ProjectSlideOver from "../components/admin/ProjectSlideOver";
import { openProjectDetailsSlideOver,  } from "../redux/actions/sliderOverActions";
import { openErrorAlert } from "../redux/actions/alertActions";
import { clearErrors } from "../redux/actions/errorActions";  

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMountedRef = useRef(true);
 
  const [currentPage, setCurrentPage] = useState(1);
  const { open } = useSelector((state) => state.projectDetailsSlideOver);
  const [project, setProject] = useState({});

  const { billing, success, error : billingError, loading } = useSelector((state) => state.getProjectBilling);



  const {
    projects: wProjects,
    success: wSuccess,
    error: wError,
    loading: wLoading,
  } = useSelector((state) => {
    return state.getWhitelabelLiveApps;
  });

  const {
    projects: cProjects,
    success: cSuccess,
    error: cError,
    loading: cLoading,
  } = useSelector((state) => {
    return state.getSessionCloudLiveApps;
  });

  const {
   length,
   error: countError,
  } = useSelector((state) => {
    return state.getLiveAppsCount;
  });

  const itemsPerPage = 10
  const projects = wProjects.concat(cProjects);
  let totalPages = length ? Math.ceil(length / itemsPerPage) : 1;

  useEffect(() => {
    dispatch(getLiveAppsCount());
    if(countError) {
      if(countError.code === 209) {
        navigate("/login");
      } else {
        
        dispatch(openErrorAlert("Error","Something went wrong, please try again"));
      }
    }

  },[]);

  
  function handleCreate(e) {
    dispatch(clearErrors())
    e.preventDefault();
    navigate("/create-project");
  }


  useEffect(() => {
    if(success){
      dispatch(openProjectDetailsSlideOver());
    }

    if(billingError) {
        dispatch(openErrorAlert("Error",billingError.message));
      
    }

  },[success, billingError]);



  useEffect(() => {
    if (isMountedRef.current) {
      // Dispatch your action when the component mounts
      dispatch(getWhitelabelLiveApps(currentPage -1, itemsPerPage));
      dispatch(getSessionCloudLiveApps(currentPage -1, itemsPerPage));
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
    }

    totalPages = length ? Math.ceil(length / itemsPerPage) : 1;

    if (cError && wError ) {
     
      if(cError.code === 209 || wError.code === 209) {
        navigate("/login");
      } else {
        dispatch(openErrorAlert("Error", "Something went wrong, please try again "));
      }
      
    }
  }, [
    wProjects,
    wSuccess,
    wLoading,
    wError,
    cProjects,
    cSuccess,
    cError,
    cLoading,
    currentPage, billing,  loading
  ]);

  function handleNewPage(page)  {
    console.log("new page" + page)
    setCurrentPage(page);
   
  dispatch(getWhitelabelLiveApps(page -1, itemsPerPage));
    dispatch(getSessionCloudLiveApps(page -1, itemsPerPage));
  };

  function handleClick(event, project){
    event.preventDefault();
    
    console.log("clicked open slideover button");

    setProject(project)
    dispatch(getProjectBilling(project.accountName));
  };

  return (
    <>
      <AppLayout />
      <ProjectSlideOver slideOverOpen={open} billing={billing} project={project}/>
      <Container>
        <Title title="Dashboard" />
        <div className=" min-h-screen">
        {projects?.length > 0 ? (
          <LiveApps projects={projects} handleClick={handleClick}/>
        ) : (
          <EmptyState
            title={"No Live Apps"}
            description={"Please create a project"}
            buttonText={"Create Project"}
            action={handleCreate}
          />
        )}

</div>
          <Pagination
          totalPages={totalPages}
            currentPage={currentPage}

            handleNewPage={handleNewPage}
          />
        
      </Container>
    </>
  );
}
