import NotFound from "../components/error/NotFound";
import Container from "../components/containers/Container";

export default function NotFoundPage() {
  return (
    <Container>
       <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
<NotFound />
</div>
    </Container>
   
  );
}
