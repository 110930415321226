import React, { useEffect } from 'react'
import { useState, useRef } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const enabledModes = [
  { id: "Enabled", title: "Enabled" },
  { id: "Disabled", title: "Disabled" }
];

const transportOptions = [
  { id: "UDP", title: "UDP" },
  { id: "TCP", title: "TCP" },
  { id: "TLS", title: "TLS" },
];

const SIPAccount = ({addSipFragment,clearSipAccounts}) => {
  const [sipFragment, setSIPFragment] = useState('');
  const [sipUsername, setSIPUsername] = useState('');
  const [sipPassword, setSIPPassword] = useState('');
  const [sipDomain , setSIPDomain ] = useState('');
  const [authUsername , setAuthUsername ] = useState('');
  const [proxyServer , setProxyServer ] = useState('');
  const [transport , setTransport ] = useState('UDP');
  const [srtp , setSRTP ] = useState('Disabled');
  const [messaging , setMessaging ] = useState('Disabled');
  const [video , setVideo ] = useState('Disabled');
  const [callRecording , setCallRecording ] = useState('Disabled');

  const handleSIPUsernameChange = (e) => {
    setSIPUsername(e.target.value);
   
  } 


  const handleSIPPasswordChange = (e) => {
    setSIPPassword(e.target.value);
    
  } 

  const handleDomainChange = (e) => {
    setSIPDomain(e.target.value);
    
  } 

  const handleAuthChange = (e) => {
    setAuthUsername(e.target.value);
   
  } 

  const handleProxyChange = (e) => {
    setProxyServer(e.target.value);
    
  } 

const handleOnTransportChange = (e) => {
  setTransport(e.target.value);
  
} 

const handleSRTPChange = (e) => {
  setSRTP(e.target.value);
  
}

const handleVideoChange = (e) => {
  setVideo(e.target.value);
  
} 

const handleMessagingChange = (e) => {
  setMessaging(e.target.value);
  
} 

const handleRecordingChange = (e) => {
  setCallRecording(e.target.value);
  
} 

useEffect(() => {
  generateSIPFragment();  
}, [sipUsername, sipPassword, sipDomain, authUsername, proxyServer, transport, srtp, messaging, video, callRecording]);

const generateSIPFragment = () => {
  
  let frag = `{"sipusername":"${sipUsername}","sippassword":"${sipPassword}","subdomain":"${sipDomain}","authusername":"${authUsername}","proxy":"${proxyServer}","transport":"${transport}","srtp":"${srtp}","messaging":"${messaging}","video":"${video}","callrecording":"${callRecording}"}`;
  

  setSIPFragment(frag);
  console.log(frag);
}

const handleAddSipAccount = () => {
  addSipFragment(sipFragment);
} 

  return (
    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-2 border-dashed border-slate-300 px-6 py-6">
        {/* SIP Username   */}
        <div className="sm:col-span-3 ">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              SIP Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="sip-username"
                placeholder="SIP Username"
                name="sipusername"
                value={sipUsername}
                onChange={handleSIPUsernameChange}
                minLength={1}
                maxLength="64"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
        invalid:border-red-500 invalid:text-red-600
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p className="mt-2 text-sm text-slate-500" id="email-description">
              Required
            </p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              SIP Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="sip-password"
                placeholder="SIP Password"
                name="sippassword"
                value={sipPassword}
                onChange={handleSIPPasswordChange}
                minLength={1}
                maxLength="64"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
        invalid:border-red-500 invalid:text-red-600
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p className="mt-2 text-sm text-slate-500" id="email-description">
              Required
            </p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Domain
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="sipdomain"  
                name="domain"
                value={
                  sipDomain
                }
               
                onChange={handleDomainChange}
                minLength={1}
                maxLength="64"
                placeholder="sip.cloudpbx.com"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p
              className="mt-2 text-sm text-slate-500"
              id="email-description"
            ></p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Auth Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="authusername"  
                name="authuser"
                value={
                  authUsername
                }
               
                onChange={handleAuthChange}
                minLength={1}
                maxLength="64"
                placeholder="Auth Username"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p
              className="mt-2 text-sm text-slate-500"
              id="email-description"
            ></p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Proxy Domain
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="proxydomain"  
                name="proxydomain"
                value={
                  proxyServer
                }
               
                onChange={handleProxyChange}
                minLength={1}
                maxLength="64"
                placeholder="Proxy Domain"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p
              className="mt-2 text-sm text-slate-500"
              id="email-description"
            ></p>
          </div>

          <div className="col-span-full">
          <label className="text-sm font-semibold leading-6 text-slate-900">
            Transport
            
          </label>
         
          <fieldset className="mt-4">
            <legend className="sr-only"> Transport</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {transportOptions.map((option) => (
                <div key={option.id} className="flex items-center">
                  <input
                    id={`radiobutton-${option.id}`}
                    name="transport"
                    type="radio"
                    aria-describedby="transport-settings"
                    value={option.id}
                    checked={transport === option.id}
                    onChange={handleOnTransportChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="sm:col-span-3">
          <label className="text-sm font-semibold leading-6 text-slate-900">
           Srtp Encryption
            
          </label>
         
          <fieldset className="mt-4">
            <legend className="sr-only"> Srtp Encryption</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {enabledModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="srtp"
                    type="radio"
                    aria-describedby="srtp-settings"
                    value={mode.id}
                    checked={srtp === mode.id}
                    onChange={ handleSRTPChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="sm:col-span-3">
          <label className="text-sm font-semibold leading-6 text-slate-900">
           Messaging
            
          </label>
         
          <fieldset className="mt-4">
            <legend className="sr-only">Messaging</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {enabledModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="messaging"
                    type="radio"
                    aria-describedby="messaging-settings"
                    value={mode.id}
                    checked={messaging === mode.id}
                    onChange={ handleMessagingChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="sm:col-span-3">
          <label className="text-sm font-semibold leading-6 text-slate-900">
           Video
            
          </label>
         
          <fieldset className="mt-4">
            <legend className="sr-only">Video</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {enabledModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="video"
                    type="radio"
                    aria-describedby="video-settings"
                    value={mode.id}
                    checked={video === mode.id}
                    onChange={handleVideoChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="sm:col-span-3">
          <label className="text-sm font-semibold leading-6 text-slate-900">
           Call Recording
            
          </label>
         
          <fieldset className="mt-4">
            <legend className="sr-only">Call Recording</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {enabledModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="recording"
                    type="radio"
                    aria-describedby="recording-settings"
                    value={mode.id}
                    checked={callRecording === mode.id}
                    onChange={handleRecordingChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6  border-t border-slate-200 pt-10">
              
              <button
                type="button"
                onClick={handleAddSipAccount}
                disabled={false}
                className={classNames(`hover:bg-iceberg-300  w-64 whitespace-nowrap
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400`,
                 `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
              >
                Add Account
              </button>
              <button
                type="button"
                onClick={clearSipAccounts}
                disabled={false}
                className={classNames(`hover:bg-red-300 w-64 whitespace-nowrap
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400`,
                 `rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm  disabled:opacity-25`)}
              >
                Clear Accounts
              </button>
            </div>
    </div>
  )
}

export default SIPAccount
