import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import Tabs from "../components/common/Tabs";
import AccountDetails from "../components/account/AccountDetails";
import PaymentDetails from "../components/account/PaymentDetails"; 
import PlansList from "../components/account/PlansList";
import AppLayout from "../components/layout/AppLayout";
import EmptyState from "../components/common/EmptyState";
import CancelSubscriptionModal from "../components/modals/CancelSubscriptionModal";

import {
  getSubscriptions,
  getUser,
  updateUser,
  deactivateSessionCloud, 
  deactivateWhitelabel,
  getCustomerDetails,
  renewSessionCloud,
  renewWhitelabel
} from "../redux/actions/userActions";
import { openCancelSubscriptionModal } from "../redux/actions/modalActions";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";

export default function Account() {
  const { id, tabId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("account-details");
  const [projectName, setProjectName] = useState("");
  const [planId, setPlanId] = useState("");

  const { update, user, error, updateError } = useSelector(state => state.getUser);
  const { error: cError, customer } = useSelector(state => state.getCustomerDetails);
  const { open } = useSelector(state => state.cancelSubscriptionModal);
  const { subscriptions, error: sError, success, message } = useSelector(state => state.getSubscriptions);
  const [editedUser, setEditedUser] = useState(user);

  useEffect(() => {
    setActiveTab(tabId || "account-details");
  }, [tabId]);

  useEffect(() => {
   
     if(!user) {
        dispatch(getUser())
     }

     if(!subscriptions) {
        dispatch(getSubscriptions())
     }

      if(!customer) {
        dispatch(getCustomerDetails())
      }
      
  
  }, [ user, subscriptions, customer]);

  useEffect(() => {
   
   
    if (success && message) {
      dispatch(openStatusAlert("Success", message));
     
    }
  }, [  sError,  success, message]);

useEffect(() => { 
  
  if (update) {
    dispatch(openStatusAlert("Success", "Account details successfully updated"));
  }

  if (updateError) {
    dispatch(openErrorAlert("Error", "Something went wrong, please try again"));
  }

  }, [update, updateError]); 



  const handleOnChange = (e) => {
    setEditedUser(prev => ({...prev, [e.target.name]: e.target.value}));
   };
   
   const handleCountryChange = (country) => {
    setEditedUser(prev => ({...prev, country})); 
   };
   
   const handleSave = (e) => {
    e.preventDefault();
    dispatch(updateUser(editedUser));
   };
   
   // Update initial state when user changes
   useEffect(() => {
    setEditedUser(user);
   }, [user]);

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleCancelSubscription = (event, appName, type) => {
    event.preventDefault();
    setProjectName(appName);
    setPlanId(type);
    dispatch(openCancelSubscriptionModal());
  };

  const handleViewInvoices = (e, appName) => {
    e.preventDefault();
    navigate(`/subscription-history/${appName}`);
  };

  const handleRenewSubscription = (e, appName, type) => {
    e.preventDefault();
    if (type.includes("sessioncloud") || type.includes("generic")) {
      dispatch(renewSessionCloud(appName));
    } else if (type.includes("whitelabel")) {
      dispatch(renewWhitelabel(appName));
    }
  };

  const handleDeactivate = (e, appName, type) => {
    e.preventDefault();
    if (type.includes("sessioncloud") || type.includes("generic")) {
      dispatch(deactivateSessionCloud(appName));
    } else if (type.includes("whitelabel")) {
      dispatch(deactivateWhitelabel(appName));
    }
  };

  const handleCreateProject = (e) => {
    e.preventDefault();
    navigate("/create-project");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    navigate(`/edit-payment/${id}`);
  };

  const tabs = [
    {
      id: "account-details",
      name: "Account Details",
      href: "account-details",
      current: activeTab === "account-details"
    },
    {
      id: "billing",
      name: "Billing",
      href: "billing",
      current: activeTab === "billing"
    },
    {
      id: "subscriptions",
      name: "Subscriptions",
      href: "subscriptions",
      current: activeTab === "subscriptions"
    }
  ];

  if (!user) return null;

  return (
    <>
      <CancelSubscriptionModal
        modalOpen={open}
        handleDeactivate={handleDeactivate}
        projectName={projectName}
        type={planId}
      />
      <AppLayout />
      <Container>
        <Title title="Manage Account" />
        <Tabs tabs={tabs} />
        
        {activeTab === "account-details" && (
          <AccountDetails
            userData={editedUser}
            handleOnChange={handleOnChange}
            handleCountryChange={handleCountryChange}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}

        {activeTab === "billing" && (
          customer?.customer?.card_status !== "no_card" ? (
            <PaymentDetails
              userData={editedUser}
              action={handleUpdate}
              customer={customer}
            />
          ) : (
            <EmptyState
              title="No Payment Methods"
              description="Please add a payment method"
            />
          )
        )}

        {activeTab === "subscriptions" && (
          subscriptions?.length > 0 ? (
            <PlansList
              handleCancelSubscription={handleCancelSubscription}
              handleReactivate={handleRenewSubscription}
              subscriptions={subscriptions}
              handleViewInvoices={handleViewInvoices}
            />
          ) : (
            <EmptyState
              title="No Subscriptions"
              description="Please create a project"
              buttonText="Create Project"
              action={handleCreateProject}
            />
          )
        )}
      </Container>
    </>
  );
}