import React from "react";
import { useState } from 'react'
import {  useDispatch } from "react-redux";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/20/solid'
import { openProjectNameModal } from '../../redux/actions/modalActions';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const tiers = [
  {
    name: "Generic",
    id: "generic_softphone",
    href: "#",
    additional: "$1 per additional user",
    price: { monthly: "$100" },
    description: "All features to get started.",
    features: [
      "Includes 100 users",
      "$1 per additional user",
      "Windows,MacOS,Android,iOS",
      "Push Notifications",
      "Business Features",
      "Video Calls",
      "QR Code Provisioning",
    ],
    mostPopular: false,
    cta: 'Select'
  },
  {
    name: "Whitelabel Mobile",
    id: "whitelabel_mobile",
    href: "#",
    additional: "$0.4 per additional user",
    price: { monthly: "$400", },
    description: "Everything in Generic, plus your branding.",
    features: [
      "Includes 2000 users",
      "iOS and Android",
      "Business Features",
      "Video Calls",
      "QR Code Provisioning",
      
    ],
    mostPopular: true,
    cta: 'Select'
  },
  {
    name: 'Whitelabel Desktop',
    id: 'whitelabel_desktop', 
    href: '#',
    additional: "$0.4 per additional user",
    price: '$400',
    description: 'Everything in Generic, plus your branding.',
    features: [
      'Includes 2000 users',
      "Business Features",
      "Video Calls",
      "Windows and MacOS",
      "Build Servers",
    
    ],
    featured: false,
    cta: 'Select',
  },
];
function PricingPlans({}) {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const dispatch = useDispatch();

  const handleOpenProjectName = (event, type) => {
    event.preventDefault();
    dispatch(openProjectNameModal(type));
  };

  return (
    <>
      <div className="bg-white pt-0 sm:pt-0 pb-24 sm:pb-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
       
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                'rounded-3xl p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
             
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-5xl font-bold tracking-tight'
                  )}
                >
                  {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== 'string' ? (
                  <span
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <p className="mt-3 text-sm leading-6 text-gray-500">{tier.additional}</p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                    : 'bg-iceberg-400 text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline-iceberg-400',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
                onClick={(e) => handleOpenProjectName(e, tier.id)}
              >
                {tier.cta}
              </a>
              <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{tier.description}</p>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckCircleIcon
                      className={classNames(tier.featured ? 'text-white' : 'text-iceberg-400', 'h-6 w-5 flex-none')}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>

   
    </>
  );
}

export default PricingPlans;
