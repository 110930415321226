import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

function AppLayout({project,type}) {
  return (
    <>
      <Header />
      <Sidebar project={project} type={type}/>
    </>
  );
}

export default AppLayout;
