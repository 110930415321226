import { OPEN_STATUS_ALERT } from "../constants/alertConstants";
import { CLOSE_STATUS_ALERT } from "../constants/alertConstants"; 
import { OPEN_ERROR_ALERT } from "../constants/alertConstants"; 
import { CLOSE_ERROR_ALERT } from "../constants/alertConstants";  

export const openStatusAlert = (title, message) => (dispatch) => {
  dispatch({
    type: OPEN_STATUS_ALERT,
    payload: {
      statusTitle: title,
      statusMessage: message
    }
    
  });
};

export const openErrorAlert = (title,message) => (dispatch) => {
  dispatch({
    type: OPEN_ERROR_ALERT,
    payload: {
      errorTitle: title,
      errorMessage: message
    }
  });
};

export const closeStatusAlert = () => (dispatch) => { 
  dispatch({
    type: CLOSE_STATUS_ALERT,
  });
};

export const closeErrorAlert = () => (dispatch) => { 
  dispatch({
    type: CLOSE_ERROR_ALERT,
  });
};
