import React, { useState } from "react";

const traversalOptions = [
  { id: "Private", title: "Private" },
  { id: "Static", title: "Static" },
  { id: "Global", title: "Global" },
];

const ipOptions = [
  { id: "Prefer IPV4", title: "Prefer IPV4" },
  { id: "Prefer IPV6", title: "Prefer IPV6" },
  { id: "IPV4 Only", title: "IPV4 Only" },
  { id: "IPV6 Only", title: "IPV6 Only" },
];

function getChoice(typeId, choiceId) {
  if (typeId === choiceId) {
    return true;
  }
  return false;
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Video({ projectData, handleOnChange, projectDisabled }) {
  //toggleTraversalRadio(project);

  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          Video Settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
          Fine-tune your video experience with our comprehensive video settings,
          putting you in control of your visual communication.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 pt-6">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="checkbox"
                    aria-describedby="enablevideo-settings"
                    name="enablevideo"
                    type="checkbox"
                    disabled={projectDisabled}
                    checked={convertText(
                      projectData?.settings?.provisioning?.enablevideo
                        ? projectData?.settings?.provisioning.enablevideo
                        : "Enabled"
                    )}
                    onChange={handleOnChange}
                    className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-slate-900"
                  >
                    Allow Video Calls
                  </label>{" "}
                </div>
              </div>
            </div>

            {projectData?.settings ? (
              projectData?.settings.provisioning.enablevideo == "Enabled" ? (
                <div className="sm:col-span-3 pt-6">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="checkbox"
                        aria-describedby="auto-video-description"
                        name="autovideo"
                        disabled={projectDisabled}
                        checked={convertText(
                          projectData?.settings?.provisioning?.autovideo
                            ? projectData?.settings?.provisioning.autovideo
                            : "Disabled"
                        )}
                        onChange={handleOnChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-slate-900"
                      >
                        Auto Enable Video
                      </label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            <div className="col-span-full ">
              <label className="text-sm font-semibold leading-6 text-slate-900">
                SIP Traversal
              </label>
              <p className="text-sm text-slate-500">
                Select the most suitable SIP Traversal configuration to enhance
                the performance and connectivity of your softphone app.
              </p>
              <fieldset className="mt-4">
                <legend className="sr-only">SIP Traversal</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {traversalOptions.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        disabled={projectDisabled}
                        id={`radiobutton-${option.id}`}
                        name="siptraversal"
                        aria-describedby="traversal-settings"
                        type="radio"
                        value={option.id}
                        checked={getChoice(
                          projectData?.settings?.provisioning?.siptraversal
                            ? projectData?.settings.provisioning.siptraversal
                            : "Private",
                          option.id
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                      <label
                        htmlFor={option.id}
                        className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                      >
                        {option.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            {projectData?.settings ? (
              projectData?.settings.provisioning.siptraversal == "Static" ? (
                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-slate-900 "
                  >
                    Static IP
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      
                      id="last-name"
                      disabled={projectDisabled}
                      name="staticip"
                      value={
                        projectData?.settings?.provisioning?.staticip
                          ? projectData?.settings.provisioning.staticip
                          : ""
                      }
                      autoComplete="static-ip"
                      onChange={handleOnChange}
                      noValidate
                      aria-describedby="static-ip-settings"
                      maxLength="64"
                      placeholder="sip.cloudpbx.com"
                      className={classNames(
                        `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                      )}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            <div className="col-span-full">
              <label className="text-sm font-semibold leading-6 text-slate-900">
                IP Version
              </label>
              <p className="text-sm text-slate-500">
                Configure the IP version settings to align with your network
                requirements.
              </p>
              <fieldset className="mt-4">
                <legend className="sr-only">IP Version</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {ipOptions.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={`radiobutton-${option.id}`}
                        name="ipversion"
                        aria-describedby="ip-version-settings"
                        value={option.id}
                        disabled={projectDisabled}
                        type="radio"
                        checked={getChoice(
                          projectData?.settings?.provisioning?.ipversion
                            ? projectData?.settings.provisioning.ipversion
                            : "Prefer IPV4",
                          option.id
                        )}
                        onChange={handleOnChange}
                        className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                      />
                      <label
                        htmlFor={option.id}
                        className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                      >
                        {option.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </dl>
      </div>
    </>
  );
}

export default Video;
