import React,  {  useState } from "react";
//import { Tooltip, Typography } from "@material-tailwind/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

function TooltipIcon({ description }) {
  const [show, setShow] = useState(false);

  return (
    <>
     <div className="relative flex flex-col items-center group">
      <button data-tooltip-target="tooltip-default"  data-tooltip-trigger="hover"  
      data-tooltip-placement="top" type="button" className="flex justify-center"
      onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <InformationCircleIcon className="h-5 w-5 shrink-0 text-gray-400" />
      </button>
      <div className={`absolute whitespace-normal bottom-full flex flex-col items-center w-48  group-hover:flex ${!show ? "hidden" : null}`}>
      {/* absolute whitespace-nowrap bottom-full flex flex-col items-center
       */}
        <span className="relative z-10 p-2  text-xs leading-none text-white font-medium whitespace-no-wrap bg-gray-700 shadow-lg rounded-md">
          {description}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600" />
      </div>
      </div>
    </>
  );
}

export default TooltipIcon;
