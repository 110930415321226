import React, { useMemo } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function SelectRegion({ projectData, handlePushChange, projectDisabled }) {
  const [query, setQuery] = React.useState("");

  // Memoize the selected server and its display name
  const selectedServer = useMemo(() => {
    return findServer(projectData?.settings?.provisioning.resolvedpush, projectData?.settings?.provisioning.pushservers);
  }, [projectData?.settings?.provisioning.resolvedpush, projectData?.settings?.provisioning.pushservers]);

  const selectedServerName = useMemo(() => {
    return findServerName(projectData?.settings?.provisioning.resolvedpush, projectData?.settings?.provisioning.pushservers);
  }, [projectData?.settings?.provisioning.resolvedpush, projectData?.settings?.provisioning.pushservers]);

  // Helper functions
  function findServer(resolvedpush, servers) {
    if (resolvedpush && servers?.length > 0) {
      return servers.find(server => server.domain === resolvedpush);
    }
    return { domain: "auto", region: "Auto" };
  }

  function findServerName(resolvedpush, servers) {
    if (resolvedpush && servers?.length > 0) {
      const server = servers.find(server => server.domain === resolvedpush);
      return server?.region || "Auto";
    }
    return "Auto";
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Combobox
      as="div"
      disabled={projectDisabled}
      name="resolvedpush"
      required
      value={selectedServer} // Use memoized value
      onChange={handlePushChange}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-slate-900">
        Select Region
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className={classNames(
            `w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-slate-900 
            shadow-sm ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-inset focus:ring-iceberg-400 sm:text-sm sm:leading-6
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() => selectedServerName} // Use memoized value
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
        </Combobox.Button>

        {projectData?.settings?.provisioning.pushservers?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {projectData?.settings?.provisioning.pushservers.map((server) => (
              <Combobox.Option
                key={server.domain}
                value={server}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-iceberg-400 text-white" : "text-slate-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames("block truncate", selected && "font-semibold")}>
                      {server?.region || ""}
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-iceberg-400"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default SelectRegion;