import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./input.css";
import "./App.css";
import Login from "./pages/login";
import Projects from "./pages/projects";
import QRCodes from './pages/qrcodes';
import Dashboard from "./pages/dashboard";
import Register from "./pages/register";
import AdminProjects from "./pages/admin-projects";
import Layout from "./pages/layout";
import NotFoundPage from "./pages/404";
import EditProject from "./pages/edit-project";
import Provisioning from "./pages/provisioning";
import DesktopGraphics from "./pages/desktop-graphics";
import MobileGraphics from "./pages/mobile-graphics";
import Miscellaneous from "./pages/miscellaneous";
import WhitelabelMacGraphics from "./pages/whitelabel-mac-graphics";
import WhitelabelWinGraphics from "./pages/whitelabel-win-graphics";
import AppStore from "./pages/app-store";
import WhitelabelMobileGraphics from "./pages/whitelabel-mobile-graphics";
import WhitelabelIOSGraphics from "./pages/whitelabel-ios-graphics";
import WhitelabelAndroidGraphics from "./pages/whitelabel-android-graphics";
import CreateProject from "./pages/create-project";
import ForgotPassword from "./pages/forgot-password";
import Account from "./pages/account";
import Checkout from "./pages/checkout";
import ResetPassword from "./pages/reset-password";
import CheckEmail from "./pages/check-email";
import PasswordSuccess from "./pages/password-success";
import AccountVerification from "./pages/account-verification";
import Files from "./pages/files";
import React from "react";
import Parse from "parse/dist/parse.min.js";
import Advanced from "./pages/advanced";
import SubscriptionHistory from "./pages/subscription-history";
import Builds from "./pages/builds";
import EditPayment from "./pages/edit-payment";
import OrderConfirm from "./pages/order-confirm";
import OrderSuccess from "./pages/order-success";
import CheckoutSuccess from "./pages/checkout-success";
import CheckoutConfirm from './pages/checkout-confirm';
// import ContextProvider from "./ContextProvider";

function App() {
  Parse.initialize("fZT8HdINRTV1x60KUmvYOJT7VZIWz0sfXGTeaCXM");
  //javascriptKey is required only if you have it on server.
  Parse.serverURL = process.env.REACT_APP_PUBLIC_SERVER_URL;
  console.log("PUBLIC_SERVER_URL", process.env.REACT_APP_PUBLIC_SERVER_URL);

  return (
    // <ContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="admin-projects" element={<AdminProjects />} />
          <Route path="projects" element={<Projects />} />
          <Route path="qrcodes" element={<QRCodes />} />
          <Route path="projects/:accountName" element={<EditProject />} />
          <Route path="provisioning/:accountName" element={<Provisioning />} />
          <Route path="advanced/:accountName/:tabId" element={<Advanced />} />
          <Route
            path="desktop-graphics/:accountName"
            element={<DesktopGraphics />}
          />
          <Route path="mobile-graphics/:accountName" element={<MobileGraphics />} />
          <Route
            path="miscellaneous/:accountName/:tabId"
            element={<Miscellaneous />}
          />
          <Route path="app-store/:accountName" element={<AppStore />} />
          <Route
            path="whitelabel-mac-graphics/:accountName"
            element={<WhitelabelMacGraphics />}
          />
          <Route
            path="whitelabel-win-graphics/:accountName"
            element={<WhitelabelWinGraphics />}
          />
          <Route
            path="whitelabel-ios-graphics/:accountName"
            element={<WhitelabelIOSGraphics />}
          />
           <Route
            path="whitelabel-mobile-graphics/:accountName"
            element={<WhitelabelMobileGraphics />}
          />
          <Route
            path="whitelabel-android-graphics/:accountName"
            element={<WhitelabelAndroidGraphics />}
          />
          <Route path="create-project" element={<CreateProject />} />
          <Route path="account/:id/:tabId" element={<Account />} />
          <Route
            path="subscription-history/:id"
            element={<SubscriptionHistory />}
          />
          <Route path="builds/:accountName" element={<Builds />} />
          <Route path="checkout/:accountName" element={<Checkout />} />
          <Route path="files" element={<Files />} />
          <Route path="edit-payment/:id" element={<EditPayment />} />
          <Route path="order-confirm" element={<OrderConfirm />} />
          <Route path="order-success" element={<OrderSuccess />} />
          <Route path="checkout-success/:hostedPageId/:accountName" element={<CheckoutSuccess />} />
          <Route path="checkout-confirm/:invoiceId/:accountName" element={<CheckoutConfirm />} />

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="password-success" element={<PasswordSuccess />} />
          <Route path="check-email" element={<CheckEmail />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route
            path="account-verification"
            element={<AccountVerification />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    // </ContextProvider>
  );
}

export default App;
