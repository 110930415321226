import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText  (text) {
  if (text === "Enabled") {
    return true;
  }
  return false;
};

function STUN({projectData, handleOnChange, projectDisabled}) {
  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          {" "}
          STUN Settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-500">
        Configure your network settings effortlessly to ensure seamless and reliable connectivity within our web app.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  aria-describedby="stun-wifi-description"
                  name="stunWifi"
                  disabled={projectDisabled}
                  checked={convertText(
                    projectData?.settings?.provisioning?.stunWifi
                      ? projectData?.settings?.provisioning.stunWifi
                      : "Disabled")
                  }
              onChange={handleOnChange}
                  type="checkbox"
                  className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-slate-900">
                  STUN Enabled WiFi
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  aria-describedby="stun-3g-description"
                  name="stun3g"
                  disabled={projectDisabled}
                  checked={convertText(
                    projectData?.settings?.provisioning?.stun3g
                      ? projectData?.settings?.provisioning.stun3g
                      : "Disabled")
                  }
              onChange={handleOnChange}
                  type="checkbox"
                  className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-slate-900">
                  STUN Enabled Mobile
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              STUN Server
            </label>
            <div className="mt-2">
              <input
                type="text"
                maxLength={100}
                name="stun"
                value={
                  projectData?.settings?.provisioning?.stun
                    ? projectData?.settings.provisioning.stun
                    : ""
                }
                disabled={projectDisabled}
                aria-describedby="stun-server"
            onChange={handleOnChange}
                id="first-name"
                autoComplete="stun-server-name"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                  invalid:border-red-500 invalid:text-red-600
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
                
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default STUN;
