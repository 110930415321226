import React, { useEffect } from "react";

const provisioning = {
  id: "provisioning",
  title: "Provisioning",
  page: "/provisioning", //
  description: "Configure SIP settings for your app here",
};
const mobileGraphics = {
  id: "mobile-graphics",
  title: "Mobile Branding",
  page: "/mobile-graphics",

  description: "Upload mobile graphics here",
};

const desktopGraphics = {
  id: "desktop-graphics",
  title: "Desktop Branding",
  page: "/desktop-graphics",

  description: "Upload desktop graphics here",
};
const whitelabelWinGraphics = {
  id: "whitelabel-win-graphics",
  title: "Windows Branding",
  page: "/whitelabel-win-graphics",

  description: "Upload whitelabel windows graphics here",
};

const whitelabelMacGraphics = {
  id: "whitelabel-mac-graphics",
  title: "MacOS Branding",
  page: "/whitelabel-mac-graphics",

  description: "Upload whitelabel mac graphics here",
};
const miscellaneous = {
  id: "miscellaneous",
  title: "Miscellaneous",
  page: "/miscellaneous",

  description: "Links for Help and contact email address",
};
const appStore = {
  id: "app-store",
  title: "App Store",
  page: "/app-store",
  platform: "desktop",
  description: "App specific data such as package identifiers",
};

const whitelabelMobileGraphics = {
  id: "whitelabel-mobile-graphics",
  title: "Mobile Branding",
  page: "/whitelabel-mobile-graphics",

  description: "Upload whitelabel mobile graphics here",
};

const whitelabelIOSGraphics = {
  id: "whitelabel-ios-graphics",
  title: "Mobile iOS Branding",
  page: "/whitelabel-ios-graphics",

  description: "Upload whitelabel mobile graphics here",
};
const whitelabelAndroidGraphics = {
  id: "whitelabel-android-graphics",
  title: "Android Branding",
  page: "/whitelabel-Android-graphics",

  description: "Upload whitelabel android graphics here",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function showItem(type, id, usewindows) {
  if (id === "provisioning") {
    return true;
  } else if (
    (type === "sessioncloud_vvs" ||
      type === "sessioncloud_business" ||
      type === "sessioncloud_secure" ||
      type === "sessioncloud_all" ||
      type === "generic_softphone" ||
      type === "generic-platforms") &&
    (id === "mobile-graphics" || id === "desktop-graphics")
  ) {
    return true;
  } else if (
    (type === "whitelabel-mobile-annual" ||
      type === "whitelabel_mobile" ||
      type === "whitelabel_advanced" ||
      type === "whitelabel_premium") &&
    ( id === "whitelabel-mobile-graphics")
  ) {
    return true;
  } else if (
    (type === "whitelabel-windows" ||
      type === "whitelabel-windows-annual" ||
      type === "whitelabel_desktop" || usewindows === 'Enabled') && !type.includes("generic") && !type.includes("sessioncloud") 
      && (id === "whitelabel-win-graphics" )
  ) {
    return true;
  } else if (
    (type === "whitelabel_desktop") &&
    (id === "whitelabel-mac-graphics")
  ) {
    return true;
  } 
  else if (
    (type.includes("whitelabel")) &&
    id === "app-store"
  ) {
    return true;
  } else if (id === "miscellaneous") {
    return true;
  }

  return false;
}

function getField(id) {
  if (id === "provisioning") {
    return "provisioning";
  } else if (id === "miscellaneous") {
    return "misc";
  } else if (id === "mobile-graphics") {
    return "androidGraphics" //"operatorandroidgraphics";
  } else if (id === "desktop-graphics") {
    return  "winGraphics" //"operatorwingraphics";
  } else if (id === "whitelabel-mobile-graphics") {
    return "whitelabelmobilegraphics";
  }  else if (id === "whitelabel-ios-graphics") {
    return "whitelabeliosgraphics";
  } else if (id === "whitelabel-android-graphics") {
    return "whitelabelandroidgraphics";
  } else if (id === "whitelabel-win-graphics") {
    return "whitelabelwingraphics";
  } else if (id === "whitelabel-mac-graphics") {
    return "whitelabelmacgraphics";
  }  else if (id === "app-store") {  
    return "appstore";
  }
  return "misc";
}

function TaskList({ project, handleNavigate }) {
  const items = [
    provisioning,
    appStore,
    mobileGraphics,
    desktopGraphics,
    whitelabelMobileGraphics,
    whitelabelIOSGraphics,
    whitelabelAndroidGraphics,
    whitelabelMacGraphics,
    whitelabelWinGraphics,
    miscellaneous,
  ];

  useEffect(() => {
   
  }, [items, project]);

  return (
    <>
      <ul role="list" className="space-y-5">
        {items.map((item) => (
          <li
            key={item.id}
            style={{
              display: showItem(
                project?.type,
                item.id,
                project?.settings.provisioning.usewindows
              )
                ? "block"
                : "none",
            }}
            className="overflow-hidden rounded-md bg-white px-6 lg:px-8 py-2   cursor-pointer
            shadow hover:shadow-md focus-within:ring-2 focus-within:ring-iceberg-300 focus-within:ring-offset-2   "
          >
            {/* border border-gray-200 hover:border-gray-300 */}
            {/* Your content */}

            <div>
              <a onClick={(e) => handleNavigate(item.page)}>
                <div>
                  <div className="mt-3 text-start sm:mt-5 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 className="text-base font-semibold text-gray-900 leading-6">
                        {item.title}
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {item.description}
                        </p>
                      </div>
                    </div>

                    {(project?.status[`${getField(item.id)}`]?.status
                      ? project?.status[`${getField(item.id)}`]?.status
                      : "Incomplete") === "Complete" ? (
                      <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                        {
                          //item.stage
                          project?.status[`${getField(item.id)}`]?.status
                            ? project?.status[`${getField(item.id)}`]?.status
                            : "Incomplete"
                        }
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-md bg-iceberg-100 px-2 py-1 text-xs font-medium text-iceberg-700">
                        {
                          //item.stage
                          project?.status[`${getField(item.id)}`]?.status
                            ? project?.status[`${getField(item.id)}`]?.status
                            : "Incomplete"
                        }
                      </span>
                    )}
                  </div>
                </div>

                <div className="py-4  lg:py-6">
                  <h4 className="sr-only">Status</h4>
                  <p className="text-sm font-medium text-gray-900">
                    {project?.status[`${getField(item.id)}`]?.completion
                      ? project?.status[`${getField(item.id)}`]?.completion
                      : "0"}
                    % complete
                  </p>
                  <div className="mt-2" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200">
                      <div
                        className="h-2 rounded-full bg-iceberg-400"
                        style={{
                          width: `${
                            project?.status[`${getField(item.id)}`]
                            ?.completion
                              ? project?.status[`${getField(item.id)}`]
                                  ?.completion
                              : 0
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}

export default TaskList;
