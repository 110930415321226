import React, { useEffect } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Pagination({ currentPage, totalPages, handleNewPage }) {
 
  let maxPageOptions = 10;
  const pageOptions = selectPages();

  console.log("pageOptions " + pageOptions);

  function selectPages() {
    // sets nb options
    const min = Math.min(totalPages, maxPageOptions);
    //select this many options from a range

    if (currentPage >= 10 && currentPage != totalPages) {
      const startIndex = currentPage - 8;
      return Array.from({ length: min }, (_, index) => startIndex + index);
    } else if (currentPage >= 10 && currentPage == totalPages) {
      const startIndex = currentPage - 9;
      return Array.from({ length: min }, (_, index) => startIndex + index);
    } else {
      return Array.from(
        { length: Math.min(totalPages, maxPageOptions) },
        (_, index) => index + 1
      );
    }
  }

  useEffect(() => {}, [currentPage]);

  return (
    <>
      <div className="items-end">
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 ">
          <div className="-mt-px flex w-0 flex-1 ">
            <button
              disabled={currentPage == 1 ? true : false}
              onClick={() => handleNewPage(1)}
              className={classNames(
                currentPage == 1
                  ? ""
                  : "cursor-pointer hover:border-gray-300 hover:text-gray-700",
                `inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm 
              font-medium text-gray-500  `
              )}
            >
              <ArrowLongLeftIcon
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              First
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            <ul>
              {pageOptions.map((page, index) => (
                // {pages.map((page, pIndex) => (
                <a
                  onClick={() => handleNewPage(page)}
                  key={index}
                  className={classNames(
                    currentPage === page
                      ? " border-iceberg-300 text-iceberg-400"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    `inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium cursor-pointer`
                  )}
                >
                  {page}
                </a>
              ))}
            </ul>
          </div>
          <div className="-mt-px flex w-0 flex-1 justify-end">
          
            <button
              disabled={currentPage == totalPages ? true : false}
              onClick={(e) => handleNewPage(totalPages)}
              className={classNames(
                currentPage == totalPages ? "" : "hover:border-gray-300 hover:text-gray-700 cursor-pointer",
                `inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm 
              font-medium text-gray-500 `
              )}
            >
              Last
              <ArrowLongRightIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Pagination;
