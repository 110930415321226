import React from "react";
import {
    DocumentIcon,IdentificationIcon
  } from "@heroicons/react/24/solid";

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

function MacOS({ projectData, handleOnChange, projectDisabled, handleOnFileUpload, handleImageDrop }) {

  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event){
    event.preventDefault();
  };

  return (
    <>
      <div >
        <h2 className="text-base font-semibold leading-7 text-slate-900">
          MacOS
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-600">
          We'll always let you know about important changes, but you pick what
          else you want to hear about.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Mac OS Version
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="macVersion"
                value={
                  projectData?.settings?.appstore?.macVersion ? projectData?.settings?.appstore?.macVersion : ""
                }
                maxLength={100}
                  autoComplete="mac-version"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
              
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
              Mac OS Build No
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="macBuildNumber"
                value={
                  projectData?.settings?.appstore?.macBuildNumber ? projectData?.settings?.appstore?.macBuildNumber : ""
                }
                 maxLength={100}
                  autoComplete="mac-build-number"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
              
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              MacOS Update Url
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="macupdateurl"
                value={
                  projectData?.settings?.appstore?.macupdateurl ? projectData?.settings?.appstore?.macupdateurl : ""
                }
                 maxLength={100}
                  autoComplete="mac-update-url"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
               
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Distribution Certificate Identity
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="maccertificateIdentity"
                value={
                  projectData?.settings?.appstore?.maccertificateIdentity ? projectData?.settings?.appstore?.maccertificateIdentity : ""
                }
                 maxLength={100}
                  autoComplete="mac-certificate-identity"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
              minLength={1}
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Distribution Certificate Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="macCertPassword"
                value={
                  projectData?.settings?.appstore?.macCertPassword ? projectData?.settings?.appstore?.macCertPassword : ""
                }
                 maxLength={100}
                  autoComplete="mac-certificate-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
               minLength={1}
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
              Distribution Certificate
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
             name="macdistcert"
             disabled={projectDisabled}
             onDrop={(e) => handleImageDrop(e, "macdistcert")}
             onDragOver={handleDragOver}>
              <div className="text-center">

              { projectData?.macdistcertName ? (
                    <IdentificationIcon
                    className="mx-auto h-12 w-12 text-slate-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload6"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    focus-within:outline-none focus-within:ring-2 focus-within:ring-iceberg-400 focus-within:ring-offset-2 hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload6"
                     
                      type="file"
                      className="sr-only"
                       name="macdistcert"
                      onChange={(e) => handleOnFileUpload(e, "macdistcert", "macdistcertName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-slate-600">
                  {
                    projectData?.macdistcertName
                    ? projectData?.macdistcertName
                    : ""
                  }
                  </p>
              </div>
            </div>
          </div>
        </div>
        </dl>
      </div>
    </>
  );
}

export default MacOS;
