import React from "react";
import TooltipIcon from "../provisioning/TooltipIcon";
import { useNavigate } from "react-router-dom";

const contentTypes = [
  {
    id: "0",
    name: "application/x-www-form-urlencoded",
  },
  { id: "1", name: "application/xml" },
  { id: "3", name: "application/json" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function getChoice(typeId, choiceId) {
  if (choiceId === typeId) {
    return true;
  }
  return false;
}

const appTitleDescription =
  "This is the title that will be shown in the softphone apps where remote contacts are displayed. In the Desktop softphones this will be the name of the group. On mobile softphones it will be shown as a separate tab. This can be used to differentiate remote contacts from local contacts in the phone address book.";
const urlDescription =
  "Enter the url of the REST API where the softphones will obtain contacts from";
const dataDescription =
  "If this field is filled in, the softphone will make a POST request with this data";
const usernameDescription = "Username for use with Http Basic authentication";
const passwordDescription = "Password for use with Http Basic authentication";

function PhoneBook({ projectData, handleOnChange, projectDisabled, handleSave }) {

  const navigate = useNavigate();
  
  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Contacts
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
                Effortlessly retrieve and organize server-based contact details.
                Update and sync contact information seamlessly. Your network,
                effortlessly accessible.
              </p>

              <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full pt-6">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="checkbox"
                          name="remotephonebookenabled"
                          disabled={projectDisabled}
                          onChange={handleOnChange}
                          aria-describedby="remote-phone-book-enabled-input"
                          checked={convertText(
                            projectData?.settings?.provisioning?.remotephonebookenabled
                              ? projectData?.settings?.provisioning
                                  .remotephonebookenabled
                              : "Disabled"
                          )}
                          type="checkbox"
                          className="h-4 w-4 rounded border-slate-300 text-iceberg-400 focus:ring-iceberg-400"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="comments"
                          className="font-medium text-slate-900"
                        >
                          Remote PhoneBook
                        </label>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Title shown in Apps
                      <span className=" ml-4 inline-block align-top ">
                        <TooltipIcon description={appTitleDescription} />
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        disabled={projectDisabled}
                        name="remotephonebookdisplayname"
                        aria-describedby="remote-phone-book-display-name-input"
                        value={
                          projectData?.settings?.provisioning?.remotephonebookdisplayname
                            ? projectData?.settings.provisioning
                                .remotephonebookdisplayname
                            : ""
                        }
                        maxLength={100}
                        onChange={handleOnChange}
                        id="first-name"
                        autoComplete="remote-phonebook-display-name"
                        className={classNames(
                          `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Remote Phonebook Url
                      <span className=" ml-4 inline-block align-top ">
                        <TooltipIcon description={urlDescription} />
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        disabled={projectDisabled}
                        name="remotephonebookurl"
                        aria-describedby="remote-phone-book-url-input"
                        value={
                          projectData?.settings?.provisioning?.remotephonebookurl
                            ? projectData?.settings.provisioning
                                .remotephonebookurl
                            : ""
                        }
                        onChange={handleOnChange}
                        id="last-name"
                        maxLength={100}
                        autoComplete="remote-phonebook-url"
                        className={classNames(
                          `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-full pt-6">
                    <label className="text-sm font-semibold leading-6 text-slate-900">
                      Content Type for Post
                    </label>
                    <p className="text-sm text-slate-500">
                      How do you prefer to receive POST data?
                    </p>
                    <fieldset className="mt-4">
                      <legend className="sr-only">Content Type for Post</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        {contentTypes.map((type) => (
                          <div key={type.id} className="flex items-center">
                            <input
                              id={type.id}
                              disabled={projectDisabled}
                              name="phonebookcontenttype"
                              checked={getChoice(
                                projectData?.settings?.provisioning
                                ?.phonebookcontenttype
                                  ? projectData?.settings.provisioning
                                      .phonebookcontenttype
                                  : "0",
                                type.id
                              )}
                              aria-describedby="phone-book-content-type-input"
                              onChange={handleOnChange}
                              type="radio"
                              value={type.id}
                              className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                            />
                            <label
                              htmlFor={type.id}
                              className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                            >
                              {type.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      Remote Phonebook POST Data
                      <span className=" ml-4 inline-block align-top ">
                        <TooltipIcon description={dataDescription} />
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        disabled={projectDisabled}
                        name="remotephonepostdata"
                        aria-describedby="remote-phone-post-data-input"
                        value={
                          projectData?.settings?.provisioning?.remotephonepostdata
                            ? projectData?.settings.provisioning
                                .remotephonepostdata
                            : ""
                        }
                        onChange={handleOnChange}
                        maxLength={100}
                        id="first-name"
                        autoComplete="remote-phone-post-data-input"
                        className={classNames(
                          `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      REST API Username
                      <span className=" ml-4 inline-block align-top ">
                        <TooltipIcon description={usernameDescription} />
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        disabled={projectDisabled}
                        name="remotephonebookusername"
                        aria-describedby="remote-phone-book-username-input"
                        value={
                          projectData?.settings?.provisioning?.remotephonebookusername
                            ? projectData?.settings.provisioning
                                .remotephonebookusername
                            : ""
                        }
                        onChange={handleOnChange}
                        maxLength={100}
                        id="first-name"
                        autoComplete="remote-phonebook-username"
                        className={classNames(
                          `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3 pt-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-slate-900"
                    >
                      REST API Password
                      <span className=" ml-4 inline-block align-top ">
                        <TooltipIcon description={passwordDescription} />
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        maxLength={100}
                        disabled={projectDisabled}
                        name="remotephonebookpassword"
                        aria-describedby="remote-phone-book-password-input"
                        value={
                          projectData?.settings?.provisioning?.remotephonebookpassword
                            ? projectData?.settings.provisioning
                                .remotephonebookpassword
                            : ""
                        }
                        onChange={handleOnChange}
                        id="first-name"
                        autoComplete="remote-phonebook-password"
                        className={classNames(
                          `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="button"
                onClick={(e) => projectData ? navigate(`/provisioning/${projectData.accountName}`) : ""}
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
              >
                Back
              </button>
              <button
                type="button"
                disabled={projectDisabled}
                onClick={handleSave}
                className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`,
                `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:opacity-25`)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default PhoneBook;
