import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon,QrCodeIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import {
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function Sidebar({ project, type }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [activePage, setActivePage] = useState("details");
  const { accountName } = useParams();
  
  const { isAdmin } = useSelector((state) => state.checkAdminReducer);

  const projectsSelected = location.pathname === "/projects" ? true : false;
  const dashboardSelected = location.pathname === "/" ? true : false;
  const adminSelected = location.pathname === "/admin-projects" ? true : false;
  const qrcodesSelected = location.pathname === "/qrcodes" ? true : false;

  const provisioningSelected =
    location.pathname === "/provisioning" ? true : false;
  const mobileSelected = location.pathname === "/mobile-graphics" ? true : false;
  const desktopSelected =
    location.pathname === "/desktop-graphics" ? true : false;
  const appSelected = location.pathname === "/app-store" ? true : false;
  const whitelabelWSelected =
    location.pathname === "/whitelabel-win-graphics" ? true : false;
    const whitelabelMSelected =
    location.pathname === "/whitelabel-mac-graphics" ? true : false;
    const whitelabelMobileSelected =
    location.pathname === "/whitelabel-mobile-graphics" ? true : false;
  
    const whitelabelISelected =
    location.pathname === "/whitelabel-ios-graphics" ? true : false;
    const whitelabelASelected =
    location.pathname === "/whitelabel-android-graphics" ? true : false;
  const miscellaneousSelected =
    location.pathname === "/miscellaneous" ? true : false;

    let navigation = [
      {
        name: "Dashboard",
        href: "/",
        icon: HomeIcon,
        current: dashboardSelected,
      },
      {
        name: "Projects",
        href: "/projects",
        icon: FolderIcon,
        current: projectsSelected,
      },
      {
        name: "QR Codes",
        href: "/qrcodes",
        icon: QrCodeIcon,
        current: projectsSelected,
      },
     
    ];

  
   // const [navArray, setNavArray] = useState(() => navigation);
  let projectPages = [
    {
      id: 1,
      name: "Provisioning",
      href: `/provisioning/${accountName}`,
      initial: "P",
      current: provisioningSelected,
    },

  ];

  useEffect(() => {
    setActivePage(() => location.pathname);

  }, [location, projectPages, isAdmin, navigation]);

  if(isAdmin){
    //if (navArray.length == 2 && !navArray.some((item) => item.name === "Admin")){

    if (navigation.length == 3 && !navigation.some((item) => item.name === "Admin")){
      navigation.push({
          name: "Admin",
          href: "/admin-projects",
          icon: UsersIcon,
          current: adminSelected,
        })


    // setNavArray(prevNavArray => [...prevNavArray, {
    //   name: "Admin",
    //   href: "/admin-projects",
    //   icon: UsersIcon,
    //   current: adminSelected,
    // }]);
  }}


  if (type != undefined && type != "") {
    if (
      type === "sessioncloud_vvs" ||
      type === "sessioncloud_business" ||
      type === "sessioncloud_secure" ||
      type === "sessioncloud_all" ||
      type === "generic-platforms"
    ) {
      projectPages.push({
        id: 2,
        name: "Mobile Branding",
        href: `/mobile-graphics/${accountName}`,
        initial: "G",
        current: mobileSelected,
      });

      projectPages.push({
        id: 3,
        name: "Desktop Branding",
        href: `/desktop-graphics/${accountName}`,
        initial: "D",
        current: desktopSelected,
      });
    } else if (
      type === "whitelabel_advanced" ||
      type === "whitelabel_premium" ||
      type === "whitelabel_mobile" ||
      type === "whitelabel-mobile-annual" ||
      type === "whitelabel_advanced"
    ) {

      projectPages.push({
        id: 4,
        name: "App Store",
        href: `/app-store/${accountName}`,
        initial: "A",
        current: appSelected,
      },)

      projectPages.push({
        id: 5,
        name: "Mobile Branding",
        href: `/whitelabel-mobile-graphics/${accountName}`,
        initial: "I",
        current: whitelabelMobileSelected,
      });

      if(project?.settings?.provisioning?.usewindows === "Enabled")
      {
        projectPages.push({
          id: 6,
          name: "Windows Branding",
          href: `/whitelabel-win-graphics/${accountName}`,
          initial: "W",
          current: whitelabelWSelected,
        });
      }
      

      
    }else if(   type === "whitelabel-windows" ||
    type === "whitelabel-windows-annual" )
    {

      projectPages.push({
        id: 4,
        name: "App Store",
        href: `/app-store/${accountName}`,
        initial: "A",
        current: appSelected,
      },)
      
      projectPages.push({
        id: 5,
        name: "Windows Branding",
        href: `/whitelabel-win-graphics/${accountName}`,
        initial: "W",
        current: whitelabelWSelected,
      });

     
    }

  }else if( 
    type === "whitelabel_desktop" 
     )
    {

      projectPages.push({
        id: 4,
        name: "App Store",
        href: `/app-store/${accountName}`,
        initial: "A",
        current: appSelected,
      },)
      
      projectPages.push({
        id: 5,
        name: "Windows Branding",
        href: `/whitelabel-win-graphics/${accountName}`,
        initial: "W",
        current: whitelabelWSelected,
      });

      projectPages.push({
        id: 8,
        name: "MacOS Branding",
        href: `/whitelabel-mac-graphics/${accountName}`,
        initial: "M",
        current: whitelabelMSelected,
      });
    }


  projectPages.push({
    id: 7,
    name: "Miscellaneous",
    href: `/miscellaneous/${accountName}/details`,
    initial: "M",
    current: miscellaneousSelected,
  });

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-slate-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="logo_purple.svg"
                        alt="SessionTalk"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-slate-50 text-iceberg-400"
                                      : "text-slate-700 hover:text-iceberg-400 hover:bg-slate-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-iceberg-400"
                                        : "text-slate-400 group-hover:text-iceberg-400",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-white px-6 shadow">
            {/* border-r border-slate-200 */}
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-10 w-auto"
                src="/logo_purple.svg"
                alt="SessionTalk"
              />
            </div>
            <nav className="flex flex-1 flex-col mt-2">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {!item.children ? (
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-slate-50 text-iceberg-400"
                                : "text-slate-700 hover:text-iceberg-400 hover:bg-slate-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-iceberg-400"
                                  : "text-slate-400 group-hover:text-iceberg-400",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ) : (
                          <Disclosure as="div">
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "bg-slate-50"
                                      : "hover:bg-slate-50",
                                    "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-700"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-slate-400"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? "rotate-90 text-slate-500"
                                        : "text-slate-400",
                                      "ml-auto h-5 w-5 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      {/* 44px */}
                                      <Disclosure.Button
                                        as="a"
                                        href={subItem.href}
                                        className={classNames(
                                          subItem.current
                                            ? "bg-slate-50"
                                            : "hover:bg-slate-50",
                                          "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-slate-700"
                                        )}
                                      >
                                        {subItem.name}
                                      </Disclosure.Button>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>

                {accountName ? (
                  <li>
                    <div className="text-xs font-semibold leading-6 text-slate-400">
                      {accountName ? accountName : ""}
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {projectPages.map((page) => (
                        <li key={page.name}>
                          <a
                            href={page.href}
                            className={classNames(
                              page.current
                                ? "bg-slate-50 text-iceberg-400"
                                : "text-slate-700 hover:text-iceberg-400 hover:bg-slate-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <span
                              className={classNames(
                                page.current
                                  ? "text-iceberg-400 border-iceberg-400"
                                  : "text-slate-400 border-slate-200 group-hover:border-iceberg-400 group-hover:text-iceberg-400",
                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                              )}
                            >
                              {page.initial}
                            </span>
                            <span className="truncate">{page.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-slate-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-slate-900">
            Dashboard
          </div>
        </div>
      </div>
    </>
  );
}
