import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICE_PDF_REQUEST,
  GET_INVOICE_PDF_SUCCESS,
  GET_INVOICE_PDF_FAIL,
  DEACTIVATE_APP_REQUEST,
  DEACTIVATE_APP_SUCCESS,
  DEACTIVATE_APP_FAIL,
  REACTIVATE_APP_REQUEST,
  REACTIVATE_APP_SUCCESS,
  REACTIVATE_APP_FAIL,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  GET_CUSTOMER_DETAILS_FAIL,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  CHECKOUT_EXISTING_FAIL,
  CHECKOUT_EXISTING_REQUEST,
  CHECKOUT_EXISTING_SUCCESS,
  CHECKOUT_NEW_FAIL,
  CHECKOUT_NEW_REQUEST,
  CHECKOUT_NEW_SUCCESS,
  CREATE_ESTIMATE_REQUEST,
  CREATE_ESTIMATE_FAIL,
  CREATE_ESTIMATE_SUCCESS,
  UPDATE_ESTIMATE_FAIL,
  UPDATE_ESTIMATE_REQUEST,
  UPDATE_ESTIMATE_SUCCESS,
  RETRIEVE_PAGE_FAIL,
  RETRIEVE_PAGE_REQUEST,
  RETRIEVE_PAGE_SUCCESS,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  RETRIEVE_INVOICE_FAIL,
  RETRIEVE_INVOICE_REQUEST,
  RETRIEVE_INVOICE_SUCCESS,
  DEACTIVATE_SESSIONCLOUD_REQUEST,
  DEACTIVATE_WHITELABEL_REQUEST,
  REACTIVATE_SESSIONCLOUD_REQUEST,
  REACTIVATE_WHITELABEL_REQUEST,
  DEACTIVATE_SESSIONCLOUD_SUCCESS,
  DEACTIVATE_WHITELABEL_SUCCESS,
  REACTIVATE_SESSIONCLOUD_SUCCESS,
  REACTIVATE_WHITELABEL_SUCCESS,
  REACTIVATE_SESSIONCLOUD_FAIL,
  REACTIVATE_WHITELABEL_FAIL,
  DEACTIVATE_SESSIONCLOUD_FAIL,
  DEACTIVATE_WHITELABEL_FAIL,
  CHECK_ADMIN_USER_FAIL, CHECK_ADMIN_USER_REQUEST, CHECK_ADMIN_USER_SUCCESS,
  SEND_EMAIL_REQUEST,SEND_EMAIL_SUCCESS,SEND_EMAIL_FAIL
} from "../constants/userConstants";

import { CLEAR_ERRORS } from "../constants/errorConstants";

export const getUserReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    user: null,
    update: false,
    updateError:false
  },
  action
) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        loading: true,
      };

    case UPDATE_USER_REQUEST:
      return {
        loading: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        update: true,
      };

    case GET_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        success: true,
      };

    case GET_USER_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    case UPDATE_USER_FAIL:
      return {
        success: false,
        updateError: action.payload,
      };

    default:
      return state;
  }
};

export const getInvoicesReducer = (
  state = { success: false, loading: false, error: false, invoices: null },
  action
) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST:
      return {
        loading: true,
      };

    case GET_INVOICES_SUCCESS:
      return {
        loading: false,
        invoices: action.payload,
        success: true,
      };

    case GET_INVOICES_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getInvoicePDFReducer = (
  state = { success: false, loading: false, error: false, pdf: null },
  action
) => {
  switch (action.type) {
    case GET_INVOICE_PDF_REQUEST:
      return {
        loading: true,
      };

    case GET_INVOICE_PDF_SUCCESS:
      return {
        loading: false,
        pdf: action.payload,
        success: true,
      };

    case GET_INVOICE_PDF_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getSubscriptionsReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    message: null,
    subscriptions: null,
  },
  action
) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        loading: true,
      };

    case REACTIVATE_APP_REQUEST:
      return {
        loading: true,
      };

    case REACTIVATE_SESSIONCLOUD_REQUEST:
      return {
        loading: true,
      };

    case REACTIVATE_WHITELABEL_REQUEST:
      return {
        loading: true,
      };

    case DEACTIVATE_APP_REQUEST:
      return {
        loading: true,
      };

    case DEACTIVATE_SESSIONCLOUD_REQUEST:
      return {
        loading: true,
      };

    case DEACTIVATE_WHITELABEL_REQUEST:
      return {
        loading: true,
      };

    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        success: true,
        loading: false,
        subscriptions: action.payload,
      };

    case REACTIVATE_APP_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription reactivated.",
      };

    case REACTIVATE_SESSIONCLOUD_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription reactivated.",
      };

    case REACTIVATE_WHITELABEL_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription reactivated.",
      };

    case DEACTIVATE_APP_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription deactivated.",
      };

    case DEACTIVATE_SESSIONCLOUD_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription deactivated.",
      };

    case DEACTIVATE_WHITELABEL_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Subscription deactivated.",
      };

    case GET_SUBSCRIPTIONS_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    case REACTIVATE_APP_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Reactivate Subscription failed. Please try again.",
      };

    case REACTIVATE_SESSIONCLOUD_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Reactivate Subscription failed. Please try again.",
      };

    case REACTIVATE_WHITELABEL_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Reactivate Subscription failed. Please try again.",
      };

    case DEACTIVATE_APP_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Deactivate Subscription failed. Please try again.",
      };

    case DEACTIVATE_SESSIONCLOUD_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Deactivate Subscription failed. Please try again.",
      };

    case DEACTIVATE_WHITELABEL_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Deactivate Subscription failed. Please try again.",
      };

    default:
      return state;
  }
};

export const getCustomerDetailsReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    customer: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_DETAILS_REQUEST:
      return {
        loading: true,
      };

    case GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        success: true,
        loading: false,
        customer: action.payload,
      };

    case GET_CUSTOMER_DETAILS_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getSubscriptionReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    subscription: null,
  },
  action
) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
      };

    case GET_SUBSCRIPTION_SUCCESS:
      return {
        success: true,
        loading: false,
        subscription: action.payload,
      };

    case GET_SUBSCRIPTION_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getEstimateReducer = (
  state = { success: false, loading: false, error: false, estimate: null },
  action
) => {
  switch (action.type) {
    case CREATE_ESTIMATE_REQUEST:
      return {
        loading: true,
      };

    case UPDATE_ESTIMATE_REQUEST:
      return {
        loading: true,
      };

    case CREATE_ESTIMATE_SUCCESS:
      return {
        loading: false,
        estimate: action.payload,
        success: true,
      };

    case UPDATE_ESTIMATE_SUCCESS:
      return {
        loading: false,
        estimate: action.payload,
        success: true,
      };

    case CREATE_ESTIMATE_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    case UPDATE_ESTIMATE_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getCheckoutHostedPageReducer = (
  state = { success: false, loading: false, error: false, page: null },
  action
) => {
  switch (action.type) {
    case CHECKOUT_EXISTING_REQUEST:
      return {
        loading: true,
      };

    case RETRIEVE_PAGE_REQUEST:
      return {
        loading: true,
      };

    case CHECKOUT_NEW_REQUEST:
      return {
        loading: true,
      };

    case UPDATE_PAYMENT_REQUEST:
      return {
        loading: true,
      };

    case CHECKOUT_EXISTING_SUCCESS:
      return {
        loading: false,
        page: action.payload,
        success: true,
      };

    case RETRIEVE_PAGE_SUCCESS:
      return {
        loading: false,
        page: action.payload,
        success: true,
      };

    case CHECKOUT_NEW_SUCCESS:
      return {
        loading: false,
        page: action.payload,
        success: true,
      };

    case UPDATE_PAYMENT_SUCCESS:
      return {
        loading: false,
        page: action.payload,
        success: true,
      };

    case CHECKOUT_EXISTING_FAIL:
      return {
        success: false,
        page: null,
        error: action.payload,
      };

    case RETRIEVE_PAGE_FAIL:
      return {
        success: false,
        page: null,
        error: action.payload,
      };

    case CHECKOUT_NEW_FAIL:
      return {
        success: false,
        page: null,
        error: action.payload,
      };

    case UPDATE_PAYMENT_FAIL:
      return {
        success: false,
        page: null,
        error: action.payload,
      };

      case CLEAR_ERRORS:
        return {
          success: false,
          page: null,
          error: false,
        };  

    default:
      return state;
  }
};

export const retrieveInvoiceReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    invoice: null,
  },
  action
) => {
  switch (action.type) {
    case RETRIEVE_INVOICE_REQUEST:
      return {
        loading: true,
      };

    case RETRIEVE_INVOICE_SUCCESS:
      return {
        success: true,
        loading: false,
        invoice: action.payload,
      };

    case RETRIEVE_INVOICE_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const checkAdminReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    isAdmin: null,
  },
  action
) => {
  switch (action.type) {
    case CHECK_ADMIN_USER_REQUEST:
      return {
        loading: true,
      };

    case CHECK_ADMIN_USER_SUCCESS:
      return {
        success: true,
        loading: false,
        isAdmin: action.payload,
      };

    case CHECK_ADMIN_USER_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const sendQRCodeEmailReducer = (
  state = {
    success: false,
    loading: false,
    error: false,
    
  },
  action
) => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return {
        loading: true,
      };

    case SEND_EMAIL_SUCCESS:
      return {
        success: true,
        loading: false,
        
      };

    case SEND_EMAIL_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
