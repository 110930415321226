import React from "react";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ResetPasswordForm() {
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 w-auto"
            src="logo_purple.svg"
            alt="Sessiontalk"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Set new password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6 " action="#" method="POST">
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  minLength={8}
                  maxLength={32}
                  noValidate
                  className={classNames(
                    `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500 text-left" id="email-description">
                Must be at least 8 characters
              </p>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  minLength={8}
                  maxLength={32}
                  noValidate
                  className={classNames(
                    `block w-full  py-1 bg-white border border-gray-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-gray-400
                    focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                    invalid:border-red-500 invalid:text-red-600
                    disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                    focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-iceberg-400 px-3 py-1.5 text-sm font-semibold 
                leading-6 text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 
                focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
              >
                Reset Password
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Back to{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-iceberg-400 hover:text-iceberg-300"
            >
              Log In
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
