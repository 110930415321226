import React, { useEffect, useCallback } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { closeProjectNameModal } from "../../redux/actions/modalActions";
import { checkProjectName } from "../../redux/actions/projectActions";
import { createProject } from "../../redux/actions/projectActions";
import { openErrorAlert } from "../../redux/actions/alertActions";  
import { useNavigate } from "react-router-dom"; 


function ProjectNameModal({ modalOpen, plan }) {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState("");
  
  const cancelButtonRef = useRef(null);
  const { nameExists, loading, error } = useSelector((state) => state.checkProject);

  useEffect(() => {
      console.log("addEventListener ");
      document.addEventListener("keydown", escFunction);

    return () => {
      console.log("removeEventListener");
      document.removeEventListener("keydown", escFunction);
    };
  }, []);

  useEffect(() => {

    if(error){
      dispatch(openErrorAlert("Error", error.message));
      navigate('/login');  
    }

    if (nameExists == false && projectName != "") 
    {
      dispatch(createProject(projectName, plan));
      handleCloseModal();
    }
  },[nameExists, error]);

  const handleCloseModal = () => {
    setProjectName("");
    dispatch(closeProjectNameModal());
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      console.log("esc pressed");
      handleCloseModal();
    }
  }, []);

  const handleCheck = (name) => {
    dispatch(checkProjectName(name));
    
  };

  function isValidText(text) {
    const regex = /^[A-Za-z0-9]*$/;
    return regex.test(text);
  }

  return (
    <>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => handleCloseModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-slate-900"
                      >
                        {plan === "generic_softphone"
                          ? "Generic"
                          : plan === "whitelabel_mobile"
                          ? "Whitelabel Mobile"
                          : "Whitelabel Desktop"}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-slate-500">
                          Please choose a name for your application. This will
                          be used to identify your app in the portal.
                          {plan === "generic-platforms"
                            ? "The operator code is what your customers will use to provision the app on first install."
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      App Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="appName"
                        id="appName"
                        minLength="6"
                        maxLength="12"
                        pattern="^[A-Za-z0-9]*$"
                        autoComplete="app-name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className={`block w-full py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                        invalid:border-red-500 invalid:text-red-600
                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        focus:invalid:border-red-500 focus:invalid:ring-red-500`}
                        placeholder="AppName"
                      />
                      <p className="mt-2 text-slate-500 text-sm">
                        App Name must have between six and twelve characters and
                        must not include whitespace or punctuation.
                      </p>
                      {nameExists ? (
                        <p className="mt-2 text-red-600 text-sm">
                          App name already exists, please choose alternative.
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400 sm:col-start-2"
                      onClick={(e) => { if (isValidText(projectName)) handleCheck(projectName); }}
                    >
                      {loading ? (
                        <>
                          <svg
                            className="animate-spin h-5 w-5 mr-3  text-white"
                            viewBox="0 0 24 24"
                          ></svg>
                        </>
                      ) : (
                        "Create"
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={handleCloseModal}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ProjectNameModal;
