import React from "react";
import { Fragment, useState , useEffect} from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon,ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");  
}

const languages = [
  { id: 1, name: "Arabic" },
  { id: 2, name: "BrazilianPortuguese" },
  { id: 3, name: "ChineseSimplified" },
  { id: 4, name: "ChineseTraditional" },
  { id: 5, name: "English" },
  { id: 6, name: "French" },
  { id: 7, name: "Spanish" },
  { id: 8, name: "German" },
  { id: 9, name: "Italian" },
  { id: 10, name: "Malay" },
  { id: 11, name: "Polish" },
  { id: 12, name: "Russian" },
  { id: 13, name: "Japanese" },
  { id: 14, name: "Turkish" },
  
];

const Language = ({onHandleLanguage,language}) => {
  
  const defaultLanguage = languages[4];
  //find the language object from the array of languages

  let currentLanguage = languages.find((lang) => lang.name === language);
  if (!currentLanguage) {
    currentLanguage = languages[4];
  }
  ////we only want to update the language when the props language is not null
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    onHandleLanguage(language.name);
  }

  useEffect(() => {
    if (language !== null && language !== undefined) {
      const currentLanguage = languages.find((lang) => lang.name === language);
      if (currentLanguage) {
        setSelectedLanguage(currentLanguage);
      }
    }
  }, [language]);

  return (
    <Listbox value={selectedLanguage} onChange={handleLanguageChange}>
      {({ open }) => (
        <>
          <h2 className="text-sm font-semibold leading-7 text-gray-900">
            Select Language
          </h2>
          <div className="relative mt-1 w-64">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-iceberg-200 focus:outline-none focus:ring-1 focus:ring-iceberg-200 sm:text-sm">
              <span className="block truncate">{selectedLanguage.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages.map((language) => (
                  <Listbox.Option
                    key={language.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-iceberg-400 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={language}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {language.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-iceberg-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Language;