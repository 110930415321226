import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICE_PDF_REQUEST,
  GET_INVOICE_PDF_SUCCESS,
  GET_INVOICE_PDF_FAIL,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  DEACTIVATE_APP_REQUEST,
  DEACTIVATE_APP_SUCCESS,
  DEACTIVATE_APP_FAIL,
  REACTIVATE_APP_REQUEST,
  REACTIVATE_APP_SUCCESS,
  REACTIVATE_APP_FAIL,
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  GET_CUSTOMER_DETAILS_FAIL,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAIL,
  CHECKOUT_EXISTING_FAIL,
  CHECKOUT_EXISTING_REQUEST,
  CHECKOUT_EXISTING_SUCCESS,
  CHECKOUT_NEW_FAIL,
  CHECKOUT_NEW_REQUEST,
  CHECKOUT_NEW_SUCCESS,
  CREATE_ESTIMATE_REQUEST,
  CREATE_ESTIMATE_FAIL,
  CREATE_ESTIMATE_SUCCESS,
  UPDATE_ESTIMATE_FAIL,
  UPDATE_ESTIMATE_REQUEST,
  UPDATE_ESTIMATE_SUCCESS,
  RETRIEVE_PAGE_FAIL,
  RETRIEVE_PAGE_REQUEST,
  RETRIEVE_PAGE_SUCCESS,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
  RETRIEVE_INVOICE_FAIL,
  RETRIEVE_INVOICE_REQUEST,
  RETRIEVE_INVOICE_SUCCESS,
  DEACTIVATE_SESSIONCLOUD_FAIL,
  DEACTIVATE_SESSIONCLOUD_REQUEST,
  DEACTIVATE_SESSIONCLOUD_SUCCESS,
  DEACTIVATE_WHITELABEL_FAIL,
  DEACTIVATE_WHITELABEL_REQUEST,
  DEACTIVATE_WHITELABEL_SUCCESS,
  REACTIVATE_SESSIONCLOUD_FAIL,
  REACTIVATE_SESSIONCLOUD_REQUEST,
  REACTIVATE_SESSIONCLOUD_SUCCESS,
  REACTIVATE_WHITELABEL_REQUEST,
  REACTIVATE_WHITELABEL_SUCCESS,
  REACTIVATE_WHITELABEL_FAIL,
  CHECK_ADMIN_USER_FAIL, CHECK_ADMIN_USER_REQUEST, CHECK_ADMIN_USER_SUCCESS,
  SEND_EMAIL_REQUEST,SEND_EMAIL_SUCCESS,SEND_EMAIL_FAIL
} from "../constants/userConstants";

import Parse from "parse/dist/parse.min.js";

export const extractUser = (result) => {
  const user = {};
  user.firstname = result.get("firstname");
  user.lastname = result.get("lastname");
  user.email = result.get("email");
  user.phone = result.get("phone");
  user.country = result.get("country");
  user.website = result.get("website");

  return user;
}

export const getUser = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });

    var result = Parse.User.current();
    if (!result) {
      dispatch({
        type: GET_USER_FAIL,
        payload: "User not found",
      });
    }

    const user = extractUser(result);

    dispatch({
      type: GET_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAIL,
      payload: error.message,
    });
  }
};

export const updateUser = (updatedUser) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });

    const user = Parse.User.current();
    const email = user.get("email");
    if (email != updatedUser.email) {
      user.setEmail(updatedUser.email);
    }
    user.setUsername(updatedUser.email);
    //  user.setPassword($scope.user.password);
    user.set("phone", updatedUser.phone);
    user.set("website", updatedUser.website);
    user.set("country", updatedUser.country);
    user.set("firstname", updatedUser.firstname);
    user.set("lastname", updatedUser.lastname);
    //user.set("emailVerified", true);
    const result = await user.save();

    const newUser = extractUser(result);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: newUser,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.message,
    });
  }
};

export const getInvoices = (name) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INVOICES_REQUEST,
    });

    const result = await Parse.Cloud.run("listInvoices", { appName: name });

    dispatch({
      type: GET_INVOICES_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: GET_INVOICES_FAIL,
      payload: error.message,
    });
  }
};

export const getInvoicePDF = (invoiceId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INVOICE_PDF_REQUEST,
    });

    const result = await Parse.Cloud.run("getInvoicePDF", {
      invoiceId: invoiceId,
    });

    dispatch({
      type: GET_INVOICE_PDF_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: GET_INVOICE_PDF_FAIL,
      payload: error.message,
    });
  }
};

export const renewSubscription = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: REACTIVATE_APP_REQUEST,
    });

    const result = await Parse.Cloud.run("reactivateSubscription", {
      appName: projectName,
    });

    dispatch({
      type: REACTIVATE_APP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REACTIVATE_APP_FAIL,
      payload: error.message,
    });
  }
};

export const renewSessionCloud = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: REACTIVATE_SESSIONCLOUD_REQUEST,
    });

    const result = await Parse.Cloud.run("reactivateSessionCloud", {
      appName: projectName,
    });

    dispatch({
      type: REACTIVATE_SESSIONCLOUD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REACTIVATE_SESSIONCLOUD_FAIL,
      payload: error.message,
    });
  }
};

export const renewWhitelabel = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: REACTIVATE_WHITELABEL_REQUEST,
    });

    const result = await Parse.Cloud.run("reactivateWhitelabel", {
      appName: projectName,
    });

    dispatch({
      type: REACTIVATE_WHITELABEL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REACTIVATE_WHITELABEL_FAIL,
      payload: error.message,
    });
  }
};

export const deactivateSessionCloud = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_SESSIONCLOUD_REQUEST,
    });

    const result = await Parse.Cloud.run("cancelSessionCloud", {
      appName: projectName,
    });

    dispatch({
      type: DEACTIVATE_SESSIONCLOUD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEACTIVATE_SESSIONCLOUD_FAIL,
      payload: error.message,
    });
  }
};

export const deactivateWhitelabel = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_WHITELABEL_REQUEST,
    });

    const result = await Parse.Cloud.run("cancelWhitelabel", {
      appName: projectName,
    });

    dispatch({
      type: DEACTIVATE_WHITELABEL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEACTIVATE_WHITELABEL_FAIL,
      payload: error.message,
    });
  }
};

export const deactivateSubscription = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_APP_REQUEST,
    });

    const result = await Parse.Cloud.run("cancelSessionCloud", {
      appName: projectName,
    });

    dispatch({
      type: DEACTIVATE_APP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEACTIVATE_APP_FAIL,
      payload: error.message,
    });
  }
};

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBSCRIPTIONS_REQUEST,
    });

    const results = await Parse.Cloud.run("listSubscriptions");

    dispatch({
      type: GET_SUBSCRIPTIONS_SUCCESS,
      payload: results.list,
    });
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTIONS_FAIL,
      payload: error.message,
    });
  }
};

export const getCustomerDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_DETAILS_REQUEST,
    });

    //var user = Parse.User.current();
    const results = await Parse.Cloud.run("retrieveCustomer");

    dispatch({
      type: GET_CUSTOMER_DETAILS_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const getSubscription = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBSCRIPTION_REQUEST,
    });

    const results = await Parse.Cloud.run("retrieveSubscription", {
      appName: projectName,
    });

    dispatch({
      type: GET_SUBSCRIPTION_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_FAIL,
      payload: error.message,
    });
  }
};

export const createEstimate =
  (customer, projectName, planid, addonarray, couponid) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_ESTIMATE_REQUEST,
      });

      const result = await Parse.Cloud.run("createSubscriptionEstimate", {
        customer: customer,
        appName: projectName,
        planid: planid,
        addonarray: addonarray,
        couponid: couponid,
      });

      dispatch({
        type: CREATE_ESTIMATE_SUCCESS,
        payload: result.estimate,
      });
    } catch (error) {
      dispatch({
        type: CREATE_ESTIMATE_FAIL,
        payload: error.message,
      });
    }
  };

export const updateEstimate =
  (customer, projectName, planid, addonarray, couponid) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ESTIMATE_REQUEST,
      });

      const result = await Parse.Cloud.run("updateSubscriptionEstimate", {
        customer: customer,
        appName: projectName,
        planid: planid,
        addonarray: addonarray,
        couponid: couponid,
      });

      dispatch({
        type: UPDATE_ESTIMATE_SUCCESS,
        payload: result.estimate,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ESTIMATE_FAIL,
        payload: error.message,
      });
    }
  };

export const checkoutExisting =
  (projectName, planid, addonarray, couponid) => async (dispatch) => {
    try {
      dispatch({
        type: CHECKOUT_EXISTING_REQUEST,
      });

      const results = await Parse.Cloud.run("checkoutExistingSubscription", {
        appName: projectName,
        planid: planid,
        addonarray: addonarray,
        couponid: couponid,
      });

      dispatch({
        type: CHECKOUT_EXISTING_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: CHECKOUT_EXISTING_FAIL,
        payload: error.message,
      });
    }
  };

export const retrievePage = (hostedid, projectName) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_PAGE_REQUEST,
    });

    const results = await Parse.Cloud.run("retrieveHostedPage", {
      hostedid: hostedid,
      appName: projectName,
    });

    dispatch({
      type: RETRIEVE_PAGE_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: RETRIEVE_PAGE_FAIL,
      payload: error.message,
    });
  }
};

export const checkoutNew =
  (projectName, planid, addonarray, couponid) => async (dispatch) => {
    try {
      dispatch({
        type: CHECKOUT_NEW_REQUEST,
      });

      const results = await Parse.Cloud.run("checkoutNewSubscription", {
        appName: projectName,
        planid: planid,
        addonarray: addonarray,
        couponid: couponid,
      });

      dispatch({
        type: CHECKOUT_NEW_SUCCESS,
        payload: results,
      });
    } catch (error) {
      dispatch({
        type: CHECKOUT_NEW_FAIL,
        payload: error.message,
      });
    }
  };

export const updatePayment = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PAYMENT_REQUEST,
    });

    const results = await Parse.Cloud.run("updatePayment");

    dispatch({
      type: UPDATE_PAYMENT_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PAYMENT_FAIL,
      payload: error.message,
    });
  }
};

export const retrieveInvoice = (invoiceId) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_INVOICE_REQUEST,
    });

    const results = await Parse.Cloud.run("retrieveInvoice", {
      invoiceId: invoiceId,
    });

    dispatch({
      type: RETRIEVE_INVOICE_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: RETRIEVE_INVOICE_FAIL,
      payload: error,
    });
  }
};

export const checkAdminUser = () => async (dispatch) => {
  try {
    dispatch({
      type: CHECK_ADMIN_USER_REQUEST,
    });

    const results = await Parse.Cloud.run("admin");

    dispatch({
      type: CHECK_ADMIN_USER_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: CHECK_ADMIN_USER_FAIL,
      payload: error.code,
    });
  }
};

export const sendQRCodeEmail = (email, qrcodedata) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_EMAIL_REQUEST,
    });

    const result = await Parse.Cloud.run("SendQRCodeEmail", {  email: email, qrcodedata: qrcodedata });

    dispatch({
      type: SEND_EMAIL_SUCCESS,
      payload: true,
    });
  } catch (error) {
    dispatch({
      type: SEND_EMAIL_FAIL,
      payload: error.code,
    });
  }
};
