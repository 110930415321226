import React from "react";

function Container({ children }) {
  return (
    <>
      <main className="lg:pl-72 ">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 space-y-8">
          {/* Main area */}
          {children}
        </div>
      </main>
    </>
  );
}

export default Container;
