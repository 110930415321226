import React from "react";

function getPlanName(plan) {
  switch (plan) {
    case "sessioncloud_vvs":
      return "Generic VVS";
    case "sessioncloud_business":
      return "Generic Business";
    case "sessioncloud_secure":
      return 'Generic Secure';
    case "sessioncloud_all":
      return "Generic";
    case "generic-platforms":
      return "Generic";
    case "whitelabel_basic":
      return "Whitelabel Basic";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    case "whitelabel-windows":
      return "Whitelabel Desktop";
    case "whitelabel_desktop":
      return "Whitelabel Desktop";
    case "whitelabel-windows-annual":
      return "Whitelabel Desktop";
    case "whitelabel_premium":
      return "Whitelabel Premium";
      case "whitelabel_mobile":
      return "Whitelabel Mobile";
    case "whitelabel-mobile-annual":
      return "Whitelabel Premium";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    default:
      return "Generic";
  }
}

const stages = {
  Prepare: "text-blue-700 bg-blue-100 ",
  Cancelled: "text-red-700 bg-red-100 ",
  Live: "text-green-700 bg-green-100 ",
  Submitted: "text-violet-700 bg-violet-50 ",
  Approved: "text-lime-700 bg-lime-100 ",
  Update: "text-teal-700 bg-teal-100 ",
  Trial: "text-gray-700 bg-gray-100 ",
  Rejected: "text-pink-700 bg-pink-100",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProjectsTable({
  handleShowDetails,
  projects,
  handleEditProject,
  handleNewVersion,
  handleReject,
  handleApprove,

  handleDelete,
}) {
  return (
    <>
      <div className="flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full  align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-slate-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Plan
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Status
                  </th>

                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Details, View, New Version</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-200 bg-white">
                {projects.map((project) => (
                  <tr key={project.id}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div>
                          <div className="font-medium text-slate-900">
                            {project.accountName ? project.accountName : ""}
                          </div>
                          <div className="mt-1 text-slate-500">
                            {project.email ? project.email : ""}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
                      <div className="text-slate-900">
                        {project.type ? getPlanName(project.type) : ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
                      <span
                        className={classNames(
                          stages[
                            project.status.approval.status
                              ? project.status.approval.status
                              : ""
                          ],
                          "inline-flex items-center rounded-md  px-2 py-1 text-xs"
                        )}
                      >
                        {project.status.approval.status
                          ? project.status.approval.status
                          : ""}
                      </span>
                    </td>

                    <td className="text-sm font-medium sm:pr-0 text-right py-4 pl-3 pr-4 whitespace-nowrap relative ">
                      <div className="space-x-8">
                        {project?.status?.approval?.status === "Live" ||
                        project?.status?.approval?.status === "Trial" 
                       
                        ? (
                          <button
                            onClick={(e) => handleShowDetails(e, project)}
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900
                             shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            {" "}
                            Details
                            <span className="sr-only">
                              , {project.accountName ? project.accountName : ""}
                            </span>
                          </button>
                        ) : (
                          <></>
                        )}

                        {project.status.approval.status === "Submitted" ? (
                          <button
                            onClick={(e) => handleApprove(e, project)}
                            className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          >
                            Approve
                          </button>
                        ) : (
                          <></>
                        )}

                        {project.status.approval.status === "Submitted" ? (
                          <button
                            onClick={(e) => handleReject(e, project)}
                            className="rounded-md bg-pink-600 px-2.5 py-1.5 text-sm font-semibold
                             text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                          >
                            Reject
                          </button>
                        ) : (
                          <></>
                        )}

                        {/* {project?.status?.approval?.status === "Update" ||
                        project?.status?.approval?.status === "Prepare" ? ( */}
                          <button
                            onClick={(e) => handleEditProject(e, project)}
                            className="rounded-md bg-iceberg-400 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
                          >
                            Edit
                            <span className="sr-only">
                              , {project.accountName ? project.accountName : ""}
                            </span>
                          </button>
                        {/* ) : (
                          <></>
                        )} */}

                        {project?.status?.overall?.completion === 100 &&
                        (project?.status?.approval?.status === "Live" ||
                          project?.status?.approval?.status === "Trial") ? (
                          <button
                            onClick={(e) => handleNewVersion(e, project)}
                            className="rounded-md bg-slate-600 px-2.5 py-1.5 text-sm font-semibold
                            text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                          >
                            New Version
                            <span className="sr-only">
                              , {project.accountName ? project.accountName : ""}
                            </span>
                          </button>
                        ) : (
                          <></>
                        )}

                        {project?.status?.approval?.status === "Prepare" ||
                        project?.status?.approval?.status === "Approved" ||
                        project?.status?.approval?.status === "Rejected" ? (
                          <button
                            onClick={(e) => handleDelete(e, project)}
                            className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          >
                            Delete
                            <span className="sr-only">
                              , {project.accountName ? project.accountName : ""}
                            </span>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectsTable;
