import React from "react";
import AccountVerificationForm from "../components/auth/AccountVerificationForm";

export default function AccountVerification() {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen py-2">
        <AccountVerificationForm />
      </div>
    </>
  );
}
