import React from "react";
import AccountForm from "./AccountForm";

function AccountDetails({userData, handleOnChange, handleCountryChange, handleSave, handleCancel}) {
  return (
    <>
      <form>
        <div className="space-y-12 max-w-6xl ">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
            Manage and update your personal information.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
              <AccountForm userData={userData} handleOnChange={handleOnChange} handleCountryChange={handleCountryChange}/>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={handleCancel}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-iceberg-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AccountDetails;
