import React from "react";


function getPlanName(plan) {
  switch (plan) {
    case "sessioncloud_vvs":
      return "Generic VVS";
    case "generic_softphone":
      return "Generic";
    case "sessioncloud_business":
      return "Generic Business";
    case "sessioncloud_secure":
      return '"Generic Secure';
    case "sessioncloud_all":
      return "Generic";
    case "generic-platforms":
      return "Generic";
    case "whitelabel_basic":
      return "Whitelabel Basic";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    case "whitelabel-windows":
      return "Whitelabel Desktop";
    case "whitelabel_desktop":
      return "Whitelabel Desktop";
    case "whitelabel-windows-annual":
      return "Whitelabel Desktop";
    case "whitelabel_premium":
      return "Whitelabel Premium";
      case "whitelabel_mobile":
      return "Whitelabel Mobile";
    case "whitelabel-mobile-annual":
      return "Whitelabel Premium";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    default:
      return "Generic";
  }
}

function ProjectDetails({ billing, project }) {
  return (
    <>
      <div className="h-full overflow-y-auto bg-white p-8">
        <div className="space-y-6 pb-16">
          <div>
            <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
              <img
                src={project?.logo}
                alt=""
                className="object-cover mx-auto h-12 w-auto"
              />
            </div>
            <div className="mt-4 flex items-start justify-between">
              <div>
                <h2 className="text-base font-semibold leading-6 text-slate-900">
                  <span className="sr-only">Details for </span>{" "}
                  {project?.accountName ? project.accountName : ""}
                </h2>
                <p className="text-sm font-medium text-slate-500">
                  {" "}
                  {project?.user ? project.user : ""}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-medium text-slate-900">Information</h3>
            <dl className="mt-2 divide-y divide-slate-200 border-b border-t border-slate-200">
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">Plan</dt>
                <dd className="text-slate-900">
                  {" "}
                  {project?.type ? getPlanName(project.type) : ""}
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">Price</dt>
                <dd className="text-slate-900">
                  {billing?.subscription.plan_amount ? `${billing.subscription.plan_amount/100} USD` : `0 USD`}
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">
                 Next Billing
                </dt>
                <dd className="text-slate-900">
                  {billing.subscription.next_billing_at ? new Date(billing.subscription.next_billing_at * 1000).toLocaleDateString() : "" }
                </dd>
              </div>
            
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">Active Users</dt>
                <dd className="text-slate-900">
                  {" "}
                  {project?.numUsers ? project.numUsers : "0"}
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">Num G729</dt>
                <dd className="text-slate-900">
                  {" "}
                  {project?.numG729 ? project.numG729 : "0"}
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-slate-500">Activation Date</dt>
                <dd className="text-slate-900">
                  {" "}
                  {billing?.subscription.started_at
                    ? new Date(
                        billing.subscription.started_at * 1000
                      ).toLocaleDateString()
                    : ""}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetails;
