import React from "react";

function GrayPanel({ title, note, action, buttonText }) {
  return (
    <>
       <div className="bg-slate-50  sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-base font-semibold leading-6 text-slate-950">
              {title}
            </h3>
            <div className="mt-2 max-w-5xl text-sm text-slate-500">
              <p>{note}</p>
            </div>
          </div>
          
          {
            buttonText ?  <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <button
                type="button"
                className="inline-flex items-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                {buttonText}
              </button>
              {/* <button
                type="button"
                className="inline-flex items-center rounded-md bg-iceberg-50 text-iceberg-400 hover:text-iceberg-500 shadow-sm  focus:outline-none focus:ring-2 focus:ring-iceberg-500 focus:ring-offset-2"
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}
            </div>: <>
            </>
          }
         
        </div>
      </div>
    </div>
    </>
  );
}

export default GrayPanel;
