import React, { useState, useEffect, useRef } from "react";
import BadgeTitle from "../components/titles/BadgeTitle";
import AppLayout from "../components/layout/AppLayout";
import Container from "../components/containers/Container";
import BuildsTable from "../components/builds/BuildsTable";
import GrayPanel from "../components/actionPanels/GrayPanel";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import { useParams } from "react-router-dom";
import BuildsEmptyState from "../components/builds/BuildsEmptyState";
import { getProjectByName } from "../redux/actions/projectActions";
import { 

  getBuilds,
  createBuild,
  getCurrentBuilds,
  downloadBuild,
  deleteBuild, 
  deleteBuildClear,
  createBuildClear,
  downloadBuildClear
} from "../redux/actions/buildActions";
import { openErrorAlert, openStatusAlert } from "../redux/actions/alertActions";
import CurrentBuildList from "../components/builds/CurrentBuildList";


const buildNote = `Tap the 'Build' button to start a build. If the button is disabled you will need to either delete the existing build or submit the project for approval. Submitting the project for approval will increase the build number which is required if you have already uploaded to iTunes or Google Play. After we have approved the project the build button will be enabled again. Tap the gear icon for options to Download or Delete the build.

Please note builds are only kept for 30 days after which they are deleted. Please download builds and upload to your iTunes or Google Play Developer accounts for long term storage.`;

export default function Builds( ) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountName, platform } = useParams();

  const [loading, setLoading] = useState(false);
  const isProjectRef = useRef(false);
  const isBuildsRef = useRef(false);

  const [platformEnabled, setPlatformEnabled] = useState({
    mac: true,
    windows: true,
    ios: true,
    android: true,
  });

  const { project, error: projectError } = useSelector((state) => state.getProject);
  const { builds, error: buildsError } = useSelector((state) => state.getBuilds);
  const { builds: newBuilds, error: currentBuildsError } = useSelector(
    (state) => state.getCurrentBuilds
  );
  const { success: createSuccess, error: createError } = useSelector(
    (state) => state.createBuild
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    (state) => state.deleteBuild
  );
  const { artifact, error: downloadError } = useSelector(
    (state) => state.downloadBuild
  );

  const itemsPerPage = 20;

  // Fetch project on mount
  useEffect(() => {
    if (!isProjectRef.current) {
      setLoading(true);
      dispatch(getProjectByName(accountName));
      isProjectRef.current = true;
    }
  }, [project]);

  // Fetch builds when project changes
  useEffect(() => {
    if (project && !isBuildsRef.current) {
      dispatch(getBuilds(0, itemsPerPage, accountName, project.type));
      dispatch(getCurrentBuilds(accountName, project.type));
      isBuildsRef.current = true;
    }
  }, [project, builds]);

  // Handle success and error states
  useEffect(() => {
    console.log( "deleteError :" + deleteError);
    if( projectError === 209 || createError === 209 || deleteError === 209 || downloadError === 209 || currentBuildsError === 209){ 
      dispatch(openErrorAlert("Session Expired","Please login again to continue"));
      // navigate to login  
       navigate('/login');
       return;
    }

    if (projectError || buildsError || currentBuildsError) {
      dispatch(openErrorAlert("Error", "Something went wrong. Please try again"));
    }

    if (createSuccess) {
      dispatch(createBuildClear());
      dispatch(openStatusAlert("Success", "Build created successfully"));
      dispatch(getBuilds(0, itemsPerPage, accountName, project?.type,platform));
      dispatch(getCurrentBuilds(accountName, project?.type,platform));
      isBuildsRef.current = false;
    } else if (createError) {
      dispatch(openErrorAlert("Error", "Failed to create build. Please try again"));
    }

    if (deleteSuccess) {
      dispatch(deleteBuildClear());
      dispatch(openStatusAlert("Success", "Build deleted successfully"));
      dispatch(getBuilds(0, itemsPerPage, accountName, project?.type,platform));
      dispatch(getCurrentBuilds(accountName, project?.type,platform));
      isBuildsRef.current = false;

    } else if (deleteError) {
      dispatch(openErrorAlert("Error", "Failed to delete build. Please try again"));
    }

    if (artifact) {
      dispatch(downloadBuildClear());
      dispatch(openStatusAlert("Success", "Build downloaded successfully"));
      downloadPDF(artifact);
    } else if (downloadError) {
      dispatch(openErrorAlert("Error", "Failed to download build. Please try again"));
    }

    setLoading(false);
  }, [
    projectError,
    buildsError,
    currentBuildsError,
    createSuccess,
    createError,
    deleteSuccess,
    deleteError,
    artifact,
    downloadError,
    dispatch,
  ]);

  // Update platform availability based on current builds
  useEffect(() => {
    const updatedPlatforms = { mac: true, windows: true, ios: true, android: true };
    if(newBuilds === undefined || newBuilds === null) return;
    newBuilds?.forEach((build) => {
      if (build.platform === "ios") updatedPlatforms.ios = false;
      if (build.platform === "mac") updatedPlatforms.mac = false;
      if (build.platform === "win") updatedPlatforms.windows = false;
      if (build.platform === "android") updatedPlatforms.android = false;
    });

    setPlatformEnabled(updatedPlatforms);
  }, [newBuilds]);

  const updateFinalBuild = () => {
    dispatch(getBuilds(0, itemsPerPage, accountName, project?.type,platform));
    dispatch(getCurrentBuilds(accountName, project?.type,platform));
   
  }

  // Handlers
  const handleCreateBuild = (platform) => {
    setLoading(true);
    dispatch(createBuild(accountName, platform));
  };

  const handleDeleteBuild = (buildId) => {
    setLoading(true);
    dispatch(deleteBuild(accountName, buildId));
  };

  const handleDownloadBuild = (buildId) => {
    setLoading(true);
    dispatch(downloadBuild(accountName, buildId));
  };

  const downloadPDF = (document) => {
    window.location.assign(document);
  };

  return (
    <>
      <AppLayout  project={project} type={project?.type || ""} />
      <Container>
        <BadgeTitle title="Builds" projectName={accountName || ""} />
        <GrayPanel note={buildNote} title="How to create a build?" />

        {loading && <div>Loading...</div>}

        {newBuilds?.length > 0 && <CurrentBuildList currentBuilds={newBuilds} 
        deleteBuild={handleDeleteBuild}  updateFinalBuild={updateFinalBuild} />}

        {builds?.length > 0 ? (
          <BuildsTable
            project={project}
            builds={builds}
            type={project?.type}
            handleCreateBuild={handleCreateBuild}
            androidEnabled={platformEnabled.android}
            winEnabled={platformEnabled.windows}
            macEnabled={platformEnabled.mac}
            iosEnabled={platformEnabled.ios}
            downloadBuild={handleDownloadBuild}
            deleteBuild={handleDeleteBuild}
          />
        ) : (
          <BuildsEmptyState
            type={project?.type}
            androidEnabled={platformEnabled.android}
            winEnabled={platformEnabled.windows}
            macEnabled={platformEnabled.mac}
            iosEnabled={platformEnabled.ios}
            handleCreateBuild={handleCreateBuild}
          />
        )}
      </Container>
    </>
  );
}
