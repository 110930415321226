import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function convertText(text) {
  if (text === "Enabled" || text === "on") {
    return true;
  }
  return false;
}

function Miscellaneous({projectData, handleOnChange, projectDisabled}) {
  return (
    <>
     <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {" "}
          Miscellaneous
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
        Enhance your calling experience and fine-tune various aspects of the app to achieve your unique communication goals.
        </p>

        <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="sms"
                  disabled={projectDisabled}
                  onChange={handleOnChange}
                  aria-describedby="sms-settings"
                  checked={convertText(
                    projectData?.settings
                      ? projectData?.settings?.provisioning.sms
                      : "Disabled"
                  )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  SMS
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="uselog"
                  disabled={projectDisabled}
                  onChange={handleOnChange}
                  aria-describedby="use-log-seetings"
                  checked={convertText(
                    projectData?.settings
                      ? projectData?.settings?.provisioning.uselog
                      : "Disabled"
                  )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Show Log Menu
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 ">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="usesubdomains"
                onChange={handleOnChange}
                disabled={projectDisabled}
                aria-describedby="use-subdomains-setting"
                checked={convertText(
                  projectData?.settings
                    ? projectData?.settings?.provisioning.usesubdomains
                    : "Disabled"
                )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Use Subdomains
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 ">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="tlsverify"
                  disabled={projectDisabled}
                  onChange={handleOnChange}
                  aria-describedby="tls-verify-description"
                  checked={convertText(
                    projectData?.settings
                      ? projectData?.settings?.provisioning.tlsverify
                      : "Disabled"
                  )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Verify TLS Cert
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="usenat64"
                  disabled={projectDisabled}
                  onChange={handleOnChange}
                  aria-describedby="use-nat-64-settings"
                  checked={convertText(
                    projectData?.settings?.provisioning?.usenat64
                      ? projectData?.settings?.provisioning.usenat64
                      : "Disabled"
                  )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  NAT64
                </label>{" "}
              </div>
            </div>
          </div>

          <div className="sm:col-span-3 ">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="checkbox"
                  name="busylamp"
                  disabled={projectDisabled}
                  onChange={handleOnChange}
                  aria-describedby="busy-lamp-settings"
                  checked={convertText(
                    projectData?.settings?.provisioning?.busylamp
                      ? projectData?.settings?.provisioning.busylamp
                      : "Disabled"
                  )}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Busy Lamp
                </label>{" "}
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Miscellaneous;
