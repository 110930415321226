import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_WHITELABEL_LIVE_APPS_REQUEST,
  GET_WHITELABEL_LIVE_APPS_SUCCESS,
  GET_WHITELABEL_LIVE_APPS_FAIL,
  GET_SESSIONCLOUD_LIVE_APPS_REQUEST,
  GET_SESSIONCLOUD_LIVE_APPS_SUCCESS,
  GET_SESSIONCLOUD_LIVE_APPS_FAIL,
  GET_PROJECTS_COUNT_REQUEST,
  GET_PROJECTS_COUNT_SUCCESS,
  GET_PROJECTS_COUNT_APPS_FAIL,
  GET_LIVE_APPS_COUNT_REQUEST,
  GET_LIVE_APPS_COUNT_SUCCESS,
  GET_LIVE_APPS_COUNT_APPS_FAIL,
  GET_PROJECT,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  CHECK_PROJECT_REQUEST,
  CHECK_PROJECT_SUCCESS,
  CHECK_PROJECT_FAIL,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_BILLING_REQUEST,
  GET_PROJECT_BILLING_SUCCESS,
  GET_PROJECT_BILLING_FAIL,
  SUBMIT_APPROVAL_REQUEST,
  SUBMIT_APPROVAL_SUCCESS,
  SUBMIT_APPROVAL_FAIL,
  GET_ADMIN_PROJECTS_FAIL,
  GET_ADMIN_PROJECTS_SUCCESS,
  GET_ADMIN_PROJECTS_REQUEST,
  GET_RULE,
  CREATE_NEW_VERSION_REQUEST,
  CREATE_NEW_VERSION_SUCCESS,
  CREATE_NEW_VERSION_FAIL,
  APPROVE_PROJECT_REQUEST,
  APPROVE_PROJECT_SUCCESS,
  APPROVE_PROJECT_FAIL,
  REJECT_PROJECT_REQUEST,
  REJECT_PROJECT_SUCCESS,
  REJECT_PROJECT_FAIL,
  ACTIVATE_TRIAL_FAIL,
  ACTIVATE_TRIAL_REQUEST,
  ACTIVATE_TRIAL_SUCCESS,
  MAKE_LIVE_REQUEST,
  MAKE_LIVE_SUCCESS,
  MAKE_LIVE_FAIL,
  GET_GRAPHICS_FAIL, GET_GRAPHICS_REQUEST, GET_GRAPHICS_SUCCESS, 
  GET_GRAPHICS_CLEAR,
  SAVE_GRAPHICS_REQUEST, SAVE_GRAPHICS_SUCCESS, SAVE_GRAPHICS_FAIL,
  SAVE_FILE_REQUEST, SAVE_FILE_SUCCESS, SAVE_FILE_FAIL
  
} from "../constants/projectConstants";

import { CLOSE_PROJECT_NAME_MODAL } from "../constants/modalConstants";
import { CLEAR_ERRORS } from "../constants/errorConstants";

export const getProjectsReducer = (
  state = { projects: [], success: false, error: undefined, loading: false },
  action
) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST:
     
      return {
        loading: true,
        success: false,
        projects: [],
        error: undefined,
      };

    case GET_PROJECTS_SUCCESS:
     
      return {
        success: true,
        loading: false,
        projects: action.payload,
        error: undefined,
      };

    case GET_PROJECTS_FAIL:
      return {
        loading: false,
        success: false,
        projects: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: undefined,
        success: false,
        projects: [],
      };

    default: {
      return state;
    }
  }
};

export const getWhitelabelLiveAppsReducer = (
  state = { projects: [], success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_WHITELABEL_LIVE_APPS_REQUEST:
     
      return {
        loading: true,
        success: false,
        projects: [],
      };

    case GET_WHITELABEL_LIVE_APPS_SUCCESS:
     
      return {
        success: true,
        loading: false,
        projects: action.payload,
      };

    case GET_WHITELABEL_LIVE_APPS_FAIL:
      return {
        loading: false,
        success: false,
        projects: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default: {
      return state;
    }
  }
};

export const getSessionCloudLiveAppsReducer = (
  state = { projects: [], success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_SESSIONCLOUD_LIVE_APPS_REQUEST:
     
      return {
        loading: true,
        success: false,
        projects: [],
      };

    case GET_SESSIONCLOUD_LIVE_APPS_SUCCESS:
      
      return {
        success: true,
        loading: false,
        projects: action.payload,
      };

    case GET_SESSIONCLOUD_LIVE_APPS_FAIL:
      return {
        loading: false,
        success: false,
        projects: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default: {
      return state;
    }
  }
};

export const getProjectsCountReducer = (
  state = { length: 0, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_PROJECTS_COUNT_REQUEST:
      
      return {
        loading: true,
        success: false,
        length: 0,
      };

    case GET_PROJECTS_COUNT_SUCCESS:
      return {
        success: true,
        loading: false,
        length: action.payload,
      };

    case GET_PROJECTS_COUNT_APPS_FAIL:
      return {
        loading: false,
        success: false,
        length: 0,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default: {
      return state;
    }
  }
};

export const getLiveAppsCountReducer = (
  state = { length: 0, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_LIVE_APPS_COUNT_REQUEST:
     
      return {
        loading: true,
        success: false,
        length: 0,
      };

    case GET_LIVE_APPS_COUNT_SUCCESS:
     
      return {
        success: true,
        loading: false,
        length: action.payload,
      };

    case GET_LIVE_APPS_COUNT_APPS_FAIL:
      return {
        loading: false,
        success: false,
        length: 0,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default: {
      return state;
    }
  }
};

export const updateProjectReducer = (
  state = {
    updateSuccess: false,
    updateError: null,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PROJECT_REQUEST:
     
    return {
      ...state,
      loading: true,
      updateSuccess: false,
    };

    case UPDATE_PROJECT_SUCCESS:
     
    return {
      ...state,
      updateSuccess: true,
      loading: false,
    };

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateError: action.payload,
      };

      case CLEAR_ERRORS:
        return {
          ...state,
          updateSuccess: false,
          updateError: null,
        };

      default:
        return state;
    }
  };

export const getProjectReducer = (
  state = {
    project: null,
    success: false,
    error: null,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case GET_PROJECT:
      
      return {
        ...state,
        project: action.payload,
      };

    case GET_PROJECT_REQUEST:
     
      return {
        ...state,
        loading: true,
        success: false,
        project: null
      };

  
    case GET_PROJECT_SUCCESS:
     
      return {
        ...state,
        success: true,
        loading: false,
        project: action.payload,
      };


    case GET_PROJECT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        project: null,
        error: action.payload,
      };
  

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        project: null,
      };

    default:
      return state;
  }
};

export const getAccountReducer = (
  state = { account: undefined, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case GET_ACCOUNT_REQUEST:
      return {
        loading: true,
        success: false,
        account: {},
      };

    case GET_ACCOUNT_SUCCESS:
      return {
        success: true,
        loading: false,
        account: action.payload,
      };

    case GET_ACCOUNT_FAIL:
      return {
        loading: false,
        success: false,
        projects: [],
        error: action.payload,
        account: action.payload,
      };

    default:
      return state;
  }
};

export const checkProjectReducer = (
  state = { nameExists: null, loading: false , error: null  },
  action
) => {
  switch (action.type) {
    case CHECK_PROJECT_REQUEST:
     
      return {
        nameExists: null,
        loading: true,
        error: null,  
      };

    case CHECK_PROJECT_SUCCESS:
      return {
        nameExists: action.payload,
        loading: false,
        error: null,  
      };

    case CHECK_PROJECT_FAIL:
      return {
        nameExists: null,
        loading: false,
        error: action.payload,
      };

      case CLOSE_PROJECT_NAME_MODAL:
        return {
          nameExists: null,
          loading: false,
          error: null,
        };

    default:
      return state;
  }
};

export const createProjectReducer = (
  state = { app: undefined, success: false, error: null, loading: false },
  action
) => {
  switch (action.type) {
    case CREATE_PROJECT_REQUEST:
      return {
        loading: true,
        success: false,
        app: {},
      };

    case CREATE_PROJECT_SUCCESS:
      return {
        success: true,
        loading: false,
        app: action.payload,
      };

    case CREATE_PROJECT_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        app: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default:
      return state;
  }
};

export const getRuleReducer = (state = { rule: undefined }, action) => {
  switch (action.type) {
    case GET_RULE:
     
      return {
        rule: action.payload,
      };

    default:
      return state;
  }
};

export const getProjectBillingReducer = (
  state = {
    billing: undefined,
    success: false,
    loading: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case GET_PROJECT_BILLING_REQUEST:
      return {
        loading: true,
      };

    case GET_PROJECT_BILLING_SUCCESS:
      return {
        loading: false,
        success: true,
        billing: action.payload,
      };

    case GET_PROJECT_BILLING_FAIL:
      return {
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
      };

    default:
      return state;
  }
};

export const getAdminProjectsReducer = (
  state = { success: false, loading: false, error: null, projects: undefined },
  action
) => {
  switch (action.type) {
    case GET_ADMIN_PROJECTS_REQUEST:
      return {
        loading: true,
      };

    case GET_ADMIN_PROJECTS_SUCCESS:
      return {
        loading: false,
        success: true,
        projects: action.payload,
      };

    case GET_ADMIN_PROJECTS_FAIL:
      return {
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const changeProjectReducer = (
  state = { success: false, loading: false, error: false, message: undefined },
  action
) => {
  switch (action.type) {
    case DELETE_PROJECT_REQUEST:
      return {
        loading: true,
      };

    case CREATE_NEW_VERSION_REQUEST:
      return {
        loading: true,
      };

    case APPROVE_PROJECT_REQUEST:
      return {
        loading: true,
      };

    case REJECT_PROJECT_REQUEST:
      return {
        loading: true,
      };

    case SUBMIT_APPROVAL_REQUEST:
      return {
        loading: true,
      };

    case ACTIVATE_TRIAL_REQUEST:
      return {
        loading: true,
      };

    case MAKE_LIVE_REQUEST:
      return {
        loading: true,
      };

    case REJECT_PROJECT_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Project rejected.",
      };

    case APPROVE_PROJECT_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Project approved.",
      };

    case CREATE_NEW_VERSION_SUCCESS:
      return {
        success: true,
        loading: false,
        message: "Project moved to update mode.",
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        loading: false,
        success: true,
        message: "Project deleted.",
      };

    case SUBMIT_APPROVAL_SUCCESS:
      return {
        loading: false,
        success: true,
        message: "Project submitted.",
      };

    case ACTIVATE_TRIAL_SUCCESS:
      return {
        loading: false,
        success: true,
        message: "Trial activated.",
      };

    case MAKE_LIVE_SUCCESS:
      return {
        loading: false,
        success: true,
        message: "Project live.",
      };

    case REJECT_PROJECT_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Reject project failed.",
      };

    case APPROVE_PROJECT_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Approve project failed.",
      };

    case CREATE_NEW_VERSION_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Create new version failed.",
      };

    case DELETE_PROJECT_FAIL:
      return {
        success: false,
        error: action.payload,
        message: "Delete project failed.",
      };

    case SUBMIT_APPROVAL_FAIL:
      return {
        error: action.payload,
        message: "Submit project failed.",
      };

    case ACTIVATE_TRIAL_FAIL:
      return {
        error: action.payload,
        message: "Activate trial failed.",
      };

    case MAKE_LIVE_FAIL:
      return {
        error: action.payload,
        message: "Move to live failed.",
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        message: undefined,
      };

    default:
      return state;
  }
};

export const getGraphicsReducer = (
  state = { success: false, loading: false, error: null, graphics: null },
  action
) => {
  switch (action.type) {
    case GET_GRAPHICS_REQUEST:
      return {
        ...state, 
        error: null,
        loading: true,
      };

    case GET_GRAPHICS_SUCCESS:
      return {
        ...state, 
        loading: false,
        success: true,
        error: null,
        graphics: action.payload,
        
      };

      case GET_GRAPHICS_CLEAR:
        return {
          ...state, 
          error: null,
          loading: false,
          graphics: null
        };

    case GET_GRAPHICS_FAIL:
      return {
        ...state, 
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const saveGraphicsReducer = (
  state = { saved: false, loading: false, error: null, },
  action
) => {
  switch (action.type) {
    case SAVE_GRAPHICS_REQUEST:
      return {
        ...state, 
        error: null,
        loading: true,
        saved: false,
      };

    case SAVE_GRAPHICS_SUCCESS:
      return {
        ...state, 
        loading: false,
        error: null,
        saved: true,
      };

    case SAVE_GRAPHICS_FAIL:
      return {
        ...state, 
        saved: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const saveFileReducer = (
  state = { saved: false, loading: false, error: null, },
  action
) => {
  switch (action.type) {
    case SAVE_FILE_REQUEST:
      return {
        ...state, 
        error: null,
        loading: true,
        saved: false,
      };

    case SAVE_FILE_SUCCESS:
      return {
        ...state, 
        loading: false,
        error: null,
        saved: true,
      };

    case SAVE_FILE_FAIL:
      return {
        ...state, 
        saved: false,
        error: action.payload,
      };

    default:
      return state;
  }
};