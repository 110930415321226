import React from 'react'
import {
  DocumentIcon
} from "@heroicons/react/24/solid";

import { DocumentPlusIcon } from '@heroicons/react/24/outline'

const buildOutputModes = [
  { id: "apk", title: "APK" },
  { id: "aab", title: "AAB" }
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getChoice(typeId, choiceId) {
  if (choiceId === typeId) {
    return true;
  }
  return false;
}


function Android({ projectData, handleOnChange, projectDisabled, handleOnFileUpload, handleImageDrop }) {

  // Prevent the default behavior of the drop event to allow dropping
  function handleDragOver(event){
    event.preventDefault();
  };

  


  return (
   <>
   <div >
        <h2 className="text-base font-semibold leading-7 text-slate-900">
        Android
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-600">
          We'll always let you know about important changes, but you pick what
          else you want to hear about.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
           Android Version
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="androidVersionName"
                value={
                  projectData?.settings?.appstore?.androidVersionName ? projectData?.settings?.appstore?.androidVersionName : ""
                }
               
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 maxLength={100}
                  autoComplete="android-version-name"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3 pt-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
             Android Build No
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="androidVersionCode"
                value={
                  projectData?.settings?.appstore?.androidVersionCode ? projectData?.settings?.appstore?.androidVersionCode : ""
                }
                maxLength={100}
                  autoComplete="android-version-code"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
              
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full pt-6">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                Android App Identifier
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="last-name"
                  name="appIdentifierAndroid"
                  value={
                    projectData?.settings?.appstore?.appIdentifierAndroid
                      ? projectData?.settings?.appstore?.appIdentifierAndroid
                      : ""
                  }
                  autoComplete="app-identifier"
                  onChange={handleOnChange}
                  noValidate
                  disabled={projectDisabled}
                  aria-describedby="domain-settings"
                  maxLength="32"
                  minLength={6}
                  
                  className={classNames(
                    `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                  )}
                />
              </div>
              <p className="mt-2 text-sm text-slate-500" id="email-description">
                App Identifier must have between six and thirty two characters
              </p>
            </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
            Android Signing Key
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
            name="signingkey"
            disabled={projectDisabled}
             onDrop={(e)=> handleImageDrop(e, "signingkey", "signingkeyName")}
             onDragOver={handleDragOver}>
              
              <div className="text-center">
              <p className="text-lg font-semibold leading-5 text-slate-600 mb-2">
                  {
                    projectData?.signingkeyName
                    ? projectData?.signingkeyName
                    : ""
                  }
                  </p>
              { projectData?.signingkeyName ? (
                    <DocumentIcon
                    className="mx-auto h-12 w-12 text-iceberg-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentPlusIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload4"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload4"
                      name="signingkey"
                      type="file"
                      className="sr-only"
                      onChange={(e) => handleOnFileUpload(e, "signingkey", "signingkeyName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
               
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <label className="text-sm font-semibold leading-6 text-slate-900">
            Android Google Services JSON
            </label>

            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-slate-900/25 px-6 py-6"
            name="googleservices"
            disabled={projectDisabled}
             onDrop={(e)=> handleImageDrop(e, "googleservices", "googleservicesName")}
             onDragOver={handleDragOver}>
              
              <div className="text-center">
              <p className="text-lg font-semibold leading-5 text-slate-600 mb-2">
                  {
                    projectData?.googleservicesName
                    ? projectData?.googleservicesName
                    : ""
                  }
                  </p>
              { projectData?.googleservicesName ? (
                    <DocumentIcon
                    className="mx-auto h-12 w-12 text-iceberg-300"
                    aria-hidden="true"
                  />
                  ) : (
                <DocumentPlusIcon
                  className="mx-auto h-12 w-12 text-slate-300"
                  aria-hidden="true"
                />)}


                <div className="mt-4 flex text-sm leading-6 text-slate-600">
                  <label
                    htmlFor="file-upload9"
                    className={classNames( projectDisabled ? "text-slate-400" : `text-iceberg-400 
                    hover:text-iceberg-300 cursor-pointer`, 
                    `relative  rounded-md bg-white font-semibold `)}
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload9"
                      name="googleservices"
                      type="file"
                      className="sr-only"
                      onChange={(e) => handleOnFileUpload(e, "googleservices", "googleservicesName")}
                      disabled={projectDisabled}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
               
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
            Key Alias
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="keyalias"
                value={
                  projectData?.settings?.appstore?.keyalias ? projectData?.settings?.appstore?.keyalias : ""
                }
                maxLength={100}
                  autoComplete="key-alias"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
           Key Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="signingpassword"
                value={
                  projectData?.settings?.appstore?.signingpassword ? projectData?.settings?.appstore?.signingpassword : ""
                }
                 maxLength={100}
                  autoComplete="key-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
                minLength={1}
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="col-span-full">
          <label className="text-sm font-semibold leading-6 text-slate-900">
            BuildOutput
          </label>
          <p className="text-sm text-slate-500">
            Select between APK format(local device testing)  or AAB format for Google Play Store.
          </p>
          <fieldset className="mt-4">
            <legend className="sr-only">APK or AAB</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {buildOutputModes.map((mode) => (
                <div key={mode.id} className="flex items-center">
                  <input
                    id={`radiobutton-${mode.id}`}
                    name="androidbuildOutput"
                    aria-describedby="output-settings"
                    type="radio"
                    disabled={projectDisabled}
                    value={mode.id}
                    checked={
                      projectData?.settings?.appstore?.androidbuildOutput
                        === mode.id
                    }
                    onChange={handleOnChange}
                    className="h-4 w-4 border-slate-300 text-iceberg-400 focus:ring-iceberg-400 disabled:opacity-50"
                  />
                  <label
                    htmlFor={mode.id}
                    className="ml-3 block text-sm font-medium leading-6 text-slate-900"
                  >
                    {mode.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
                     
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
           Google Play Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="googlePlayUsername"
                value={
                  projectData?.settings?.appstore?.googlePlayUsername ? projectData?.settings?.appstore?.googlePlayUsername : ""
                }
                 maxLength={100}
                  autoComplete="play-username"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
             
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900"
            >
           Google Play Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="googlePlayPassword"
                value={
                  projectData?.settings?.appstore?.googlePlayPassword ? projectData?.settings?.appstore?.googlePlayPassword : ""
                }
                 maxLength={100}
                  autoComplete="play-password"
                onChange={handleOnChange}
                noValidate
                disabled={projectDisabled}
                aria-describedby="domain-settings"
              
                 
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
                invalid:border-red-500 invalid:text-red-600
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
          </div>

          

        
        </div>
        </dl>
      </div>
   </>
  )
}

export default Android