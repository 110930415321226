import { useNavigate } from "react-router-dom";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



const links = [
  {
    id: 1,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },
  {
    id: 2,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },

  {
    id: 3,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },

  {
    id: 4,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },
  {
    id: 5,
    title: "Link 1",
    source: "leslie.alexander@example.com",
  },
];
function Help({ projectData, handleOnChange, projectDisabled, handleSave }) {

  const navigate = useNavigate();
  
  return (
    <>
      <form>
        <div className="px-4 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pb-20 max-w-7xl">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-slate-900">
                {" "}
                Help
              </h2>
              <p className="mt-1 text-sm leading-6 text-slate-500">
                Tailor your softphone's support resources to your specific needs
                and access assistance effortlessly.
              </p>

              <div className="mt-6 space-y-6 divide-y divide-slate-100 border-t border-slate-200 text-sm leading-6">
                <ul role="list" className="divide-y divide-slate-100">
                  {links.map((link, index) => (
                    <li key={link.id}>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium leading-6 text-slate-900"
                          >
                            Help Title {link.id}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              disabled={projectDisabled}
                              name={[`title${index + 1}`]}
                              aria-describedby="help-title-input"
                              value={
                                projectData?.settings?.miscellaneous[ `title${index + 1}`
                                ]
                                  ? projectData?.settings.miscellaneous[
                                      `title${index + 1}`
                                    ]
                                  : ""
                              }
                              maxLength={100}
                              autoComplete="link-title"
                              onChange={handleOnChange}
                              id="first-name"
                              className={classNames(
                                `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                            focus:outline-none focus:border-iceberg-500 focus:ring-1 focus:ring-iceberg-500
                            invalid:border-red-500 invalid:text-red-600
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            focus:invalid:border-red-500 focus:invalid:ring-red-500`
                              )}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium leading-6 text-slate-900"
                          >
                            Help Link {link.id}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              disabled={projectDisabled}
                              name={[`link${index + 1}`]}
                              aria-describedby="help-link-input"
                              value={
                                projectData?.settings?.miscellaneous[`link${index + 1}`
                                ]
                                  ? projectData?.settings.miscellaneous[
                                      `link${index + 1}`
                                    ]
                                  : ""
                              }
                               maxLength={100}
                              autoComplete="link-id"
                              onChange={handleOnChange}
                              id="first-name"
                             
                              className={classNames(
                                `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
                            focus:outline-none focus:border-iceberg-500 focus:ring-1 focus:ring-iceberg-500
                            invalid:border-red-500 invalid:text-red-600
                            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                            focus:invalid:border-red-500 focus:invalid:ring-red-500`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              type="button"
              onClick={(e) => projectData ? navigate(`/projects/${projectData.accountName}`) : navigate(`/login`)}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50"
            >
              Back
            </button>
            <button
              type="button"
              disabled={projectDisabled}
              onClick={handleSave}
              className={classNames(projectDisabled ? "" : `hover:bg-iceberg-300 focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2 focus-visible:outline-iceberg-400`, `rounded-md bg-iceberg-400 px-3 py-2 text-sm font-semibold 
              text-white shadow-sm disabled:opacity-25`)}
            >
              Save
            </button>
          </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Help;
