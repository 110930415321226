import React, { useState, useEffect, useRef } from "react";
import Container from "../components/containers/Container";
import Title from "../components/titles/Title";
import AppLayout from "../components/layout/AppLayout";
import {  useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openErrorAlert } from "../redux/actions/alertActions"; 

import {
    getUser,
    getInvoicePDF, getInvoices
  } from "../redux/actions/userActions";
  import Invoices from "../components/account/Invoices";
  import EmptyState from "../components/common/EmptyState";
  import Pagination from "../components/common/Pagination";

export default function SubscriptionHistory() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const isMountedRef = useRef(true);
  const isMountedRefTwo = useRef(true);
  const isMountedRefThree = useRef(true);
  const isErrorRef = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { user,  error } = useSelector(
    (state) => state.getUser
  );

  const {
    invoices,
  
    error: iError,
  } = useSelector((state) => state.getInvoices);

  const {
    pdf,
  
    error: pdfError,
  } = useSelector((state) => state.getInvoicePDF);

  useEffect(() => {
    if ((user == undefined || user == {}) && isMountedRef) {
      // Dispatch your action when the component mounts
      dispatch(getUser());
      isMountedRef.current = false; // Set the ref to false to prevent further dispatches
    }

    if(invoices == undefined && isMountedRefTwo){
      dispatch(getInvoices(id));
      isErrorRef.current = true;
      isMountedRefTwo.current = false;
    }

    if ((error || pdfError || iError) && isErrorRef.current) {
      isErrorRef.current = false;
      dispatch(openErrorAlert("Error","Something went wrong, please try again"));
     
    }

    console.log("invoices");
    console.log(invoices);

    if (pdf != {} && pdf != undefined && isMountedRefThree.current) {
      isMountedRefThree.current = false;
      downloadPDF(pdf);
    }
  }, [user, error, pdf, invoices, pdfError, iError]);

  function handleGetPDF(invoiceId) {
    isMountedRefThree.current = true;
    isErrorRef.current = true;
    dispatch(getInvoicePDF(invoiceId));
  }

  function downloadPDF(document) {
    try{
      window.location.assign(document.download.download_url);
    }catch(error){
      
      dispatch(openErrorAlert("Error","Download failed, please try again"));  
    }
    
  }

  function handleNewPage(page) {
    console.log("new page" + page);
    setCurrentPage(page);
  }

  return (
    <>
      <AppLayout />
      <Container>
        <Title title={"Subscription History"} />

        {invoices?.list?.length > 0 ? (
          <Invoices
          
          checks={invoices.list}
            handleDownload={handleGetPDF}
            appName={id}
          />
        ) : (
          <EmptyState
            title={"No Invoices"}
            description={"Please create a project"}
          />
        )}

        {invoices?.list?.length > 0 ? (
          <Pagination
            totalPages={"1"}
            currentPage={currentPage}
            handleNewPage={handleNewPage}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
