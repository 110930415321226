import React from "react";

import { useNavigate } from 'react-router-dom';

const stages = {
  Prepare: "text-blue-700 bg-blue-100 ",
  Cancelled: "text-red-700 bg-red-100 ",
  Live: "text-green-700 bg-green-100 ",
  Submitted: "text-violet-700 bg-violet-50 ",
  Approved: "text-lime-700 bg-lime-100 ",
  Update: "text-teal-700 bg-teal-100 ",
  Trial: "text-gray-700 bg-gray-100 ",
  Rejected: "text-pink-700 bg-pink-100",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getPlanName(plan) {
  switch (plan) {
    case "sessioncloud_vvs":
      return "Generic VVS";
    case "sessioncloud_business":
      return "Generic Business";
    case "sessioncloud_secure":
      return '"Generic Secure';
    case "sessioncloud_all":
      return "Generic";
    case "generic-platforms":
      return "Generic";
    case "whitelabel_basic":
      return "Whitelabel Basic";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
      case "whitelabel-windows":
      return "Whitelabel Desktop";
    case "whitelabel_desktop":
      return "Whitelabel Desktop";
      case "whitelabel-windows-annual":
      return "Whitelabel Desktop";
    case "whitelabel_premium":
      return "Whitelabel Premium";
      case "whitelabel_mobile":
      return "Whitelabel Mobile";
      case "whitelabel-mobile-annual":
      return "Whitelabel Premium";
    case "whitelabel_advanced":
      return "Whitelabel Advanced";
    default:
      return "Generic";
  }
}

function LiveApps({ projects, handleClick }) {
  const navigate = useNavigate();

  return (
    <>
      <div className=" flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full border-separate border-spacing-y-4 ">
              <thead>
                <tr className="rounded-md shadow border border-slate-200">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-3"
                  >
                    <span className="sr-only">Logo</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Project
                  </th>
                  {/* <th
                    scope="col"
                    className="hidden  px-3 py-3.5 text-left text-sm font-semibold text-slate-900 sm:table-cell"
                  >
                    Price
                  </th> */}
                  <th
                    scope="col"
                    className="hidden  px-3 py-3.5 text-left text-sm font-semibold text-slate-900 sm:table-cell"
                  >
                    Creation Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Num Users
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody className=" bg-white">
                {projects.map((project) => (
                  <tr
                    key={project.id} 
                    className="rounded-md shadow border border-slate-200 "
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-0">
                    <div className="flex">
                      <img
                        className="h-10 w-auto flex-shrink-0 rounded bg-slate-100 lg:ml-6 md:ml-4 sm:ml-4"
                        src={project?.logo ? project?.logo : null}
                        alt=""
                      />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
                      <div className="text-slate-900 font-medium ">
                        {project.accountName ? project.accountName : ""}
                      </div>
                      <div className="mt-1 text-slate-500">{project.plan ? getPlanName(project.plan) : ""}</div>
                    </td>
                    
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500 sm:table-cell">
                      {project.createdAt ? project.createdAt : ""}
                    </td>
                  

                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
                      {project.numUsers ? project.numUsers : "0"}
                    </td>
                   
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
                      <span
                        className={classNames(
                           project.status ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100",
                          "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium  lg:mr-6 md:mr-4 sm:mr-4"
                        )}
                      >
                        {project.status ? "Active": "Inactive"}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium">
                    <a
                          onClick={(e) =>  handleClick(e, project)}
                          className="text-iceberg-400 hover:text-iceberg-300 cursor-pointer"
                        >
                          Details
                          <span className="sr-only">, {project.accountName}</span>
                        </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveApps;
