export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const  GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const  GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const  UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const  GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";
export const  GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const  GET_INVOICES_FAIL = "GET_INVOICES_FAIL";
export const  GET_INVOICE_PDF_REQUEST = "GET_INVOICE_PDF_REQUEST";
export const  GET_INVOICE_PDF_SUCCESS = "GET_INVOICE_PDF_SUCCESS";
export const  GET_INVOICE_PDF_FAIL = "GET_INVOICE_PDF_FAIL";

export const REACTIVATE_APP_REQUEST = "REACTIVATE_APP_REQUEST";
export const  REACTIVATE_APP_SUCCESS = "REACTIVATE_APP_SUCCESS";
export const  REACTIVATE_APP_FAIL = "REACTIVATE_APP_FAIL";

export const REACTIVATE_SESSIONCLOUD_REQUEST = "REACTIVATE_SESSIONCLOUD_REQUEST";
export const  REACTIVATE_SESSIONCLOUD_SUCCESS = "REACTIVATE_SESSIONCLOUD_SUCCESS";
export const  REACTIVATE_SESSIONCLOUD_FAIL = "REACTIVATE_SESSIONCLOUD_FAIL";

export const REACTIVATE_WHITELABEL_REQUEST = "REACTIVATE_WHITELABEL_REQUEST";
export const  REACTIVATE_WHITELABEL_SUCCESS = "REACTIVATE_WHITELABEL_SUCCESS";
export const  REACTIVATE_WHITELABEL_FAIL = "REACTIVATE_WHITELABEL_FAIL";

export const DEACTIVATE_WHITELABEL_REQUEST = "DEACTIVATE_WHITELABEL_REQUEST";
export const  DEACTIVATE_WHITELABEL_SUCCESS = "DEACTIVATE_WHITELABEL_SUCCESS";
export const  DEACTIVATE_WHITELABEL_FAIL = "DEACTIVATE_WHITELABEL_FAIL";

export const DEACTIVATE_SESSIONCLOUD_REQUEST = "DEACTIVATE_SESSIONCLOUD_REQUEST";
export const  DEACTIVATE_SESSIONCLOUD_SUCCESS = "DEACTIVATE_SESSIONCLOUD_SUCCESS";
export const  DEACTIVATE_SESSIONCLOUD_FAIL = "DEACTIVATE_SESSIONCLOUD_FAIL";


export const  DEACTIVATE_APP_REQUEST = "DEACTIVATE_APP_REQUEST";
export const  DEACTIVATE_APP_SUCCESS = "DEACTIVATE_APP_SUCCESS";
export const  DEACTIVATE_APP_FAIL = "DEACTIVATE_APP_FAIL";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const  GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const  GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";
export const GET_CUSTOMER_DETAILS_REQUEST = "GET_CUSTOMER_DETAILS_REQUEST";
export const  GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS";
export const  GET_CUSTOMER_DETAILS_FAIL = "GET_CUSTOMER_DETAILS_FAIL";
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const  GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const  GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";


export const CREATE_ESTIMATE_REQUEST = "CREATE_ESTIMATE_REQUEST";
export const  CREATE_ESTIMATE_SUCCESS = "CREATE_ESTIMATE_SUCCESS";
export const  CREATE_ESTIMATE_FAIL = "CREATE_ESTIMATE_FAIL";
export const UPDATE_ESTIMATE_REQUEST = "UPDATE_ESTIMATE_REQUEST";
export const  UPDATE_ESTIMATE_SUCCESS = "UPDATE_ESTIMATE_SUCCESS";
export const  UPDATE_ESTIMATE_FAIL = "UPDATE_ESTIMATE_FAIL";
export const CHECKOUT_EXISTING_REQUEST = "CHECKOUT_EXISTING_REQUEST";
export const  CHECKOUT_EXISTING_SUCCESS = "CHECKOUT_EXISTING_SUCCESS";
export const  CHECKOUT_EXISTING_FAIL = "CHECKOUT_EXISTING_FAIL";
export const RETRIEVE_PAGE_REQUEST = "RETRIEVE_PAGE_REQUEST";
export const  RETRIEVE_PAGE_SUCCESS = "RETRIEVE_PAGE_SUCCESS";
export const  RETRIEVE_PAGE_FAIL = "RETRIEVE_PAGE_FAIL";
export const CHECKOUT_NEW_REQUEST = "CHECKOUT_NEW_REQUEST";
export const  CHECKOUT_NEW_SUCCESS = "CHECKOUT_NEW_SUCCESS";
export const  CHECKOUT_NEW_FAIL = "CHECKOUT_NEW_FAIL";

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const  UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const  UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL";

export const RETRIEVE_INVOICE_REQUEST = "RETRIEVE_INVOICE_REQUEST";
export const  RETRIEVE_INVOICE_SUCCESS = "RETRIEVE_INVOICE_SUCCESS";
export const  RETRIEVE_INVOICE_FAIL = "RETRIEVE_INVOICE_FAIL";

export const CHECK_ADMIN_USER_REQUEST = "CHECK_ADMIN_USER_REQUEST";
export const  CHECK_ADMIN_USER_SUCCESS = "CHECK_ADMIN_USER_SUCCESS";
export const  CHECK_ADMIN_USER_FAIL = "CHECK_ADMIN_USER_FAIL";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const  SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const  SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

