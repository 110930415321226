import React, { useEffect } from 'react'
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExternalProvisioningCredentials = ({externalprovString,setExternalprovString}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
   
  } 


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  } 

  useEffect(() => { 
    let extstr = `${username}:${password}`;
    setExternalprovString(extstr);
    console.log(extstr);
  }, [username, password]);

  return (
    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-2 border-dashed border-slate-300 px-6 py-6">
   
   <div className="sm:col-span-3 ">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="sip-username"
                placeholder="Username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
                minLength={1}
                maxLength="64"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
        invalid:border-red-500 invalid:text-red-600
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p className="mt-2 text-sm text-slate-500" id="email-description">
              Required
            </p>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-slate-900 after:content-['*'] after:ml-0.5 after:text-red-500"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                minLength={1}
                maxLength="64"
                className={classNames(
                  `block w-full  py-1 bg-white border border-slate-300 rounded-md sm:text-sm sm:leading-6 shadow-sm placeholder-slate-400
        focus:outline-none focus:border-iceberg-400 focus:ring-1 focus:ring-iceberg-400
        invalid:border-red-500 invalid:text-red-600
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        focus:invalid:border-red-500 focus:invalid:ring-red-500`
                )}
              />
            </div>
            <p className="mt-2 text-sm text-slate-500" id="email-description">
              Required
            </p>
          </div>

    </div>
  )
}

export default ExternalProvisioningCredentials