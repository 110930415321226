import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_WHITELABEL_LIVE_APPS_REQUEST,
  GET_WHITELABEL_LIVE_APPS_SUCCESS,
  GET_WHITELABEL_LIVE_APPS_FAIL,
  GET_SESSIONCLOUD_LIVE_APPS_REQUEST,
  GET_SESSIONCLOUD_LIVE_APPS_SUCCESS,
  GET_SESSIONCLOUD_LIVE_APPS_FAIL,
  GET_PROJECTS_COUNT_REQUEST,
  GET_PROJECTS_COUNT_SUCCESS,
  GET_LIVE_APPS_COUNT_REQUEST,
  GET_LIVE_APPS_COUNT_SUCCESS,
  GET_LIVE_APPS_COUNT_APPS_FAIL,
  GET_PROJECTS_COUNT_APPS_FAIL,
  GET_PROJECT,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  CHECK_PROJECT_REQUEST,
  CHECK_PROJECT_SUCCESS,
  CHECK_PROJECT_FAIL,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_BILLING_REQUEST,
  GET_PROJECT_BILLING_SUCCESS,
  GET_PROJECT_BILLING_FAIL,
  SUBMIT_APPROVAL_REQUEST,
  SUBMIT_APPROVAL_SUCCESS,
  SUBMIT_APPROVAL_FAIL,
  GET_ADMIN_PROJECTS_FAIL,
  GET_ADMIN_PROJECTS_SUCCESS,
  GET_ADMIN_PROJECTS_REQUEST,
  GET_RULE,
  CREATE_NEW_VERSION_REQUEST,
  CREATE_NEW_VERSION_SUCCESS,
  CREATE_NEW_VERSION_FAIL,
  APPROVE_PROJECT_REQUEST,
  APPROVE_PROJECT_SUCCESS,
  APPROVE_PROJECT_FAIL,
  REJECT_PROJECT_REQUEST,
  REJECT_PROJECT_SUCCESS,
  REJECT_PROJECT_FAIL,
  ACTIVATE_TRIAL_FAIL,
  ACTIVATE_TRIAL_REQUEST,
  ACTIVATE_TRIAL_SUCCESS,
  MAKE_LIVE_FAIL,
  MAKE_LIVE_REQUEST,
  MAKE_LIVE_SUCCESS,
  GET_GRAPHICS_REQUEST,
  GET_GRAPHICS_FAIL,
  GET_GRAPHICS_SUCCESS,
  GET_GRAPHICS_CLEAR,
  SAVE_GRAPHICS_REQUEST,
  SAVE_GRAPHICS_SUCCESS,
  SAVE_GRAPHICS_FAIL,
  SAVE_FILE_REQUEST,
  SAVE_FILE_SUCCESS,
  SAVE_FILE_FAIL,
} from "../constants/projectConstants";
import { extractUser } from "./userActions";

import Parse from "parse/dist/parse.min.js";

function extractProject(result) {
  const { ...fields } = result.attributes;
  const project = {
    ...fields,
  };
  project.id = result.id;
  project.user = undefined;
  const user = result.get("user");
  if (user != undefined) {
    project.email = user.get("email");
    //project.user = extractUser(user);
  }

 // const wingraphics = result.get("operatorwingraphics");
 // if( wingraphics != undefined) {
  //  project.logo = wingraphics.get("logo92_36");
  //}
  
  const iosdistcert = result.get("iosdistcert");
  project.iosdistcert = iosdistcert?._name;
  const macdistcert = result.get("macdistcert");
  project.macdistcert = macdistcert?._name;
  const signingkey = result.get("signingkey");
  project.signingkey = signingkey?._name;
  const googleservices = result.get("googleservices");
  project.googleservices = googleservices?._name;
  const msg_pprofile = result.get("msg_pprofile");
  project.msg_pprofile = msg_pprofile?._name;
  const pprofile = result.get("pprofile");
  project.pprofile = pprofile?._name;
  const wincert = result.get("wincert");
  project.wincert = wincert?._name; //result.get("wincert")
  let cDate = new Date(result.get("createdAt"));
  project.createdAt = cDate.toLocaleString();
  let uDate = new Date(result.get("updatedAt"));
  project.updatedAt = uDate.toLocaleString();
  project.ACL = undefined;
  // Leave this is, it fixes errors with redux and parse objects
  project.operatorandroidgraphics = undefined;
  project.operatoriosgraphics = undefined;
  project.operatorwingraphics = undefined;
  project.whitelabelandroidgraphics = undefined;
  project.whitelabeliosgraphics = undefined;
  project.whitelabelwingraphics = undefined;
  project.whitelabelmacgraphics = undefined;
  project.logo =  undefined;

  return project;
}

function extractApp(result) {
  const project = {};
  project.id = result.id;
  project.accountName = result.get("accountName");
  project.type = result.get("type");
  let cDate = new Date(result.get("createdAt"));
  project.createdAt = cDate.toLocaleString();
  let uDate = new Date(result.get("updatedAt"));
  project.updatedAt = uDate.toLocaleString();
  let users = result.get("users");
  project.numUsers = users.length;
  let gUsers = result.get("g729users");
  project.numG729 = gUsers.length;
  project.status = result.get("licensed");

  const logo = result.get("logo");
  if( logo != undefined) {  
    project.logo = result.get("logo")?.url();
  }
  return project;
}

function extractWhitelabelMacGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");
  graphics.completion = result.get("completion");
  graphics.icon16 = result.get("icon16")
    ? extractImage(result.get("icon16"))
    : undefined;
  graphics.icon32 = result.get("icon32")
    ? extractImage(result.get("icon32"))
    : undefined;
  graphics.icon64 = result.get("icon64")
    ? extractImage(result.get("icon64"))
    : undefined;
  graphics.icon128 = result.get("icon128")
    ? extractImage(result.get("icon128"))
    : undefined;
  graphics.icon256 = result.get("icon256")
    ? extractImage(result.get("icon256"))
    : undefined;
  graphics.icon512 = result.get("icon512")
    ? extractImage(result.get("icon512"))
    : undefined;
  graphics.icon1024 = result.get("icon1024")
    ? extractImage(result.get("icon1024"))
    : undefined;
  graphics.logo92_36 = result.get("logo92_36")
    ? extractImage(result.get("logo92_36"))
    : undefined;
  graphics.platform = "mac";
  return graphics;
}

function extractWhitelabelWinGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");

  graphics.ico = result.get("ico")
    ? extractImage(result.get("ico"))
    : undefined;
 
 
  graphics.icon24 = result.get("icon24")
    ? extractImage(result.get("icon24"))
    : undefined;
  graphics.icon44 = result.get("icon44")
    ? extractImage(result.get("icon44"))
    : undefined;

    graphics.icon50 = result.get("icon50")
    ? extractImage(result.get("icon50"))
    : undefined;
  graphics.icon88 = result.get("icon88")
    ? extractImage(result.get("icon88"))
    : undefined;
  graphics.icon48 = result.get("icon48")
    ? extractImage(result.get("icon48"))
    : undefined;
    graphics.widetile620_300 = result.get("widetile620_300")
    ? extractImage(result.get("widetile620_300"))
    : undefined;
    graphics.largetile620_620 = result.get("largetile620_620")
    ? extractImage(result.get("largetile620_620"))
    : undefined;
 
  graphics.logo92_36 = result.get("logo92_36")
    ? extractImage(result.get("logo92_36"))
    : undefined;
  graphics.platform = "win";
  return graphics;
}

function extractWhitelabeliOSGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");

  graphics.icon57 = result.get("icon57")
    ? extractImage(result.get("icon57"))
    : undefined;
  graphics.icon114 = result.get("icon114")
    ? extractImage(result.get("icon114"))
    : undefined;
  graphics.icon120 = result.get("icon120")
    ? extractImage(result.get("icon120"))
    : undefined;
  graphics.icon180 = result.get("icon180")
    ? extractImage(result.get("icon180"))
    : undefined;
  graphics.icon1024 = result.get("icon1024")
    ? extractImage(result.get("icon1024"))
    : undefined;
 
  graphics.logo200_60 = result.get("logo200_60")
    ? extractImage(result.get("logo200_60"))
    : undefined;
  graphics.logo400_120 = result.get("logo400_120")
    ? extractImage(result.get("logo400_120"))
    : undefined;

  graphics.logo600_180 = result.get("logo600_180")
    ? extractImage(result.get("logo600_180"))
    : undefined;
  graphics.platform = "ios";
  return graphics;
}

function extractWhitelabelAndroidGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");

  graphics.icon36 = result.get("icon36")
    ? extractImage(result.get("icon36"))
    : undefined;
  graphics.icon48 = result.get("icon48")
    ? extractImage(result.get("icon48"))
    : undefined;
  graphics.icon72 = result.get("icon72")
    ? extractImage(result.get("icon72"))
    : undefined;
  graphics.icon96 = result.get("icon96")
    ? extractImage(result.get("icon96"))
    : undefined;
  graphics.icon144 = result.get("icon144")
    ? extractImage(result.get("icon144"))
    : undefined;
  graphics.icon192 = result.get("icon192")
    ? extractImage(result.get("icon192"))
    : undefined;
  
    
  graphics.logo200_96 = result.get("logo200_96")
    ? extractImage(result.get("logo200_96"))
    : undefined;
  graphics.logo280_134 = result.get("logo280_134")
    ? extractImage(result.get("logo280_134"))
    : undefined;
  graphics.logo900_576 = result.get("logo900_576")
    ? extractImage(result.get("logo900_576"))
    : undefined;
  graphics.platform = "android";
  return graphics;
}

function extractAndroidGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");

  graphics.logo200_96 = result.get("logo200_96")
    ? extractImage(result.get("logo200_96"))
    : undefined;
  graphics.logo280_134 = result.get("logo280_134")
    ? extractImage(result.get("logo280_134"))
    : undefined;
  graphics.logo400_240 = result.get("logo400_240")
    ? extractImage(result.get("logo400_240"))
    : undefined;
  graphics.logo600_384 = result.get("logo600_384")
    ? extractImage(result.get("logo600_384"))
    : undefined;
  graphics.logo900_576 = result.get("logo900_576")
    ? extractImage(result.get("logo900_576"))
    : undefined;
 

  graphics.logoTextColor = result.get("logoTextColor");
  graphics.logoBackgroundColor = result.get("logoBackgroundColor");
  graphics.platform = "android";
  return graphics;
}

function extractiOSGraphics(result) {
  const graphics = {};
  graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");

  graphics.logo200_60 = result.get("logo200_60")
    ? extractImage(result.get("logo200_60"))
    : undefined;
  graphics.logo400_120 = result.get("logo400_120")
    ? extractImage(result.get("logo400_120"))
    : undefined;
  graphics.logo600_180 = result.get("logo600_180")
    ? extractImage(result.get("logo600_180"))
    : undefined;
  
  graphics.platform = "ios";
  return graphics;
}

function extractMacGraphics(result) {
  const graphics = {};
  // graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");
  graphics.logo92_36 = result.get("logo92_36")
    ? extractImage(result.get("logo92_36"))
    : undefined;
 graphics.icon1024  = result.get("icon1024")  
 ? extractImage(result.get("icon1024"))
    : undefined;
    graphics.icon128  = result.get("icon128")  
 ? extractImage(result.get("icon128"))
    : undefined;
    graphics.icon16  = result.get("icon16")  
    ? extractImage(result.get("icon16"))
       : undefined;

  graphics.platform = "mac";
  return graphics;


}

function extractDesktopGraphics(result) {
  const graphics = {};
  // graphics.id = result.id;
  graphics.accountName = result.get("accountName");
  graphics.type = result.get("type");

  graphics.status = result.get("status");
  graphics.logo92_36 = result.get("logo92_36")
    ? extractImage(result.get("logo92_36"))
    : undefined;
 graphics.ico  = result.get("ico")  
 ? extractImage(result.get("logo92_36"))
    : undefined;

  graphics.platform = "win";
  return graphics;
}

function extractImage(result) {
  const image = {};
  image.name = result._name;
  image.url = result._url;
  return image;
}

function extractDate(result) {}

function extractBasicUser(result){

  const basicUser = {};
  basicUser.id = result.get("id");
  basicUser.email = result.get("email");

}

export const getProjects = (page, displaylimit) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECTS_REQUEST,
    });

    const results = await Parse.Cloud.run("getApplications", {
      page: page,
      limit: displaylimit,
    });
    const projects = [];

    results.forEach((result) => {
      const project = extractProject(result);
      //console.log(project.user);

      projects.push(project);
    });

    dispatch({
      type: GET_PROJECTS_SUCCESS,
      payload: projects,
    });
  } catch (error) {
    console.log("GET_PROJECTS_FAIL");
    console.log(error);
    // dispatch error message
    dispatch({
      type: GET_PROJECTS_FAIL,
      payload: error,
    });
  }
};

export const getWhitelabelLiveApps =
  (page, displaylimit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_WHITELABEL_LIVE_APPS_REQUEST,
      });

      const results = await Parse.Cloud.run("whitelabelLiveApps", {
        page: page,
        limit: displaylimit,
      }); //query.find();
      const projects = [];

      console.log(results);

      results.forEach((result) => {
        const project = extractApp(result);
        projects.push(project);
      });

      dispatch({
        type: GET_WHITELABEL_LIVE_APPS_SUCCESS,
        payload: projects,
      });
    } catch (error) {
      console.log("GET_WHITELABEL_LIVE_APPS_FAIL");
      console.log(error);
      // dispatch error message
      dispatch({
        type: GET_WHITELABEL_LIVE_APPS_FAIL,
        payload: error,
      });
    }
  };

export const getSessionCloudLiveApps =
  (page, displaylimit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SESSIONCLOUD_LIVE_APPS_REQUEST,
      });

      const results = await Parse.Cloud.run("sessioncloudLiveApps", {
        page: page,
        limit: displaylimit,
      }); //query.find();
      const projects = [];

      console.log(results);

      results.forEach((result) => {
        const project = extractApp(result);
        projects.push(project);
      });

     

      dispatch({
        type: GET_SESSIONCLOUD_LIVE_APPS_SUCCESS,
        payload: projects,
      });
    } catch (error) {
      console.log("GET_SESSIONCLOUD_LIVE_APPS_FAIL");
      console.log(error);
      // dispatch error message
      dispatch({
        type: GET_SESSIONCLOUD_LIVE_APPS_FAIL,
        payload: error,
      });
    }
  };

export const getProjectsCount = (sortAndFilter) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECTS_COUNT_REQUEST,
    });

    const parameters = {};

    if (sortAndFilter != undefined) {
      if (sortAndFilter.search != undefined && sortAndFilter.search != "") {
        parameters.search = sortAndFilter.search;
      }

      if (sortAndFilter.stages.length > 0) {
        parameters.stages = sortAndFilter.stages;
      }

      if (sortAndFilter.plans.length > 0) {
        parameters.plans = sortAndFilter.plans;
      }

      if (sortAndFilter.progress) {
        parameters.progress = sortAndFilter.progress;
      }
    }

    const length = await Parse.Cloud.run("getAppCount", parameters);

    dispatch({
      type: GET_PROJECTS_COUNT_SUCCESS,
      payload: length,
    });
  } catch (error) {
    console.log("GET_PROJECTS_COUNT_APPS_FAIL");
    console.log(error);
    // dispatch error message
    dispatch({
      type: GET_PROJECTS_COUNT_APPS_FAIL,
      payload: error,
    });
  }
};

export const getLiveAppsCount = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIVE_APPS_COUNT_REQUEST,
    });

    const nbSessionCloud = await Parse.Cloud.run("getSessionCloudCount");
    const nbWhitelabel = await Parse.Cloud.run("getWhitelabelCount");

    dispatch({
      type: GET_LIVE_APPS_COUNT_SUCCESS,
      payload: nbSessionCloud + nbWhitelabel,
    });
  } catch (error) {
    console.log(error);
   
    // dispatch error message
    dispatch({
      type: GET_LIVE_APPS_COUNT_APPS_FAIL,
      payload: error,
    });
  }
};

export const getProject = (project) => {
  return {
    type: GET_PROJECT,
    payload: project,
  };
};

export const getAccount = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACCOUNT_REQUEST,
    });

    dispatch({
      type: GET_ACCOUNT_SUCCESS,
      payload: {},
    });
  } catch (error) {
    dispatch({
      type: GET_ACCOUNT_FAIL,
      payload: error,
    });
  }
};

export const getProjectById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECT_REQUEST,
    });

    const application = Parse.Object.extend("Application");
    const query = new Parse.Query(application);

    //query.equalTo("id", id.id);

    const result = await query.get(id);

    if (result === undefined || result == [] || result == {}) {
      dispatch({
        type: GET_PROJECT_FAIL,
        payload: "Project not found",
      });

      return;
    }
    const project = extractProject(result);
    const basicUser = extractBasicUser(project.user);
    project.user = basicUser;

    console.log(project);

    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: error.code,
    });
  }
};

export const getProjectByName = (accountName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECT_REQUEST,
    });

    const result = await Parse.Cloud.run("getProject", {
      appName: accountName,
    });

    if (result === undefined || result == [] || result == {}) {
      dispatch({
        type: GET_PROJECT_FAIL,
        payload: "Project not found",
      });

      return;
    }
    const project = extractProject(result);

    console.log(project);

    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: error.code,
    });
  }
};

export const checkProjectName = (name) => async (dispatch) => {
  try {
    dispatch({
      type: CHECK_PROJECT_REQUEST,
    });

    const nameExists = await Parse.Cloud.run("checkAppName", { appName: name });

    dispatch({
      type: CHECK_PROJECT_SUCCESS,
      payload: nameExists,
    });
  } catch (error) {
    dispatch({
      type: CHECK_PROJECT_FAIL,
      payload: error,
    });
  }
};

export const createProject = (name, plan) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PROJECT_REQUEST,
    });

    const app = await Parse.Cloud.run("createApp", {
      accountName: name,
      type: plan,
    });

    if (app === undefined) {
      dispatch({
        type: CREATE_PROJECT_FAIL,
        payload: "App in undefined",
      });

      return;
    }

    dispatch({
      type: CREATE_PROJECT_SUCCESS,
      payload: {},
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROJECT_FAIL,
      payload: error,
    });
  }
};

export const updateProject = (project) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROJECT_REQUEST,
    });

      const something = await Parse.Cloud.run("updateSettings", {
        appName: project.accountName,
        settings: project.settings,
        operatorCode: project.operatorcode,
      });
    

    dispatch({
      type: UPDATE_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROJECT_FAIL,
      payload: error,
    });
  }
};

export const getRule = (rule) => {
  return {
    type: GET_RULE,
    payload: rule,
  };
};

export const deleteProject = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PROJECT_REQUEST,
    });

    if (projectName === "" || projectName === undefined) {
      return;
    }
    const success = await Parse.Cloud.run("deleteProject", {
      appName: projectName,
    });

    if (success == {}) {
      dispatch({
        type: DELETE_PROJECT_FAIL,
        payload: "resp is not defined",
      });
    }

    dispatch({
      type: DELETE_PROJECT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PROJECT_FAIL,
      payload: error,
    });
  }
};

export const getProjectBilling = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECT_BILLING_REQUEST,
    });

    const result = await Parse.Cloud.run("retrieveSubscription", {
      appName: projectName,
    });

    if (result == undefined) {
      dispatch({
        type: GET_PROJECT_BILLING_FAIL,
        payload: "Project has not been found",
      });

      return;
    }

    dispatch({
      type: GET_PROJECT_BILLING_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: GET_PROJECT_BILLING_FAIL,
      payload: error,
    });
  }
};

export const submitApprovalProject = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_APPROVAL_REQUEST,
    });

    const result = await Parse.Cloud.run("submitApproval", {
      appName: projectName,
    });

    if (result == undefined || result == {}) {
      dispatch({
        type: SUBMIT_APPROVAL_FAIL,
        payload: "Error. Admin user not project's user",
      });
    }

    dispatch({
      type: SUBMIT_APPROVAL_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: SUBMIT_APPROVAL_FAIL,
      payload: error,
    });
  }
};

export const getAdminProjects =
  (page, displaylimit, sortAndFilter) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ADMIN_PROJECTS_REQUEST,
      });

      const parameters = {};

      parameters.page = page;
      parameters.limit = displaylimit;

      if (sortAndFilter != undefined) {
        if (sortAndFilter.search != undefined && sortAndFilter.search != "") {
          parameters.search = sortAndFilter.search;
        }

        if (sortAndFilter.stages.length > 0) {
          parameters.stages = sortAndFilter.stages;
        }

        if (sortAndFilter.plans.length > 0) {
          parameters.plans = sortAndFilter.plans;
        }

        if (sortAndFilter.progress) {
          parameters.progress = sortAndFilter.progress;
        }

        if (sortAndFilter.sort) {
          parameters.sort = sortAndFilter.sort;
        }
      }

      const results = await Parse.Cloud.run("getAdminApplications", parameters);

      const projects = [];

      results.forEach((result) => {
        const project = extractProject(result);

        console.log(project.user);

        projects.push(project);
      });

      dispatch({
        type: GET_ADMIN_PROJECTS_SUCCESS,
        payload: projects,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_PROJECTS_FAIL,
        payload: error,
      });
    }
  };

export const approveProject =
  (projectName, email, version) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVE_PROJECT_REQUEST,
      });

      let result = {};
      // not been converted yet
      if (version > 1) {
        result = await Parse.Cloud.run("approveVersionedProject", {
          appName: projectName,
          useremail: email,
        });
      } else {
        result = await Parse.Cloud.run("approveProject", {
          appName: projectName,
          useremail: email,
        });
      }

      if (result === undefined || result == [] || result == {}) {
        dispatch({
          type: APPROVE_PROJECT_FAIL,
          payload: "Project not found",
        });

        return;
      }

      dispatch({
        type: APPROVE_PROJECT_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: APPROVE_PROJECT_FAIL,
        payload: error,
      });
    }
  };

export const rejectProject =
  (projectName, email, version) => async (dispatch) => {
    try {
      dispatch({
        type: REJECT_PROJECT_REQUEST,
      });

      let result = {};
      // not been converted yet
      if (version > 1) {
        result = await Parse.Cloud.run("rejectVersionedProject", {
          appName: projectName,
          useremail: email,
        });
      } else {
        result = await Parse.Cloud.run("rejectProject", {
          appName: projectName,
          useremail: email,
        });
      }

      if (result === undefined || result == [] || result == {}) {
        dispatch({
          type: REJECT_PROJECT_FAIL,
          payload: "Project not found",
        });

        return;
      }

      dispatch({
        type: REJECT_PROJECT_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: REJECT_PROJECT_FAIL,
        payload: error,
      });
    }
  };

export const createNewVersionProject = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_NEW_VERSION_REQUEST,
    });

    const result = await Parse.Cloud.run("newVersion", {
      appName: projectName,
    });

    if (result === undefined || result == [] || result == {}) {
      dispatch({
        type: GET_PROJECT_FAIL,
        payload: "Project not found",
      });

      return;
    }

    dispatch({
      type: CREATE_NEW_VERSION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_VERSION_FAIL,
      payload: error,
    });
  }
};

export const activateTrialProject = (projectName, type) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_TRIAL_REQUEST,
    });

    const result = await Parse.Cloud.run("createTrialSubscription", {
      appName: projectName,
      planid: type,
      addonarray: [],
      couponid: `${type}_coupon`,
    });

    if (result === undefined || result == [] || result == {}) {
      dispatch({
        type: ACTIVATE_TRIAL_FAIL,
        payload: "Project not found",
      });

      return;
    }

    dispatch({
      type: ACTIVATE_TRIAL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATE_TRIAL_FAIL,
      payload: error,
    });
  }
};

export const moveToPaid = (projectName) => async (dispatch) => {
  try {
    dispatch({
      type: MAKE_LIVE_REQUEST,
    });

    const results = await Parse.Cloud.run("movetoPaidStatus", {
      appName: projectName,
    });

    dispatch({
      type: MAKE_LIVE_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: MAKE_LIVE_FAIL,
      payload: error,
    });
  }
};

export const getGraphics = (projectName, type) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GRAPHICS_REQUEST,
    });

    const result = await Parse.Cloud.run("getGraphics", {
      appName: projectName,
      graphics: type,
    });

    let graphicsResult;
    if (type == "whitelabelmacgraphics") {
      graphicsResult = extractWhitelabelMacGraphics(result);
    } else if (type == "whitelabelwingraphics") {
      graphicsResult = extractWhitelabelWinGraphics(result);
    } else if (type == "operatoriosgraphics") {
      graphicsResult = extractiOSGraphics(result);
    } else if (type == "operatorandroidgraphics") {
      graphicsResult = extractAndroidGraphics(result);
    } else if (type == "whitelabelandroidgraphics") {
      graphicsResult = extractWhitelabelAndroidGraphics(result);
    } else if (type == "whitelabeliosgraphics") {
      graphicsResult = extractWhitelabeliOSGraphics(result);
    } else if (type == "operatorwingraphics") {
      graphicsResult = extractDesktopGraphics(result);
    } else if (type == "operatormacgraphics") {
      graphicsResult = extractDesktopGraphics(result);
    }

    dispatch({
      type: GET_GRAPHICS_SUCCESS,
      payload: graphicsResult,
    });
  } catch (error) {
    dispatch({
      type: GET_GRAPHICS_FAIL,
      payload: error,
    });
  }
};

export const saveGraphics =
  (projectName, name, file, type) => async (dispatch) => {
    try {
      dispatch({
        type: SAVE_GRAPHICS_REQUEST,
      });

      const graphicsObject = await Parse.Cloud.run("getGraphics", {
        appName: projectName,
        graphics: type,
      });

      if(file == null) {
        graphicsObject.set(name, null);
        await graphicsObject.save();
      } else {
        const parseFile = new Parse.File(name, file);
        const result = await parseFile.save();

        // need getgraphic project result
        graphicsObject.set(name, parseFile);
        await graphicsObject.save();
      }
      dispatch({
        type: SAVE_GRAPHICS_SUCCESS,
        payload: true,
      });

    } catch (error) {
      error.name = name;
      error.type = type;
      dispatch({
        type: SAVE_GRAPHICS_FAIL,
        payload: error,
      });
    }
  };

  export const clearGraphics =
  () => async (dispatch) => {
    
      dispatch({
        type: GET_GRAPHICS_CLEAR,
      });

  };


  export const saveFile =
  (appName, filename, keyname, file) => async (dispatch) => {
    try {
      dispatch({
        type: SAVE_FILE_REQUEST,
      });

      const project = await Parse.Cloud.run('getProject', { appName: appName });

      if(file == null) {
        project.set(filename, null);
        await project.save();
      } else {

        const parseFile = new Parse.File(file.name, file);
        await parseFile.save();

        project.set(keyname, file.name);
        project.set(filename, parseFile);
        await project.save();    
      }

      dispatch({
        type: SAVE_FILE_SUCCESS,
        payload: true,
      });

    } catch (error) {
      error.name = filename;
      dispatch({
        type: SAVE_FILE_FAIL,
        payload: error,
      });
    }
  };

  
